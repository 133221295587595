import React, { Component } from "react";
import MenuOS from "../../../components/MenuOS";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "gantt-task-react/dist/index.css";
import { ProjetoEditar } from "./Component/ProjetoEditar";

class Projeto extends Component {
  handleNewItem = () => {
    this.props.history.push("/projeto/");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Projetos",
      nomeNivelCrumb: "Listar Registros",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <MenuOS />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0 ml-2">{this.state.nomePagina}</h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item active">
                            <a className="mr-2" href="/projeto">
                              {this.state.nomeNivelCrumb}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>

                    <ProjetoEditar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Projeto;
