import React from "react";
import {
  Typography,
  Tooltip,
  Grid,
  Alert,
  Snackbar,
  Box,
  IconButton,
} from "@mui/material";
import { createPortal } from "react-dom";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

export const HeadModal = ({ task }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const converteParaHoras = (totalHoras) => {
    const horas = Math.floor(totalHoras);
    return `${horas.toString().padStart(2, "0")}:00`;
  };

  const converterParaHorasMinutos = (totalMinutos) => {
    const sinal = totalMinutos < 0 ? "-" : " ";
    totalMinutos = Math.abs(totalMinutos);
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    const horasFormatadas =
      (sinal === "-" ? "-" : " ") + horas.toString().padStart(2, "0");
    const minutosFormatados = minutos.toString().padStart(2, "0");
    return sinal === "-"
      ? `(${horasFormatadas}:${minutosFormatados})`
      : `${horasFormatadas}:${minutosFormatados}`;
  };

  const formatarDatacomHora = (stringData) => {
    const data = new Date(stringData);
    return data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  };

  const rootElement = document.getElementById("root");

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#304056",
          color: "#fff",
          fontSize: 10,
          borderRadius: 2,
        }}
      >
        <Grid
          item
          container
          padding={1}
          gap={1}
          whiteSpace="nowrap"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            lg={1}
            sx={{ textAlign: { xs: "left", sm: "center" } }}
          >
            <Tooltip title="Número do chamado" followCursor>
              <Typography>{task.controleServico.codigo}</Typography>
            </Tooltip>
          </Grid>

          <Grid
            xs={5}
            sm={5}
            md={5}
            lg={3}
            sx={{
              textAlign: {
                lg: "left",
                md: "center",
                sm: "center",
                xs: "right",
              },
            }}
          >
            <Tooltip
              title={`Clique para copiar: ${task.controleServico.titulo}`}
              followCursor
            >
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick();
                  navigator.clipboard.writeText(task.controleServico.titulo);
                }}
              >
                {task.controleServico.titulo}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            lg={2}
            sx={{ textAlign: { xs: "left", sm: "center" } }}
          >
            <Tooltip title="Solicitante" followCursor>
              <Typography>{task.controleServico.userName}</Typography>
            </Tooltip>
          </Grid>

          {task.controleServico.dataGerente && (
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              lg={1}
              sx={{
                textAlign: {
                  lg: "left",
                  md: "center",
                  sm: "center",
                  xs: "right",
                },
              }}
            >
              <Tooltip title="Data de Abertura" followCursor>
                <Typography whiteSpace="nowrap">
                  {formatarDatacomHora(task.controleServico.dataGerente)}
                </Typography>
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={2} sm={5} md={5} lg={1} sx={{ textAlign: "center" }}>
            <Tooltip title="Gravidade" followCursor>
              <Typography>{task.controleServico.urgencia.descricao}</Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={2} sm={5} md={5} lg={1} sx={{ textAlign: "center" }}>
            <Tooltip title="SLA" followCursor>
              <Typography whiteSpace="nowrap" sx={{ width: "100%" }}>
                {converteParaHoras(task.controleServico.urgencia.sla)}
              </Typography>
            </Tooltip>
          </Grid>

          <Tooltip title="Hora atual" followCursor>
            <Grid item xs={2} sm={5} md={5} lg={1} sx={{ textAlign: "center" }}>
              <Typography>
                {task.controleServico.minutosRestantes < 0 &&
                  new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
              </Typography>
            </Grid>
          </Tooltip>

          <Grid item xs={2} sm={5} md={5} lg={1} sx={{ textAlign: "center" }}>
            <Tooltip
              title={
                task.controleServico.minutosRestantes < 0
                  ? "Tempo Ultrapassado"
                  : "Tempo Restante"
              }
              followCursor
            >
              <Typography>
                {converterParaHorasMinutos(
                  task.controleServico.minutosRestantes
                )}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>

        {/* <Alert severity="success"></Alert> */}
      </Grid>

      {rootElement && createPortal(
        <Snackbar
          open={open}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleClose}
          ContentProps={{
            sx: {
              backgroundColor: "success.main",
            },
          }}
          color="success"
          textAlign={"center"}
          message={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Check />
              Copiado com sucesso!
              <IconButton
                aria-label="close"
                color="inherit"
                /* className={classes.close} */
                onClick={handleClose}
              >
                <CloseIcon sx={{ marginLeft: 3 }} />
              </IconButton>
            </Box>
          }
        />,
        rootElement
      )}
    </>
  );
};
