import React, { useState } from "react";
import { Modal, CircularProgress, Box, Button, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { Close, Download } from "@mui/icons-material";

const VideoPlayer = ({ open, onClose, src, isVideo }) => {
    const [isLoadingVideo, setIsLoadingVideo] = useState(isVideo);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="video-player-title"
            aria-describedby="video-player-description"
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                minHeight: "90vh"
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    width: isMobile ? "100%" : "80%",
                    height: isMobile ? "100%" : "90%",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: isMobile ? 0 : 2,
                    p: isMobile ? 0 : 1,
                    overflow: "hidden",
                }}
            >
                {isLoadingVideo && (
                    <CircularProgress
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            color: "white",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                )}

                {/* Close Button */}
                <Box sx={{ position: "absolute", top: 16, right: 16, zIndex: 2 }}>
                    <IconButton
                        sx={{ ":hover": { backgroundColor: "rgba(255,255,255, 0.1)" }, backgroundColor: "rgba(255, 255, 255, 0.3)" }}
                        onClick={onClose}
                    >
                        <Close sx={{ color: "#fff" }} />
                    </IconButton>
                </Box>



                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    {!isVideo && (
                        <img
                            src={src}
                            alt={src}
                            style={{
                                maxWidth: "100%",
                                maxHeight: isMobile ? "100%" : "80%",
                                objectFit: "contain",
                            }}
                        />
                    )}

                    {isVideo && (<video
                        width="100%"
                        controls
                        preload="auto"
                        onLoadStart={() => setIsLoadingVideo(true)}
                        onCanPlay={() => setIsLoadingVideo(false)}
                        onError={() => onClose()}
                        style={{
                            display: isLoadingVideo ? "none" : "block",
                            objectFit: "contain",
                            maxHeight: isMobile ? "100%" : "80%",
                            borderRadius: "4px",
                        }}
                    >
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    )}

                </Box>

                <Box sx={{ position: "absolute", bottom: 16, right: 16, zIndex: 2 }}>
                    <Button color="primary" variant="contained" startIcon={<Download />} sx={{ marginRight: 1, ":hover": { color: "#e1e1e1" } }} href={src}>
                        Download
                    </Button>


                    <Button variant="contained" color="inherit" onClick={onClose}>
                        Fechar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default VideoPlayer;
