import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { AlertSuccess, AlertDanger } from "./styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";

export const ClassificacaoEditar = () => {
  let history = useHistory();
  const { id } = useParams();

  const [descricao, setDescricao] = useState("");
  const [status, setStatus] = useState("");
  const [suporte, setSuporte] = useState("");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [isFormVal, setIsFormVal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSuporteChange = (event) => {
    setSuporte(event.target.checked ? "S" : "N");
  };

  const theme = useTheme();
  const isMobile = theme.breakpoints.down("sm");

  const validateForm = () => {
    return descricao.trim() !== "" && status.trim() !== "";
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setIsFormVal(validateForm());
  }, [descricao, status]);

  /*Editar Registros*/
  const editCadastros = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const dadosJson = {
        descricao: descricao,
        status: status,
        suporte: suporte || "N",
      };

      if (id !== "0") {
        dadosJson.id = id;
        console.log("PUUUUUT ::::::", JSON.stringify(dadosJson));
        await api.put(`controle-classificacao/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        console.log("Caiu no POST ::::::", JSON.stringify(dadosJson));
        await api.post("controle-classificacao/", dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      setTimeout(() => {
        history.push({
          pathname: "/classificacao",
        });
        setIsSubmitting(false);
      }, 1000);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getClassificacao = async () => {
      await api.get(`/controle-classificacao/${id}`).then((response) => {
        setDescricao(response.data.records[0].descricao);
        setStatus(response.data.records[0].status);
        setSuporte(response.data.records[0].suporte || "N");
      });
    };

    if (id !== "0") {
      getClassificacao();
    }
  }, [id]);

  return (
    <>
      <Card>
        <CardContent>
          {statusRecord.type === "erro" ? (
            <AlertDanger>{statusRecord.mensagem}</AlertDanger>
          ) : (
            ""
          )}
          {statusRecord.type === "success" ? (
            <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
          ) : (
            ""
          )}

          <form className="form-horizontal" onSubmit={editCadastros}>
            <Box className="col-md-12 col-sm-12">
              <Typography variant="body1">Descrição</Typography>
              <textarea
                type="text"
                className="form-control"
                id="descricao"
                name="descricao"
                placeholder="Descrição..."
                onChange={(e) => setDescricao(e.target.value)}
                defaultValue={descricao}
                required="required"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                marginTop: 2,
                marginLeft: 1.5,
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ marginRight: 2 }}>
                <Typography variant="body1">Status</Typography>
                <Box>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Ativo
                  </FormLabel>
                  <Radio
                    checked={status == "1"}
                    onChange={(e) => setStatus(e.target.value)}
                    value="1"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "1" }}
                  />
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Inativo
                  </FormLabel>
                  <Radio
                    checked={status == "0"}
                    onChange={(e) => setStatus(e.target.value)}
                    value="0"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "0" }}
                  />
                </Box>
              </Box>

              <Box>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  Suporte Técnico
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={suporte === "S"}
                      onChange={handleSuporteChange}
                      name="suporteSwitch"
                      color="primary"
                    />
                  }
                  label={suporte === "S" ? "Sim" : "Não"}
                  sx={{ marginTop: 1, marginLeft: 1 }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 2,
                marginTop: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormVal || isSubmitting}
              >
                Salvar
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );
};
