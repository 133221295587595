import { createGlobalStyle } from "styled-components";
import px2vw from "../utils/px2vw";
import "font-awesome/css/font-awesome.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-gantt/styles/material.css";
import "@syncfusion/ej2-grids/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-layouts/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-richtexteditor/styles/material.css";
import "@syncfusion/ej2-treegrid/styles/material.css";

const GlobalStyle = createGlobalStyle`
.e-gantt .e-gantt-chart .e-timeline-header-table-container > thead:first-of-type > tr:first-of-type {
  height: 32px !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}

:root {
  /* font-size: ${px2vw(24)};

  @media (min-width: 768px) {
    font-size: ${px2vw(18)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(16)};
  } */
}

body, html {
  /* font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif; */
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  width: 100%;
}
`;

export default GlobalStyle;
