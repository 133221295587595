import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import MenuOS from "../../components/MenuOS";
import { DetalheSprintsGrid } from "./Components/DetalheSprintsGrid";
import { useSprintData } from "../../contexts/SprintDataContext";
import { formatStringToCapitalize } from "../../utils/formatStringToCapitalize";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useUserSprint } from "../../contexts/UserSprint";
import { useQuery } from "react-query";
import api from "../../services/api";

const DetalheSprint = () => {
  const nomePagina = "Detalhe do Planejamento / Sprint";

  const { sprintId } = useParams();
  const { selectedUser } = useUserSprint();

  const { data: sprint, isLoading } = useQuery(
    [`selected-sprint-${sprintId}-detalhe`, sprintId, selectedUser],
    async ({ signal }) => {
      const params = {};

      if (selectedUser?.codigo) {
        params.tecnico = selectedUser.codigo;
      }

      const response = await api.get(`controle-sprint/lista/${sprintId}`, {
        signal,
        params,
      });
      return response.data.records[0];
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="wrapper">
      <Header />
      <MenuOS />

      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="col-sm-6">
                  <Typography variant="h4" className="m-0" fontSize={28.8}>
                    {!isLoading && formatStringToCapitalize(sprint.sprint.nome)}
                  </Typography>
                </div>

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/sprint">Listar Registros</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/sprintItem/n">Criar Sprint</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/atendimento">Página Inicial</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="content" style={{ marginTop: "20px" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="w-100">
                  <DetalheSprintsGrid
                    sprint={sprint}
                    isLoadingSprint={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalheSprint;
