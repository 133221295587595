import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import "gantt-task-react/dist/index.css";
import { EventosEditarSubcategorias } from "./Component/EventosEditarSubcategorias";
import DirecionamentoPaginas from "./Direcionamento";

class EventoSub extends Component {
  handleNewItem = () => {
    this.props.history.push("/eventoItem/:id");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "SubCategorias de Eventos",
      nomeNivelCrumb: "Página Categorias",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0 ml-2">{this.state.nomePagina}</h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <DirecionamentoPaginas />
                        </ol>
                      </div>
                    </div>
                    <EventosEditarSubcategorias />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EventoSub;
