import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  FormControl,
  Link,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatarDataBaseDados, formatarDataBr } from "../../../utils/utils";
import dayjs from "dayjs";
import ConfirmBox from "../../../components/controles/ConfirmBox";
import { AlertDanger, AlertSuccess } from "../Motorista/Component/styles";

const Transporte = () => {
  let history = useHistory();
  const { id } = useParams();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [openMsg, setOpenMsg] = useState(false);
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [tipoTransporte, setTipoTransporte] = useState("");
  const [cupomApp, setCupomApp] = useState([]);
  const [aluguel, setAluguel] = useState([]);
  const [empresa, setEmpresa] = useState([]);

  const [localSaida, setLocalSaida] = useState([]);
  const [dataHoraSaida, setDataHoraSaida] = useState(dayjs(new Date()));
  const [idViagem, setIdViagem] = useState([]);

  const [selectedMotorista, setSelectedMotorista] = useState(0);
  const [listMotorista, setListMotorista] = useState([]);

  const [deleteData, setDeleteData] = useState({});
  const [dataTransporte, setDataTransporte] = useState([]);

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }
  function deleteTransporte() {
    try {
      api.delete(`transporte/` + deleteData?.id);

      setMensagemDialog(`Operação realizada com sucesso!`);
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpen(false);
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    }
  }

  const handleChangeMotorista = (selectMot) => {
    setSelectedMotorista(selectMot.value);
  };

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  useEffect(() => {
    const getViagem = async () => {
      await api.get(`/viagem/demanda/` + id).then((response) => {
        setIdViagem(response.data.records[0].id);
      });
    };

    const getTransporte = async () => {
      await api.get(`/transporte/demanda/` + id).then((response) => {
        setDataTransporte(response.data.records);
      });
    };

    const getTipoMotorista = async () => {
      await api.get(`/motorista`).then((response) => {
        setListMotorista(response.data.records);
      });
    };

    const interval = setInterval(() => {
      getTransporte();
      getViagem();
      getTipoMotorista();
    }, 500);
    return () => clearInterval(interval);
  }, [id]);

  const altData = async (event) => {
    const dataString = event;
    const dataObj = new Date(dataString);

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    let dataAtual = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    var dia = String(dataObj.getDate()).padStart(2, "0");
    var mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    var ano = dataObj.getFullYear();
    let dataSelec = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    const d1 = dataAtual;
    const d2 = dataSelec;

    const diffInNewDate = new Date(d2) - new Date(d1);
    const diffInNovaData = diffInNewDate / (1000 * 60 * 60 * 24);

    let dataSelecionada = diffInNovaData.toFixed(0);

    try {
      if (dataSelecionada < 0) {
        setMensagemDialog(
          `Data Limite não pode ser inferior a data de hoje: ${formatarDataBr(
            dataAtual
          )}!`
        );

        setTipoDialog("error");
        setCorDialog("error");
        setOpenMsg(!openMsg);
      } else {
        setDataHoraSaida(dataString);
      }
    } catch (err) {
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleTransportType = (type) => {
    setTipoTransporte(type);
    setShowModal(true);
  };

  const handleClose = () => {
    setTipoTransporte("");
    setShowModal(false);
  };

  const SalvarMotorista = async (e) => {
    var dadosJson = {
      motorista: selectedMotorista,
      tipoTransporte: "MOTORISTA",
      dataHoraSaida: dataHoraSaida,
      localSaida: localSaida,
      viagem: idViagem,
    };

    e.preventDefault();

    await api.post(`/transporte`, dadosJson);

    try {
      setStatusRecord({
        type: "success",
        mensagem: "Motorista cadastrado com sucesso!",
      });
      setSelectedMotorista([]);
      setDataHoraSaida([]);
      setLocalSaida([]);

      setTipoTransporte("");
      setShowModal(false);

      const limparMSG = setTimeout(() => {
        setStatusRecord({
          type: "",
          mensagem: "",
        });
      }, 2000);

      return () => clearTimeout(limparMSG);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  const optionsMotorista = listMotorista.map(function (respons) {
    return { value: respons.id, label: respons.usuario.descricao };
  });

  const SalvarAluguel = async (e) => {
    var dadosJson = {
      aluguel: aluguel,
      tipoTransporte: "ALUGUEL",
      empresa: empresa,
      viagem: idViagem,
    };
    e.preventDefault();
    console.log(JSON.stringify(dadosJson));
    await api.post(`/transporte`, dadosJson);
    try {
      setStatusRecord({
        type: "success",
        mensagem: "Aluguel cadastrado com sucesso!",
      });

      setAluguel([]);
      setEmpresa([]);

      setTipoTransporte("");
      setShowModal(false);

      const limparMSG = setTimeout(() => {
        setStatusRecord({
          type: "",
          mensagem: "",
        });
      }, 2000);

      return () => clearTimeout(limparMSG);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };
  const SalvarApp = async (e) => {
    var dadosJson = {
      cupomApp: cupomApp,
      tipoTransporte: "APP",
      empresa: empresa,
      viagem: idViagem,
    };

    e.preventDefault();
    await api.post(`/transporte`, dadosJson);
    try {
      setStatusRecord({
        type: "success",
        mensagem: "App cadastrado com sucesso!",
      });
      setCupomApp([]);
      setEmpresa([]);
      setTipoTransporte("");
      setShowModal(false);
      const limparMSG = setTimeout(() => {
        setStatusRecord({
          type: "",
          mensagem: "",
        });
      }, 2000);

      return () => clearTimeout(limparMSG);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  const renderForm = () => {
    if (tipoTransporte === "MOTORISTA") {
      return (
        <div className="col-md-12 col-sm-12 mt-2">
          {statusRecord.type === "erro" ? (
            <AlertDanger>{statusRecord.mensagem}</AlertDanger>
          ) : (
            ""
          )}
          {statusRecord.type === "success" ? (
            <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
          ) : (
            ""
          )}
          <form className="form-horizontal" onSubmit={SalvarMotorista}>
            <div className="card card-outline card-primary">
              <div className="card-title col-12 mt-2">
                <h5>{tipoTransporte}</h5>
                <hr />
              </div>
              <div className="form-group">
                <FormControl>
                  <label>Motorista</label>
                  <Select
                    value={optionsMotorista.filter(
                      (obj) => obj.value === selectedMotorista
                    )}
                    onChange={handleChangeMotorista}
                    options={optionsMotorista}
                    clearable={"true"}
                    searchable={"true"}
                    labelKey={"descricao"}
                    valueKey={"id"}
                    noOptionsMessage={() => "Nenhum registro Encontrado"}
                    required="required"
                  />
                </FormControl>
                <br />
                <label className="mt-3">Data de embarque</label>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <div class="input-group date" data-provide="datepicker">
                    <DatePicker
                      className="form-control"
                      value={dataHoraSaida}
                      onChange={(newValue) => {
                        altData(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Size"
                          id="outlined-size-small"
                          defaultValue="Small"
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </div>
                </LocalizationProvider>
                <br />
                <label>Local de Embarque: </label>
                <input
                  type="text"
                  id="localSaida"
                  name="localSaida"
                  value={localSaida}
                  className="form-control"
                  placeholder="Local..."
                  onChange={(e) => setLocalSaida(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn btn-outline-success float-right"
            >
              Salvar
            </button>
          </form>
        </div>
      );
    } else if (tipoTransporte === "APP") {
      return (
        <div className="col-md-12 col-sm-12 mt-2">
          {statusRecord.type === "erro" ? (
            <AlertDanger>{statusRecord.mensagem}</AlertDanger>
          ) : (
            ""
          )}
          {statusRecord.type === "success" ? (
            <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
          ) : (
            ""
          )}
          <form className="form-horizontal" onSubmit={SalvarApp}>
            <div className="card card-outline card-primary">
              <div className="card-title col-12 mt-2">
                <h5>{tipoTransporte}</h5>
                <hr />
              </div>
              <div className="form-group">
                <label>Cupom: </label>
                <input
                  type="text"
                  id="cupomApp"
                  name="cupomApp"
                  value={cupomApp}
                  className="form-control"
                  placeholder="Cupom..."
                  onChange={(e) => setCupomApp(e.target.value)}
                />
                <br />
                <label>Empresa: </label>
                <input
                  type="text"
                  id="empresa"
                  name="empresa"
                  value={empresa}
                  className="form-control"
                  placeholder="Empresa..."
                  onChange={(e) => setEmpresa(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn btn-outline-success float-right"
            >
              Salvar
            </button>
          </form>
        </div>
      );
    } else if (tipoTransporte === "ALUGUEL") {
      return (
        <div className="col-md-12 col-sm-12 mt-2">
          {statusRecord.type === "erro" ? (
            <AlertDanger>{statusRecord.mensagem}</AlertDanger>
          ) : (
            ""
          )}
          {statusRecord.type === "success" ? (
            <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
          ) : (
            ""
          )}
          <form className="form-horizontal" onSubmit={SalvarAluguel}>
            <div className="card card-outline card-primary">
              <div className="card-title col-12 mt-2">
                <h5>{tipoTransporte}</h5>
                <hr />
              </div>
              <div className="form-group">
                <label>Aluguel: </label>
                <input
                  type="text"
                  id="aluguel"
                  name="aluguel"
                  value={aluguel}
                  className="form-control"
                  placeholder="Numero aluguel..."
                  onChange={(e) => setAluguel(e.target.value)}
                />
                <br />
                <label>Empresa: </label>
                <input
                  type="text"
                  id="empresa"
                  name="empresa"
                  value={empresa}
                  className="form-control"
                  placeholder="Empresa..."
                  onChange={(e) => setEmpresa(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="bbtn btn btn-outline-success float-right"
            >
              Salvar
            </button>
          </form>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div>
                  <button
                    type="button"
                    className="btn bg-primary btn-sm float-right"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="fa fa-plus mr-2"></i> Adicionar Transporte
                  </button>
                </div>

                <div className="timeline">
                  <div className="time-label">
                    <span className="bg-success">Transportes</span>
                  </div>
                  {dataTransporte.map((item) => {
                    if (item.tipoTransporte === "MOTORISTA") {
                      return (
                        <div key={item.id}>
                          <i className="fas fa-car bg-primary" />
                          <div className="timeline-item">
                            <h3 className="timeline-header no-border">
                              <div className="row">
                                <div className="col-2">
                                  <span className="text-primary">
                                    {item.tipoTransporte}
                                  </span>
                                </div>
                                <div className="col-10 ">
                                  <span
                                    className="text-md float-right"
                                    type="button"
                                  >
                                    <Link
                                      onClick={() => openDelete(item)}
                                      to={"#"}
                                      className="description text-danger"
                                    >
                                      <b>Excluir</b>
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <span>
                                <label>Nome:</label>{" "}
                                {item.motorista?.usuario.descricao}
                              </span>
                              <br />
                              <span>
                                <label>Contato:</label>{" "}
                                {item.motorista?.contato}
                              </span>
                              <br />
                              <span>
                                <label>Data Saída:</label>{" "}
                                {formatarDataBaseDados(
                                  item.dataHoraSaida,
                                  "DataHora"
                                )}
                              </span>
                              <br />
                              <span>
                                <label>Local de Embarque:</label>{" "}
                                {item.localSaida}
                              </span>
                            </h3>
                          </div>
                        </div>
                      );
                    } else if (item.tipoTransporte === "ALUGUEL") {
                      return (
                        <div key={item.id}>
                          <i className="fas fa-car bg-primary" />
                          <div className="timeline-item">
                            <h3 className="timeline-header no-border">
                              <div className="row">
                                <div className="col-2">
                                  <span className="text-primary">
                                    {item.tipoTransporte}
                                  </span>
                                </div>
                                <div className="col-10">
                                  <span
                                    className="text-md float-right"
                                    type="button"
                                  >
                                    <Link
                                      onClick={() => openDelete(item)}
                                      to={"#"}
                                      className="description  text-danger"
                                    >
                                      <b>Excluir</b>
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <span>
                                <label>Número Reserva:</label> {item.aluguel}
                              </span>
                              <br />
                              <span>
                                <label>Empresa:</label> {item.empresa}
                              </span>
                            </h3>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={item.id}>
                          <i className="fas fa-car bg-primary" />
                          <div className="timeline-item">
                            <h3 className="timeline-header no-border">
                              <div className="row">
                                <div className="col-2">
                                  <span className="text-primary">
                                    {item.tipoTransporte}
                                  </span>
                                </div>
                                <div className="col-10">
                                  <span
                                    className="text-md float-right"
                                    type="button"
                                  >
                                    <Link
                                      onClick={() => openDelete(item)}
                                      to={"#"}
                                      className="description  text-danger"
                                    >
                                      <b>Excluir</b>
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <span>
                                <label>Cupom:</label> {item.cupomApp}
                              </span>
                              <br />
                              <span>
                                <label>Empresa:</label> {item.empresa}
                              </span>
                            </h3>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <Modal show={showModal} onHide={handleClose}>
                  <Button
                    className="d-flex justify-content-end"
                    onClick={() => handleClose(false)}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </Button>
                  <Modal.Header>
                    <Modal.Title>Escolha o tipo de transporte:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            className="btn btn-primary  mr-2"
                            onClick={() => handleTransportType("MOTORISTA")}
                          >
                            Traslado
                          </button>
                          <button
                            className="btn btn-primary  mr-2"
                            onClick={() => handleTransportType("ALUGUEL")}
                          >
                            Aluguel
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => handleTransportType("APP")}
                          >
                            APP
                          </button>
                        </div>

                        {renderForm()}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={openMsg} onClose={handleFecharAlert}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            //width: '80%',
            //margin: 'auto',
            "& .MuiAlert-icon": {
              color: "blue",
            },
            //backgroundColor: "green"
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={deleteData?.descricao}
        deleteFunction={deleteTransporte}
      />
    </>
  );
};

export default Transporte;
