import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import TreeView from "./TreeViewEvent";
import api from "../../../services/api";
import {
  AlertSuccess,
  AlertDanger,
} from "../../../components/controles/styles";
import { Box } from "@mui/material";

export const EventosCadastro = () => {
  const { id } = useParams();
  const [descricao, setDescricao] = useState("");
  const [statusRecord, setStatusRecord] = useState({ type: "", mensagem: "" });
  const [sigla, setSigla] = useState("");

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const getEvento = async () => {
    const response = await api.get(`/demanda_eventos/`);

    return response.data.records;
    1;
  };

  const {
    data: eventosResponse,
    isLoading,
    refetch,
  } = useQuery("eventos", getEvento);

  const handleSaveEditMutation = async ({ id, editedValue }) => {
    setIsLoadingRequest(true);
    try {
      var dadosJson = {
        descricao: editedValue,
      };

      await api.put(`demanda_eventos/${id}`, dadosJson);
      setStatusRecord({
        type: "success",
        mensagem: "Evento editado com sucesso",
      });
      refetch();
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na edição deste Evento",
      });
    } finally {
      setTimeout(() => {
        setStatusRecord({ type: "", mensagem: "" });
      }, 3000);
      setIsLoadingRequest(false);
    }
  };

  const { mutate } = useMutation(handleSaveEditMutation);

  const handleSaveEdit = (id, editedValue) => {
    mutate({ id, editedValue });
  };

  const newEvent = async (e) => {
    e.preventDefault();

    if (!descricao || !sigla) {
      setStatusRecord({
        type: "erro",
        mensagem: "Preencha todos os campos",
      });
      return;
    }

    try {
      setIsLoadingRequest(true);
      var dadosJson = {
        descricao: descricao,
        sigla: sigla,
      };
      await api.post(`/demanda_eventos/`, dadosJson);
      refetch();

      setDescricao("");
      setSigla("");
      setStatusRecord({
        type: "success",
        mensagem: "Novo Evento criado com sucesso",
      });
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na criação deste Evento",
      });
    } finally {
      setTimeout(() => {
        setStatusRecord({ type: "", mensagem: "" });
      }, 3000);
      setIsLoadingRequest(false);
    }
  };

  function handleKeyPress(event) {
    console.log(event.key);
    if (event.key === "Enter") {
      newEvent(event);
    }
  }

  return (
    <>
      <div>
        {statusRecord.type === "erro" ? (
          <AlertDanger>{statusRecord.mensagem}</AlertDanger>
        ) : (
          ""
        )}
        {statusRecord.type === "success" ? (
          <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="form-horizontal">
                <div className="row">
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1.5fr 0.2fr 0.2fr",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      flex: 1,
                      gap: 1,
                    }}
                  >
                    <div>
                      <label>DESCRIÇÃO</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Descrição do Evento..."
                        id="description"
                        name="description"
                        onChange={(e) => setDescricao(e.target.value)}
                        value={descricao}
                        size="80"
                      />
                    </div>
                    <div>
                      <label>SIGLA</label>

                      <div>
                        <input
                          type="text"
                          className="form-control"
                          id="sigla"
                          name="sigla"
                          value={sigla}
                          size="3"
                          onKeyDown={(event) => handleKeyPress(event)}
                          onChange={(e) => setSigla(e.target.value)}
                        />
                      </div>
                    </div>

                    <button
                      className="btn btn-primary"
                      onClick={newEvent}
                      disabled={isLoadingRequest}
                    >
                      Salvar
                    </button>
                  </Box>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="form-horizontal">
                  <div className="col-sm-12">
                    <Box
                      sx={{
                        minHeight: 600,
                        flexGrow: 1,
                        fontSize: 20,
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      {!isLoading && (
                        <TreeView
                          descricao={"descricao"}
                          onSaveEdit={handleSaveEdit}
                          data={eventosResponse[0].eventos}
                        />
                      )}
                    </Box>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
