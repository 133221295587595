import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { formatarDataBr } from "../../../utils/utils";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { calculateProgress } from "./functions/calculateDateProgress";
import { grey } from "@mui/material/colors";

export const AssignedUsersCard = ({ user, task, onSelectCard }) => {
  const [userMessages, setUserMessages] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/demanda_mensagem/processo/${user.id}`);

      setUserMessages(response.data.records);
    })();
  }, []);

  const currentBorderColor = (status) => {
    if (isRequesterUser) {
      return {
        border: "#e9e9e9",
        progress: "#ff9800",
      };
    }

    if (status === "ANDAMENTO") {
      return {
        border: "#3498db",
        progress: "#3498db",
      };
    }
    if (status === "CONCLUIDO") {
      return {
        border: "#2ecc71",
        progress: "#2ecc71",
      };
    }
    if (status === "AGUARDANDO") {
      return {
        border: "#f39c12",
        progress: "#f39c12",
      };
    }

    if (status === "INATIVO") {
      return {
        border: "#e74c3c",
        progress: "#e74c3c",
      };
    }
  };

  const unreadMessages = userMessages.filter(
    (message) => message.statusLeitura === 0
  );

  const dateProgress = (status) => {
    if (status === "CONCLUIDO") {
      return 100;
    }

    return calculateProgress(
      task[0].dataAbertura,
      task[0].dataFimPrazo,
      user.dataHoraLimite
    ).progress;
  };

  const isRequesterUser =
    task[0].solicitante.codUsuario === user.usuario.usuario.codUsuario;

  return (
    <Card
      key={`${user.usuario.usuario.nome}-${user.tipoStatus}`}
      sx={{
        width: "100%",
        mb: 1,
        borderLeft: "6px solid",
        borderColor: currentBorderColor(user.tipoStatus, user.usuario).border,
        "&:hover": {
          backgroundColor: "#f8f8f8",
        },
        cursor: "pointer",
      }}
      onClick={onSelectCard}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Box>
            {user.dataHoraLimite && (
              <Typography>{formatarDataBr(user.dataHoraLimite)}</Typography>
            )}

            {!isRequesterUser && user.tipoStatus === "AGUARDANDO" && (
              <Typography
                color="#f39c12"
                textTransform={"uppercase"}
                fontWeight={600}
                fontSize={14}
              >
                Aguardando
              </Typography>
            )}

            {isRequesterUser && (
              <Typography
                color="#000"
                textTransform={"uppercase"}
                fontWeight={600}
              >
                Solicitante
              </Typography>
            )}

            <Typography variant="body1" fontWeight={500} color={grey[800]}>
              {user.usuario.usuario.nome}
            </Typography>
          </Box>

          <Box sx={{ px: 1, backgroundColor: "#f8f8f8", borderRadius: "4px" }}>
            <Typography color="#2ecc71" fontWeight={800}>
              {unreadMessages.length}
            </Typography>
          </Box>
        </Box>

        {!isRequesterUser && (
          <LinearProgress
            variant="determinate"
            value={dateProgress(user.tipoStatus)}
            sx={{
              ".css-5xe99f-MuiLinearProgress-bar1": {
                backgroundColor: currentBorderColor(
                  user.tipoStatus,
                  user.usuario
                ).progress,
              },
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};
