import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const StatusFilters = ({
  statusChamado,
  handleChangeStatusChamado,
  responsavel,
  horaEstimada,
  newHoraEstimada,
  styleSelect,
}) => {
  return (
    <div>
      <FormControl sx={{ marginTop: 1}}>
        <InputLabel sx={styleSelect} id="status">
          Status 
        </InputLabel>
        <Select
          labelId="status"
          id="status"
          value={statusChamado.current}
          onChange={handleChangeStatusChamado}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            minWidth: 150,
          }}
          label="Status"
          size="small"
          disabled={
            statusChamado.current == "CONCLUIDO" ||
            responsavel?.codigo == 9 ||
            horaEstimada == "00:00" ||
            horaEstimada == "" ||
            horaEstimada == "00"
          }
        >
          <MenuItem id="text-danger" value={"PENDENTE"}>
            PENDENTE
          </MenuItem>
          <MenuItem
            id="text-warning"
            value={"ANDAMENTO"}
            disabled={
              horaEstimada + newHoraEstimada == 0 ||
              horaEstimada === "00:00" ||
              horaEstimada === "00:0" ||
              responsavel == null ||
              responsavel.codigo == 9
            }
          >
            ANDAMENTO
          </MenuItem>
          <MenuItem
            id="text-primary"
            value={"TESTE"}
            disabled={statusChamado.current == "PENDENTE"}
          >
            TESTE
          </MenuItem>
          <MenuItem
            id="text-danger"
            value={"CONCLUIDO"}
            disabled={statusChamado.current !== "TESTE"}
          >
            CONCLUÍDO
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default StatusFilters;
