import React from "react";
import "dayjs/locale/pt-br";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Stack } from "react-bootstrap";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";
import { ColunasMovimentacao } from "./ColunasMovimentacao";

const Kanbam = () => {
  const { id } = useParams();
  const [value, setValue] = useState(dayjs(new Date()).subtract(30, "day"));
  const [valueFim, setValueFim] = useState(dayjs(new Date()));
  const [tipoRel, setTipoRel] = useState("0");

  const tipoListaStatus = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  return (
    <>
      <div className="card-header ">
        <div className="card-row">
          <form className="form-horizontal">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <div className="form-group ">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Inicial"
                        value={value}
                        onChange={(newValue) => {
                          dataFiltroInicial(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Final"
                        value={valueFim}
                        onChange={(newValue) => {
                          dataFiltroFinal(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="form-group col-sm-8 col-md-8">
                <div className="input-group input-group-lg">
                  <div
                    className="border ml-2 pl-2"
                    style={{ borderRadius: "5px" }}
                  >
                    <FormControl spacing={3}>
                      <FormLabel></FormLabel>
                      <RadioGroup
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 24,
                          },
                        }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tipoRel}
                        onChange={tipoListaStatus}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Andamento"
                          sx={{
                            color: "#5bc0de",
                          }}
                        />

                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Em Atraso"
                          sx={{
                            color: "#d9534f",
                          }}
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Concluído"
                          sx={{
                            color: "#5cb85c",
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <ColunasMovimentacao parametro={'AGUARDANDO'} tipoColuna={"SOLICITAÇÃO"} />
          <ColunasMovimentacao parametro={'ANDAMENTO'} tipoColuna={"ATENDENDO"} />
          
          <ColunasMovimentacao parametro={'ESPERA'} tipoColuna={"EM ESPERA"} />
          <ColunasMovimentacao parametro={'APROVADO'} tipoColuna={"APROVADO"} />
          <ColunasMovimentacao parametro={"RECUSADO"} tipoColuna={"RECUSADO"} />
        </div>
      </div>
    </>
  );
};

export default Kanbam;
