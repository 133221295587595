import { useQuery } from "react-query";
import { SprintTable } from "./SprintTable";
import api from "../../../../services/api";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SprintFilters } from "./SprintFilters";
import { SprintMobileCard } from "./SprintMobileCard";
import { SprintDataGrid } from "./SprintDataGrid";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";

const SprintGrid = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const { user } = useUnidadesContext();

  const { data: sprintsData, isLoading } = useQuery(
    "sprints",
    async () => {
      const response = await api.get("/controle-sprint/planejamento", {
        params: {
          codUsuario: user.codUsuario,
        },
      });
      return response.data.records;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const { data: backlogData, isLoading: isLoadingBacklog } = useQuery(
    "backlog",
    async () => {
      const response = await api.get("/controle-sprint/0");
      return response.data.records[0];
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || isLoadingBacklog) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          my: 10,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 4 }}>
      <SprintFilters />

      {!isMobile && (
        <SprintDataGrid sprints={sprintsData} backlog={backlogData} />
      )}
      {isMobile && <SprintMobileCard sprints={sprintsData} />}
    </Box>
  );
};

export default SprintGrid;
