import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TaskHeader } from "./TaskHeader";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { Box, Card, CircularProgress } from "@mui/material";
import { SplitScreenContent } from "./SplitScreenContent";
import { AttachmentIcon } from "./AttachmentIcon";
import { AssignedUsersCard } from "./AssignedUsersCard";
import VisualizarProcessos from "../Component/VisualizarProcessos";
import { TaskDialog } from "./TaskDialog";

export const TaskContent = () => {
  const { id } = useParams();

  const [taskData, setTaskData] = useState(null);
  const [assignedUserData, setAssignedUserData] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTaskData = async () => {
    try {
      setIsLoading(true);

      const [taskResponse, attachmentsResponse, assignedUserResponse] =
        await Promise.all([
          api.get("/demanda/" + id),
          api.get(`/anexo_demanda/demanda/` + id + `/anexos`),
          api.get(`demanda_processos/demanda/` + id),
        ]);

      setTaskData([
        ...taskResponse.data.records,
        { anexos: attachmentsResponse.data.records },
      ]);

      setAssignedUserData(assignedUserResponse.data.records);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleSelectUserCard = (id) => {
    setSelectedUser(id);
  };

  useEffect(() => {
    fetchTaskData();
  }, [id]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    !isLoading &&
    taskData && (
      <>
        <Card sx={{ py: 1, px: 2 }}>
          <TaskHeader
            task={{
              id: taskData[0].id,
              solicitante: taskData[0].solicitante,
              dataAbertura: taskData[0].dataAbertura,
              dataFimPrazo: taskData[0].dataFimPrazo,
              usuarios: assignedUserData,
              status: taskData[0].status,
            }}
          />

          <SplitScreenContent
            title={taskData[0].titulo}
            leftContent={taskData[0].descricao}
            attachments={taskData[1]?.anexos || []}
            rightContent={assignedUserData.map((user) => (
              <AssignedUsersCard
                key={`${user.usuario.usuario.nome}-${user.tipoStatus}`}
                user={user}
                task={taskData}
                onSelectCard={() => {
                  handleSelectUserCard(user);
                  handleOpenCard();
                }}
              />
            ))}
          />
        </Card>

        {isCardOpen && (
          <TaskDialog isOpen={isCardOpen} handleDialogState={handleOpenCard}>
            <VisualizarProcessos
              calls={selectedUser.id}
              id={id}
              opencard={isCardOpen}
              setopencard={setIsCardOpen} />
          </TaskDialog>
        )}
      </>
    )
  );
};
