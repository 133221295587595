import {
  GanttComponent,
  Inject,
  PdfExport,
  Selection,
  Toolbar,
} from "@syncfusion/ej2-react-gantt";

import * as React from "react";
import {
  checkEndDateIsSmallerThanCurrentDate,
  formatDateRemoveHoursToMMddYYYY,
  formatDateToMMddYYYY,
  formatDateToYYYYMMDD,
  formatSprintDateToMMddYYYY,
} from "../ProjetoKanban/gantt-functions/ganttDateFormatter";

import { labelSettings } from "../ProjetoKanban/gantt-configs/labelSettings";
import { toolbarOptions } from "../ProjetoKanban/gantt-configs/toolbarOptions";
import { pdfQueryTaskbarInfo } from "../ProjetoKanban/gantt-configs/pdfQueryTaskbarInfo";
import { getExportProperties } from "../ProjetoKanban/gantt-configs/pdf/exportProperties";
import { queryTaskbarInfo } from "../ProjetoKanban/gantt-configs/queryTaskbarInfo";

import { timelineSettings } from "../ProjetoKanban/gantt-configs/timelineSettings";

import { queryCellInfo } from "../ProjetoKanban/gantt-configs/queryCellInfo";
import { useMediaQuery, useTheme } from "@mui/material";

export const GanttChart = ({
  sprintData,
  projectData,
  isFullScreen,
  handleFullScreen,
}) => {
  const allTasks = [
    ...sprintData.concluido,
    ...sprintData.andamento,
    ...sprintData.fazer,
    ...sprintData.teste,
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getStatusColor = (status) => {
    if (status === "CONCLUIDO") {
      return "#33691e";
    } else if (status === "ANDAMENTO") {
      return "#ffa000";
    } else if (status === "PENDENTE") {
      return "#0d47a1";
    } else {
      return "#424242";
    }
  };

  const data = allTasks && [
    {
      TaskID: sprintData.sprint.id, 
      TaskName: sprintData.sprint.nome,
      StartDate: new Date(formatSprintDateToMMddYYYY(projectData.dataInicio)),
      EndDate: new Date(formatSprintDateToMMddYYYY(projectData.dataConclusao)),
      HorasColumn: "ESTIMADAS / TRABALHADAS",
      Progress: 100,
      TooltipDate: ``,
      DurationText: ".",
      subtasks: allTasks.map((task) => {
        return {
          TaskID: task.codigo,
          TaskName: task.titulo,
          StartDate: new Date(formatDateToMMddYYYY(task.datcad)),
          EndDate: new Date(formatDateToMMddYYYY(task.dataConcluidos)),
          HorasEstimadas: task.horasEstimadas,
          HorasTrabalhadas: task.totalHorasTrabalhadas,
          HorasExcedentes: task.totalHorasGastas,
          HorasColumn: `${task.horasEstimadas}h ${
            task.totalHorasTrabalhadas > 0
              ? `/ ${task.totalHorasTrabalhadas}h`
              : ""
          } ${
            task.totalHorasGastas > 0
              ? `(Excedeu: ${task.totalHorasGastas}h)`
              : ""
          }`,
          TooltipDate: `
          Status: ${task.status} <br />
          Inicio: ${new Date(task.datcad).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
          })} ${
            (task.dataConcluidos &&
              `<br /> Conclusão: ${new Date(
                task.dataConcluidos
              ).toLocaleDateString("pt-BR", { timeZone: "UTC" })}`) ||
            ""
          } <br />
          `,
          DurationText:
            task.status === "CONCLUIDO"
              ? `${task.horasEstimadas}h estimadas / ${task.totalHorasTrabalhadas}h trabalhadas`
              : `${task.horasEstimadas}h estimadas`,
          Progress: 100,
          status: task.status,
          labelSettings: {
            color: getStatusColor(task.status),
          },
        };
      }),
    },
  ];

  console.log("data", data);
  console.log("sprintData", sprintData);
  console.log("projectData", projectData);
  console.log("labelSettings", labelSettings);

  const taskFields = {
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    endDate: "EndDate",
    duration: "Duration",
    progress: "Progress",
    child: "subtasks",
  };

  let ganttChart;

  function toolbarClick(args) {
    if (args.item.id === "FullScreen") {
      handleFullScreen();
    }

    if (args.item.id === "PdfExport") {
      ganttChart.pdfExport(
        getExportProperties(sprintData.sprint.id, sprintData, projectData)
      );
    }
  }

  const projectStartDate = new Date(
    formatDateRemoveHoursToMMddYYYY(projectData.dataInicio)
  );

  // const checkEndDate = checkEndDateIsSmallerThanCurrentDate(
  //   projectData.dataConclusao
  // );

  // const projectEndDate = checkEndDate
  //   ? new Date(new Date().getTime() + 4)
  //   : new Date(formatDateRemoveHoursToMMddYYYY(projectData.dataConclusao));

  return (
    <React.Fragment>
      {data && allTasks && sprintData && projectData && (
        <GanttComponent
          key={`gantt-${isFullScreen}-${sprintData.sprint.id}`}
          dataSource={data}
          taskFields={taskFields}
          timelineSettings={timelineSettings} 
          labelSettings={labelSettings}
          projectStartDate={
            new Date(formatSprintDateToMMddYYYY(projectData.dataInicio))
          }
          projectEndDate={
            new Date(new Date(formatSprintDateToMMddYYYY(projectData.dataConclusao)).getTime() + 4 * 24 * 60 * 60 * 1000)
          }
          toolbar={toolbarOptions(isFullScreen)}
          toolbarClick={toolbarClick}
          allowPdfExport={true}
          resources={projectData}
          pdfQueryTaskbarInfo={pdfQueryTaskbarInfo}
          ref={(gantt) => (ganttChart = gantt)}
          width={"100%"}
          dateFormat="dd/MM/yyyy"
          height={"100%"}
          tooltipSettings={{
            showTooltip: true,
            taskbar: "${TaskName} <br /> ${taskData.TooltipDate}",
          }}
          splitterSettings={{
            columnIndex: 2,
          }}
          columns={[
            {
              field: "TaskName",
              headerText: "Título",
              minWidth: 200,
              textAlign: "left",
            },
            {
              field: "HorasColumn",
              headerText: "Horas",
              minWidthwidth: 200,
              textAlign: "left",
            },
          ]}
          queryTaskbarInfo={queryTaskbarInfo}
          queryCellInfo={queryCellInfo}
        >
          <Inject services={[Toolbar, PdfExport, Selection]} />
        </GanttComponent>
      )}
    </React.Fragment>
  );
};
