import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HotelGrid = () => {
  let history = useHistory();
  const [data, setData] = useState([]);

  useEffect(() => {
    api.get(`hotel`).then((response) => {
      setData(response.data.records);
    });
  }, []);

  const handleClick = (event, id) => {
    history.push({
      pathname: "/hotelItem/" + id,
    });
  };

  if (!data) return null;
  return (
    <>
      <div>
        <div className="card-header border-0">
          <h3 className="card-title">Registros de Hoteis</h3>
          <div className="card-tools"></div>
        </div>
        <div className="card-body table-responsive p-0">
          <table className="table table-striped table-valign-middle">
            <thead>
              <tr>
                <th style={{ width: "10%", textAlign: "center" }}>ID</th>
                <th style={{ width: "20%", textAlign: "center" }}>
                  Nome do Hotel
                </th>
                <th style={{ width: "20%", textAlign: "center" }}>Cidade</th>
                <th style={{ width: "20%", textAlign: "center" }}>País</th>
                <th style={{ width: "12%", textAlign: "center" }}>
                  Avaliação<i className="fa fa-star text-yellow ml-1"></i>
                </th>
                <th style={{ width: "18%", textAlign: "center" }}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {data.map((hoteis) => (
                <tr key={hoteis.id}>
                  <td>{hoteis.id}</td>
                  <td>{hoteis.nome}</td>
                  <td>{hoteis.cidade}</td>
                  <td>{hoteis.pais}</td>
                  <td>{hoteis.avaliacao}</td>
                  <td>
                    <>
                      <button
                        type="button"
                        className="btn btn-sm "
                        onClick={(event) => {
                          handleClick(event, hoteis.id);
                        }}
                      >
                        <i className="fas fa-pen text-primary"></i>
                      </button>
                    </>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HotelGrid;
