import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { REACT_APP_TOTAL_GRID } from "../../../../utils/constants";
import { addZeroes } from "../../../../utils/utils";
import { Link } from "@mui/material";
import api from "../../../../services/api";

const TipoChamadoGrid = () => {
  let history = useHistory();
  const [data, setData] = useState([]);

  let limit = parseInt(REACT_APP_TOTAL_GRID);


  useEffect(() => {
    api.get(`controle-tipo`).then((response) => {
      setData(response.data.records);

      
    });
  }, []);

  

  const handleClick = (event, id) => {
    history.push({
      pathname: "/tipoChamadoItem/" + id,
    });
  };

  if (!data) return null;
  return (
    <>
      <div>
        <div className="card-header border-0">
          <h4>
            <small>
              
              {data.length > 0 && <>Total: <b style={{ fontSize: 18 }}></b> {data.length}</>}
            </small>
          </h4>
          <div className="card-tools"></div>
        </div>
        <div className="card-body table-responsive p-0">
          <table>
            <thead>
              <tr>
                <th style={{ width: "10%", textAlign: "center" }}>ID</th>
                <th style={{ width: "70%", textAlign: "center" }}>Descrição</th>
              </tr>
            </thead>
            <tbody>
              {data
              .sort((a, b) => a.id - b.id)
              .map((item) => {
                return (
                  <tr key={item.id}>
                    <td data-label="ID">
                      <Link
                        className="text-primary"
                        type="button"
                        id={item.id}
                        onClick={(event) => {
                          handleClick(event, item.id);
                        }}
                      >
                        {addZeroes(item.id, 8)}
                      </Link>
                    </td>
                    <td data-label="DESCRICAO">{item.descricao}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          
        </div>
      </div>
    </>
  );
};

export default TipoChamadoGrid;
