import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Link,
} from "@mui/material";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { getControle } from "../../../services/auth";
import { useState } from "react";
import { UserUnlinkModal } from "./UserUnlinkModal";
import api from "../../../services/api";

export const TaskHeader = ({ task }) => {
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [currentTaskStatus, setCurrentTaskStatus] = useState(task.status);

  const isRequesterUser =
    task.solicitante?.codUsuario === Number(getControle());

  const usersWithoutRequester = task.usuarios.filter(
    (user) => user.usuario.usuario.codUsuario !== task.solicitante.codUsuario
  );

  const totalFinishedTasks = (users) => {
    return users.filter((task) => task.tipoStatus === "CONCLUIDO").length;
  };

  const taskProgress = (users) => {
    return Math.round((totalFinishedTasks(users) / users.length) * 100);
  };

  const handleStatusChange = async (event) => {
    try {
      const response = await api.put(`/demanda/${task.id}`, {
        status: Number(event.target.value),
      });

      if (response.status === 200) {
        setCurrentTaskStatus(Number(event.target.value));
      }

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const handleUnlinkModal = () => {
    setShowUnlinkModal(!showUnlinkModal);
  };

  const taskHeaderItems = [
    { label: "Nº", value: task.id?.toString().padStart(8, "0") },
    { label: "Solicitante", value: task.solicitante?.nome },
    {
      label: "Abertura",
      value: new Date(task.dataAbertura).toLocaleDateString("pt-BR"),
      size: 120,
    },
    {
      label: "Prazo",
      value: new Date(task.dataFimPrazo).toLocaleDateString("pt-BR"),
    },
    {
      label: null,
      value: (
        <RadioGroup
          name="status"
          value={currentTaskStatus}
          onChange={handleStatusChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <List sx={{ display: "flex", flexDirection: "row" }}>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Andamento
                    </Typography>
                  }
                  primaryTypographyProps={{ variant: "body1" }}
                />
                <Radio
                  sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                  value={0}
                  disabled={!isRequesterUser}
                  checked={currentTaskStatus === 0}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Concluído
                    </Typography>
                  }
                  primaryTypographyProps={{ variant: "body1" }}
                />
                <Radio
                  sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                  value={1}
                  disabled={!isRequesterUser}
                  checked={currentTaskStatus === 1}
                />
              </ListItem>
            </List>
          </Box>
        </RadioGroup>
      ),
    },
  ];

  return (
    <Grid container justifyContent="space-between">
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ width: "100%", gap: 2 }}
      >
        {taskHeaderItems.map((item) => (
          <Grid
            item
            key={item.label}
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #e1e1e1",
              width: item.size || null,
              minWidth: 120,
              px: 1,
              borderRadius: "4px",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {item.label}
            </Typography>
            {typeof item.value === "string" ? (
              <Typography variant="body1">{item.value}</Typography>
            ) : (
              item.value
            )}
          </Grid>
        ))}

        <Grid item>
          <CircularProgressWithLabel
            value={taskProgress(usersWithoutRequester)}
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}></Grid>
        {isRequesterUser && (
          <Grid item>
            <Link href={`/solicitacoesItem/${task.id}`}>
              <Button variant="contained" color="primary">
                Editar demanda
              </Button>
            </Link>
          </Grid>
        )}

        {!isRequesterUser && currentTaskStatus !== 1 && (
          <Grid item>
            <Tooltip title="Desvincular do processo" arrow>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUnlinkModal}
              >
                Desvincular
              </Button>
            </Tooltip>
          </Grid>
        )}

        {showUnlinkModal && (
          <UserUnlinkModal onClose={handleUnlinkModal} task={task} />
        )}
      </Grid>
    </Grid>
  );
};
