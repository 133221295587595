import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";

const TotalizadorProdutiv = ({
  graficos,
  isMobile,
  isTablet,
  converterParaHorasMinutos,
}) => {
  return (
    <Box
      sx={{ display: "flex" }}
      fontWeight={500}
      alignItems={"center"}
      width="100%"
      gap={5}
      flexDirection={isMobile ? "column" : "row"}
      justifyContent={"flex-start"}
      color="text.secondary"
    >
      <Tooltip title="Total de Chamados">
        <Typography
          className="mr-2"
          fontWeight={500}
          fontSize={isTablet? 12 : 14}
        >
          Total de Chamados:{" "}
          <strong>
            {graficos == undefined || graficos.controleServicos.length == 0
              ? " 0"
              : graficos.controleServicos.length}
          </strong>
        </Typography>
      </Tooltip>
      <Tooltip title="Horas Estimadas">
        <Typography
          className="mr-2"
          fontWeight={500}
          fontSize={isTablet? 12 : 14}
        >
          Hr. Estimativa:{" "}
          <strong>
            {graficos
              ? converterParaHorasMinutos(
                  graficos.controleTotaisHoras.totalHorasEstimadas || "00"
                )
              : "0"}
          </strong>
        </Typography>
      </Tooltip>

      <Tooltip title="Horas Trabalhadas">
        <Typography
          className="mr-2"
          fontWeight={500}
          fontSize={isTablet? 12 : 14}
        >
          Hr. Trabalhadas:{" "}
          <strong>
            {graficos
              ? converterParaHorasMinutos(
                  graficos.controleTotaisHoras.totalHorasTrabalhadas || "00"
                )
              : "0"}
          </strong>
        </Typography>
      </Tooltip>
      <Tooltip title="Horas de Diferença">
        <Typography
          className="mr-2"
          fontWeight={500}
          fontSize={isTablet? 12 : 14}
        >
          Hr. Diferença:{" "}
          <strong>
            {graficos
              ? converterParaHorasMinutos(
                  graficos.controleTotaisHoras.totalHorasDiferencas || "00"
                )
              : "0"}
          </strong>
        </Typography>
      </Tooltip>
      <Tooltip title="Horas Auxiliares">
        <Typography
          className="mr-2"
          fontWeight={500}
          fontSize={isTablet? 12 : 14}
        >
          Hr. Auxiliares:{" "}
          <strong>
            {graficos
              ? converterParaHorasMinutos(
                  graficos.controleTotaisHoras.totalHorasAuxiliares || "00"
                )
              : "0"}
          </strong>
        </Typography>
      </Tooltip>
      <Tooltip title="Horas Fora do Período">
        <Typography
          className="mr-2"
          fontWeight={500}
          fontSize={isTablet? 12 : 14}
        >
          Hr. F. Periodo:{" "}
          <strong>
            {graficos
              ? converterParaHorasMinutos(
                  graficos.controleTotaisHoras
                    .totalHorasTrabalhadasForaPeriodo || "00"
                )
              : "0"}
          </strong>
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default TotalizadorProdutiv;
