import React, { useState, useEffect } from "react";

import api from "../../services/api";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useHistory,
} from "react-router-dom";
import { logout, getControle, cc, getCC } from "../../services/auth";
import { useCollapse } from "../../contexts/ColapsedMenuContext";

/**
 * Componente criado para renderizar os detalhes dos usuario logado
 * Este compoe junto com o componente Menu exibindo a foto e outros itens
 * de informacoes do usuario
 * @returns
 */
export const UserIdentOs = () => {
  const usuarioControle = getControle();
  const [data, setData] = useState([]);
  const { handleBool, isMenuCollapsed } = useCollapse();
  const [dataMec, setDataMec] = useState([]);

  const [uDescricao, setUDescricao] = useState([]);
  const [uEmail, setUEmail] = useState([]);
  const [uCCusto, setUCCusto] = useState([]);
  const [uImagem, setImagem] = useState([]);

  const [blobImage, setBlobImage] = useState([]);

  let history = useHistory();

  function getInitials(name) {
    if (typeof name === 'string') {
      const names = name.split(' ');
      const initials = names.map((n,i,allArray) => i === 0 || i + 1 === allArray.length ? n[0] : null);
      return initials.join('').toUpperCase();
    }
    return '';
  }

  useEffect(() => {
    api.get(`/usuarioDetalhe/` + usuarioControle).then(
      (response) => {
        setUDescricao(response.data.records[0].descricao);
        setUEmail(response.data.records[0].email);
        setUCCusto(response.data.records[0].ccusto);
        cc(response.data.records[0].ccusto);
      },
      (error) => {
        logOff();
      }
    );
  }, [usuarioControle]);

  useEffect(() => {
    if (usuarioControle === null) {
      logOff();
    }
  });

  const logOff = () => {
    logout();
    history.push({
      pathname: "/",
    });

    window.location.reload();
  };

  if (!data) return null;

  return (
    <div>
      {/* Painel de Dados do Usuario Logado */}

      <div className="mt-3 pb-3 mb-3 d-flex">
        <div className="col-12 info">
          <div>

          <h3
              className={isMenuCollapsed == false 
                ? "profile-username text-center brand-text"
                : "profile-username text-center"}
              style={{
                color: "#FFF",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              {" "}
              {isMenuCollapsed == false ? uDescricao : getInitials(uDescricao)} 
            </h3>
            <h3
              className="profile-username text-center"
              style={{ color: "#FFF", fontSize: "12px" }}
            >
              <span className="brand-text">{uEmail}</span>
              
            </h3>
            <h3
              className="profile-username text-center"
              style={{ color: "#FFF", fontSize: "12px" }}
            ></h3>
            <h2
              className="profile-username text-center"
              style={{ color: "#FFF", fontSize: "15px" }}
            >
              <a href="#" className="d-block" onClick={logOff}>
                Sair
              </a>
              <br /> <br />
              <div style={{ borderBottom: '1px solid black' }}></div>
            </h2>
          </div>
        </div>
      </div>

      <p className="breadcrumb-item ml-3" style={{ color: "#FFF" }}>
        <a href="/home">
          <i className="fas fa-arrow-left mr-2" />
          <span className="brand-text">Voltar Demanda</span>
          
        </a>
      </p>
      <p>
        <hr style={{ color: "#FFF" }} />
      </p>
    </div>
  );
};
