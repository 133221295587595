import styled, { keyframes } from "styled-components";

export const AlertSuccess = styled.p`
  background-color: #d1e7dd;
  color: #0f5132;
  margin: 20px 0;
  border: 1px solid #badbcc;
  border-radius: 4px;
  padding: 7px;
`;

export const AlertDanger = styled.p`
  background-color: #f8d7da;
  color: #842029;
  margin: 20px 0;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  padding: 7px;
`;

export const BadgeContainer = styled.div`
  html,
  body {
    overflow: hidden;
  }

  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  align-items: center;
  background-color: #f0f0f0;
`;

export const BadgeCard = styled.div`
  width: 500px;
  max-width: 80%;
  height: auto;
  min-height: 700px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
`;

export const BadgeMiddleDivider = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ef476f;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #d62828;
`;

export const BadgeEmployeeImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffffff;
  object-fit: contain;
  border: 4px solid #ffffff;
  position: relative;
  top: -50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmployeeName = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;

  p {
    font-size: 1.4rem;
  }
`;

export const EmployeeData = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 10px;

  p {
    margin: 5px 0;
    font-size: 1.2rem;
    padding: 0.8rem 0;
  }
`;

export const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: #d62828;
  margin: 20px auto;
`;

export const Footer = styled.footer`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  color: #555;

  margin-bottom: 1.5rem;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;
