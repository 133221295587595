import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../services/api";
import { useUnidadesContext } from "./UnidadesContext";
import { useQuery } from "react-query";

const SprintDataContext = createContext();

export const useSprintData = () => useContext(SprintDataContext);

export const SprintDataProvider = ({ children }) => {
  const [allSprints, setAllSprints] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [selectedGravidade, setSelectedGravidade] = useState(null);

  const { currentUnidade } = useUnidadesContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setSelectedSprint(null);
  }, [currentUnidade]);

  const fetchSprints = async (signal) => {
    const params = {
      codUsuario: localStorage.getItem("@userControll"),
      ccusto: currentUnidade.ccusto,
      status: "S",
    };

    const response = await api.get("/controle-sprint", { params, signal });
    const sprintsRemovedBacklog = response.data.records.filter(
      (sprint) => sprint.id !== 0
    );

    return sprintsRemovedBacklog;
  };

  const fetchSelectedSprint = async (id, signal, params) => {
    try {
      setSelectedSprint(id);

      const response = await api.get(`controle-sprint/lista/${id}`, {
        signal,
        params,
      });
      return response.data.records[0];
    } catch (err) {
      console.log(err);
    }
  };

  const { data: initialSprints, isLoading: isLoadingSprints } = useQuery(
    ["sprint-list", currentUnidade],
    ({ signal }) => fetchSprints(signal),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAllSprints(data);
        setSelectedSprint(data[0].id);
      },
    }
  );

  const onChangeGravidade = (gravidade) => {
    setSelectedGravidade(gravidade);
  };

  const value = {
    sprints: allSprints,
    isLoadingSprints,
    selectedSprint,
    fetchSelectedSprint,
    selectedGravidade,
    onChangeGravidade,
  };

  return (
    <SprintDataContext.Provider value={value}>
      {children}
    </SprintDataContext.Provider>
  );
};
