import { useQuery } from "react-query";
import { ProjectsTable } from "./ProjectsTable";
import api from "../../../../services/api";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { ProjectsFilter } from "./ProjectsFilter";
import { ProjectMobileCard } from "./ProjectMobileCard";
import { Fragment } from "react";
import { ProjectsDataGrid } from "./ProjectsDataGrid";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";

const ProjetoGrid = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const { unidades, onChangeUnidade, currentUnidade, user } =
    useUnidadesContext();

  const { data: projectsList, isLoading } = useQuery(
    "project-list",
    async () => {
      const response = await api.get("/cadastro-projetos/status", {
        params: {
          status: "ANDAMENTO",
          codUsuario: user.codUsuario,
        },
      });

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          my: 10,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 4 }}>
      <ProjectsFilter />
      {!isMobile && <ProjectsDataGrid projects={projectsList} />}
      {isMobile && <ProjectMobileCard projects={projectsList} />}
    </Box>
  );
};

export default ProjetoGrid;
