/**
 * Formata uma string para deixar maiusculo a primeira letra de cada palavra.
 *
 * @param {string} str - A string a ser formatada.
 * @returns {string} A string formatada com a primeira letra de cada palavra em maiúscula.
 */
export const formatStringToCapitalize = (str) => {
  return str
    .split(/[\s-]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
