import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import AtendimTable from "./AtendimTable";
import { useQuery, useQueryClient } from "react-query";
import api from "../../../../services/api";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { PDFExport } from "@progress/kendo-react-pdf";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

import DemocrataLogo from "../../../../assets/democrata-logo.svg";
import TempoTrab from "./ChartTempTrab";
import Eficiencia from "./ChartEficiencia";
import Retorno from "./ChartRetorno";
import "./style.css";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";
import { getControle, getCC } from "../../../../services/auth";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";
import DataGridTable from "./DataGridTable";
import { PDFHeader } from "./PDFHeader";
import TotalizadorProdutiv from "./TotalizadorRelat";

const AtendimFilters = () => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedSprint, setSelectedSprint] = useState("");
  const [selectedTec, setSelectedTec] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Todos");
  const [manipulatedData, setManipulatedData] = useState([]);

  const UsuarioControle = getControle();
  const cCustoUser = getCC();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("1300"));
  const isMobile = useMediaQuery(theme.breakpoints.down("650"));

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );

  const { unidades, currentUnidade, onChangeUnidade } = useUnidadesContext();

  const { data: projList, isLoading: isLoadingProjList } = useQuery(
    "projectList",
    async () => {
      const response = await api.get(
        `/cadastro-projetos?codUsuario=${UsuarioControle}`
      );
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: sprintList, isLoading: isLoadingSprintList } = useQuery(
    ["sprintList", selectedProject],
    async () => {
      if (selectedProject && selectedProject !== "Todos") {
        const response = await api.get(
          `/controle-sprint/projeto/${selectedProject?.id}`
        );
        return response.data.records;
      }

      const response = await api.get(`/controle-sprint`);

      return {
        sprints: response.data.records,
      };
    },
    {
      refetchOnWindowFocus: false,
      onError: () => {
        return {
          sprints: [],
        };
      },
    }
  );

  const handleManipulatedData = (data) => {
    setManipulatedData(data);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
    setSelectedSprint("");
  };

  const handleSprintChange = (event) => {
    setSelectedSprint(event.target.value);
  };
  const handleTecChange = (event) => {
    setSelectedTec(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  const { data: tecnicos } = useQuery(
    ["tecnicos"],
    async () => {
      const response = await api.get(`/tecnico?codUsuario=${UsuarioControle}`);

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: graficos, isLoading: isLoadingGraficos } = useQuery(
    [
      "graficos",
      startDate,
      endDate,
      selectedProject,
      selectedSprint,
      selectedTec,
      currentUnidade,
      selectedStatus,
    ],
    async () => {
      let resp = `/controle-servico/relatorio-produtividade?`;

      if (startDate && endDate) {
        resp += `dtInicio=${dayjs(startDate).format("YYYY-MM-DD")}`;
        resp += `&dtFinal=${dayjs(endDate).format("YYYY-MM-DD")}`;
      }
      if (selectedProject !== "Todos" && selectedProject !== "") {
        resp += `&projeto=${selectedProject?.id}`;
      }
      if (selectedSprint !== "" && selectedSprint !== "Todos") {
        resp += `&sprintId=${selectedSprint?.id}`;
      }
      if (selectedTec && selectedTec !== "Todos") {
        resp += `&tecnico=${selectedTec?.codigo}`;
      }
      if (currentUnidade !== null) {
        resp += `&unidade=${currentUnidade.cnpj}`;
      }
      if (selectedStatus !== "Todos") {
        resp += `&status=${selectedStatus}`;
      }
      const response = await api.get(resp);

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log("graficos ..................", graficos);

  const resultado = graficos || null;

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <img
            style={{
              width: "190px",
              position: "absolute",
              top: "0",
              marginTop: "40px",
              marginBottom: "1000px",
            }}
            src={DemocrataLogo}
            alt="Logo"
          />
        </div>
      </section>

      <section
        className="content"
        style={{
          width: "100%",
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          top: 0,
          marginTop: "75px",
        }}
      >
        <div className="container-fluid mb-4">
          <p style={{ fontSize: "20px" }}>Relatório de Produtividade</p>
        </div>

        <div className="container-fluid mb-4 text-right">
          <small style={{ marginRight: "118px" }}>
            Data da Impressão {dataImpressao}
          </small>
        </div>
      </section>

      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: "20px",
          right: "40px",
          textAlign: "right",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "12px",
            marginRight: "40px",
          }}
        >
          Pg. <span>{props.pageNum}</span>/<span>{props.totalPages}</span>
        </span>
      </div>
    </div>
  );

  const htmlManut = () => {
    return (
      <Box
        component={"div"}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <PDFHeader
          dates={{
            start: dayjs(startDate).format("DD/MM/YYYY"),
            end: endDate.format("DD/MM/YYYY"),
          }}
          filters={{
            tecnico: selectedTec,
            unidade: currentUnidade?.empresa,
            projeto: selectedProject?.descricao,
            sprint: selectedSprint.nome,
            status: selectedStatus,
          }}
          task={{
            total: graficos?.controleServicos?.length,
            estimadas: converterParaHorasMinutos(
              graficos?.controleTotaisHoras?.totalHorasEstimadas || "00"
            ),
            trabalhadas: converterParaHorasMinutos(
              graficos?.controleTotaisHoras?.totalHorasTrabalhadas || "00"
            ),
            diferenca: converterParaHorasMinutos(
              graficos?.controleTotaisHoras?.totalHorasEstimadas || "00"
            ),
            auxiliares: converterParaHorasMinutos(
              graficos.controleTotaisHoras.totalHorasAuxiliares || "00"
            ),
            foraPeriodo: converterParaHorasMinutos(
              graficos.controleTotaisHoras?.totalHorasAuxiliaresForaPeriodo ||
                "00"
            ),
          }}
        />

        <AtendimTable id="atendimTable" data={graficos.controleServicos} />
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isTablet
            ? "1fr"
            : `1.3fr 1.3fr 1.1fr 1.1fr 1fr 1fr 1.2fr 0.5fr`,
          mb: 3,
          gap: 1,
          maxWidth: "100%",
          marginTop: 1.8,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"pt-br"}
        >
          <DatePicker
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" fullWidth />
            )}
            label="Data Inicial"
            dayOfWeekFormatter={(day, date) => {
              const daysOfWeek = [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sab",
              ];
              return daysOfWeek[day];
            }}
          />
        </LocalizationProvider>

        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"pt-br"}
        >
          <DatePicker
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            label={"Data Final"}
            dayOfWeekFormatter={(day, date) => {
              const daysOfWeek = [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sab",
              ];
              return daysOfWeek[day];
            }}
          />
        </LocalizationProvider>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-projeto-label">
            Projetos
          </InputLabel>
          <Select
            label="Projetos"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedProject || "Todos"}
            onChange={handleProjectChange}
          >
            <MenuItem value={"Todos"}>Todos</MenuItem>
            {/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
            {projList &&
              projList.map((project) => (
                <MenuItem key={project.id} value={project}>
                  {project.descricao}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-sprint-label">Sprint</InputLabel>
          <Select
            label="Sprint"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSprint || "Todos"}
            onChange={handleSprintChange}
          >
            <MenuItem value={"Todos"} disabled={isLoadingSprintList}>
              {isLoadingSprintList ? "Carregando..." : "Todos"}
            </MenuItem>
            {!isLoadingSprintList &&
              sprintList &&
              sprintList.sprints.map((sprint) => (
                <MenuItem key={sprint.id} value={sprint}>
                  {sprint.nome}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-tecnico-label">Técnico</InputLabel>
          <Select
            label="Técnico"
            labelId="demo-simple-select-tecnico-label"
            id="demo-simple-select-tecnico"
            value={selectedTec || "Todos"}
            onChange={handleTecChange}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
            {tecnicos &&
              tecnicos.map((tec) => (
                <MenuItem key={tec.codigo} value={tec}>
                  {tec.nome}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {cCustoUser !== "0012010301" ? null : (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-centro-label">
              Unidade Produção
            </InputLabel>
            <Select
              label="Unidade Produção"
              id="Unidade Produção"
              value={currentUnidade}
              onChange={(e) =>
                onChangeUnidade(
                  e.target.value === "Todos" ? null : e.target.value
                )
              }
            >
              <MenuItem value={"Todos"}>Todos</MenuItem>
              {unidades.map((item) => (
                <MenuItem key={item.cnpj} value={item}>
                  {item.empresa}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-status-label">Status</InputLabel>
          <Select
            label="Status"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <MenuItem value={"Todos"}>Todos</MenuItem>
            <MenuItem value={"ANDAMENTO"}>Andamento</MenuItem>
            <MenuItem value={"PENDENTE"}>Pendente</MenuItem>
            <MenuItem value={"TESTE"}>Teste</MenuItem>
            <MenuItem value={"CONCLUIDO"}>Concluído</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleExport}
          style={{ backgroundColor: "#03045e" }}
        >
          <LocalPrintshopOutlinedIcon />
        </Button>
      </Box>

      <Box>
      {!isLoadingGraficos && resultado && (
        <TotalizadorProdutiv
          graficos={graficos}
          isMobile={isMobile}
          isTablet={isTablet}
          converterParaHorasMinutos={converterParaHorasMinutos}
        />
      )}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isTablet ? "1fr" : "1fr 1fr",
          alignItems: "flex-end",
          gap: 1,
          marginTop: 2,
        }}
      >
        <Box id="productive-chart">
          {!isLoadingGraficos && resultado && (
            <>
              {resultado && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
                    gap: 1,
                  }}
                >
                  <Eficiencia chartEficiencia={graficos} />
                  <Retorno chartRetorno={graficos} />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box >
          {!isLoadingGraficos && resultado && (
            <TempoTrab
              dataInicio={startDate}
              dataFim={endDate}
              temp={graficos}
            />
          )}
        </Box>
      </Box>

      {!isLoadingGraficos && (
        <DataGridTable
          data={graficos?.controleServicos}
          handleManipulatedData={handleManipulatedData}
          manipulatedData={manipulatedData}
        />
      )}

      {/* {resultado && (
        <Box sx={{ overflow: "scroll" }}>
          <AtendimTable data={graficos.controleServicos} />
        </Box>
      )} */}

      {isLoadingGraficos ? (
        "Carregando..."
      ) : (
        <div
          style={{
            position: "relative",
            left: "-20000px",
            height: "10px",
            top: "-20000px",
          }}
        >
          <PDFExport
            scale={0.4}
            landscape="true"
            paperSize={"A4"}
            margin={{
              top: "1.5cm",
              left: "0.8cm",
              right: "0.8cm",
              bottom: "0.8cm",
            }}
            fileName={`relatorio-demanda-${new Date().toLocaleDateString(
              "pt-br"
            )}.pdf`}
            forcePageBreak=".page-break"
            pageTemplate={PDFExportPageTemplate}
            ref={pdf}
          >
            {htmlManut()}
          </PDFExport>
        </div>
      )}
    </>
  );
};

export default AtendimFilters;
