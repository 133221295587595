import React from "react";
import { Component } from "react";
import Header from "../../../../components/Header";
import Menu from "../../../../components/Menu";
import { ColaboradorEditar } from "./Component/ColaboradorEditar";
import Footer from "../../../../components/Footer";
import MenuOS from "../../../../components/MenuOS";



class Colaborador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Perfil Colaborador",
      nomeNivelCrumb: "Lista Registros",     
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <MenuOS />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">{this.state.nomePagina} </h1>
                  </div>

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="/colaborador">{this.state.nomeNivelCrumb}</a>
                      </li>
                     
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <ColaboradorEditar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Colaborador;
