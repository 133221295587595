import React from "react";

export const MsgSemSolic = ({ tipoProcess }) => {
  

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="invoice p-3 mb-3">
              <div className="col-md-12">
                {(() => {
                  if (tipoProcess != true) {
                    return (
                      <div className="info-box-content">
                        <div
                          className="info-box-text text-center text-muted"
                          style={{ textAlign: "center" }}
                        >
                          Você não têm nenhuma solicitação em Andamento!
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div className="info-box-text text-center text-muted">
                          Você não têm nenhuma solicitação Vinculada!
                        </div>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
