import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../services/api";
import {
  AlertSuccess,
  AlertDanger,
} from "../../../components/controles/styles";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TreeViewSub from "./TreeViewSub";
import { useEventosLinksContext } from "../../../contexts/LinksEventosContext";
import { Box } from "@mui/material";

export const EventosEditarSubcategorias = () => {
  const queryClient = useQueryClient();

  const { id } = useParams();
  const [novaDescricao, setNovaDescricao] = useState("");
  const [statusRecord, setStatusRecord] = useState({ type: "", mensagem: "" });
  const {eventoId, categoriaId, subCategoriaId, updateSubCategoriaId, updateCategoriaId} = useEventosLinksContext();

  const getEvento = async() => {
    const response = await api.get(`/demanda_eventos/${eventoId}`);
    return response.data.records[0].descricao;
  };

  const fetchCategoria = async () => {
    const response = await api.get(`/categorias/${id}`);
    return response.data.records[0];
  };

  const fetchSubCategoria = async () => {
    const response = await api.get(`/sub-categorias/categorias/${id}`);
    return response.data.records;
  };

  const fetchSelectedSubCategoria = async () => {
    const response = await api.get(`/sub-categorias/${subCategoriaId}`);

    setNovaDescricao(response.data.records[0].descricao);

    return response.data.records[0];
  };

  const { data: categoria, isLoading: isLoadingCategoria } = useQuery(
    "categoria",
    fetchCategoria
  );

  const { data: eventoNaSub } = useQuery("eventoNaSub", getEvento);


  const { data: subCategorias, isLoading: isLoadingSubCategorias } = useQuery(
    "subCategorias",
    fetchSubCategoria
  );

  const { data: selectedSubCategoria } = useQuery(
    ["selectedSubCategoria", subCategoriaId],
    fetchSelectedSubCategoria,
    {
      enabled: !!subCategoriaId,
    }
  );

  const handleSaveEditMutation = async ({ id, editedValue }) => {
    try {
      var dadosJson = {
        descricao: editedValue,
      };

      await api.put(`/sub-categorias/${id}`, dadosJson);
      setStatusRecord({
        type: "success",
        mensagem: "SubCategoria editada com sucesso",
      });
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na edição desta SubCategoria",
      });
    } finally {
      updateCategoriaId(null);

      setTimeout(() => {
        setStatusRecord({ type: "", mensagem: "" });
      }, 3000);
    }
  };

  const { mutate } = useMutation(handleSaveEditMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries("categoria");
      queryClient.invalidateQueries("subCategorias");
    },
  });

  const handleSaveEdit = (id, editedValue) => {
    mutate({ id, editedValue });
  };

  const newCategory = async ({ descricao, categoria }) => {
    try {
      var dadosJson = {
        evento: categoria.evento,
        descricao: descricao,
        categoria: categoria.id,
      };

      if (subCategoriaId && novaDescricao.length > 1) {
        const response = await api.put(
          `sub-categorias/${subCategoriaId}`,
          dadosJson
        );

        if (response.status === 200 || response.status === 201) {
          setNovaDescricao("");
          setStatusRecord({
            type: "success",
            mensagem: "SubCategoria editada com sucesso",
          });
        }

        return response;
      }

      await api.post(`sub-categorias/`, dadosJson);

      setNovaDescricao("");
      setStatusRecord({
        type: "success",
        mensagem: "SubCategoria criada com sucesso",
      });
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na criação desta SubCategoria",
      });
    } finally {
      updateSubCategoriaId(null);
      updateCategoriaId(null);
      setNovaDescricao("");

      setTimeout(() => {
        setStatusRecord({ type: "", mensagem: "" });
      }, 3000);
    }
  };

  const saveNewCategory = useMutation(newCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("subCategorias");
    },
  });

  return (
    <>
      <div>
        {statusRecord.type === "erro" ? (
          <AlertDanger>{statusRecord.mensagem}</AlertDanger>
        ) : (
          ""
        )}
        {statusRecord.type === "success" ? (
          <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="col-sm" style={{ color: "gray", fontSize: 15 }}>
            {!isLoadingCategoria && (
              <>
                {categoria.evento}
                {"."}
                {id} {" - "} {categoria.descricao}
              </>
            )}{" "}
          </label>
          <div className="card">
            <div className="card-body">
              <form className="form-horizontal">
                <div className="row">
                  <div className="col-sm-10">
                    <div>
                      <label>Descrição</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Descrição da nova SubCategoria..."
                        id="description"
                        name="description"
                        onChange={(e) => setNovaDescricao(e.target.value)}
                        value={novaDescricao}
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginTop: "30px", width: 100 }}
                      onClick={() =>
                        saveNewCategory.mutate({
                          categoria: categoria,
                          descricao: novaDescricao,
                        })
                      }
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row card">
                      <div className="col-sm-12">
                        <Box
                          sx={{
                            minHeight: 600,
                            flexGrow: 1,
                            //maxWidth: 1000,
                            fontSize: 20,
                          }}
                        >

                          <label className="col-sm">
                                      {
                                        <>
                                          {categoria?.evento}{" - "}
                                          {eventoNaSub}
                                        </>
                                      }{" "}
                                    </label>

                          <label className="col-sm">
                            {!isLoadingCategoria && (
                              <>
                                {categoria.evento}
                                {"."}
                                {id} {" - "} {categoria.descricao}
                              </>
                            )}{" "}
                          </label>
                          <TreeViewSub
                            data={subCategorias}
                            onSaveEdit={handleSaveEdit}
                          />
                        </Box>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
