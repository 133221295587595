import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import HistoryIcon from "@mui/icons-material/History";
import { useMetricasListContext } from "../../../contexts/MetricasListaContext";
import IconeUrgencia from "../../DetalheSprint/Components/IconeUrgencia";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconeStatus from "../BackLog/Component/IconeStatus";
import SprintModal from "../Sprint/components/SprintModal";
import dayjs from "dayjs";
import DemocrataLogo from "../../../assets/democrata-logo.svg";

import { PDFExport } from "@progress/kendo-react-pdf";
import { useQuery } from "react-query";
import api from "../../../services/api";
import { getControle } from "../../../services/auth";

export const MetricasList = (sprint) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")

  );

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const formatarDatacomHora = (stringData) => {
    const data = new Date(stringData);
    const dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    const horaFormatada = data.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${dataFormatada} ${horaFormatada}`;
  };

  const converteParaHoras = (totalHoras) => {
    const horas = Math.floor(totalHoras);
    const minutos = 0;
    return `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;
  };

  const converterParaHorasMinutos = (totalMinutos) => {
    const sinal = totalMinutos < 0 ? "-" : " ";
    totalMinutos = Math.abs(totalMinutos);

    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;

    const horasFormatadas =
      (sinal === "-" ? "-" : " ") + horas.toString().padStart(2, "0");
    const minutosFormatados = minutos.toString().padStart(2, "0");

    const resultado = `${horasFormatadas}:${minutosFormatados}`;
    return sinal === "-" ? `(${resultado})` : `${resultado}`;
  };

  const converterHoraEstimada = (mediaHoras) => {
    const partes = mediaHoras.toString().split(".");
    
    const horas = partes[0].padStart(2, "0");
    const minutos = (partes[1] || "00").padEnd(2, "0").substring(0, 2);
  
    return `${horas}:${minutos}`;
  };


  const urgenciaOrder = {
    Crítica: 1,
    Alta: 2,
    Atenção: 3,
    Média: 4,
    Baixa: 5,
    Planejada: 6,
    Success: 7,
  };

  const handleTaskSelect = (codigo) => {
    const selected = listaMetricas.chamados.find(
      (item) => item.codigo === codigo
    );
    setSelectedTask(selected);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedTask(null);
  };

  const usuarioControle = getControle();
  const { filters } = useMetricasListContext();

  const { data: listaMetricas, isLoading: isLoadingMetricas } = useQuery(
    ["listaMetricas", filters.sprint, filters.gravidade, filters.retorno],
    async () => {
      const params = {
        codUsuario: usuarioControle,
      };

      if (filters.gravidade) {
        // Busca específica por gravidade, retira a aprint da api pois estava vindo junto
        params.codGravidade = filters.gravidade;
      } 
      
      else if (filters.sprint) {
        // Busca específica por sprint
        params.codSprint = filters.sprint;

      }
        // Busca específica por retorno
      if (filters.retorno) {
        params.retorno = filters.retorno;
      }

      let response = await api.get(
        `controle-servico/metrica-individual/detalhe?`,
        {
          params,
        }
      );
      return response.data.records[0];
    },
    {
      enabled: !!filters.sprint || !!filters.gravidade || !!filters.retorno,
      refetchOnWindowFocus: false,
    }
  );

  console.log("filtros", filters)

  console.log("listaMetricas", listaMetricas)

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <img
            style={{
              width: "190px",
              position: "absolute",
              top: "0",
              marginTop: "40px",
              marginBottom: "1000px",
            }}
            src={DemocrataLogo}
            alt="Logo"
          />
        </div>
      </section>

      <section
        className="content"
        style={{
          width: "1200px",
          position: "absolute",
          top: 3,
          marginTop: "95px",
        }}
      >
        <div className="container-fluid mb-4">
          <p style={{ fontSize: "25px" }}>Resumo de Métricas Individuais</p>
        </div>

        <Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <strong className="mr-1">Total de chamados: </strong>{" "}
            <span className="mr-5">
              {listaMetricas?.totalizadorCabec?.totalChamados}
            </span>
            <strong className="mr-1">Horas Estimadas: </strong>{" "}
            <span className="mr-5">
              {converterHoraEstimada(
                listaMetricas?.totalizadorCabec?.horasEstimadas
              )}
            </span>
            <span sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <strong className="mr-1">Gravidade: </strong>
              {listaMetricas?.gravidadeCabec.map((item, index) => (
                <span key={index} style={{ marginRight: "20px" }}>
                  {item.gravidade}: {item.total}
                </span>
              ))}
            </span>
          </Box>
        </Typography>
      </section>

      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          margin: "30px 20px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "left",
        }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          Pg.
          <span>{props.pageNum}</span>/<span>{props.totalPages}</span>
        </span>
        <small
          style={{ width: "100%", textAlign: "right", margin: "30px 150px" }}
        >
          Data da Impressão {dataImpressao}
        </small>
      </div>
    </div>
  );

  const pdfExportComponent = useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };


  if (isLoadingMetricas) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box padding={2}>
        <Typography variant="h6">
          {listaMetricas?.chamados[0]?.sprintId?.nome}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr 1fr",
            alignItems: "center",
            gap: 2,
            flexWrap: isMobile ? "wrap" : "nowrap",
            minWidth: 0,
          }}
        >
          <Typography variant="body1" sx={{ marginTop: isMobile ? 2 : 0 }}>
            Total de chamados: {listaMetricas?.totalizadorCabec?.totalChamados}
          </Typography>

          <Typography variant="body1" display="flex" flexDirection="row">
            Horas Estimadas:{" "}
            {listaMetricas &&
              converterHoraEstimada(
                listaMetricas?.totalizadorCabec?.horasEstimadas
              )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gridTemplateColumns: isMobile
                ? "1fr 1fr 1fr 1fr"
                : "0.2fr 0.3fr 0.3fr 0.3fr",
              gap: 1,
              width: isMobile
                ? "100%"
                : listaMetricas?.gravidadeCabec.length == 2
                ? "270px"
                : listaMetricas?.gravidadeCabec.length == 1 && listaMetricas?.gravidadeCabec[0].gravidade == "Planejada"
                ? "190px"
                : listaMetricas?.gravidadeCabec.length == 1 && listaMetricas?.gravidadeCabec[0].gravidade == "Baixa"
                ? "155px"
                : listaMetricas?.gravidadeCabec.length == 1 && listaMetricas?.gravidadeCabec[0].gravidade == "Alta"
                ? "155px"
                : listaMetricas?.gravidadeCabec.length == 1 && listaMetricas?.gravidadeCabec[0].gravidade == "Média"
                ? "160px"
                : listaMetricas?.gravidadeCabec.length == 1 && listaMetricas?.gravidadeCabec[0].gravidade == "Crítica"
                ? "165px"
                : listaMetricas?.gravidadeCabec.length == 1 && listaMetricas?.gravidadeCabec[0].gravidade != "Planejada"
                ? "175px"
                : "450px",
              padding: 0,
              minWidth: 0,
              flexWrap: "wrap",
            }}
          >
            <Typography variant="body1" marginRight={1}>
              Gravidade:
              <span style={{  marginLeft: isMobile ? "15px" : 0 }}>
              {isMobile
                ? listaMetricas?.gravidadeCabec.map((item) => (
                    <span key={item.gravidade} style={{ marginRight: 15, display: "inline-flex" }}>
                      {item.gravidade}: {item.total}
                    </span>
                  ))
                : ""}
                </span>
            </Typography>

            {!isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {listaMetricas?.gravidadeCabec.map((item) => (
                  <Typography
                    key={item.gravidade}
                    variant="body1"
                    sx={{ marginRight: 0.5 }}
                  >
                    {item.gravidade}: {item.total}
                  </Typography>
                ))}
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: isMobile ? "flex-start" : "flex-end",
            }}
          >
            <Button
              variant="contained"
              size="smart"
              onClick={exportPDFWithComponent}
              sx={{ padding: 1.5, background: "#05095e" }}
            >
              <PrintIcon />
            </Button>
          </Box>
        </Box>

        <PDFExport
          ref={pdfExportComponent}
          scale={0.4}
          landscape="true"
          paperSize={"A4"}
          margin={{
            top: "3.5cm",
            left: "0.8cm",
            right: "0.8cm",
            bottom: "0.8cm",
          }}
          fileName="lista_Metricas-Individuais.pdf"
          forcePageBreak=".page-break"
          pageTemplate={PDFExportPageTemplate}
        >
          {listaMetricas?.chamados
            .sort(
              (a, b) =>
                urgenciaOrder[a.urgencia.descricao] -
                urgenciaOrder[b.urgencia.descricao]
            )
            .map((item, index) => (
              <Box
                key={index}
                marginTop={2}
                onClick={() => handleTaskSelect(item.codigo)}
                sx={{ cursor: "pointer" }}
              >
                {!isMobile ? (
                  <Card
                    key={item.id}
                    sx={{
                      mt: 2,
                      mb: 2,
                      borderLeft: `5px solid ${item.urgencia.cor}`,
                    }}
                  >
                    <CardContent
                      sx={{
                        px: 1,
                        display: "grid",
                        gridTemplateColumns:
                          "0.2fr 2fr minmax(150px, 1fr) minmax(115px, 1fr) 1fr 1fr minmax(80px, 1fr) 1fr 1fr",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Link
                          style={{
                            color: "black",
                            fontSize: 14,
                            cursor: "pointer",
                          }}
                        >
                          {item.codigo}
                        </Link>
                      </Typography>

                      <Link to={`/ChamadoItem/${item.codigo}`}>
                        <Tooltip title={item.titulo} arrow>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {truncateText(item.titulo, 22)}
                          </Typography>
                        </Tooltip>
                      </Link>

                      <Tooltip
                        title={"Solicitante"}
                        arrow
                        style={{ cursor: "pointer" }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {item.userName}
                        </Typography>
                      </Tooltip>

                      <Tooltip // Data aprovação Gerente
                        title={"Aprovação Gerencial "}
                        arrow
                        style={{ cursor: "pointer" }}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {item.dataGerente !== null &&
                            formatarDatacomHora(item.dataGerente)}

                          {!item.dataGerente && <Box />}
                        </Typography>
                      </Tooltip>

                      <Tooltip // Setor
                        title={"Setor"}
                        style={{ cursor: "pointer" }}
                        arrow
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {/* {truncateText(
                          item.userDetalhe.centroCustoDescricao.descricao,
                          10
                        )} */}
                        </Typography>
                      </Tooltip>

                      <Box // Ícone urgencia - Descrição urgencia - urgencia SLA
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          backgroundColor: item.urgencia.cor || "transparent",
                          color: "white",
                          borderRadius: 3,
                          border: "2px solid black",
                          minWidth: 170,
                          width: "100%",
                          py: 0.5,
                        }}
                      >
                        <IconeUrgencia
                          urgency={item.urgencia}
                          customIconColors={{
                            critica: "#fff",
                            atencao: "#fff",
                            media: "#000",
                            alta: "#fff",
                            baixa: "#fff",
                            planejada: "#fff",
                            success: "#fff",
                          }}
                        />

                        <Typography
                          sx={{
                            marginRight: "8px",
                            fontSize: "14px",
                            marginLeft: 1,
                            color:
                              item.urgencia.descricao === "Média"
                                ? "black"
                                : "white",
                          }}
                        >
                          {item.urgencia.descricao}
                        </Typography>

                        {item.urgencia.sla !== null &&
                          item.urgencia.sla !== 0 && (
                            <Box
                              data-label="sla"
                              display="flex"
                              alignItems="center"
                              sx={{
                                color:
                                  item.urgencia.descricao === "Média"
                                    ? "black"
                                    : "white",
                                cursor: "pointer",
                              }}
                            >
                              <AccessTimeIcon
                                fontSize="small"
                                className="mr-1"
                              />

                              <Tooltip
                                title="Horas sla"
                                sx={{ cursor: "pointer" }}
                                arrow
                              >
                                <span>
                                  {converteParaHoras(item.urgencia.sla)}
                                </span>
                              </Tooltip>
                            </Box>
                          )}
                      </Box>

                      <Box // Hora atual
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",

                          width: "100%",
                        }}
                      >
                        <Tooltip title="Hora atual" arrow>
                          <span>
                            {item.minutosRestantes < 0 &&
                              new Date().toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                          </span>
                        </Tooltip>
                      </Box>

                      <Box // Tempo restante
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        <Tooltip
                          arrow
                          title={
                            item.minutosRestantes < 0
                              ? "Tempo Ultrapassado"
                              : "Tempo Restante"
                          }
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color:
                                item?.minutosRestantes < 0 ? "red" : "#6C6767",
                              marginRight: item?.minutosRestantes < 0 ? 6 : 0,
                              marginLeft: item?.minutosRestantes > 0 ? 4 : 0,
                            }}
                          >
                            {converterParaHorasMinutos(item.minutosRestantes)}
                          </span>
                        </Tooltip>

                        <HistoryIcon
                          className={
                            item?.minutosRestantes < 0 ? "fa-blink" : ""
                          }
                          style={{
                            fontSize: "18px",
                            color:
                              item?.minutosRestantes < 0 ? "red" : "#6C6767",
                            marginLeft: item?.minutosRestantes >= 0 ? 13 : 0,
                          }}
                        />
                      </Box>

                      <Tooltip
                        title={item.codigoControleServicoTecnico?.nome}
                        arrow
                      >
                        <Avatar
                          src={item.codigoControleServicoTecnico?.imagem}
                          sx={{ width: 30, height: 30 }}
                        />
                      </Tooltip>
                    </CardContent>
                  </Card>
                ) : (
                  <Card
                    key={item.codigo}
                    sx={{ mt: 1, borderTop: `2px solid ${item.urgencia.cor}` }}
                  >
                    <CardContent>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          bgcolor="#F2F2F2"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              <Link
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              >
                                {item.codigo}
                              </Link>
                            </Typography>
                            <IconeUrgencia urgency={item.urgencia} />
                          </Box>

                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }} // Tempo restante
                            >
                              <HistoryIcon
                                className={
                                  item?.minutosRestantes < 0 ? "fa-blink" : ""
                                }
                                style={{
                                  fontSize: "18px",
                                  marginRight: 4,
                                  color:
                                    item?.minutosRestantes < 0
                                      ? "red"
                                      : "#6C6767",
                                }}
                              />

                              <Tooltip
                                title={
                                  item.minutosRestantes < 0
                                    ? "Tempo Ultrapassado"
                                    : "Tempo Restante"
                                }
                              >
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color:
                                      item?.minutosRestantes < 0
                                        ? "red"
                                        : "#6C6767",
                                  }}
                                >
                                  {converterParaHorasMinutos(
                                    item.minutosRestantes
                                  )}
                                </span>
                              </Tooltip>
                            </Box>
                          </Box>

                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                              title={item.codigoControleServicoTecnico?.nome}
                            >
                              <Avatar
                                src={item.codigoControleServicoTecnico?.imagem}
                                sx={{ width: 30, height: 30 }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mt={2}
                          mb={1}
                        >
                          <Tooltip title={"Solicitante"} arrow>
                            <Typography variant="subtitle2">
                              {item.userName}
                            </Typography>
                          </Tooltip>
                        </Box>

                        <Tooltip title={"Título do Chamado"} arrow>
                          <Typography sx={{ mb: 1 }} variant="subtitle2">
                            {item.titulo}
                          </Typography>
                        </Tooltip>
                      </Box>

                      <Box
                        sx={{
                          gridTemplateColumns: "2fr 0.1fr 0.2fr",
                          display: "grid",
                        }}
                      >
                        <Tooltip title={"Aprovação Gerencial"} arrow>
                          <Typography
                            variant="subtitle2"
                            sx={{ width: "fit-content" }}
                          >
                            {item.dataGerente !== null ? (
                              formatarDatacomHora(item.dataGerente)
                            ) : (
                              <Box sx={{ visibility: "hidden" }}>
                                {"--------"}
                              </Box>
                            )}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ flexGrow: 1 }} />

                        <IconeStatus task={item} />
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
            ))}
        </PDFExport>
      </Box>

      {modalIsOpen && selectedTask && (
        <SprintModal
          showModal={modalIsOpen}
          closeModal={handleCloseModal}
          data={selectedTask.codigo}
        />
      )}
    </>
  );
};
