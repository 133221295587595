import React, { Component } from "react";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import Footer from "../../../components/Footer";
import HotelGrid from "./Componentes/HotelGrid";

class Hoteis extends Component {
  handleNewItem = (e) => {
    this.props.history.push("/hotelItem/0");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Hoteis",
      nomeNivelCrumb: "Listar Hoteis",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <a
                            className="btn btn-app bg-success"
                            style={{ borderRadius: "5px" }}
                            onClick={this.handleNewItem}
                          >
                            <i className="fas fa-hotel" /> Novo Hotel
                          </a>
                        </ol>
                      </div>
                    </div>
                    <HotelGrid />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Hoteis;
