import React, { useEffect, useState } from "react";
import { getControle } from "../../../services/auth";
import api from "../../../services/api";

/**
 * Tras todos os comentários das mensagens por demanda e processos
 * exemplo de uso
 * <TotaisComentarios tipo={"DEMANDA"} parametros={ID da demanda} />
 * <TotaisComentarios tipo={"PROCESSOS"} parametros={ID do processos} />
 * @param {*} param0
 * @returns
 */
export default function TotaisComentarios({ tipo, parametros, cortipo }) {
  const [dataTotais, setDataTotais] = useState([]);

  useEffect(() => {
 
    
      if (tipo == "PROCESSOS") {
        api.get(`/demanda_mensagem/processo/` + parametros).then((response) => {
          setDataTotais(
            response.data.records
          );
        });
      } else {
        api.get(`/demanda_mensagem/demanda/` + parametros).then((response) => {
          setDataTotais(
            response.data.records
          );
        });
      }
 
  }, [tipo, parametros]);


  

  return (
     
      <>
          <a style={{ fontSize: 18}}  className="btn btn-sm bg-teal">
            <i className="fas fa-comments"></i><br />
            {dataTotais.length}
            </a>
      </>

     
    
    
  );
}
