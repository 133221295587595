import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";
import { useEffect } from "react";

export const MobileCard = ({ task, handleTaskSelect, page }) => {
  const status = {
    PENDENTE: {
      label: "Pendente",
      background: "warning",
      color: "warning",
    },
    ANDAMENTO: {
      label: "Andamento",
      background: "primary",
      color: "#fff",
    },
    TESTE: {
      label: "Teste",
      background: "default",
      color: "#fff",
    },
    CONCLUIDO: {
      label: "Concluido",
      background: "success",
      color: "success",
    },
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  return (
    <Box sx={{ m: 2 }}>
      <Accordion
        key={task.codigo}
        sx={{ margin: "0 2 0 2", cursor: "pointer", width: "100%" }}
      >
        <AccordionSummary>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#E8EAF6",
                borderRadius: "5px",
              }}
            >
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  handleTaskSelect(task.codigo);
                }}
              >
                <Typography variant="body2" fontWeight={500}>
                  Chamado: {task.codigo}
                </Typography>
              </Link>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography variant="body2" fontWeight={500}>
                Solicitante: {task.userName}
              </Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{ textAlign: "left" }}
              >
                {(() => {
                  const dateParts = task.datcad.split("T")[0].split("-");
                  const timeParts = task.datcad.split("T")[1].split(":");
                  const date = new Date(
                    dateParts[0],
                    dateParts[1] - 1,
                    dateParts[2],
                    timeParts[0],
                    timeParts[1]
                  );
                  return (
                    // biome-ignore lint/style/useTemplate: <explanation>
                    date.toLocaleDateString("pt-BR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }) +
                    " " +
                    date.toLocaleTimeString("pt-BR", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  );
                })()}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 1,
              }}
            >
              <Typography variant="body2" fontWeight={500}>
                Setor: {task.tipo.descricao}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 1,
              }}
            >
              <Typography variant="body2" fontWeight={500}>
                Título: {task.titulo}
              </Typography>
            </Box>

            <Box style={{ marginTop: "10px" }}>
              <Typography variant="body2" fontWeight={500}>
                {status[task.status] ? (
                  <Chip
                    label={`${status[task.status].label}`}
                    color={status[task.status].background || "default"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "5px",
                    }}
                  />
                ) : null}
              </Typography>
            </Box>

            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "2px" }}
            >
              <Grid item xs={6}>
                <Card style={{ background: "#E8EAF6" }}>
                  <CardContent>
                    <Typography variant="body2" fontWeight={500} align="center">
                      Técnico
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        align="center"
                      >
                        {task.codigoControleServicoTecnico?.usuario ||
                          "Não atribuído"}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card style={{ background: "#FFF59D" }}>
                  <CardContent>
                    <Typography variant="body2" fontWeight={500} align="center">
                      Horas Estimadas
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        align="center"
                      >
                        {converterParaHorasMinutos(task.horasEstimadas || "00")}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card style={{ background: "#D3D3D3" }}>
                  <CardContent>
                    <Typography variant="body2" fontWeight={500} align="center">
                      Horas Auxiliares
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        align="center"
                      >
                        {converterParaHorasMinutos(
                          task.horasAuxiliares || "00"
                        )}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card style={{ background: "#FFE082" }}>
                  <CardContent>
                    <Typography variant="body2" fontWeight={500} align="center">
                      Horas Trabalhadas
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        align="center"
                      >
                        {converterParaHorasMinutos(
                          task.totalHorasTrabalhadas || "00"
                        )}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card style={{ background: "#83e9cd" }}>
                  <CardContent>
                    <Typography variant="body2" fontWeight={500} align="center">
                      Horas Restantes
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        align="center"
                      >
                        {converterParaHorasMinutos(
                          task.totalHorasRestantes ||
                            task.totalHorasExcedentes ||
                            "00"
                        )}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!!task.controleTempoResponse?.length && !!task.horasAuxiliares && (
            <>
              <Typography
                sx={{ marginLeft: "8px", fontSize: "16px", fontWeight: "bold" }}
                component="div"
              >
                Registros Auxiliares
              </Typography>
              {task.controleTempoResponse.map((aux, index) => (
                <Card sx={{ marginBottom: "8px" }} key={index}>
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Data do Auxilio:
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        <strong>{aux.data}</strong>
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Técnico:
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        <strong>{aux.tecnicoClass.usuario}</strong>
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Tipo Serviço:
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        <strong>{aux.tipoServicoClass.descricao}</strong>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: "#83e9cd",
                        borderRadius: 1,
                        boxShadow: 1,
                        color: "#00695C",
                        width: "100%",
                        textAlign: "center",
                        padding: "2px",
                        marginBottom: "8px",
                      }}
                    >
                      Horas Gastas:
                      <strong>{converterParaHorasMinutos(aux.tempo)}</strong>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </>
          )}

          {!!task && task.controleTempoForaPeriodo.length > 0 && (
            <>
              <Typography
                sx={{
                  ml: 1,
                  mt: 2,
                  fontWeight: "bold",
                }}
                component="div"
              >
                Registros Fora Periodo
              </Typography>
              {task.controleTempoForaPeriodo.map((aux, index) => (
                <Card
                  key={index}
                  sx={{
                    marginBottom: "20px",
                    padding: "16px",
                    boxShadow: 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Data do Auxilio:
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", fontSize: "12px" }}
                        color="textSecondary"
                      >
                        <strong>{aux.data}</strong>
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Técnico
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", fontSize: "12px" }}
                        color="textSecondary"
                      >
                        <strong>{aux.tecnicoClass.usuario}</strong>
                      </Typography>
                    </Box>

                    {/* sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        fontSize: "12px",
                      }} */}

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{ fontSize: "12px" }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Tipo Serviço
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", fontSize: "12px" }}
                        color="textSecondary"
                      >
                        <strong>{aux.tipoServicoClass.descricao}</strong>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: "#83e9cd",
                        borderRadius: 1,
                        boxShadow: 1,
                        color: "#00695C",
                        width: "100%",
                        textAlign: "center",
                        padding: "2px",
                        fontSize: "12px",
                        margin: "0 auto",
                      }}
                      color="textSecondary"
                    >
                      Horas Gastas : <strong>{converterParaHorasMinutos(aux.tempo)}</strong>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
