import React from "react";
import { IconAnexos } from "../../Sprint/components/IconAnexos";
import { Box } from "@mui/material";

const Anexos = ({ task, excluirAnexo }) => {
  return (
    <Box>
      {task && task.anexos.length > 0 && (
        <>
          <Box>
            <IconAnexos
              anexos={task.anexos}
              iconButtonMostrar={true}
              onDelete={excluirAnexo}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Anexos;
