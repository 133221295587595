import { useQuery, useQueryClient } from "react-query";
import api from "../../../services/api";
import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TaskCard } from "../../DetalheSprint/Components/TaskCard";
import { useState } from "react";
import SprintModal from "../Sprint/components/SprintModal";

import { useUserSprint } from "../../../contexts/UserSprint";
import { converterParaHorasMinutos } from "../../../utils/convertToHoursMinutes";
import { SkeletonKanban } from "../../DetalheSprint/Components/SkeletonKanban";
import moment from "moment";

export const ProjetosKanbanList = ({ filters, totals }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { selectedUser } = useUserSprint();

  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: tasks, isLoading } = useQuery(
    ["projeto-chamados", id, selectedUser, filters],
    async () => {
      const formattedDates = {
        initialDate: moment(filters.initialDate).format("YYYY-MM-DD"),
        endDate: moment(filters.endDate).format("YYYY-MM-DD"),
      };

      const params = {
        projeto: id,
      };

      if (selectedUser) {
        params.tecnico = selectedUser.codigo;
      }

      if (filters.initialDate && filters.endDate) {
        params.dtInicio = formattedDates.initialDate;
        params.dtFinal = formattedDates.endDate;
      }

      if (filters.status) {
        params.status = filters.status;
      }

      const response = await api.get("/controle-servico/busca", {
        params,
      });

      return {
        records: response.data.records,
        meta: response.data.meta,
      };
    },
    {
      keepPreviousData: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const taskCategories = !isLoading && [
    {
      name: "A Fazer / Pendente",
      background: "#bbdefb",
      color: "#0d47a1",
      data: tasks.records.filter((task) => task.status === "PENDENTE"),
      horasEstimadas:
        totals.projectTotals.records[0].horasEstimadasPendente ?? 0,
      total: totals.projectTotals.records[0].pendente,
    },
    {
      name: "Andamento",
      background: "#fff3e0",
      color: "#ffa000",
      data: tasks.records.filter((task) => task.status === "ANDAMENTO"),
      horasEstimadas: converterParaHorasMinutos(
        totals.projectTotals.records[0].horasEstimadasAndamento || "00"
      ),
      total: totals.projectTotals.records[0].andamento,
    },
    {
      name: "Teste",
      background: "#e0e0e0",
      color: "#424242",
      data: tasks.records.filter((task) => task.status === "TESTE"),
      horasEstimadas: converterParaHorasMinutos(
        totals.projectTotals.records[0].horasTrabalhadasTeste || "00"
      ),
      total: totals.projectTotals.records[0].teste,
    },
    {
      name: "Concluido",
      background: "#aed581",
      color: "#33691e",
      data: tasks.records.filter((task) => task.status === "CONCLUIDO"),
      horasEstimadas: converterParaHorasMinutos(
        totals.projectTotals.records[0].horasTrabalhadasConcluido || "00"
      ),
      total: totals.projectTotals.records[0].concluido,
    },
  ];

  const handleCloseModal = () => {
    setModalIsOpen(!modalIsOpen);

    if (modalIsOpen) {
      queryClient.invalidateQueries("projeto-chamados");
    }
  };

  const handleTaskSelect = (task) => {
    setSelectedTask(task);

    setModalIsOpen(!modalIsOpen);
  };

  return (
    <Box mt={4}>
      {isLoading && <SkeletonKanban />}

      {!isLoading && (
        <Box
          sx={{
            display: isMobile ? "block" : "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr 1fr",
            gap: 1,
            width: "100%",
            overflow: "auto",
            maxHeight: isMobile ? "100vh" : "60vh",
          }}
        >
          {taskCategories.map((category) => (
            <Box key={category.name} width={"100%"} minWidth={300}>
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                  backgroundColor: category.background,
                  p: 2,
                  mb: 2,
                  borderRadius: "5px",
                  boxShadow:
                    "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px",
                  display: "flex",
                  justifyContent: "space-between",
                  minWidth: "70%",
                }}
              >
                <Typography sx={{ color: category.color }}>
                  {category.name}
                </Typography>
                <Tooltip title="chamados" arrow>
                  <Typography color={category.color}>
                    {category.total ? category.total : 0} Qtde
                  </Typography>
                </Tooltip>
                {category.name !== "A Fazer / Pendente" && (
                  <Tooltip title="horas" arrow>
                    <Typography color={category.color}>
                      {category.horasEstimadas}
                    </Typography>
                  </Tooltip>
                )}
              </Box>

              {category.data
                .sort((a, b) => a.id - b.id)
                .map((task) => (
                  <Box
                    key={task.codigo}
                    onClick={() => {
                      setSelectedTask(task.codigo);
                      handleCloseModal();
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <TaskCard
                      taskData={task}
                      openTask={() => handleTaskSelect(task.codigo)}
                    />
                  </Box>
                ))}
            </Box>
          ))}

          {selectedTask && modalIsOpen && (
            <SprintModal
              showModal={modalIsOpen}
              closeModal={handleCloseModal}
              data={selectedTask}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
