import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import csv from "../../../../assets/icones/csv.png";
import xls from "../../../../assets/icones/excel.png";
import pdf from "../../../../assets/icones/pdf.png";
import zip from "../../../../assets/icones/zip.png";
import pptx from "../../../../assets/icones/pptx.png";
import png from "../../../../assets/icones/png.png";
import rar from "../../../../assets/icones/rar.png";
import doc from "../../../../assets/icones/doc.png";
import jpg from "../../../../assets/icones/jpg.png";
import txt from "../../../../assets/icones/txt.png";
import mp4 from "../../../../assets/icones/mp4.png";
import xml from "../../../../assets/icones/xml.png";
/* import outros from "../../../../assets/icones/arquivo.png" */
import api from "../../../../services/api";

const fileIcons = {
  ".xls": xls,
  ".xlsx": xls,
  ".docx": doc,
  ".doc": doc,
  ".png": png,
  ".jpeg": jpg,
  ".jpg": jpg,
  ".zip": zip,
  ".rar": rar,
  ".pptx": pptx,
  ".csv": csv,
  ".pdf": pdf,
  ".txt": txt,
  ".mp4": mp4,
  ".xml": xml,
  /* ".outros": outros, */
};


export const AnexosAbertura = ({ anexo }) => {
  const getFileIcon = (filePath) => {
    const extension = filePath.slice(
      ((filePath.lastIndexOf(".") - 1) >>> 0) + 2
    );
    return fileIcons[`.${extension.toLowerCase()}`] || null;
  };

  const [isLoading, setIsLoading] = useState(false);

  const excluirFoto = async (img, event) => {
    try {
      setIsLoading(true);
      await api.delete(`/controle-anexos/${img}`)
        
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex">
      {anexo.map((anexos) => {
        const iconSrc = getFileIcon(anexos.path);
        return (
          iconSrc && (
            <div key={anexos.id} className="ml-3">

              <a
                href={anexos.path}
                style={{ marginRight: "5px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tooltip title={anexos.nome}>
                  <img
                    className="rounded mx-auto d-block img-thumbnail"
                    style={{ width: "55px" }}
                    src={iconSrc}
                    alt={anexos.nome}
                  />
                </Tooltip>
              </a>
            </div>
          )
        );
      })}
    </div>
  );
};
