import React from "react";

import App from "./App";

import { createRoot } from "react-dom/client";
import { registerLicense } from "@syncfusion/ej2-base";

const container = document.getElementById("root");
const root = createRoot(container);

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxcdXRSQ2RYUkxxWUU="
);

root.render(<App />);
