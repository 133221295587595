import React, { useState } from "react";
import api from "../../../services/api";
import "../../../components/controles/css/listUsuario.css";
import { FormLabel } from "react-bootstrap";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import UsuarioAtivo from "./UsuarioAtivo";
import { getControle } from "../../../services/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function UsuarioSolicitacoes({ idDemanda }) {
  const [selectedUsuario, setSelectedUsuario] = useState(0);
  const usuario = getControle();
  const queryClient = useQueryClient();

  const { data: listaUsuarioVinculado, isLoading: isLoadingLista } = useQuery(
    ["listaUsuarioVinculado", idDemanda],
    async () => {
      const response = await api.get(`vincular_usuario/demanda/${idDemanda}`);

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: listUsuario, isLoading: isLoadingUsuario } = useQuery(
    ["listUsuario", usuario],
    async () => {
      const response = await api.get(`/usuarioDetalhe?codUsuario=${usuario}`);

      return response.data.records;
    }, {
    refetchOnWindowFocus: false,
  }
  );

  const { mutate: vincularUsuario } = useMutation(
    async (dadosJson) => {
      const response = await api.post(`vincular_usuario`, dadosJson);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["listaUsuarioVinculado", idDemanda]);
      },
      onSettled: () => {
        setSelectedUsuario(0)
      }
    }
  );

  const handleChangeUsuario = (event) => {
    const valor = event.target.value;
    setSelectedUsuario(valor);

    const dadosJson = {
      demanda: idDemanda,
      usuario: valor,
      status: 0,
    };

    vincularUsuario(dadosJson);
  };

  if (isLoadingUsuario && isLoadingLista) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className="form-group">
        <FormLabel>Inserir novo relacionado</FormLabel>
        <Select
          id="demo-controlled-open-select-label"
          value={selectedUsuario}
          onChange={handleChangeUsuario}
          className="ls-select"
          style={{ width: "100%" }}
        >
          {listUsuario &&
            listUsuario.map((item) => (
              <MenuItem key={item.codUsuario} value={item.codUsuario}>
                {item.descricao}
              </MenuItem>
            ))}
        </Select>
      </div>

      <div id="plist" className="people-list">
        <ul className="list-unstyled chat-list mt-2 mb-0">
          {listaUsuarioVinculado &&
            listaUsuarioVinculado
              .filter((usuar) => {
                return usuar.usuario.codUsuario != getControle();
              })
              .map((item) => (
                <div key={item.id} className="card card-widget widget-user-2">
                  <div className="widget-user-header bg-info">
                    <h6>{item.usuario.descricao}</h6>
                  </div>
                  <div className="card-footer p-0">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <UsuarioAtivo
                          idDemanda={idDemanda}
                          idControle={item.usuario.codUsuario}
                          usuarioProcesso={item}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
        </ul>
      </div>
    </>
  );
}
