import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import api from "../../../services/api";
import { getControle } from "../../../services/auth";
import { useHistory } from "react-router-dom";
import { Flaky } from "@mui/icons-material";

export const UserUnlinkModal = ({ task, user, onClose }) => {
  const [unlinkMessage, setUnlinkMessage] = useState("");
  const [myUserData, setMyUserData] = useState(null);
  const [requesterLinkedUser, setRequesterLinkedUser] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const handleUnlinkMessage = useCallback((event) => {
    setUnlinkMessage(event.target.value);
  }, []);

  const fetchUserData = async () => {
    try {
      await api.get(`demanda_processos/demanda/` + task.id).then((response) => {
        setMyUserData(
          response.data.records.find(
            (user) => user.usuario.usuario.codUsuario == getControle()
          )
        );
      });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUsersVinculo = async () => {
    const response = await api.get(`vincular_usuario/demanda/${task.id}`);

    const findRequester = response.data.records.find(
      (user) => user.usuario.codUsuario === task.solicitante.codUsuario
    );

    setRequesterLinkedUser(findRequester);
  };

  useEffect(() => {
    fetchUserData();
    fetchUsersVinculo();
  }, []);

  const handleConfirmUnlink = async () => {
    try {
      setIsLoading(true);
      const unlinkRequestJSON = {
        demanda: task.id,
        processos: myUserData.id, // Este é o ID do usuário (processo) na demanda, e não o ID do usuário.
        descricao: unlinkMessage,
        dataHora: new Date().toISOString(),
        solicitante: requesterLinkedUser,
        status: 1,
      };

      const updateTaskJSON = {
        tipoStatus: "INATIVO",
      };

      await Promise.all([
        api.post("/demanda_mensagem/", unlinkRequestJSON),
        api.put(`/demanda_processos/${myUserData.id}`, updateTaskJSON),
      ]);
      history.push("/home");
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogContent>
        <Typography variant="h6">Obrigatório informar o motivo.</Typography>
        <TextField
          sx={{ mt: 2 }}
          label="Motivo"
          multiline
          onChange={handleUnlinkMessage}
          value={unlinkMessage}
          rows={4}
          fullWidth
          variant="outlined"
        />

        {unlinkMessage.length < 10 && (
          <Typography variant="caption" color="error">
            O motivo deve ter no mínimo 10 caracteres.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant="contained"
          disabled={unlinkMessage.length < 10 || isLoading}
          onClick={handleConfirmUnlink}
        >
          {isLoading ? <CircularProgress /> : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
