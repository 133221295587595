import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const MetricasListContext = createContext();

export const MetricasListProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const getFiltersFromURL = () => {
    const params = new URLSearchParams(location.search);
    return {
      sprint: params.get("sprint") || "",
      gravidade: params.get("gravidade") || "",
      retorno: params.get("retorno") || "",
    };
  };

  const [filters, setFilters] = useState(getFiltersFromURL);

  useEffect(() => {
    const params = new URLSearchParams();
    if (filters.sprint) params.set("sprint", filters.sprint);
    if (filters.gravidade) params.set("gravidade", filters.gravidade);
    if (filters.retorno) params.set("retorno", filters.retorno);
    history.replace({ search: params.toString() });
  }, [filters, history]);

  return (
    <MetricasListContext.Provider value={{ filters, setFilters }}>
      {children}
    </MetricasListContext.Provider>
  );
};

export const useMetricasListContext = () => {
  return useContext(MetricasListContext);
};