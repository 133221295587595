import React from 'react';
import { FormControl, Select, MenuItem, Avatar, InputLabel, Box, Typography } from '@mui/material';

export const AssignResponsavel = ({ responsavel, filteredResponsavel, statusChamado, handleChangeResponsavel }) => {
  return (
    <FormControl sx={{marginTop: 1}}>
      <Typography variant="body2" fontWeight={500} color="black"> Atribuir Responsável</Typography>
      <Select
        label="Sprint"
        value={responsavel?.codigo}
        sx={{
          height: 42,
          m: 1,
          minWidth: 150,
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border:
              responsavel?.codigo === 9
                ? "2px solid red"
                : "1px solid rgba(0, 0, 0, 0.23)",
          },
        }}
        labelId="responsavel"
        id="responsavel"
        size="small"
      >
        {filteredResponsavel
          .sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          })
          .map((item) => (
            <MenuItem
              disabled={statusChamado.current != "PENDENTE"}
              key={item.codigo}
              value={item.codigo}
              onClick={() => handleChangeResponsavel(item)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  className="mr-2"
                  src={item.imagem}
                  width={15}
                  height={15}
                />
                {item.nome}
              </Box>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};