export const converterParaHorasMinutos = (mediaHoras) => {
  if (!mediaHoras) {
    return ``;
  }

  if (mediaHoras.toString().includes(".")) {
    const [horas, minutos] = mediaHoras.toString().split(".");
    const horasFormatadas = horas.padStart(2, "0");
    const minutosFormatados = minutos.padEnd(2, "0");
    return `${horasFormatadas}:${minutosFormatados.slice(0, 2)}`;
  } else {
    const horas = mediaHoras.toString().padStart(2, "0");
    return `${horas}:00`;
  }
};
