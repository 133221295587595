import { useState, useEffect } from "react";
import { getControle } from "../../../services/auth";
import api from "../../../services/api";


const TotaisSolicitacoes = ({setValor}) => {
  const [data, setData] = useState([]);


  useEffect(() => {
    api.get("/demanda/solicitante/" + getControle()).then((response) => {
      setData(response.data.records);
      /* console.log("response.data.records====", response.data.records); */
    });
  }, [getControle()]);


  var somatorioSolicitadas = data.filter(
    (solic) => solic.status >= 0 ).length
  setValor(somatorioSolicitadas);
  /* console.log("quantidadeElementos1=", somatorioSolicitadas); */

  return (
    <>
      <b className="mr-1" style={{ color: 'black' }}> {somatorioSolicitadas}</b><h7 style={{ color: 'black' }}></h7>
    </>
  );
};

export default TotaisSolicitacoes;
