export const TOKEN_KEY = "@demandaToken";
export const USER_CONTROLL = "@userControll";
export const USER_TECNICO = "@userTecnico";
export const USERCC = "@cc";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getControle = () => localStorage.getItem(USER_CONTROLL);
export const getTecnico = () => localStorage.getItem(USER_TECNICO);
export const getCC = () => localStorage.getItem(USERCC);

export const login = (token, usuario, nomeUsuario, tecnico) => {
  localStorage.setItem(TOKEN_KEY, token);

  localStorage.setItem(USER_CONTROLL, usuario);

  localStorage.setItem("USER_NAME", nomeUsuario);

  localStorage.setItem(USER_TECNICO, JSON.stringify(tecnico));
};

export const cc = (cod) => {
  localStorage.setItem(USERCC, cod);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_CONTROLL);
};
