import { Box } from "@mui/material";
import { useWindowSize } from "../hooks/useWindowSize";
import { calculateGridColumns } from "../utils/calculateGridColumns";

export const CustomGrid = ({ children, columns, custom }) => {
  const { width } = useWindowSize();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${calculateGridColumns(
          width,
          columns
        )}, 1fr)`,
        gap: 1,
        ...custom,
      }}
    >
      {children}
    </Box>
  );
};
