import React, { Component } from "react";
import Solicitacoes from "../pages/Processos/Component/Solicitacoes";

export default class Dashboard extends Component {
  render() {
    return (
      <div>
        <div className="content-wrapper">
          <Solicitacoes />
        </div>
      </div>
    );
  }
}
