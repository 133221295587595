import React, { useState, useEffect } from "react";
import { getCC } from "../../services/auth";
const menuJson = require("./menuElement.json");

export const MenuItem = () => {
  const [data, setData] = useState(menuJson.data);

  return (
    <div>
      {data
        .filter((st) => st.ativo == "S")
        
        .map(
          (item) => (
            (
              <div>
                {(() => {
                    if (item.ccusto == "") {
                        return (
                            (
                              <li className="nav-item" key={item.id}>
                                <a href="#" className="nav-link">
                                  <i className={item.nomeIcon} />
      
                                  <p className="ml-1">
                                    {item.itemPaiLabel} {/* {getCC()} */} {/* Demandas */}
                                    <i className="right fas fa-angle-left" />
                                  </p>
                                </a>
      
                                <ul className="nav nav-treeview">
                                  {item.menuIten.map(
                                    (mitem) => (
                                      (
                                        <li className="nav-item" key={mitem.id}>
                                          <a href={mitem.link} className="nav-link">
                                            <i className={mitem.nomeIcon} />
                                            <p>{mitem.elementoLabel}</p>
                                          </a>
                                        </li>
                                      )
                                    )
                                  )}
                                </ul>
                              </li>
                            )
                          );
                         } else if (item.ccusto == getCC()) {
                    return (
                      (
                        <li className="nav-item" key={item.id}>
                          <a href="#" className="nav-link">
                            <i className={item.nomeIcon} />

                            <p className="ml-1">
                              {item.itemPaiLabel}
                              <i className="right fas fa-angle-left" />
                            </p>
                          </a>

                          <ul className="nav nav-treeview">
                            {item.menuIten.map(
                              (mitem) => (
                                (
                                  <li className="nav-item" key={mitem.id}>
                                    <a href={mitem.link} className="nav-link">
                                      <i className={mitem.nomeIcon}/> {/* icone */}
                                      <p>{mitem.elementoLabel}</p>  {/* eventos */}
                                    </a>
                                  </li>
                                )
                              )
                            )}
                          </ul>
                        </li>
                      )
                    );
                  }
                })()}
              </div>
            )
          )
        )}
    </div>
  );
};
