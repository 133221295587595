import {
  Avatar,
  Box,
  Tooltip,
  useTheme,
  useMediaQuery,
  AvatarGroup,
} from "@mui/material";

import { useUserSprint } from "../contexts/UserSprint";

import { useEffect, useState, useMemo, memo, useRef } from "react";

export const AvatarSprint = memo(({ users }) => {
  const { selectedUser, setSelectedUser } = useUserSprint();
  const [showAllAvatars, setShowAllAvatars] = useState(false);
  const showAllAvatarsRef = useRef(showAllAvatars);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    showAllAvatarsRef.current = showAllAvatars;
  }, [showAllAvatars]);

  useEffect(() => {
    if (showAllAvatarsRef.current) {
      setShowAllAvatars(false);
    }
  }, []);

  const handleUserSelect = (user) => {
    if (selectedUser && selectedUser.codigo === user.codigo) {
      setSelectedUser("");
    } else {
      setSelectedUser(user);
    }
  };

  const handleShowAllAvatars = () => {
    setShowAllAvatars(true);
  };

  const orderedUsers = useMemo(() => {
    if (!selectedUser) return users;
    const filteredUsers = users.filter(
      (user) => user?.codigo !== selectedUser?.codigo
    );
    return [selectedUser, ...filteredUsers];
  }, [users, selectedUser]);

  return (
    <Box
      sx={{
        display: "flex",
        userSelect: "none",
        maxWidth: `${`${users.length}5%`}px`,
        justifyContent: isMobile ? "center" : "end",
      }}
    >
      {users?.length === 0 && <Avatar />}
      <AvatarGroup
        max={Math.max(showAllAvatars ? users.length : 4, 2)}
        onClick={() => handleShowAllAvatars()}
        sx={{ cursor: "pointer" }}
      >
        {orderedUsers?.map((user) => (
          <Tooltip title={user?.nome} arrow key={user?.codigo}>
            <Avatar
              onClick={() => {
                handleUserSelect(user);
              }}
              alt={user?.nome}
              src={user?.imagem}
              sx={{
                outline:
                  selectedUser && selectedUser?.codigo === user?.codigo
                    ? "2px solid #ff3d00"
                    : "0.1px solid darkgray",
                backgroundColor: "#f8f8f8",
                "&:hover": {
                  outline: "2px solid #ff3d00",
                  cursor: "pointer",
                },
              }}
            />
          </Tooltip>
        ))}
      </AvatarGroup>
    </Box>
  );
});
