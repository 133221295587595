import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import api from "../services/api";

const UnidadesContext = createContext();

export const UnidadesProvider = ({ children }) => {
  const [currentUnidade, setCurrentUnidade] = useState(null); // useState do Painel, nao usar em outra tela.
  const userCCusto = localStorage.getItem("@cc");
  const user = {
    isMatriz: userCCusto === "0012010301",
    codUsuario: localStorage.getItem("@userControll"),
  };

  const {
    data: unidades,
    error,
    isLoading: isLoadingUnidades,
  } = useQuery(
    "lista-unidades-producao",
    async () => {
      const response = await api.get("/lista-empresa-ccusto");
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: (data) => {
        if (!currentUnidade) {
          setCurrentUnidade(
            data.find((unidade) => unidade.ccusto === userCCusto)
          );
        }
      },
    }
  );

  const onChangeUnidade = (unidade) => {
    setCurrentUnidade(unidade);
  };

  const resetCurrentUnidade = () => {
    setCurrentUnidade(
      unidades.find((unidade) => unidade.ccusto === "0012010301")
    );
  };

  return (
    <UnidadesContext.Provider
      value={{
        unidades,
        isLoadingUnidades,
        currentUnidade,
        onChangeUnidade,
        resetCurrentUnidade,
        user,
        userCCusto,
      }}
    >
      {error && <p>Ocorreu um erro ao carregar as unidades de produção</p>}
      {isLoadingUnidades && <p>Carregando...</p>}
      {!isLoadingUnidades && children}
    </UnidadesContext.Provider>
  );
};

export const useUnidadesContext = () => {
  const context = useContext(UnidadesContext);
  if (!context) {
    throw new Error(
      "useUnidadesContext must be used within a UnidadesProvider"
    );
  }
  return context;
};
