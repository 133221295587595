export const calculateRemainingDays = (endDate) => {
  const currentDate = new Date();
  const endTime = new Date(endDate).getTime();
  const remainingTime = endTime - currentDate.getTime();
  const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

  return remainingDays > 0 ? remainingDays : 0;
};

export const calculateProgress = (initialDate, endDate, userAssignedDate) => {
  if (!userAssignedDate) {
    return {
      progress: 0,
      remainingDays: 0,
      totalDays: 0,
    };
  }

  const initialTime = new Date(initialDate).getTime();
  const endTime = new Date(endDate).getTime();

  const totalDays = Math.ceil((endTime - initialTime) / (1000 * 60 * 60 * 24));
  const remainingDays = calculateRemainingDays(endDate);

  const progress = ((totalDays - remainingDays) / totalDays) * 100;

  return {
    progress: Math.round(progress),
    remainingDays: remainingDays,
    totalDays: totalDays,
  };
};
