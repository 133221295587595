import React, { Component, memo } from 'react';

import LogoMin from "../../src/assets/LogoDemocratavaMin.svg";
import { loadTree } from '../utils/loadtree';
import { MenuItem } from './controles/MenuItem';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

import { UserIdent } from './controles/UserIdent';


class Menu extends Component {

    // Criando construtor
    constructor(props) {
        super(props)

    }



    componentDidMount() {

        // AdicionarScript('js', 'treview.js')
        loadTree();

    }




    render() {

        return (
            <div>

                <aside className="main-sidebar sidebar-dark-primary elevation-4">

                    {/* Brand Logo */}
                    <a href="/home" className="brand-link">


                        <div style={{ alignContent: "center", fontSize: "1.2em" }}>
                            <img
                                src={LogoMin}
                                alt="Logo Democrata"
                                className="brand-image img-circle elevation-3"
                                style={{ opacity: '.8' }} />
                            <span className="brand-text font-weight-light"
                                style={{ color: "#FFF" }}
                            >
                                PROCESSOS 
                            </span>
                            <span className="badge bg-danger"
                            style={{ fontSize: 10}}>BETA</span> 

                        </div>

                    </a>

                    {/* Sidebar  */}
                    <div className="sidebar nav-pills" style={{ backgroundColor: "#304056"}}>

                        <UserIdent /> {/* Nome Usuário - E-mail - Sair - Nova Solicitação - Atendimento Task */}


                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul
                                className="nav nav-pills nav-sidebar flex-column"
                                data-widget="treeview"
                                role="menu"
                                data-accordion="false">

                                <MenuItem />  {/* Demandas e Cadastros Auxiliares */}


                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>


            </div>
        );
    }
}

export default Menu;
