import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSprintData } from "../../contexts/SprintDataContext";
import api from "../../services/api";

export const useSprintUsers = () => {
  const { selectedSprint } = useSprintData();

  const { data: users, isLoading: isLoadingUsers } = useQuery(
    ["sprint-users", selectedSprint],
    async ({ signal }) => {
      const response = await api.get(
        `controle-sprint/lista-tecnicos/${selectedSprint}`,
        {
          signal,
        }
      );

      return response.data.records;
    },
    {
      enabled: !!selectedSprint,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  return {
    users,
    isLoadingUsers,
  };
};
