import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  BadgeCard,
  BadgeContainer,
  BadgeEmployeeImage,
  BadgeMiddleDivider,
  EmployeeInfo,
  EmployeeName,
  EmployeeData,
  Footer,
  LoadingSpinner,
} from "./styles";
import { Divider } from "@mui/material";
import avatarPlaceholderIcon from "../../../assets/avatar-placeholder.png";
import democrataLogo from "../../../assets/logo_ti_democrata_sembox.png";
import { dateFormatter } from "../../../utils/dateFormatter";
import api from "../../../services/api";

const fetchData = async () => {
  const response = await api.get("/leitura-tag/");
  const { records } = response.data;

  if (records && records.length > 0) {
    const {
      reading_created_at: dataMovimento,
      colaborador: {
        imagem: avatar,
        userColaborador: {
          nome,
          descricao,
          unidadeDescricao: { fantasia: unidade },
          email,
          centroCustoDescricao: { descricao: centroCusto },
        },
      },
    } = records[0];

    return {
      dataMovimento,
      nome,
      descricao,
      unidade,
      email,
      centroCusto,
      avatar,
    };
  } else {
    throw new Error("Erro ao buscar dados.");
  }
};

export const LeituraRfidGrid = () => {
  const { data, isLoading, isError } = useQuery("userData", fetchData, {
    refetchInterval: 2000,
  });

  const userData = data;

  const [showCompanyLogo, setShowCompanyLogo] = useState(false);
  const [prevData, setPrevData] = useState(null);

  useEffect(() => {
    if (prevData && JSON.stringify(prevData) !== JSON.stringify(userData)) {
      setShowCompanyLogo(false);
    }

    setPrevData(userData);
  }, [userData, prevData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showCompanyLogo) {
        setShowCompanyLogo(true);
      }
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [showCompanyLogo]);

  if (isLoading) {
    return (
      <BadgeContainer>
        <LoadingSpinner />
      </BadgeContainer>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return showCompanyLogo ? (
    <BadgeContainer>
      <img src={democrataLogo} alt="Democrata Logo" />
    </BadgeContainer>
  ) : (
    <BadgeContainer>
      <BadgeCard>
        <BadgeMiddleDivider>
          <BadgeEmployeeImage src={userData.avatar || avatarPlaceholderIcon} />
        </BadgeMiddleDivider>
        <Divider />
        <EmployeeInfo>
          <EmployeeName>{userData.nome}</EmployeeName>
          <EmployeeData>
            <p>
              <strong>Nome: </strong> {userData.descricao || "..."}
            </p>
            <p>
              <strong>Unidade:</strong> {userData.unidade || "..."}
            </p>
            <p>
              <strong>E-mail:</strong> {userData.email || "..."}
            </p>
            <p>
              <strong>Centro de Custo:</strong> {userData.centroCusto || "..."}
            </p>
            <p>
              <strong>Data de Movimentação:</strong>{" "}
              {userData.dataMovimento && dateFormatter(userData.dataMovimento)}
            </p>
          </EmployeeData>
        </EmployeeInfo>
        <Footer>Democrata &copy; 2023</Footer>
      </BadgeCard>
    </BadgeContainer>
  );
};
