import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Logo from "../../assets/democrata-logo.svg";
import api from "../../services/api";
import { login } from "../../services/auth";

import { Form, Container } from "./styles";
import {
  REACT_APP_ANO_DEMANDA,
  REACT_APP_COPYRIGHT_DEMANDA,
  REACT_APP_DESC_AMBIENTE_DEMANDA,
} from "../../utils/constants";

class SignIn extends Component {
  state = {
    usuario: "",
    senha: "",
    error: "",
  };

  handleSignIn = async (e) => {
    e.preventDefault();
    const { usuario, senha } = this.state;
    if (!usuario || !senha) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post(
          `${process.env.REACT_APP_LINK_API_AUTH_DEMANDA}auth/`,
          { usuario, senha }
        );

        login(
          response.data.records[0].token,
          response.data.records[0].id,
          usuario,
          response.data.records[0].tecnico
        );

        this.props.history.push("/Home");
      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o seu Acesso, verifique suas credenciais.",
        });
      }
    }
  };

  render() {
    return (
      <Container>
        <div className="login-box">
          <div className="login-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <p class="login-box-msg">GERENCIAMENTO DE PROCESSOS E DEMANDAS</p>

          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">
                {this.state.error && <p>{this.state.error}</p>}
              </p>
              <form onSubmit={this.handleSignIn}>
                <div className="input-group mb-3">
                  <input
                    type="usuario"
                    className="form-control"
                    placeholder="Usuário "
                    onChange={(e) => this.setState({ usuario: e.target.value })}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Senha"
                    onChange={(e) => this.setState({ senha: e.target.value })}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-block btn-dark">
                      Login
                    </button>
                  </div>
                </div>
              </form>
              <div className="social-auth-links text-center mb-3">
                <spam>
                  © {REACT_APP_COPYRIGHT_DEMANDA} / {REACT_APP_ANO_DEMANDA} -{" "}
                  {REACT_APP_DESC_AMBIENTE_DEMANDA}{" "}
                </spam>{" "}
                <span className="badge bg-danger">BETA</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(SignIn);
