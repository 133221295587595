import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { CustomDatePicker } from "./CustomDatePicker";
import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import api from "../../../../services/api";
import { CustomGrid } from "../../../../components/CustomGrid";
import { Add, FilterAltOff } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";

export const ProjectsFilter = () => {
  const queryClient = useQueryClient();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    unidades,

    user,
  } = useUnidadesContext();

  const [currentUnidade, setCurrentUnidade] = useState(null);

  const onChangeUnidade = (unidade) => {
    setCurrentUnidade(unidade);
  };

  const [filters, setFilters] = useState({
    initialDate: null,
    finalDate: null,
    status: null,
  });

  const fetchProjects = async () => {
    const params = {
      codUsuario: user.codUsuario,
      status: filters.status || "ANDAMENTO",
      dtInicial: filters.initialDate
        ? moment(filters.initialDate).format("YYYY-MM-DD")
        : undefined,
      dtFinal: filters.finalDate
        ? moment(filters.finalDate).format("YYYY-MM-DD")
        : undefined,
    };

    if (currentUnidade && currentUnidade.ccusto) {
      params.ccusto = currentUnidade.ccusto;
    }

    const response = await api.get("/cadastro-projetos/status", {
      params,
    });

    return response.data.records;
  };

  const { mutate } = useMutation(fetchProjects, {
    onSuccess: (data) => {
      queryClient.setQueryData("project-list", data);
    },
    onError: (error) => {
      queryClient.setQueryData("project-list", []);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    mutate();
  }, [filters.status, filters.initialDate, filters.finalDate, currentUnidade]);

  const handleChangeInitialDate = (date) => {
    setFilters({
      ...filters,
      initialDate: date,
    });
  };

  const handleChangeFinalDate = (date) => {
    setFilters({
      ...filters,
      finalDate: date,
    });
  };

  const handleStatusChange = (event) => {
    setFilters({
      ...filters,
      status: event.target.value,
    });
  };

  const handleRemoveFilters = () => {
    setFilters({
      initialDate: null,
      finalDate: null,
      status: "",
    });
  };

  const history = useHistory();

  const columnsGrid = user.isMatriz ? [6, 6, 6, 1] : [5, 5, 5, 1];

  return (
    <CustomGrid
      key={"filtro-projetos"}
      columns={columnsGrid}
      custom={{ alignItems: "center" }}
    >
      {user.isMatriz && (
        <FormControl>
          <Select
            value={currentUnidade || "all"}
            onChange={(event) => onChangeUnidade(event.target.value)}
            name="ccusto"
          >
            <MenuItem value="all" disabled>
              Unidade
            </MenuItem>
            {unidades.map((unidade) => (
              <MenuItem key={unidade.ccusto} value={unidade}>
                {unidade.empresa}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <Select
          value={filters.status || "ANDAMENTO"}
          onChange={handleStatusChange}
          name="status"
        >
          <MenuItem value="ANDAMENTO">Andamento</MenuItem>
          <MenuItem value="INATIVADO">Inativo</MenuItem>
          <MenuItem value="ATRASADO">Atrasado</MenuItem>
          <MenuItem value="CONCLUIDO">Concluído</MenuItem>
        </Select>
      </FormControl>

      <CustomDatePicker
        key="initialDate"
        name="initialDate"
        value={filters.initialDate}
        onChange={handleChangeInitialDate}
        label="Início"
      />

      <CustomDatePicker
        key="finalDate"
        name="finalDate"
        value={filters.finalDate}
        onChange={handleChangeFinalDate}
        label="Conclusão"
      />

      {isMobile && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip title="Limpar filtros" arrow>
            <IconButton
              sx={{
                ml: 2,
                "&:hover": {
                  color: "error.main",
                },
              }}
            >
              <FilterAltOff onClick={() => handleRemoveFilters()} />
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => history.push("/projetoItem/0")}
            size="small"
          >
            Criar Projeto
          </Button>
        </Box>
      )}

      {!isMobile && (
        <Fragment>
          <Box>
            <Tooltip title="Limpar filtros" arrow>
              <IconButton
                sx={{
                  ml: 2,
                  "&:hover": {
                    color: "error.main",
                  },
                }}
              >
                <FilterAltOff onClick={() => handleRemoveFilters()} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => history.push("/projetoItem/0")}
              size="small"
            >
              Criar Projeto
            </Button>
          </Box>
        </Fragment>
      )}
    </CustomGrid>
  );
};
