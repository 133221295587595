import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const ClassificationFilters = ({
  selectedClassificacao,
  statusChamado,
  handleChangeClassificacao,
  filteredClassificacao,
  styleSelect,
}) => {
  return (
    <FormControl sx={{ marginTop: 1 }}>
      <InputLabel sx={styleSelect} id="classificacao">
        Classificação
      </InputLabel>
      <Select
        label="Classificação"
        value={selectedClassificacao}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: 170,
          minWidth: 150,
        }}
        disabled={statusChamado.current == "CONCLUIDO"}
        onChange={handleChangeClassificacao}
        labelId="classificacao"
        id="classificacao"
        size="small"
      >
        <MenuItem value="" disabled>
          Selecionar
        </MenuItem>
        {filteredClassificacao
          .sort((a, b) => {
            if (a.descricao > b.descricao) return 1;
            if (a.descricao < b.descricao) return -1;
            return 0;
          })
          .map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.descricao}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ClassificationFilters;
