import React from "react";
import Chart from "react-apexcharts";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FixedSizeList } from "react-window";

export const BacklogCharts = ({ metrics, filters }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (!metrics) return null;

  const departmentData = metrics.departamento;
  const severityData = metrics?.gravidade || [];
  const taskSummaryData = metrics?.resumoTasks || { novos: 0, entregues: 0 };

  const applicantsData = {
    series: departmentData.map((dep) => dep.quantidade),
    options: {
      chart: {
        type: "pie",
      },
      title: {
        text: "Solicitantes",
        offsetX: -11,
        style: {
          fontSize: "20px",
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val, { seriesIndex }) {
            return `${val} chamados`;
          },
        },
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 150,
        },
      },
      subtitle: {
        text: `${filters.dtInicio} a ${filters.dtFinal}`,
        offsetX: -9,
        style: {
          fontSize: "14px",
          color: grey[700],
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FF4560", "#008FFB", "#00E396", "#775DD0"],
      labels: departmentData.map((dep) => dep.departamento),
    },
  };

  const severityChartData = {
    series: [
      {
        name: "Gravidade",
        data: severityData.map((sev) => sev.quantidade),
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      title: {
        text: "Gravidade",
        offsetX: -11,
        style: {
          fontSize: "20px",
        },
      },
      subtitle: {
        text: `${filters.dtInicio} a ${filters.dtFinal}`,
        offsetX: -9,
        style: {
          fontSize: "14px",
          color: grey[700],
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: severityData.map((sev) => sev.gravidade),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      fill: {
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100, 100],
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    },
  };

  const chartsGrid = () => {
    if (!isLargeScreen && !isMobile) {
      return {
        gridTemplateAreas: `
          "applicants severity tasks"
        `,
        gridTemplateColumns: "0.8fr 1fr 0.8fr",
      };
    }

    if (isMobile) {
      return {
        gridTemplateAreas: `
          "applicants"
          "severity"
          "tasks"
        `,
        gridTemplateColumns: "1fr",
      };
    }

    return {
      gridTemplateAreas: `
        "tasks applicants"
        "severity severity"
      `,
      gridTemplateColumns: "1fr 1fr",
    };
  };

  const renderRow = ({ index, style }) => {
    const dep = departmentData[index];
    return (
      <Box
        key={index}
        sx={{ display: "flex", alignItems: "center", mb: 1, ...style }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            backgroundColor:
              applicantsData.options.colors[
                index % applicantsData.options.colors.length
              ],
            marginRight: 1,
          }}
        />
        <Tooltip title={dep.departamento} placement="top" arrow>
          <Typography
            variant="body2"
            onClick={() => console.log(dep.departamento)}
            sx={{
              cursor: "pointer",
              maxWidth: 150,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {dep.departamento}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: chartsGrid().gridTemplateAreas,
        gridTemplateColumns: chartsGrid().gridTemplateColumns,
        width: "100%",
        height: "100%",
        mt: 2,
        gap: 1,
        alignItems: "start",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          gridArea: "applicants",
          maxHeight: 250,
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Box width={"100%"}>
            <Chart
              options={applicantsData.options}
              series={applicantsData.series}
              type="donut"
              width={"100%"}
              height={200}
            />
          </Box>

          <Box mt={6} backgroundColor="transparent">
            <FixedSizeList
              height={120}
              width={"100%"}
              itemSize={35}
              itemCount={departmentData.length}
              itemData={departmentData}
            >
              {renderRow}
            </FixedSizeList>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ height: "100%", maxHeight: 250, gridArea: "severity" }}>
        <CardContent>
          <Chart
            options={severityChartData.options}
            series={severityChartData.series}
            type="bar"
            width="100%"
            height={isMobile ? 200 : 230}
          />
        </CardContent>
      </Card>

      <Card
        sx={{
          width: "100%",
          height: "100%",

          gridArea: "tasks",
          minHeight: 250,
        }}
      >
        <CardContent sx={{ height: "100%" }}>
          <Typography variant="h6">Resumo Tasks</Typography>
          <Typography
            variant="subtitle2"
            fontWeight={400}
            letterSpacing={-0.5}
            fontSize={14}
            color={grey[700]}
          >
            {filters.dtInicio} a {filters.dtFinal}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75%",
              gap: 3,
            }}
          >
            <Box>
              <Typography
                variant="h3"
                fontSize={34}
                fontWeight={600}
                color={grey[800]}
              >
                {taskSummaryData.novos}
              </Typography>

              <Typography fontSize={14} fontWeight={400} color={grey[600]}>
                Solicitados
              </Typography>
            </Box>
            <Typography variant="h3" fontWeight={700} color={grey[800]}>
              /
            </Typography>
            <Box>
              <Typography
                variant="h3"
                fontSize={34}
                fontWeight={600}
                color={grey[800]}
              >
                {taskSummaryData.entregues}
              </Typography>

              <Typography fontSize={14} fontWeight={400} color={grey[600]}>
                Entregue
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
