import React from "react";
import Routes from "./routes";

import { QueryClient, QueryClientProvider } from "react-query";
import GlobalStyle from "./styles/global";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <GlobalStyle />
    <Routes />
  </QueryClientProvider>
);
export default App;
