import React, { useState } from "react";
import { useCollapse } from "../contexts/ColapsedMenuContext";
import { useLocation } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Search } from "./Search";
import { IconButton, Popover } from "@mui/material";
import { AnalisesMetricas } from "./Search/AnalisesMetricas";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { handleBool, isMenuCollapsed } = useCollapse();

  const location = useLocation();
  const rotaAtual = location.pathname;
  const rotasDemanda = [
    "/Home",
    "/home",
    "/Processos",
    "/ProcessoVinculado",
    "/solicitacoesItem",
    "/processoItem",
  ];
  const rotaBase = rotaAtual.split("/")[1];
  const rotasDemandas = rotasDemanda.some((rota) => rota.includes(rotaBase));

  const handleClick = (event) => {

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget={isMenuCollapsed ? "pushmenu" : "pushmenu"}
              href="#"
              role="button"
              onClick={() => handleBool()}
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          {!rotasDemandas && (
            <li>
              <IconButton id={id} aria-label="delete" onClick={handleClick}>
                <NotificationsActiveIcon fontSize="small" />
              </IconButton>
              {!rotasDemandas && (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <AnalisesMetricas onClose={handleClose} />
                </Popover>
              )}
            </li>
          )}
        </ul>

        {/* Viagens*/}

        {/* Right navbar links */}

        {!rotasDemandas ? (
          <Search />
        ) : (
          <ul className="navbar-nav ml-auto">
            {/* Messages Dropdown Menu */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-comments" />
                <span className="badge badge-danger navbar-badge">0</span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                {/* <a href="#" className="dropdown-item">
                                Message Start
                                <div className="media">
                                    <img src="../dist/img/user1-128x128.jpg" alt="User Avatar"
                                        className="img-size-50 mr-3 img-circle"/>
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Brad Diesel
                                            <span className="float-right text-sm text-danger"><i
                                                className="fas fa-star"/></span>
                                        </h3>
                                        <p className="text-sm">Call me whenever you can...</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"/> 4 Hours
                                            Ago</p>
                                    </div>
                                </div>
                                Message End
                            </a> */}
                {/* <div/> */} {/* <div className="dropdown-divider"/> */}
                <a>
                  {" "}
                  {/*  <a href="#" className="dropdown-item">}
                                {/* Message Start */}
                  {/* <div className="media">
                                    <img src="../dist/img/user8-128x128.jpg" alt="User Avatar"
                                        className="img-size-50 img-circle mr-3"/>
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            John Pierce
                                            <span className="float-right text-sm text-muted"><i
                                                className="fas fa-star"/></span>
                                        </h3>
                                        <p className="text-sm">I got your message bro</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"/> 4 Hours
                                            Ago</p>
                                    </div>
                                </div> */}
                  {/* Message End */}
                </a>
                <div /> {/* <div className="dropdown-divider"/> */}
                <a>
                  {" "}
                  {/* <a href="#" className="dropdown-item">}
                                {/* Message Start */}
                  <div className="media">
                    {/* <img src="../dist/img/user3-128x128.jpg" alt="User Avatar"
                                        className="img-size-50 img-circle mr-3"/>
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Nora Silvester
                                            <span className="float-right text-sm text-warning"><i
                                                className="fas fa-star"/></span>
                                        </h3>
                                        <p className="text-sm">The subject goes here</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1"/> 4 Hours
                                            Ago</p>
                                    </div> */}
                  </div>
                  {/* Message End */}
                </a>
                <div /> {/* <div className="dropdown-divider"/> */}
                <a className="dropdown-item dropdown-footer">Sem mensagens</a>
                {/* <a href="#" className="dropdown-item dropdown-footer">See All Messages</a> */}
              </div>
            </li>

            {/* Notifications Dropdown Menu */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell" />
                <span className="badge badge-warning navbar-badge">0</span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                {/*<span className="dropdown-item dropdown-header">15 Notifications</span>
                                <div className="dropdown-divider"/>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-envelope mr-2"/> 4 new messages
                                <span className="float-right text-muted text-sm">3 mins</span>
                            </a>
                            <div className="dropdown-divider"/>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-users mr-2"/> 8 friend requests
                                <span className="float-right text-muted text-sm">12 hours</span>
                            </a>
                            <div className="dropdown-divider"/>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-file mr-2"/> 3 new reports
                                <span className="float-right text-muted text-sm">2 days</span>
                            </a>
                            <div className="dropdown-divider"/>
                            <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a> */}
                <a className="dropdown-item dropdown-footer">
                  Sem Notificações
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fas fa-expand-arrows-alt" />
              </a>
            </li>
            {/* <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#"
                            role="button">
                            <i className="fas fa-th-large"/>
                        </a>
                    </li> */}
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Header;
