import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { formatarDataBaseDados } from "../../../../../utils/utils";

export const HistoricoHorasEst = ({ listaHistorico }) => {
  return (
    <TableContainer sx={{ borderRadius: 2}}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ fontSize: 10, alignItems: "center", borderRadius: 2}}>
            <TableCell sx={{ textAlign: "center", width: "26%", borderInlineEnd: "1px solid #E0E0E0" }}>
              <Typography variant="body2">Data</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "center", width: "19%", borderInlineEnd: "1px solid #E0E0E0" }}>
            <Typography variant="body2">Responsável</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: "center", width: "10%", borderInlineEnd: "1px solid #E0E0E0" }}>
            <Typography variant="body2">H Est.</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listaHistorico
            .sort((a, b) => {
              if (formatarDataBaseDados(a.datcad) < formatarDataBaseDados(b.datcad)) return 1;
              if (formatarDataBaseDados(a.datcad) > formatarDataBaseDados(b.datcad)) return -1;
              return 0;
            })
            .map((it) => (
              <TableRow key={it.id} sx={{ fontSize: 8, textAlign: "center", backgroundColor: "white", borderRadius: 2}}>
                <TableCell sx={{ textAlign: "center", borderInlineEnd: "1px solid #E0E0E0"}}>{formatarDataBaseDados(it.datcad)}</TableCell>
                <TableCell sx={{ textAlign: "center", borderInlineEnd: "1px solid #E0E0E0"}}>{it.tecnico?.nome}</TableCell>
                <TableCell sx={{ textAlign: "center", borderInlineEnd: "1px solid #E0E0E0"}}>{it.horasEstimadas}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
