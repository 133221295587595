import { useQuery } from "react-query";
import api from "../../../services/api";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GanttFilters } from "./GanttFilters";
import { GanttChart } from "./GanttChart";
import {
  Box,
  CircularProgress,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GanttSubtitles, ganttSubtitles } from "./GanttSubtitles";

export const GanttProjeto = ({ tabs }) => {
  const { id } = useParams();

  const [selectedSprint, setSelectedSprint] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: projectData, isLoading: isLoadingProjectData } = useQuery(
    ["projeto-data-gantt", id],
    async () => {
      const response = await api.get(`controle-sprint/projeto/${id}`);

      return response.data.records;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data.sprints.length > 0) {
          const removeBacklog = data.sprints.filter(
            (sprint) => sprint.id !== 0
          );

          setSelectedSprint(removeBacklog[0].id);
        }
      },
    }
  );

  const {
    data: sprintData,
    isLoading: isLoadingSprintData,
    error,
  } = useQuery(
    ["gantt-tasks-projeto", selectedSprint],
    async () => {
      const response = await api.get(`controle-sprint/lista/${selectedSprint}`);

      return response.data.records[0];
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!selectedSprint,
    }
  );

  const handleSelectSprint = (event) => {
    setSelectedSprint(event.target.value);
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  if (isLoadingProjectData || isLoadingSprintData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          my: 10,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          my: 10,
        }}
      >
        Erro ao carregar os dados
      </Box>
    );
  }

  if (
    !projectData ||
    !projectData.sprints ||
    projectData.sprints.length === 0
  ) {
    return (
      <>
      <Box
      sx={{
        display: "grid",
        justifyContent: "flex-end",
      }}> {tabs}</Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          my: 10,
        }}
      >
        Não há sprints disponíveis
       
      </Box>
      </>
    );
  }

  return (
    <Box sx={{ height: "100vh" }}>
      {isFullScreen && selectedSprint && (
        <Modal open={isFullScreen} onClose={handleFullScreen}>
          <Box sx={{ width: "100vw", height: "100vh", p: 1 }}>
            <GanttChart
              sprintData={sprintData}
              projectData={projectData.projeto}
              isFullScreen={isFullScreen}
              handleFullScreen={handleFullScreen}
            />
          </Box>
        </Modal>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : `1fr 1fr 1fr`,
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <GanttFilters
          projectId={id}
          currentSprint={selectedSprint}
          onSelectSprint={handleSelectSprint}
        />

        <Box></Box>

        {tabs}
      </Box>

      <GanttSubtitles />

      {!isFullScreen && selectedSprint && (
        <GanttChart
          key={`gantt-chart-${selectedSprint}-${new Date().getTime()}`}
          sprintData={sprintData}
          projectData={projectData.projeto}
          isFullScreen={isFullScreen}
          handleFullScreen={handleFullScreen}
        />
      )}
    </Box>
  );
};
