import React, { useState } from "react";
import "../../../components/controles/css/TreeView.css"; // Importe estilos CSS
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { useEventosLinksContext } from "../../../contexts/LinksEventosContext";
import { Box, IconButton, Tooltip } from "@mui/material";
import { AddCircle, Circle } from "@mui/icons-material";

const TreeNode = ({ node, onSaveEdit, nodeLink, updateId }) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const [expandedCategories, setExpandedCategories] = useState({});

  const { updateEventoId, updateCategoriaId, updateSubCategoriaId } =
    useEventosLinksContext();

  const handleEdit = () => {
    setEditedValue(node.descricao);
    setIsEditing(true);
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();
    onSaveEdit(node.id, editedValue);
    setIsEditing(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleCategoryExpand = (categoryId) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const sendId = () => {
    history.push({
      pathname: "/eventoItem/" + node.id,
    });
  };

  return (
    <div>
      <div className="tree-node">
        {!isExpanded && node.categorias && node.categorias.length > 0 && (
          <span className="expand mr-2 text-primary" onClick={toggleExpand}>
            <i className="fa fa-folder mr-2"></i>
          </span>
        )}

        {isExpanded && node.categorias.length > 0 && (
          <span className="expand mr-2 text-primary" onClick={toggleExpand}>
            <i className="fa fa-folder-open-o mr-2"></i>
          </span>
        )}

        {isEditing ? (
          <>
            <input
              type="text"
              value={editedValue}
              onChange={handleInputChange}
            />
            <Tooltip title="Salvar edição" arrow>
              <button
                type="button"
                onClick={(e) => handleSaveEdit(e)}
                className="edit-button"
              >
                <span>
                  <i className="fa fa-check"></i>
                </span>
              </button>
            </Tooltip>

            <Tooltip title="Sair da edição" arrow>
              <button
                type="button"
                onClick={(e) => handleClose(e)}
                className="edit-button"
                style={{ background: "#d9534f" }}
              >
                <span>
                  <i className="fa fa-close"></i>
                </span>{" "}
              </button>
            </Tooltip>
          </>
        ) : (
          <>
            {nodeLink && (
              <Link to={nodeLink} onClick={updateId}>
                <span>{node.evento}.</span>
                {node.categoria && <span>{node.categoria}.</span>}
                <span>{node.id}</span>
                <span className="ml-3">{node.descricao}</span>
              </Link>
            )}

            {!nodeLink && (
              <>
                <span>{node.id}</span>
                <span className="node-description ml-2">{node.descricao}</span>
              </>
            )}

            {node.categorias && node.categorias.length >= 0 && (
              <Tooltip title="Editar Evento" arrow>
                <button
                  onClick={handleEdit}
                  className="edit-button"
                  style={{ background: "#449e48" }}
                >
                  <span>
                    <i className="fa fa-pencil"></i>
                  </span>
                </button>
              </Tooltip>
            )}

            {node.categorias && node.categorias.length >= 0 && (
              <Tooltip title="Criar Categoria" arrow>
                <button
                  className="edit-button "
                  onClick={() => {
                    sendId(node.id);
                    updateEventoId(node.id);
                  }}
                  style={{ background: "#0055b3" }}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </Tooltip>
            )}

            {/* aqui  é onde cria SubCategoria */}
            {node.subCategorias && node.subCategorias.length >= 0 && (
              <Link to={`/eventoSubcategorias/${node.id}`}>
                <Tooltip title="Criar SubCategoria" arrow>
                  <IconButton
                    className="edit-button"
                    sx={{
                      backgroundColor: "inherit !important",
                      color: "#0055b3 !important",
                    }}
                    onClick={() => updateCategoriaId(node.id)}
                  >
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
          </>
        )}
      </div>
      {isExpanded && node.categorias && node.categorias.length > 0 && (
        <ul className="sub-tree node-description">
          {node.categorias.map((categoria) => (
            <React.Fragment key={categoria.id}>
              <Box sx={{ display: "flex" }}>
                {!expandedCategories[categoria.id] &&
                  categoria.subCategorias &&
                  categoria.subCategorias.length > 0 && (
                    <span
                      className="text-warning mr-2"
                      onClick={() => toggleCategoryExpand(categoria.id)}
                    >
                      <i className="fa fa-chevron-right text-sm text-warning mr-2"></i>
                    </span>
                  )}

                {expandedCategories[categoria.id] &&
                  categoria.subCategorias &&
                  categoria.subCategorias.length > 0 && (
                    <span
                      className="text-warning mr-2 "
                      onClick={() => toggleCategoryExpand(categoria.id)}
                    >
                      <i className="fa fa-chevron-down text-sm text-warning mr-2"></i>
                    </span>
                  )}
                <TreeNode
                  node={categoria}
                  nodeLink={`/eventoItem/${categoria.id}`}
                  updateId={() => {
                    updateEventoId(node.id);
                    updateCategoriaId(categoria.id);
                  }}
                  onSaveEdit={onSaveEdit}
                />
              </Box>

              {expandedCategories[categoria.id] &&
                categoria.subCategorias &&
                categoria.subCategorias.length > 0 && (
                  <ul className="sub-tree node-description">
                    {categoria.subCategorias.map((subCategoria) => (
                      <React.Fragment key={subCategoria.id}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Circle
                            sx={{ width: "10px", mr: 1, color: "gray" }}
                          />
                          <Link
                            to={`/eventoSubcategorias/${categoria.id}`}
                            onClick={() => {
                              updateSubCategoriaId(subCategoria.id);
                            }}
                          >
                            <TreeNode
                              node={subCategoria}
                              nodeLink={`/eventoSubcategorias/${categoria.id}`}
                              onSaveEdit={onSaveEdit}
                            />
                          </Link>
                        </Box>
                      </React.Fragment>
                    ))}
                  </ul>
                )}
            </React.Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

const TreeView = ({ data, onSaveEdit }) => {
  return (
    <div className="tree-view">
      {data && data.length > 0 && (
        <>
          {data
            .sort((a, b) => a.id - b.id)
            .map((item) => (
              <div key={item.id} className="tree-view-item">
                <TreeNode node={item} onSaveEdit={onSaveEdit} />
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default TreeView;
