import { useMutation, useQuery } from "react-query";
import { useSprintData } from "../../contexts/SprintDataContext";
import { useUserSprint } from "../../contexts/UserSprint";
import { useUnidadesContext } from "../../contexts/UnidadesContext";

export const useSprint = () => {
  const { selectedSprint, fetchSelectedSprint, selectedGravidade } =
    useSprintData();
  const { selectedUser } = useUserSprint();
  const { currentUnidade } = useUnidadesContext();

  const { data: sprint, isLoading: isQueryLoading } = useQuery(
    [
      `selected-sprint-${selectedSprint}`,
      selectedUser,
      currentUnidade,
      selectedGravidade,
    ],
    ({ signal }) =>
      fetchSelectedSprint(selectedSprint, signal, {
        tecnico: selectedUser?.codigo,

        gravidade: selectedGravidade,
      }),
    {
      enabled: !!selectedSprint,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  return {
    sprint,
    isLoading: isQueryLoading,
  };
};
