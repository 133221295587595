import React from "react";
import "dayjs/locale/pt-br";
import PassagemSaida from "./Componentes/PassagemSaida";
import PassagemRetorno from "./Componentes/PassagemRetorno";

const Passagem = () => {
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="form-group col-md-12 col-sm-12">
        <div className="form-body">
          <div className="col-md-12 col-sm-12 mb-5">
            <div className="row mt-4">
              <nav className="w-100">
                <div className="nav nav-tabs" id="passagens-tab" role="tablist">
                  <a
                    className="nav-item nav-link active"
                    id="passagens-saida-tab"
                    data-toggle="tab"
                    href="#passagens-saida"
                    role="tab"
                    aria-controls="passagens-saida"
                    aria-selected="true"
                  >
                    Ida
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="passagens-retorno-tab"
                    data-toggle="tab"
                    href="#passagens-retorno"
                    role="tab"
                    aria-controls="passagens-retorno"
                    aria-selected="false"
                  >
                    Volta
                  </a>
                </div>
              </nav>
              <div className="tab-content p-3" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="passagens-saida"
                  role="tabpanel"
                  aria-labelledby="passagens-saida-tab"
                >
                  <PassagemSaida />
                </div>
                <div
                  className="tab-pane fade"
                  id="passagens-retorno"
                  role="tabpanel"
                  aria-labelledby="passagens-retorno-tab"
                >
                  <PassagemRetorno />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Passagem;
