import {
  Box,
  Typography,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { TaskCard } from "./TaskCard";
import { useEffect, useRef, useState } from "react";
import SprintModal from "../../AtendimentoOS/Sprint/components/SprintModal";
import { useSprint } from "../../../hooks/query/useSprint";

import { SkeletonKanban } from "./SkeletonKanban";
import { converterParaHorasMinutos } from "../../../utils/convertToHoursMinutes";

export const DetalheSprintKanban = ({ sprintData, isLoading }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const scrollRef = useRef();

  const taskCategories = !isLoading && [
    {
      name: "A Fazer / Pendente",
      background: "#bbdefb",
      color: "#0d47a1",
      tasks: sprintData?.fazer,
      horasEstimadas: sprintData?.sprint.horasEstimadasPendente,
      total: sprintData?.sprint.pendente,
    },
    {
      name: "Andamento",
      background: "#fff3e0",
      color: "#ffa000",
      tasks: sprintData?.andamento,
      horasEstimadas: converterParaHorasMinutos(
        sprintData?.sprint.horasEstimadasAndamento
      ),
      total: sprintData?.sprint.andamento,
    },
    {
      name: "Teste",
      background: "#e0e0e0",
      color: "#424242",
      tasks: sprintData?.teste,
      horasEstimadas: converterParaHorasMinutos(
        sprintData?.sprint.horasTrabalhadasTeste
      ),
      total: sprintData?.sprint.teste,
    },
    {
      name: "Concluido",
      background: "#aed581",
      color: "#33691e",
      tasks: sprintData?.concluido,
      horasEstimadas: converterParaHorasMinutos(
        sprintData?.sprint.horasTrabalhadasConcluido
      ),
      total: sprintData?.sprint.concluido,
    },
  ];

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    setModalIsOpen(!modalIsOpen);
  };

  const preventPageScroll = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: isMobile ? "block" : "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr 1fr",
          gap: 1,
          mt: isMobile && 4,
          width: "100%",
          overflow: "auto",
          maxHeight: isMobile ? "100vh" : "60vh",
        }}
        ref={scrollRef}
        onWheel={preventPageScroll}
      >
        {!isLoading && (
          // biome-ignore lint/complexity/noUselessFragments: <explanation>
          <>
            {taskCategories.map((category) => (
              <Box
                key={category.name}
                width={"100%"}
                minWidth={300}
                overflowY="hidden"
              >
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    backgroundColor: category.background,
                    p: 2,
                    borderRadius: "5px",
                    boxShadow:
                      "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px",
                    display: "flex",
                    justifyContent: "space-between",
                    minWidth: "70%",
                  }}
                >
                  <Typography color={category.color}>
                    {category.name}
                  </Typography>
                  <Typography color={category.color}>
                    {category.total} Qtde
                  </Typography>
                </Box>
                <Box
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    mt: 2,
                    mb: 2,
                  }}
                  onWheel={preventPageScroll}
                >
                  {category?.tasks?.length > 0 &&
                    category.tasks.map((task) => (
                      <TaskCard
                        key={task.codigo}
                        taskData={task}
                        openTask={() => handleTaskSelect(task.codigo)}
                      />
                    ))}
                </Box>
              </Box>
            ))}
          </>
        )}
        {modalIsOpen && (
          <SprintModal
            showModal={modalIsOpen}
            closeModal={handleTaskSelect}
            data={selectedTask}
          />
        )}
      </Box>
    </>
  );
};

export default DetalheSprintKanban;
