import { Box, Skeleton, useMediaQuery, useTheme } from "@mui/material";

export const SkeletonKanban = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const skeletonCategories = [
    {
      id: 0,
      name: "A Fazer / Pendente",
      background: "#bbdefb",
      color: "#0d47a1",
      tasks: Array.from(new Array(2)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={118}
          sx={{ mb: 2 }}
        />
      )),
    },
    {
      id: 1,
      name: "Andamento",
      background: "#fff3e0",
      color: "#ffa000",
      tasks: Array.from(new Array(1)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={118}
          sx={{ mb: 2 }}
        />
      )),
    },
    {
      id: 3,
      name: "Teste",
      background: "#e0e0e0",
      color: "#424242",
      tasks: Array.from(new Array(3)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={118}
          sx={{ mb: 2 }}
        />
      )),
    },
    {
      id: 4,
      name: "Concluido",
      background: "#aed581",
      color: "#33691e",
      tasks: Array.from(new Array(4)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={118}
          sx={{ mb: 2 }}
        />
      )),
    },
  ];

  return (
    <Box
      sx={{
        display: isMobile ? "block" : "grid",
        gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr 1fr",
        gap: 1,
        mt: isMobile && 4,
        width: "100%",
        overflowX: "scroll",
        overflowY: "auto",
        maxHeight: "60vh",
      }}
    >
      {skeletonCategories.map((category, index) => (
        <Box key={index} width="100%" minWidth={300} mb={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: category.background,
              minWidth: "70%",
              p: 2,
              borderRadius: "5px",
            }}
          >
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="20%" />
            <Skeleton variant="text" width="20%" />
          </Box>
          <Box mt={2}>
            {category.tasks.map((task) => {
              return task;
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
