import React, { useState } from "react";
import "dayjs/locale/pt-br";
import { formatarDataBr } from "../../../utils/utils";
import { useEffect } from "react";
import api from "../../../services/api";
import dayjs from "dayjs";
import { CardActionArea } from "@mui/material";
import { useHistory } from "react-router-dom";

const Cards = ({ it, compromisso, destino, gravidade }) => {
  let history = useHistory();

  const [value, setValue] = useState(dayjs(new Date()).add(15, "day"));
  const [id, setId] = useState([]);
  const [idViagem, setIdViagem] = useState();
  const [solicitante, setSolicitante] = useState([]);
  const [dataAbertura, setDataAbertura] = useState([]);
  const [listDemanda, setListDemanda] = useState([]);

  const [nivelGravidade, setnivelGravidade] = useState([]);

  const handleNavegacao = (event, codigo) => {
    history.push({
      pathname: "/viagemItem/" + codigo,
    });
  };

  useEffect(() => {
    const getDemanda = async () => {
      await api.get(`/demanda/` + it).then((response) => {
        setListDemanda(response.data.records);
        setSolicitante(response.data.records[0].solicitante.nome);
        setDataAbertura(response.data.records[0].dataAbertura);
      });
    };

    const getGravidade = async () => {
      await api.get(`/viagem/demanda/` + it).then((response) => {
        setnivelGravidade(response.data.records[0].nivelGravidade);
        setIdViagem(response.data.records[0].id);
      });
    };
    getGravidade();
    getDemanda();
  }, [it]);

  const StatusSolicitacao = (gravidade, parametro) => {
    if (parametro == "BAIXA") {
      if (gravidade == parametro) {
        return "#5bc0de ";
      } else {
        return "";
      }
    } else if (parametro == "MEDIA") {
      if (gravidade == parametro) {
        return "#f0ad4e ";
      } else {
        return "";
      }
    } else if (parametro == "ALTA") {
      if (gravidade == parametro) {
        return "#d9534f ";
      } else {
        return "";
      }
    }
  };

  return (
    <>
      <div
        className="card "
        style={{
          width: "14rem",
          background: "#304056",
          transition: "box-shadow 0.3s",
          boxShadow: "none",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.boxShadow =
            "0px 5px 10px 0px rgba(0, 0, 0, 0.6)")
        }
        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
      >
        <CardActionArea
          onClick={(event) => {
            handleNavegacao(event, it);
          }}
        >
          <div className="card-body">
            <div className="row">
              <div className="text-center">
                <input
                  disabled="disabled"
                  style={{
                    borderRadius: "10px",
                    width: "25%",
                    background: StatusSolicitacao(gravidade, "BAIXA"),
                    height: "30%",
                    marginRight: "2px",
                  }}
                ></input>

                <input
                  disabled="disabled"
                  style={{
                    borderRadius: "10px",
                    width: "25%",
                    background: StatusSolicitacao(gravidade, "MEDIA"),
                    height: "30%",
                    marginRight: "2px",
                  }}
                ></input>

                <input
                  disabled="disabled"
                  style={{
                    borderRadius: "10px",
                    width: "25%",
                    background: StatusSolicitacao(gravidade, "ALTA"),
                    height: "30%",
                  }}
                ></input>
              </div>
            </div>
            <div className="direct-chat-infos clearfix text-center">
              <h5 className=" text-white">{solicitante}</h5>
              <h6 className="text-white">
                {formatarDataBr(dataAbertura)}
                {" - "}
                {destino}
              </h6>
            </div>
            <div className="row">
            <div class="card-body direct-chat-infos clearfix text-center">
            <div className="row">
              <span className="text-white">Viagem:</span>
              <span className="text-white">
             {formatarDataBr(compromisso, "DataHora")}
              </span>
            </div>
            </div>
            </div>
          </div>
        </CardActionArea>
      </div>
    </>
  );
};

export default Cards;
