import {
  Box,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import { CustomGrid } from "../../../../components/CustomGrid";

export const PainelSprintsSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const skeletonItems = Array.from({ length: 7 });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobile ? "1fr" : "1fr",
        width: "100%",
        gap: 2,
        alignItems: "end",
      }}
    >
      <CustomGrid>
        {skeletonItems.map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: "5px" }}
          />
        ))}
        <Skeleton
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ borderRadius: "5px" }}
        />
      </CustomGrid>
    </Box>
  );
};
