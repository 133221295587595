import React, { useState } from "react";
import "../../../components/controles/css/TreeView.css"; // Importe estilos CSS
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Send } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const TreeNodeSub = ({ node, onSaveEdit }) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);

  const handleEdit = () => {
    setEditedValue(node.descricao);
    setIsEditing(true);
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();
    onSaveEdit(node.id, editedValue);
    setIsEditing(false);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };
  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="tree-node">
        <span onClick={toggleExpand} className="expand">
          {isExpanded}
        </span>

        {isEditing ? (
          <>
            <input
              type="text"
              value={editedValue}
              onChange={handleInputChange}
            />
            <Tooltip title="Salvar edição" arrow>
              <button
                type="button"
                onClick={(e) => handleSaveEdit(e)}
                className="edit-button"
              >
                <span>
                  <i className="fa fa-check"></i>
                </span>
              </button>
            </Tooltip>

            <Tooltip title="Sair da edição" arrow>
              <button
                type="button"
                onClick={(e) => handleClose(e)}
                className="edit-button"
                style={{ background: "#d9534f" }}
              >
                <span>
                  <i className="fa fa-close"></i>
                </span>{" "}
              </button>
            </Tooltip>
          </>
        ) : (
          <>
            <span className="node-description" style={{ cursor: "default" }}>
              {node.evento}
              {"."}
              {node.categoria}
              {node.categoria && "."}
              {node.id} {node.descricao}{" "}
            </span>

            <Tooltip title="Editar SubCategoria" arrow>
              <button
                onClick={handleEdit}
                className="edit-button"
                style={{ background: "#449e48" }}
              >
                <span>
                  <i className="fa fa-pencil"></i>
                </span>
              </button>
            </Tooltip>
          </>
        )}
      </div>
      {!isExpanded && node.subCategorias && !node.subCategorias.length >= 0 && (
        <ul className="sub-tree">
          {node.subCategorias.map((subCategoria) => (
            <li key={subCategoria.descricao}>
              <TreeNode node={subCategoria} onSaveEdit={onSaveEdit} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TreeViewSub = ({ data, onSaveEdit }) => {
  return (
    <div className="tree-view">
      {data &&
        data.map((item) => (
          <div key={item.id} className="tree-view-item">
            <TreeNodeSub node={item} onSaveEdit={onSaveEdit} />
          </div>
        ))}
    </div>
  );
};

export default TreeViewSub;
