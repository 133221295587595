import { createContext, useContext, useEffect, useState } from "react";

const EventosLinksContext = createContext();

export const EventosLinksProvider = ({ children }) => {
  const [categoriaId, setCategoriaId] = useState();
  const [eventoId, setEventoId] = useState();
  const [subCategoriaId, setSubCategoriaId] = useState();

  const updateSubCategoriaId = (id) => {
    setSubCategoriaId(id);
  };

  const updateCategoriaId = (id) => {
    setCategoriaId(id);
  };

  const updateEventoId = (id) => {
    setEventoId(id);
  };

  const url = window.location.pathname;



  return (
    <EventosLinksContext.Provider
      value={{
        subCategoriaId,
        categoriaId,
        eventoId,
        updateSubCategoriaId,
        updateCategoriaId,
        updateEventoId,
      }}
    >
      {children}
    </EventosLinksContext.Provider>
  );
};

export const useEventosLinksContext = () => useContext(EventosLinksContext);
