import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Icon,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import IconeUrgencia from "../../pages/DetalheSprint/Components/IconeUrgencia";
import api from "../../services/api";
import { useQuery } from "react-query";
import { getControle } from "../../services/auth";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useMetricasListContext } from "../../contexts/MetricasListaContext";
import Header from "../Header";

export const AnalisesMetricas = ({ onClose }) => {
  const history = useHistory();
  const usuarioControle = getControle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: dataMetricas, isLoading: isLoadingMetricas } = useQuery(
    "metricas",
    async () => {
      const response = await api.get(
        `controle-servico/metrica-individual?codigoUsuario=${usuarioControle}`
      );

      return response;
    }
  );

  const { setFilters } =
    useMetricasListContext();

    const handleChange = (id) => {
      console.log("click", id);
      setFilters((prevFilters) => ({
        ...prevFilters,
        sprint: id,
        gravidade: "",
        retorno: "",
  
      }));
    };
  
    const handleChangeGravidade = (id) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        gravidade: id,
        sprint:"",
        retorno: ""
      }));
    };

  const urgenciaOrder = {
    Crítica: 1,
    Alta: 2,
    Atenção: 3,
    Média: 4,
    Baixa: 5,
    Planejada: 6,
    Success: 7,
  };

  const sprintsGroup = dataMetricas?.data?.records[0]?.sprintAtual?.reduce(
    (acc, cur) => {
      const key = cur.totalChamadosSprint;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(cur);
      return acc;
    },
    {}
  );

  const sprintsGroupAtraso =
    dataMetricas?.data?.records[0]?.sprintAtraso?.reduce((acc, cur) => {
      const key = cur.totalChamadosSprint;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(cur);
      return acc;
    }, {});

  if (isLoadingMetricas) {
    return (
      <Card
        sx={{
          width: isMobile ? 300 : 500,
          maxHeight: 500,
          padding: 2,
          overflowY: "auto",
          boxShadow: "10px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      </Card>
    );
  }
  <Header sprintsGroup={sprintsGroup} />

  if (!isLoadingMetricas && !sprintsGroup && !sprintsGroupAtraso) {
    return (
      <Card
        sx={{
          width: isMobile ? 300 : 500,
          maxHeight: 500,
          padding: 2,
          overflowY: "auto",
          boxShadow: "10px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          Sem chamado!
        </Box>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: isMobile ? 300 : 500,
        maxheight: 500,
        padding: 2,
        overflowY: "auto",
        boxShadow: "10px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 3 }}>
          Análises Métricas
        </Typography>
      </Box>

      <Box sx={{ marginTop: 3 }}>
        <Typography component="span">Sprint Atual </Typography>

        {Object.keys(sprintsGroup || {}).map((key) => (
          <Box key={key} sx={{ marginBottom: 2 }}>
            {sprintsGroup[key]?.map((sprint, index) => (
              <Tooltip key={index} title={sprint.sprintDescricao}>
                <Typography
                  component="span"
                  sx={{
                    color: "gray",
                    backgroundColor: "#E6E6FA",
                    padding: "2px 6px",
                    borderRadius: "4px",
                    marginRight: 1,
                    wordBreak: "break-word",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleChange(sprint.sprintId);
                    history.push(`/metricas/${sprint.sprintId}`);
                    onClose();
                  }}
                >
                  {sprint.sprintDescricao}
                </Typography>
              </Tooltip>
            ))}

            <Typography component="span"> com </Typography>

            <Typography
              component="span"
              sx={{
                color: "blue",
                backgroundColor: "#E6E6FA",
                padding: "2px 6px",
                borderRadius: "4px",
                wordBreak: "break-word",
              }}
            >
              {key}
            </Typography>

            <Typography component="span">
              {key > 1 ? " chamados ativos." : " chamado ativo."}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Sprint em Atraso */}

      {dataMetricas?.data?.records[0].sprintAtraso != null ? (
        <Box sx={{ marginTop: 3 }}>
          <Typography component="span">Sprint em atraso </Typography>

          {Object.keys(sprintsGroupAtraso || {}).map((key) => (
            <Box key={key} sx={{ marginBottom: 2 }}>
              {sprintsGroupAtraso[key].map((sprint, index) => (
                <Tooltip key={index} title={sprint.sprintDescricao}>
                  <Typography
                    component="span"
                    sx={{
                      color: "gray",
                      backgroundColor: "#E6E6FA",
                      padding: "2px 6px",
                      borderRadius: "4px",
                      marginRight: 1,
                      wordBreak: "break-word",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChange(sprint.sprintId);
                      history.push(`/metricas/${sprint.sprintId}`);
                      onClose();
                    }}
                  >
                    {sprint.sprintDescricao}
                  </Typography>
                </Tooltip>
              ))}

              <Typography component="span"> com </Typography>

              <Typography
                component="span"
                sx={{
                  color: "blue",
                  backgroundColor: "#E6E6FA",
                  padding: "2px 6px",
                  borderRadius: "4px",
                  wordBreak: "break-word",
                }}
              >
                {key}
              </Typography>

              <Typography component="span">
                {key > 1 ? " chamados ativos." : " chamado ativo."}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        ""
      )}
     

      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginTop: 1,
            marginBottom: 2,
          }}
        >
          {dataMetricas?.data?.records[0]?.chamadosAtivos
            .slice()
            .sort((a, b) => urgenciaOrder[a.tipo] - urgenciaOrder[b.tipo])
            .map((chamado, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleChangeGravidade(chamado.gravidade);
                  history.push(`/metricas/${chamado.gravidade}`);
                  onClose();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "20px",
                    }}
                  >
                    <IconeUrgencia
                      sx={{ marginRight: 2 }}
                      urgency={{ descricao: chamado.tipo }}
                      customIconColors={{
                        critica: "#ff0000",
                        atencao: "#e50d0d",
                        media: "#e9db30",
                        alta: "#fa0000",
                        baixa: "#0f16ee",
                        planejada: "#62089e",
                        success: "#08c632",
                        retornado: "#ffffff",
                      }}
                    />
                  </Box>
                  <Typography sx={{ marginLeft: 1, wordBreak: "break-word" }}>
                    {chamado.tipo}
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  sx={{
                    color: "blue",
                    backgroundColor: "#E6E6FA",
                    padding: "2px 6px",
                    borderRadius: "4px",
                    wordBreak: "break-word",
                    textAlign: "center",
                    flexBasis: "auto",
                    minWidth: "50px",
                  }}
                >
                  {chamado.valor}
                </Typography>
              </Box>
            ))}

          {dataMetricas?.data?.records[0]?.retornos > 0 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 1,
                  width: "100%",
                  cursor: "pointer",
                  marginBottom: 2,
                }}
                onClick={() => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    sprint: "",
                    gravidade: "",
                    retorno: "S",
                  }));
                  history.push(`/metricas/S`);
                  onClose();
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <RefreshIcon />
                  </Box>
                  <Typography>
                    {dataMetricas?.data.records[0]?.retornos == 0
                      ? ""
                      : dataMetricas?.data.records[0]?.retornos > 1
                      ? "Retornados de testes"
                      : "Retornado de teste"}
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  sx={{
                    color: "blue",
                    backgroundColor: "#E6E6FA",
                    padding: "2px 6px",
                    borderRadius: "4px",
                    wordBreak: "break-word",
                    textAlign: "center",
                    flexBasis: "auto",
                    minWidth: "50px",
                  }}
                >
                  {dataMetricas?.data?.records[0]?.retornos}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Divider orientation="horizontal" height="30" />

      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <Icon sx={{ marginRight: 1, marginLeft: 0 }}>
              access_time_filled
            </Icon>
            Tempo médio em atividades no mês
          </Typography>
        </Box>

        <Typography
          sx={{
            textAlign: "center",
            minWidth: "50px",
            color: "gray",
            backgroundColor: "#E6E6FA",
            padding: "2px px",
            borderRadius: "4px",
            marginRight: 0,
            wordBreak: "break-word",
          }}
        >
          {dataMetricas?.data?.records[0].tempoMedioMes.toLocaleString(
            "pt-BR",
            {
              minimumIntegerDigits: 2,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          )}{" "}
          h
        </Typography>
      </Box>
    </Card>
  );
};