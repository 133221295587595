import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { REACT_APP_TOTAL_GRID } from "../../../../utils/constants";
import { addZeroes } from "../../../../utils/utils";
import api from "../../../../services/api";
import Avatar from "../../../../assets/avatar-placeholder.png";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ColaboradorGrid = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [image, setImage] = useState("");

  let limit = parseInt(REACT_APP_TOTAL_GRID);
  useEffect(() => {
    api.get(`tecnico`).then((response) => {
      setData(response.data.records);
      setImage(response.data.records.imagem);
    });
  }, []);

  const handleClick = (event, id) => {
    history.push({
      pathname: "/colaboradorItem/" + id,
    });
  };

  if (!data) return null;
  return (
    <>
      <div>
        <div className="card">
          <div className="border-0 card-header">
            <div className="row">
              <div className="col-6">
                <h4 className="mb-0">
                  <small>
                    {data.length > 0 && (
                      <>
                        Total: <b style={{ fontSize: 18 }}></b> {data.length}
                      </>
                    )}
                  </small>
                </h4>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="align-items-center table-flush table">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "12%", textAlign: "center" }}> </th>
                  <th style={{ width: "12%", textAlign: "center" }}>Cód</th>
                  <th style={{ width: "35%", textAlign: "center" }}>Nome</th>
                  <th style={{ width: "35%", textAlign: "center" }}>E-mail</th>
                  <th style={{ width: "7%", textAlign: "center" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) => {
                    const compararAtivo = b.ativo.localeCompare(a.ativo);

                    if (compararAtivo === 0) {
                      return a.codigo - b.codigo;
                    }

                    return compararAtivo;
                  })
                  .map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="table-user">
                          <img
                            style={{ width: 48, height: 45 }}
                            alt="..."
                            className="avatar rounded-circle"
                            src={item.imagem != null ? item.imagem : Avatar}
                          />
                        </td>
                        <td>
                          <Link to={`/colaboradorItem/${item.codigo}`}>
                            <span>{addZeroes(item.codigo, 6)}</span>
                          </Link>
                        </td>
                        <td>
                          <span>{item.nome}</span>
                        </td>
                        <td>
                          <span>{item.email}</span>
                        </td>
                        <td
                          style={{ marginTop: 9 }}
                          className={
                            item.ativo == "S"
                              ? "badge badge-success text-center"
                              : "badge badge-danger text-center"
                          }
                        >
                          <span>{item.ativo == "S" ? "Ativo" : "Inativo"}</span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColaboradorGrid;
