import { Box, Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AttachmentIcon } from "./AttachmentIcon";
import { AttachmentButtonDropdown } from "./AttachmentButtonDropdown";
import { IconAnexos } from "../../AtendimentoOS/Sprint/components/IconAnexos";
import { DescQuill } from "../../../components/EditorToolbar";

export const SplitScreenContent = ({
  leftContent,
  attachments,
  title,
  rightContent,
}) => {
  return (
    <Grid
      container
      direction="row"
      sx={{ mt: 3 }}
      gap={2}
      justifyContent={"space-between"}
    >
      <Grid item xl={8.8} lg={8.8} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: title }}
            textAlign={"left"}
            fontWeight={700}
            color={grey[800]}
            fontSize={24}
            mb={1}
          ></Typography>
        </Box>

        <Box>
          <DescQuill id="modal-justificacao" value={leftContent} />
          <Box sx={{ marginTop: 1.5 }}>
            {attachments && <IconAnexos anexos={attachments} />}
          </Box>
        </Box>
      </Grid>
      <Grid item xl={3} lg={3} sm={12} xs={12}>
        <Box>{rightContent}</Box>
      </Grid>
    </Grid>
  );
};
