import { createContext, useContext, useState } from "react";

const GanttProjectContext = createContext();

export const GanttProjectProvider = ({ children }) => {
  const [selectedProjectData, setSelectedProjectData] = useState(null);

  const saveProjectData = (projectData) => {
    setSelectedProjectData(projectData);
  };

  return (
    <GanttProjectContext.Provider
      value={{ selectedProjectData, saveProjectData }}
    >
      {children}
    </GanttProjectContext.Provider>
  );
};

export const useGanttProjectContext = () => {
  const context = useContext(GanttProjectContext);

  if (!context) {
    throw new Error(
      "useGanttProjectContext must be used within a GanttProjectProvider"
    );
  }

  return context;
};
