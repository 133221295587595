import React, { Component } from "react";
import {
  REACT_APP_ANO_DEMANDA,
  REACT_APP_COPYRIGHT_DEMANDA,
  REACT_APP_DESC_AMBIENTE_DEMANDA,
} from "../utils/constants";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="main-footer">
          <strong>
            {REACT_APP_COPYRIGHT_DEMANDA} / {REACT_APP_ANO_DEMANDA}{" "}
          </strong>
          <div className="float-right d-none d-sm-inline-block">
            <span className="badge bg-danger">BETA</span>{" "}
            <b> {REACT_APP_DESC_AMBIENTE_DEMANDA} </b>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
