import React from "react";
import { Tooltip } from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import { yellow } from "@mui/material/colors";

function IconeStatus({ task }) {
  switch (task.status) {
    case "ANDAMENTO":
      return (
        <Tooltip title={task.status}>
          <PendingIcon color="primary" fontSize="medium" />
        </Tooltip>
      );
    case "TESTE":
      return (
        <Tooltip title={task.status}>
          <AssignmentIcon sx={{ color: yellow[600] }} />
        </Tooltip>
      );
    case "CONCLUIDO":
      return (
        <Tooltip title={task.status}>
          <CheckCircleIcon color="success" />
        </Tooltip>
      );
  }
}

export function IconeStatusSprint({ task }) {
  switch (task.status) {
    case "A":
      return (
        <Tooltip title="Aguardando ">
          <AccessTimeIcon color="primary" fontSize="medium" className="mr-2" />
        </Tooltip>
      );
     
    case "S":
      return (
        <Tooltip title="Andamento">
          <DoneIcon color="success" className="mr-2" />
        </Tooltip>
      );
  }
}

export default IconeStatus;
