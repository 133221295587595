import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";
import { AlertSuccess, AlertDanger } from "./styles";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  FormHelperText,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Select from "react-select";
import dayjs from "dayjs";
import { z } from "zod";
import api from "../../../../services/api";
import { formatarDataBaseDados } from "../../../../utils/utils";
import { getControle } from "../../../../services/auth";
import { createFormSchema } from "./form-validation/create-form-zod";
import { CircularLoading } from "../../../../components/CircularLoading";
import { CustomGrid } from "../../../../components/CustomGrid";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const ProjetoEditar = () => {
  let history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const [descricao, setDescricao] = useState("");
  const [dataInicio, setDataInicio] = useState(dayjs(new Date()));
  const [dataFim, setDataFim] = useState(new Date().addDays(7));

  const [colorValue, setColorValue] = useState("#000000");
  const [status, setStatus] = useState("ANDAMENTO");
  const [observacao, setObservacao] = useState("");

  const [listResponsaveis, setListResponsaveis] = useState([]);
  const [selectedResponsavel, setSelectedResponsavel] = useState(null);
  const [statusRecord, setStatusRecord] = useState({ type: "", mensagem: "" });
  const [errors, setErrors] = useState({});

  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMutatingProject, setIsMutatingProject] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const usuarioControle = getControle();

  useEffect(() => {
    const validate = () => {
      const formData = {
        descricao,
        dataInicio: dataInicio?.toString(),
        dataFim: dataFim?.toString(),
        responsavel: selectedResponsavel?.value
          ? selectedResponsavel.value.toString()
          : "",
        colorValue,
        observacao,
        status,
      };

      try {
        createFormSchema.parse(formData);
        setErrors({});
        setIsFormValid(true);
      } catch (error) {
        if (error instanceof z.ZodError) {
          const fieldErrors = error.formErrors.fieldErrors;
          setErrors(fieldErrors);
          setIsFormValid(false);
        }
      }
    };

    validate();
  }, [
    descricao,
    dataInicio,
    dataFim,
    selectedResponsavel,
    colorValue,
    observacao,
    status,
  ]);

  const getResponsavel = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`tecnico?codUsuario=${usuarioControle}`);
      const activeResponsaveis = response.data.records.filter(
        (responsavel) => responsavel.ativo === "S"
      );

      setListResponsaveis(activeResponsaveis);
    } catch (err) {
      console.error("Erro ao buscar responsáveis:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getProjeto = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/cadastro-projetos/${id}`);
      const projeto = response.data.records[0];
      setDescricao(projeto.descricao);
      setObservacao(projeto.observacao);
      setDataInicio(projeto.dataInicio);
      setDataFim(projeto.dataConclusao);
      setSelectedResponsavel({
        label: projeto.responsavel.nome,
        value: projeto.responsavel.codigo,
      });
      setStatus(projeto.status);
      setColorValue(projeto.cor);
    } catch (err) {
      console.error("Erro ao buscar projeto:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id !== "0") {
      getProjeto();
    }
    getResponsavel();
  }, [id, usuarioControle]);

  const optionsResponsavel = listResponsaveis.map((respons) => ({
    value: respons.codigo.toString(),
    label: respons.nome,
  }));

  const handleChangeResponsavel = (selectedOption) => {
    setSelectedResponsavel(selectedOption);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const editCadastros = async (e) => {
    setIsMutatingProject(true);

    e.preventDefault();
    if (!isFormValid) {
      setStatusRecord({
        type: "erro",
        mensagem: "Por favor, corrija os erros antes de salvar.",
      });
      return;
    }

    try {
      let dadosJson;
      if (id === "0") {
        dadosJson = {
          descricao,
          dataInicio: formatarDataBaseDados(dataInicio),
          dataConclusao: formatarDataBaseDados(dataFim),
          responsavel: Number(selectedResponsavel.value),
          cor: colorValue,
          observacao,
          status: "ANDAMENTO",
        };

        console.log("API POST ::::::", JSON.stringify(dadosJson));
        const response = await api.post(`cadastro-projetos/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });

        const redirecionar = setTimeout(() => {
          history.push({
            pathname: "/projeto",
          });

          setIsMutatingProject(false);
        }, 1500);
        return () => clearTimeout(redirecionar);
      } else {
        dadosJson = {
          id: id,
          descricao,
          dataInicio: formatarDataBaseDados(dataInicio),
          dataConclusao: formatarDataBaseDados(dataFim),
          responsavel: selectedResponsavel.value,
          cor: colorValue,
          observacao,
          status,
        };

        const response = await api.put(`cadastro-projetos/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });

        const redirecionar = setTimeout(() => {
          history.push({
            pathname: "/projeto",
          });

          setIsMutatingProject(false);
        }, 1500);
        return () => clearTimeout(redirecionar);
      }
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });

      setIsMutatingProject(false);
    }
  };

  if (isLoading) return <CircularLoading />;

  return (
    <>
      <form className="form-horizontal" onSubmit={editCadastros}>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" && (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                )}
                {statusRecord.type === "success" && (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                )}

                {/* Título do Projeto */}

                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box sx={{ width: "100%" }}>
                    <InputLabel htmlFor="descricao">
                      Título do Projeto
                    </InputLabel>
                    <input
                      type="text"
                      className={`form-control ${errors.descricao ? "is-invalid" : "is-valid"
                        }`}
                      id="descricao"
                      name="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                      placeholder="Descrição ..."
                      style={{ height: "41px" }}
                    />
                    {errors.descricao && (
                      <div className="invalid-feedback">
                        {errors.descricao[0]}
                      </div>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: isMobile
                      ? "1fr 1fr"
                      : "0.4fr 0.4fr 1fr 0.1fr",
                    my: 1,
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <InputLabel htmlFor="dataInicio">Data Início</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                      <DatePicker
                        value={dataInicio}
                        onChange={(newValue) => {
                          if (newValue && (!dataFim || newValue.isAfter(dataFim))) {
                            setDataFim(newValue);
                          }
                          setDataInicio(newValue);
                        }}
                        disablePast
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                        dayOfWeekFormatter={(day) =>
                          ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"][day]
                        }
                      />
                    </LocalizationProvider>
                    
                  </Box>

                  <Box>
                    <InputLabel htmlFor="dataFim">Data Final</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                      <DatePicker
                        value={dataFim}
                        onChange={(newValue) => {
                          setDataFim(newValue);
                        }}
                        minDate={dataInicio}
                        disablePast
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                        dayOfWeekFormatter={(day) =>
                          ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"][day]
                        }
                      />
                    </LocalizationProvider>
                  </Box>


                  <Box width={"100%"}>
                    <InputLabel htmlFor="responsavel">Responsável</InputLabel>
                    <Select
                      value={selectedResponsavel}
                      onChange={handleChangeResponsavel}
                      options={optionsResponsavel}
                      isClearable
                      isSearchable
                      placeholder="Selecione..."
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: "41px",
                        }),
                      }}
                    />
                    {errors.responsavel && (
                      <div className="invalid-feedback d-block">
                        {errors.responsavel[0]}
                      </div>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <InputLabel htmlFor="colorPicker" sx={{ width: 60 }}>
                      Cor
                    </InputLabel>
                    <div className="input-group color d-flex justify-content-end">
                      <input
                        id="colorPicker"
                        type="color"
                        name="color"
                        className={`form-control ${errors.colorValue ? "is-invalid" : ""
                          }`}
                        style={{ height: "41px", maxWidth: 60 }}
                        onChange={(event) => setColorValue(event.target.value)}
                        value={colorValue}
                      />
                      {errors.colorValue && (
                        <div className="invalid-feedback">
                          {errors.colorValue[0]}
                        </div>
                      )}
                    </div>
                  </Box>
                </Box>

                {/* Observação */}
                <div className="row">
                  <div className="col-sm-12 mt-1">
                    <div className="form-group">
                      <InputLabel
                        htmlFor="observacao"
                        className="col-sm-6 col-form-label"
                      >
                        Descrição do Projeto/Observação
                      </InputLabel>
                      <textarea
                        className={`form-control ${errors.observacao ? "is-invalid" : "is-valid"
                          }`}
                        id="observacao"
                        placeholder="Descrição / Observação..."
                        onChange={(e) => setObservacao(e.target.value)}
                        value={observacao}
                        required
                      />
                      {errors.observacao && (
                        <div className="invalid-feedback">
                          {errors.observacao[0]}
                        </div>
                      )}
                      {!errors.observacao && (
                        <div className="valid-feedback">Validado.</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Status e Botões */}
                {location.pathname !== "/projetoItem/0" ? (
                  <div className="row d-flex justify-content-between items-center">
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="status"
                        name="status"
                        value={status}
                        onChange={handleChangeStatus}
                      >
                        <FormControlLabel
                          value="ANDAMENTO"
                          control={<Radio />}
                          label="ANDAMENTO"
                        />
                        <FormControlLabel
                          value="CONCLUIDO"
                          control={<Radio />}
                          label="CONCLUÍDO"
                        />
                        <FormControlLabel
                          value="ATRASADO"
                          control={<Radio />}
                          label="ATRASADO"
                        />
                        <FormControlLabel
                          value="INATIVADO"
                          control={<Radio />}
                          label="INATIVADO"
                        />
                      </RadioGroup>
                      {errors.status && (
                        <div className="invalid-feedback d-block">
                          {errors.status}
                        </div>
                      )}
                    </FormControl>


                    <Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "flex-end", width: isMobile ? "100%" : "auto" }}>
                      <button
                        type="submit"
                        className={`btn ${!isFormValid ? "btn-secondary" : "btn-primary"}`}
                        disabled={!isFormValid || isMutatingProject}
                      >
                        Salvar
                      </button>

                      <Link to="/projeto" className="btn btn-danger">
                        Sair
                      </Link>
                    </Box>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-sm-12 d-flex justify-content-end align-items-center">
                      <div className="mr-1 mr-sm-2 mr-md-3">
                        <button
                          type="submit"
                          className={`btn ${!isFormValid ? "btn-secondary" : "btn-primary"}`}
                          disabled={!isFormValid || isMutatingProject}
                        >
                          Salvar
                        </button>
                      </div>
                      <div>
                        <Link to="/projeto" className="btn btn-danger">
                          Sair
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
