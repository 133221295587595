import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useQuery } from "react-query";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";
import { useState } from "react";
import api from "../../../../services/api";
import { getCC } from "../../../../services/auth";
import { useSprintData } from "../../../../contexts/SprintDataContext";
import { ArrowDropDown, Close } from "@mui/icons-material";

export const KanbanFilters = () => {
  const { unidades, currentUnidade, onChangeUnidade } = useUnidadesContext();
  const { selectedGravidade, onChangeGravidade } = useSprintData();

  const { data: gravidades, isLoading: isLoadingGravidades } = useQuery(
    "lista-gravidades-painel",
    async () => {
      const response = await api.get("controle-prioridade");

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: getCC() === "0012010301" ? "1fr 1fr" : "1fr",
        gap: 1,
      }}
    >
      {getCC() === "0012010301" && (
        <FormControl fullWidth size="small">
          <InputLabel id="unidade-label">Unidade de Produção</InputLabel>
          <Select
            labelId="unidade-label"
            label="Unidade de Produção"
            value={currentUnidade}
            onChange={(e) => onChangeUnidade(e.target.value)}
          >
            {unidades.map((item) => (
              <MenuItem key={item.ccusto} value={item}>
                {item.empresa}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth size="small">
        <InputLabel id="gravidade-label">Gravidade</InputLabel>
        <Select
          label="Gravidade"
          labelId="gravidade-label"
          value={selectedGravidade || ""}
          onChange={({ target: { value } }) => onChangeGravidade(value)}
          IconComponent={() => {
            if (selectedGravidade) {
              return (
                <IconButton onClick={() => onChangeGravidade("")}>
                  <Close />
                </IconButton>
              );
            }

            return <ArrowDropDown />;
          }}
        >
          {!isLoadingGravidades &&
            gravidades
              .filter((gravidade) => gravidade.status === "1")
              .map((gravidade) => (
                <MenuItem key={gravidade.id} value={gravidade.id}>
                  {gravidade.descricao}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Box>
  );
};
