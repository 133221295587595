import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  createFilterOptions,
  InputLabel,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  FormControlLabel,
  Switch,
  FormGroup,
  Link,
  Alert,
  AlertTitle,
} from "@mui/material";

import Select from "react-select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import api from "../../../../services/api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  AlertDanger,
  AlertSuccess,
} from "../../../../components/controles/styles";
import ConfirmBox from "../../../../components/controles/ConfirmBox";
import { formatarDataBaseDados, formatarDataBr } from "../../../../utils/utils";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

const aerJson = require("./aeroportos.json");
const filter = createFilterOptions();

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const PassagemSaida = () => {
  let history = useHistory();
  const { id } = useParams();

  const [modalOpen, setModalOpen] = useState(false);

  const [idPassagem, setIdPassagem] = useState([]);
  const [numeroVoo, setNumeroVoo] = useState([]);
  const [numeroVooEscala, setNumeroVooEscala] = useState([]);
  const [numeroPassagem, setNumeroPassagem] = useState([]);
  const [idaVolta, setIdaVolta] = useState("I");
  const [classe, setClasse] = useState([]);
  const [cidade, setCidade] = useState([]);
  const [aeroporto1, setAeroporto1] = useState("");
  const [selectedAeroporto, setSelectedAeroporto] = useState(0);

  const [selectedAeroportoEscala, setSelectedAeroportoEscala] = useState(0);
  const [cidadeEscala, setCidadeEscala] = useState([]);
  const [empresaAerea, setEmpresaAerea] = useState([]);
  const [observacao, setObservacao] = useState([]);
  const [observacaoEscala, setObservacaoEscala] = useState([]);
  const [paradaAereas, setParadaAereas] = useState([]);
  const [escalasDireto, setEscalasDireto] = useState([]);
  const [data, setData] = useState(aerJson);

  const [openAeroporto1, setOpenAeroporto1] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = openAeroporto1 && options.length === 0;

  const [value, setValue] = useState(dayjs(new Date()));
  const [dataHora, setDataHora] = useState("");

  const [checked, setChecked] = useState(false);

  const [openMsg, setOpenMsg] = useState(false);
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [idViagem, setIdViagem] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);

  const [maxWidth, setMaxWidth] = useState("lg");
  const [open, setOpen] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);

  const [deleteData, setDeleteData] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      await sleep(1e3);
      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!openAeroporto1) {
      setOptions([]);
    }
  }, [openAeroporto1]);

  // const handleCheckedOpen = () => {
  //   if (checked === "1") {
  //     setEscala(true);
  //   } else {
  //     setEscala(false);
  //   }
  // };

  const handleCancelarModal = () => {
    setOpen(false);
  };

  const handleClick = (event, id) => {
    history.push({
      pathname: "/viagens/" + id,
    });
  };

  useEffect(() => {
    const getEscala = async () => {
      await api.get(`/parada-aerea/` + id).then((response) => {
        setDataHora(response.data.records[0].dataHora);
      });
    };

    getEscala();
  }, [id]);

  useEffect(() => {
    const getPassagens = async () => {
      await api.get(`/passagem-aerea/demanda/` + id).then((response) => {
        setIdViagem(response.data.records[0].viagem);
        setEscalasDireto(response.data.records[0].direto);
      });
    };

    setChecked(escalasDireto === "N" ? true : false);

    const interval = setInterval(() => {
      getPassagens();
    }, 1000);

    return () => clearInterval(interval);
  }, [id]);

  useEffect(() => {
    const getViagem = async () => {
      await api
        .get(`/passagem-aerea/viagem/` + idViagem + "?idaVolta=I")
        .then((response) => {
          setNumeroVoo(response.data.records[0].numeroVoo);
          setNumeroPassagem(response.data.records[0].numeroPassagem);
          setAeroporto1(response.data.records[0].aeroporto);
          setSelectedAeroporto(response.data.records[0].aeroporto);
          setCidade(response.data.records[0].cidade);
          setEmpresaAerea(response.data.records[0].empresaAerea);
          setObservacao(response.data.records[0].observacao);
          setClasse(response.data.records[0].classe);
          setIdPassagem(response.data.records[0].id);
          setParadaAereas(response.data.records[0].paradasAereas);

          /* setNumeroVooEscala(response.data.records[0].numeroVoo);          
          setSelectedAeroportoEscala(response.data.records[0].aeroporto);
          setCidadeEscala(response.data.records[0].cidade);
          setObservacaoEscala(response.data.records[0].observacao); */
        });
    };
    const interval = setInterval(() => {
      getViagem();
    }, 500);

    return () => clearInterval(interval);
  }, [idViagem]);

  const altData = async (event) => {
    const dataString = event;
    const dataObj = new Date(dataString);

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    let dataAtual = dia + "-" + mes + "-" + ano + " " + "00:00:00";

    var dia = String(dataObj.getDate()).padStart(2, "0");
    var mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    var ano = dataObj.getFullYear();
    let dataSelec = dia + "-" + mes + "-" + ano + " " + "00:00:00";

    const d1 = dataAtual;
    const d2 = dataSelec;

    const diffInNewDate = new Date(d2) - new Date(d1);
    const diffInNovaData = diffInNewDate / (1000 * 60 * 60 * 24);

    let dataSelecionada = diffInNovaData.toFixed(0);

    try {
      if (dataSelecionada < 0) {
        setMensagemDialog(
          `Data Limite não pode ser inferior a data de hoje: ${formatarDataBr(
            dataAtual
          )}!`
        );

        setTipoDialog("error");
        setCorDialog("error");
        setOpenMsg(!openMsg);
      } else {
        setValue(dataString);
        setDataHora(dataString);
      }
    } catch (err) {
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const editPassagens = async (e) => {
    /**
     
CRIADO UMA LISTA DE JSON SEPARADO
DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO*/
    var dadosJson = {
      aeroporto: selectedAeroporto,
      classe: classe,
      dataPassagem: value,
      empresaAerea: empresaAerea,
      numeroPassagem: numeroPassagem,
      numeroVoo: numeroVoo,
      cidade: cidade,
      observacao: observacao,
      viagem: idViagem,
      idaVolta: idaVolta,
    };

    e.preventDefault();
    try {
      if (idPassagem !== "0") {
        await api.put("/passagem-aerea/" + idPassagem, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        await api.post("/passagem-aerea/", dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const limparMSG = setTimeout(() => {
        setStatusRecord({
          type: "",
          mensagem: "",
        });
      }, 2000);

      return () => clearTimeout(limparMSG);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  const editEscalas = () => {
    /**
     
CRIADO UMA LISTA DE JSON SEPARADO
DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO*/
    var dadosJson = {
      aeroporto: selectedAeroportoEscala,
      dataHora: dataHora,
      numeroVoo: numeroVooEscala,
      cidade: cidadeEscala,
      observacao: observacaoEscala,
      passagemAerea: idPassagem,
    };

    //e.preventDefault();
    try {
      api.post("/parada-aerea/", dadosJson);
      setStatusRecord({
        type: "success",
        mensagem: "Novo Registro criado com sucesso",
      });

      setNumeroVooEscala([]);
      setDataHora([]);
      setCidadeEscala([]);
      setObservacaoEscala([]);

      api
        .get(`/passagem-aerea/viagem/` + idViagem + "?idaVolta=V")
        .then((response) => {
          setParadaAereas(response.data.records[0].paradasAereas);
        });
      const limparMSG = setTimeout(() => {
        setStatusRecord({
          type: "",
          mensagem: "",
        });
        setOpen(false);
      }, 1000);

      return () => clearTimeout(limparMSG);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  function openDelete(data, hora) {
    setOpenDel(true);
    setDeleteData(data);
  }
  function deleteEscala() {
    try {
      api.delete(`parada-aerea/` + deleteData?.id);

      setMensagemDialog(`Operação realizada com sucesso!`);
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpenDel(false);
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    }
  }

  const optionsAeroporto = data
    .sort((a, b) => a.name - b.name)
    .map(function (ff) {
      return { value: ff.name, label: ff.name };
    });

  const handleChangeAeroporto = (select) => {
    setSelectedAeroporto(select.value);
  };
  const handleChangeAeroportoEscala = (select) => {
    setSelectedAeroportoEscala(select.value);
  };

  return (
    <>
      <div className="form-group col-md-12 col-sm-12">
        {statusRecord.type === "erro" ? (
          <AlertDanger>{statusRecord.mensagem}</AlertDanger>
        ) : (
          ""
        )}
        {statusRecord.type === "success" ? (
          <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
        ) : (
          ""
        )}
        <form onSubmit={editPassagens}>
          <div className="form-body">
            <div className="col-md-12 col-sm-12 mb-5">
              <h3>
              Retorno
                <i className="fas fa-plane-arrival text-primary ml-2"></i>
                <hr />
              </h3>

              <div className="row">
                <div className="col-md-3 col-sm-3">
                  <InputLabel>Núm. do Voo</InputLabel>

                  <input
                    type="text"
                    className="form-control"
                    id="Numero do Voo"
                    name="Numero do Voo"
                    value={numeroVoo}
                    onChange={(e) => setNumeroVoo(e.target.value)}
                    placeholder="Ex... GRU "
                  />
                </div>
                <div className="col-md-3 col-sm-3">
                  <InputLabel>Núm. da Passagem</InputLabel>

                  <input
                    type="text"
                    className="form-control"
                    id="Numero da Passagem"
                    name="Numero da Passagem"
                    value={numeroPassagem}
                    onChange={(e) => setNumeroPassagem(e.target.value)}
                    placeholder="Ex...333 "
                  />
                </div>
                <div className="col-md-3 col-sm-3">
                  <InputLabel>Classe</InputLabel>

                  <input
                    type="text"
                    className="form-control"
                    id="Classe"
                    name="Classe"
                    value={classe}
                    onChange={(e) => setClasse(e.target.value)}
                    placeholder="Ex...Executiva"
                  />
                </div>

                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <InputLabel>Data de embarque</InputLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <div class="input-group date" data-provide="datepicker">
                        <DateTimePicker
                          className="form-control"
                          value={value}
                          onChange={(newValue) => {
                            altData(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Size"
                              id="outlined-size-small"
                              defaultValue="Small"
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 col-sm-4">
                  <InputLabel>Aeroporto</InputLabel>
                  <Select
                    value={optionsAeroporto.filter(
                      (obj) => obj.value === selectedAeroporto
                    )}
                    onChange={handleChangeAeroporto}
                    options={optionsAeroporto}
                    sx={{ m: 1, maxWidth: 120 }}
                    size="small"
                    clearable={"true"}
                    searchable={"true"}
                    labelKey={"name"}
                    valueKey={"city"}
                    noOptionsMessage={() => "Nenhum registro Encontrado"}
                  />
                </div>

                <div className="col-md-4 col-sm-4">
                  <InputLabel>Cidade</InputLabel>

                  <input
                    type="text"
                    className="form-control"
                    id="Cidade"
                    name="Cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    placeholder="Ex...Franca "
                  />
                </div>

                <div className="col-md-4 col-sm-4">
                  <InputLabel>Empresa Aérea</InputLabel>

                  <input
                    type="text"
                    className="form-control"
                    id="Empresa Aerea"
                    name="Empresa Aerea"
                    value={empresaAerea}
                    onChange={(e) => setEmpresaAerea(e.target.value)}
                    placeholder="Ex...Gol"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12 col-sm-12">
                  <InputLabel>Observação</InputLabel>
                  <textarea
                    className="form-control"
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                    placeholder="Observação..."
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <InputLabel className="col-sm-12 col-form-label">
                    Voo Direto
                  </InputLabel>
                  <div className="col-sm-6">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checked} // Define o estado do Switch baseado no valor de checked
                            onChange={() => {
                              setChecked(!checked); // Inverte o valor de checked ao trocar o estado do Switch
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <div className="col-3 float-right ml-2">
                      <button type="submit" className="btn btn-outline-success">
                        GRAVAR
                      </button>
                    </div>

                    <div className="col-3 float-right">
                      <button
                        onClick={(event) => {
                          handleClick(event, id);
                        }}
                        type="submit"
                        className="btn btn-outline-danger"
                      >
                        CANCELAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {!checked && (
                <>
                  <div className="row mt-3">
                    <div className="col-sm-6 mb-2">
                      <div className="float-left">
                        <div
                          onClick={handleClickOpen}
                          style={{ cursor: "pointer" }}
                        >
                          <Button variant="contained" onClick={handleModalOpen}>
                            Adicionar Escala
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline mt-4">
                    <div className="time-label">
                      <span className="bg-success">Escalas</span>
                    </div>

                    {paradaAereas.map((item) => {
                      return (
                        <div key={item.id}>
                          <i className="fas fa-plane bg-primary" />
                          <div className="timeline-item">
                            <h3 className="timeline-header no-border float-right">
                              <span type="button">
                                <Link
                                  onClick={() => openDelete(item)}
                                  to={"#"}
                                  className="description text-danger"
                                >
                                  <b>Excluir</b>
                                </Link>
                              </span>
                            </h3>

                            <h3 className="timeline-header no-border">
                              <span className="text-primary text-bold">
                                {formatarDataBaseDados(
                                  item.dataHora,
                                  "DataHora"
                                )}
                              </span>
                            </h3>

                            <h3 className="timeline-header no-border">
                              <span>{item.aeroporto}</span>
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>

        <DialogContent>
          <DialogContentText></DialogContentText>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="">
                  <button
                    type="button"
                    className="btn bg-primary btn-sm float-left"
                    onClick={() => setTransportType({ type: "", active: "" })}
                  >
                    <i className="fa fa-plus mr-2"></i> Adicionar Escala
                  </button>
                </div> */}
                  </div>
                </div>
              </div>

              <div className="form-body">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}
                <div className="col-md-12 col-sm-12 mb-5">
                  <h3>
                    Escala
                    <i className="fas fa-plane text-warning ml-2"></i>
                  </h3>

                  <div className="row">
                    <div className="col-md-3 col-sm-3">
                      <InputLabel>Núm. do Voo</InputLabel>

                      <input
                        type="text"
                        className="form-control"
                        id="Numero do Voo"
                        name="Numero do Voo"
                        value={numeroVooEscala}
                        onChange={(e) => setNumeroVooEscala(e.target.value)}
                        placeholder="Ex... GRU "
                      />
                    </div>

                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <InputLabel>Data / Hora</InputLabel>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pt-br"
                        >
                          <div
                            class="input-group date"
                            data-provide="datepicker"
                          >
                            <DateTimePicker
                              className="form-control"
                              value={dataHora}
                              onChange={(newValue) => {
                                altData(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-4 col-sm-4">
                      <InputLabel>Aeroporto</InputLabel>
                      <Select
                        onChange={handleChangeAeroportoEscala}
                        options={optionsAeroporto}
                        clearable={"true"}
                        searchable={"true"}
                        labelKey={"name"}
                        valueKey={"city"}
                        noOptionsMessage={() => "Nenhum registro Encontrado"}
                      />
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <InputLabel>Cidade</InputLabel>

                      <input
                        type="text"
                        className="form-control"
                        id="Cidade"
                        name="Cidade"
                        value={cidadeEscala}
                        onChange={(e) => setCidadeEscala(e.target.value)}
                        placeholder="Ex...Franca "
                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-12 col-sm-12">
                      <InputLabel>Observação</InputLabel>
                      <textarea
                        className="form-control"
                        value={observacaoEscala}
                        onChange={(e) => setObservacaoEscala(e.target.value)}
                        placeholder="Observação..."
                      />
                    </div>
                  </div>
                  <div className="float-right mt-2">
                    <button
                      onClick={handleClose}
                      className="btn btn-outline-danger"
                    >
                      CANCELAR
                    </button>
                  </div>
                  <div className="float-right mr-2 mt-2">
                    <button
                      onClick={editEscalas}
                      className="btn btn-outline-success"
                    >
                      GRAVAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          ></Box>
        </DialogContent>
      </Dialog>
      {/* Este Dialog pertence ao Excluir */}
      <Dialog open={openMsg} onClose={handleFecharAlert}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            //width: '80%',
            //margin: 'auto',
            "& .MuiAlert-icon": {
              color: "blue",
            },
            //backgroundColor: "green"
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
      <ConfirmBox
        open={openDel}
        closeDialog={() => setOpenDel(false)}
        title={deleteData?.paradasAereas}
        deleteFunction={deleteEscala}
      />
    </>
  );
};

export default PassagemSaida;
