import { Box, InputLabel } from "@mui/material";
import React from "react";
import SwitchP from "../../Sprint/components/SwitchPublic";

const MessageDialog = ({ privado, handlePrivateChange }) => {
  return (
    <Box justifyContent="space-between" display="flex" marginTop={2}>
      <Box >
        <InputLabel
        sx={{marginTop: 2}}>Mensagem</InputLabel>
      </Box>
      <Box>
        <SwitchP privado={privado} handlePrivate={handlePrivateChange} />
      </Box>
    </Box>
  );
};

export default MessageDialog;
