import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import csv from "../../../../assets/icones/csv.png";
import xls from "../../../../assets/icones/excel.png";
import pdf from "../../../../assets/icones/pdf.png";
import zip from "../../../../assets/icones/zip.png";
import pptx from "../../../../assets/icones/pptx.png";
import png from "../../../../assets/icones/png.png";
import rar from "../../../../assets/icones/rar.png";
import doc from "../../../../assets/icones/doc.png";
import jpg from "../../../../assets/icones/jpg.png";
import txt from "../../../../assets/icones/txt.png";
import mp4 from "../../../../assets/icones/mp4.png";
import xml from "../../../../assets/icones/xml.png";
import fileIcon from "../../../../assets/file.png"
import DeleteIcon from "@mui/icons-material/Delete";
import { Download } from "@mui/icons-material";
import VideoPlayer from "../../../../components/VideoPlayer";

const videoExtensions = [".mp4", ".avi", ".mov", ".wmv", ".flv", ".mkv", ".webm"];
const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];

const fileIcons = {
  ".xls": xls,
  ".xlsx": xls,
  ".docx": doc,
  ".doc": doc,
  ".png": png,
  ".jpeg": jpg,
  ".jpg": jpg,
  ".zip": zip,
  ".rar": rar,
  ".pptx": pptx,
  ".csv": csv,
  ".pdf": pdf,
  ".txt": txt,
  ".mp4": mp4,
  ".xml": xml,
};

export const IconAnexos = ({ anexos, iconButtonMostrar, onDelete }) => {
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isVideo, setIsVideo] = useState(false);

  const [isLoadingVideo, setIsLoadingVideo] = useState(false);



  const handleOpenPreview = (anexo, isVideo) => {
    setSelectedFile(anexo);
    setIsVideo(isVideo);
    setShowPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setShowPreviewDialog(false);
    setSelectedFile(null);
    setIsVideo(false);
  };

  const handleDeleteConfirmation = () => {
    if (selectedFile !== null) {
      onDelete(selectedFile.id);
    }
    setShowDeleteModal(false);
    setSelectedFile(null);
  };

  const handleDeleteClick = (anexo) => {
    setSelectedFile(anexo);
    setShowDeleteModal(true);
  };

  const getFileIcon = (filePath) => {
    const extension = filePath.slice(
      ((filePath.lastIndexOf(".") - 1) >>> 0) + 2
    ).toLowerCase();

    if (imageExtensions.includes(`.${extension}`)) {
      return filePath;
    }

    if (videoExtensions.includes(`.${extension}`)) {
      return `video/${extension}`;
    }

    return fileIcons[`.${extension}`] || fileIcon;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "initial",
        flexWrap: "wrap",
        maxWidth: "100%",
        gap: 1.5,
      }}
    >
      {anexos &&
        anexos.map((anexo) => {
          const iconSrc = getFileIcon(anexo.caminho);
          const isVideoFile = videoExtensions.some((ext) =>
            anexo.caminho.toLowerCase().endsWith(ext)
          );
          const isImageFile = imageExtensions.some((ext) =>
            anexo.caminho.toLowerCase().endsWith(ext)
          );

          return (
            <Box key={anexo.id}>
              {iconButtonMostrar && (
                <IconButton
                  style={{
                    background: "#f0f0f0",
                  }}
                  aria-label="delete"
                  size="small"
                  color="error"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteClick(anexo);
                  }}
                >
                  <Tooltip title="Excluir">
                    <DeleteIcon fontSize="inherit" />
                  </Tooltip>
                </IconButton>
              )}

              <Tooltip title={anexo.nome}>
                {isVideoFile ? (
                  <Box
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <video
                      width="55"
                      height="50"
                      style={{
                        objectFit: "cover",
                        borderRadius: "4px",
                        border: "1px solid #ddd",
                      }}
                      poster={mp4}
                      onClick={() => handleOpenPreview(anexo, true)}
                    >
                      <source src={anexo.caminho} type={iconSrc} />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                ) : isImageFile ? (
                  <Box>
                    <img
                      className="rounded mx-auto d-block img-thumbnail"
                      style={{
                        width: "55px",
                        height: "50px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      src={iconSrc}
                      alt={anexo.nome}
                      onClick={() => handleOpenPreview(anexo, false)}
                    />
                  </Box>
                ) : (
                  <a
                    href={anexo.caminho}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="rounded mx-auto d-block img-thumbnail"
                      style={{
                        width: "55px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                      src={iconSrc}
                      alt={anexo.nome}
                    />
                  </a>
                )}
              </Tooltip>
            </Box>
          );
        })}

      {showDeleteModal && (
        <Modal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box
            sx={{
              p: 4,
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              width: "400px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography id="delete-modal-title" variant="h6" component="h2">
              Confirmação
            </Typography>
            <Typography id="delete-modal-description" sx={{ mt: 2 }}>
              Deseja realmente excluir este anexo?
            </Typography>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setShowDeleteModal(false)}
              >
                Sair
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteConfirmation}
                sx={{ ml: 2 }}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {selectedFile && (
        <VideoPlayer open={showPreviewDialog} onClose={handleClosePreview} isVideo={isVideo} src={selectedFile.caminho} />
      )}
    </Box>
  );
};
