import React, { useState, useEffect, useMemo } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Switch,
  Tooltip,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { useUserFilters } from "../../contexts/FiltersSearchContext";

export const SideFilter = ({
  filterName,
  data = [],
  setFilter,
  switchButton,
  noTooltip,
}) => {
  const [open, setOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const checkedData = useUserFilters(`checked${filterName}s`);
  const setCheckedData = useUserFilters(`setChecked${filterName}s`);
  const selectedData = useUserFilters(`selected${filterName}s`);
  const setSelectedData = useUserFilters(`setSelected${filterName}s`);

  const firstDataList = useMemo(() => {
    if (toggleSwitch) return data.filter((item) => item?.status === "S").slice(0, 3);
    return data.slice(0, 3)
  }, [data, toggleSwitch]
  );

  const finalDataList = useMemo(() => {
    if (toggleSwitch) return data.filter((item) => item?.status === "S")
    return data.slice(3);
  }, [toggleSwitch, data, firstDataList]);

  const allSelectedValues = useMemo(() => {
    return [...new Set([...selectedData, ...checkedData])];
  }, [selectedData, checkedData]);

  const mapData = {
    Solicitante: {
      id: 'codUsuario',
      name: 'nome',
    },
    Sprint: {
      id: 'id',
      name: 'nome',
    },
    Tecnico: {
      id: 'codigo',
      name: 'nome',
    },
    Projeto: {
      id: 'id',
      name: 'descricao',
    }
  }

  useEffect(() => {
    setFilter(allSelectedValues);
  }, [selectedData, checkedData]);

  useEffect(() => {
    const updatedDataIds = toggleSwitch
      ? [
        ...firstDataList.filter((item) => item.status === "S"),
        ...finalDataList,
      ].map((item) => item[mapData[filterName].id])
      : data.map((item) => item[mapData[filterName].id]);

    if (allSelected) {
      setSelectedData(updatedDataIds);
      setCheckedData(updatedDataIds);
    }
  }, [allSelected, toggleSwitch, data, firstDataList, finalDataList]);

  const handleCheck = (id) => {
    const isActive = checkedData.includes(id);
    if (isActive) {
      const updatedList = checkedData.filter((item) => item !== id);
      setCheckedData(updatedList);
      setSelectedData(updatedList);
    } else {
      setCheckedData((prev) => [...prev, id]);
      setSelectedData((prev) => [...prev, id]);
    }
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleToggleSwitch = () => {
    setToggleSwitch(!toggleSwitch);
    setAllSelected(false);
    setSelectedData([]);
    setCheckedData([]);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setAllSelected(isChecked);

    if (isChecked && toggleSwitch) {
      const allActiveData = [
        ...firstDataList.filter((item) => item.status === "S"),
        ...finalDataList,
      ].map((item) => item[mapData[filterName].id]);

      setSelectedData(allActiveData);
      setCheckedData(allActiveData);
    } else if (isChecked) {
      const allDataIds = data.map((item) => item[mapData[filterName].id]);
      setSelectedData(allDataIds);
      setCheckedData(allDataIds);
    } else {
      setSelectedData([]);
      setCheckedData([]);
    }
  };

  return (
    <FormGroup sx={{ gap: 0.5 }}>
      <Typography
        variant="subtitle2"
        color="#585757"
        paddingLeft={1}
        paddingTop={1}
      >
        {`Por ${filterName}`}
      </Typography>

      {firstDataList.map((item) => (
        <FormGroup sx={{ flexWrap: 'unset', width: "100%", flexDirection: "row" }} key={item[mapData[filterName].id]}>
          <Tooltip
            title={item[mapData[filterName].name]}
            arrow
            placement="right"
            disableHoverListener={noTooltip}
            disableTouchListener={noTooltip}
          >
            <FormControlLabel
              color="#585757"
              control={
                <Checkbox
                  sx={{
                    paddingLeft: 1,
                    paddingBottom: "0px",
                    paddingTop: "0px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  checked={allSelectedValues.includes(item[mapData[filterName].id])}
                  onChange={() => handleCheck(item[mapData[filterName].id])}
                  value={item[mapData[filterName].id]}
                />
              }
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: isSmUp ? 14 : 10,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "8px"
                },
                ".MuiButtonBase-root": {
                  padding: "0 8px 0 8px",
                  minWidth: "40px"
                },
                width: "100%",
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
              label={item[mapData[filterName].name]}
            />
          </Tooltip>
        </FormGroup>
      ))}

      <FormControl
        sx={{
          "legend": {
            width: filterName === "Solicitante" ? "110px" : "90px",
          },
          marginTop: 1,
        }}
        fullWidth
        size="small"
      >
        {filterName !== "Solicitante" && (
          <InputLabel
            id={filterName}
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#a2b8b8",
            }}
          >
            {`Buscar ${filterName}`}
          </InputLabel>
        )}

        {filterName === "Solicitante" ? (
          <Autocomplete
            multiple
            id="tags-outlined"
            options={finalDataList}
            getOptionLabel={(option) => option[mapData[filterName].name]}
            value={selectedData}
            onChange={(event, newValue) => {
              setCheckedData((prev) => [...prev, newValue[newValue.length - 1][mapData[filterName].id]]);
              setSelectedData((prev) => [...prev, newValue[newValue.length - 1][mapData[filterName].id]]);
            }}
            renderTags={(value) => {
              const arraySelected = finalDataList.filter((item) => value.includes(item[mapData[filterName].id]));
              return arraySelected.map((item) => (
                <Chip
                  variant="outlined"
                  label={item.nome}
                  onDelete={() => {
                    setCheckedData((prev) => prev.filter((id) => id !== item[mapData[filterName].id]));
                    setSelectedData((prev) => prev.filter((id) => id !== item[mapData[filterName].id]));
                  }}
                />
              ))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Buscar Solicitante"
                placeholder="Digite para filtrar..."
                size="small"
                sx={{
                  marginTop: 1,
                  "& .MuiInputLabel-root": {
                    color: "#a2b8b8",
                  },
                }}
              />
            )}
            sx={{
              background: "none",
              ".MuiAutocomplete-option": { fontSize: 10 },
              fontSize: 10,
            }}
          />
        ) : (
          <Select
            id="demo-simple-select"
            multiple
            value={selectedData}
            onOpen={handleOpen}
            onClose={handleClose}
            open={open}
            label={`Buscar${filterName}`}
            input={<OutlinedInput label="Tag" />}
            sx={{ background: "none" }}
            renderValue={() =>
              finalDataList
                .filter((item) => selectedData.includes(item[mapData[filterName].id]))
                .map((item) => item[mapData[filterName].name])
                .join(", ")
            }
          >
            <MenuItem>
              <FormControlLabel
                label="Todos"
                sx={{ marginLeft: 0.1 }}
                control={
                  <Checkbox
                    checked={allSelected}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedData.length > 0 &&
                      selectedData.length <
                      (toggleSwitch ? finalDataList.length : data.length)
                    }
                  />
                }
              />
              {switchButton && (
                <FormControlLabel
                  control={<Switch checked={toggleSwitch} onChange={handleToggleSwitch} />}
                  label="Sprints ativas"
                  sx={{ display: "flex", justifyContent: "end" }}
                />
              )}
            </MenuItem>
            {finalDataList
              .sort((a, b) => a[mapData[filterName].name].localeCompare(b[mapData[filterName].name]))
              .map((item) => (
                <MenuItem
                  key={item[mapData[filterName].id]}
                  value={item[mapData[filterName].id]}
                  onClick={() => handleCheck(item[mapData[filterName].id])}
                >
                  <Checkbox checked={allSelectedValues.includes(item[mapData[filterName].id])} />
                  <ListItemText primary={item[mapData[filterName].name]} />
                </MenuItem>
              ))}
          </Select>
        )
        }
      </FormControl>
    </FormGroup>
  );
};
