import React, { Component } from 'react';
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import Footer from "../../../components/Footer";
import { MotoristaEditar } from "./Component/MotoristaEditar";


class Motorista extends Component {


    constructor(props) {
        super(props);
        this.state = {
            nomePagina: "Motoristas",
            nomeNivelCrumb: "Formulário do Registro",
            nomeNivel2Crumb: "Lista Registros"

        };

    }


    render() {
        return (
            <div className="wrapper">
                <Header />
                <Menu />

                <div>
                    <div className="content-wrapper">

                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0">{this.state.nomePagina} </h1>
                                    </div>

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">

                                            <li className="breadcrumb-item"><a href="/motoristas">{this.state.nomeNivel2Crumb}</a></li>
                                            <li className="breadcrumb-item active">{this.state.nomeNivelCrumb}</li>
                                        </ol>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="content">

                            <div className="container-fluid">

                                <div className="row">

                                    <div className="col-lg-12">
                                        
                                            <MotoristaEditar />

                                        
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        );
    }
}

export default Motorista;
