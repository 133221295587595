import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const Retorno = ({ chartRetorno }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const options = {
    chart: {
      type: "bar",
      height: 200,
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      background: "#f5f5f5",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        barHeight: "40%",
        borderRadius: 8,
        borderRadiusApplication: "end",
      },
    },
    colors: ["#223ff9", "#223ff9"],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Retornados",
      offsetY: -13,
      style: {
        fontWeight: "normal",
        fontSize: "17px",
      },
      margin: 20,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Entregue", "Retorno"],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  if (!chartRetorno?.retorno) {
    return null;
  }

  const {  retorno: { entregues, retorno }  } = chartRetorno;

  const series = [
    {
      name: "Entregue, Retorno",
      data: [entregues, retorno],
    },
  ];

  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        padding: "20px",
        borderRadius: "8px",
        height: 280,
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={200}
        width={100}
      />
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1.5fr 1fr",
          gap: 1,
          flex: 1,
          marginLeft: 10,
          marginBottom: 10,
          height: "100%",
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: "end",

        }}
      >
        <Box style={{ display: "grid", marginBottom: "10px" }}>
          <Typography variant="subtitle1">Entregue</Typography>
          <Typography variant="subtitle2" fontFamily="16">{entregues}</Typography>
        </Box>
        <Box
          style={{ display: "grid", alignItems: "center", marginBottom: "10px" }}
        >
          <Typography variant="subtitle1">Retorno</Typography>
          <Typography variant="subtitle2" fontFamily="16">{retorno}</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default Retorno;