import { Edit } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { addZeroes } from "../../../../utils/utils";

export const SprintMobileCard = ({ sprints, backlog }) => {
  return (
    <Box sx={{ backgroundColor: "#f8f8f8", px: 2, py: 1 }}>
      {sprints.map((sprint, index) => (
        <Fragment key={sprint.id}>
          <Box
            sx={{
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "1fr",
              gap: 1,
              width: "100%",
              my: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    py: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    sx={{
                      flexWrap: "nowrap",
                      maxLines: 1,

                      maxWidth: "100%",
                    }}
                  >
                    {addZeroes(sprint.id, 6)} - {sprint.nome}
                  </Typography>
                </Box>

                <Link to={`/sprintItem/${sprint.id}`}>
                  <IconButton size="small" color="primary">
                    <Edit />
                  </IconButton>
                </Link>
              </Box>

              <Link to={`detalhe-sprint/${sprint.id}`}>
                <Button fullWidth variant="outlined" size="small">
                  Painel
                </Button>
              </Link>
            </Box>

            {sprint.id !== 0 && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  mb: 2,
                }}
              >
                <Typography variant="body2">{sprint.inicio}</Typography>
                <Typography textAlign={"end"} variant="body2">
                  {sprint.termino}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 1,
                my: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f8edeb",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Horas Estimadas
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {sprint.totalHorasEstimadasSprint}h
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#fdffb6",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Andamento
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {sprint.andamento}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#ffd166",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Teste
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {sprint.teste}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#baf2d8",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Concluído
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {sprint.concluido}
                </Typography>
              </Box>
            </Box>
          </Box>

          {index !== sprints.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Box>
  );
};
