import React from "react";
import {
  Box,
  Avatar,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
  StepContent,
  Skeleton,
  useTheme,
  useMediaQuery,
  Tooltip,
  Divider,
} from "@mui/material";
import { useQuery } from "react-query";
import api from "../../../../../services/api";
import { converterParaHorasMinutos } from "../../../../../utils/convertToHoursMinutes";

const TechnList = ({ task }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const stepperStyles = {
    "& .MuiStepIcon-root": {
      color: "transparent",
      border: "2px solid gray",
      borderRadius: "50%",
      background: "white",
    },
    "& .MuiStepIcon-active": {
      color: "transparent",
      border: "2px solid gray",
      background: "white",
    },
    "& .MuiStepIcon-completed": {
      color: "transparent",
      border: "2px solid gray",
      background: "white",
    },
  };

  return (
    <Box
      sx={{
        margin: "0 auto",
        padding: 2,
        display: "grid",
        gap: 2,
        overflowY: "scroll",
        maxHeight: "400px",
        alignItems: "start",
        width: "100%",
        // border: "1px solid red",
      }}
    >
      <Stepper
        orientation="vertical"
        activeStep={null}
        connector={
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              height: "30px",
              color: "blue",
              marginRight: isMobile ? "47%" : "79%",
            }}
          />
        }
        sx={{
          ...stepperStyles,
          "& .MuiStep-root": {
            position: "relative",
          },
          "& .MuiStepLabel-labelContainer": {
            position: "relative",
            zIndex: 1,
          },
          "& .MuiStepConnector-lineVertical": {
            minHeight: "100%",
            marginLeft: "12px",
            borderLeftWidth: "2px",
            borderColor: "gray",
          },
          gridTemplateColumns: "1fr 2fr",
          // border: " 1px solid yellow"
        }}
      >
        {task.controleTempoAuxiliar?.map((tech, index) => (
          <Step
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr 1fr" : "0.5fr 2fr",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Avatar sx={{ marginBottom: 1 }} src={tech.tecnico.imagem} />
              <Box>
                <Typography variant="h6" sx={{ fontSize: 15 }}>
                  {tech.tecnico.nome}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: 10 }}
                >
                  {tech.dataTrabalho}
                </Typography>
              </Box>
            </Box>

            <StepLabel
              sx={{
                display: "flex",
                alignItems: "start",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {tech.lista.map((tipo) => (
                <React.Fragment key={tipo.descricaoTipoServico}>
                  <Box
                    sx={{
                      background: "#F3FBEE",
                      borderRadius: 1,
                      width: 52,
                      py: 0.2,
                      px: 0.2,
                      textAlign: "center",
                    }}
                  >
                    <Typography color="green" sx={{ fontSize: 12 }}>
                      {tipo.descricaoTipoServico}
                    </Typography>
                  </Box>
                  {isMobile ? (
                    <Tooltip title={tipo.comentario}>
                      {/* biome-ignore lint/style/useSelfClosingElements: <explanation> */}
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: 300,
                        }}
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                        dangerouslySetInnerHTML={{ __html: tipo.comentario }}
                      ></Typography>
                    </Tooltip>
                  ) : (
                    // biome-ignore lint/style/useSelfClosingElements: <explanation>
                    <Typography
                      sx={{
                        fontSize: 12,
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }}
                      // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                      dangerouslySetInnerHTML={{ __html: tipo.comentario }}
                    ></Typography>
                  )}

                  <Typography sx={{ fontSize: 12 }}>
                    <b>Tempo:</b> {converterParaHorasMinutos(tipo.tempo)}
                  </Typography>
                </React.Fragment>
              ))}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TechnList;
