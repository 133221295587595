import React, { useEffect, useState } from "react";

import api from "../../../services/api";

import "../../../components/controles/css/listUsuario.css";

import { FormControl } from "react-bootstrap";
import { Button, CircularProgress, InputLabel, MenuItem, Select } from "@mui/material";
import { formatarDataBr } from "../../../utils/utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Delete } from "@mui/icons-material";
import { CircularLoading } from "../../../components/CircularLoading";

export default function UsuarioAtivo({
  idDemanda,
  idControle,
  usuarioProcesso,
}) {
  const queryClient = useQueryClient();

  const {
    data: demandaProcessos,
    isLoading,
    isError,
  } = useQuery(
    ["demandaProcessos", idDemanda],
    async () => {
      const response = await api.get(`demanda_processos/demanda/${idDemanda}`);
      return response.data.records;
    },
    {
      enabled: !!idDemanda,
    }
  );

  const dataUsuario = demandaProcessos
    ? demandaProcessos.filter(
      (user) => user.usuario.usuario.codUsuario === idControle
    )
    : [];

  const { mutate: excluirUsuario, isLoading: isLoadingDelete } = useMutation(
    async (id) => {
      await api.delete(`vincular_usuario/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["listaUsuarioVinculado", idDemanda]);
      },
    }
  );

  const handleExcluirUsuario = (id) => {
    try {
      excluirUsuario(id);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dataUsuario.map((item) =>
            (() => {
              if (item.dataHoraLimite != null) {
                return (
                  <a href="" className="d-flex justify-content-between align-items-center">
                    Prazo Definido{" "}
                    <span className="float-right badge bg-primary">
                      {" "}
                      {formatarDataBr(item.dataHoraLimite, "DataHora")}{" "}
                    </span>
                  </a>
                );
              } else {
                return (
                  <>
                    {isLoadingDelete ? (
                      <CircularLoading />
                    ) : (
                      <Button size="small" endIcon={<Delete />} fullWidth color="error" onClick={() => handleExcluirUsuario(usuarioProcesso.id)}>
                        Excluir
                      </Button>
                    )}
                  </>
                );
              }
            })()
          )}
        </>
      )}
    </>
  );
}
