import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Dialog,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import api from "../../../../../services/api";
import { getControle } from "../../../../../services/auth";
import { useEffect, useState } from "react";
import { DescQuill } from "../../../../../components/EditorToolbar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatarDataBaseDados } from "../../../../../utils/utils";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';

moment.locale('pt-br')

export const TecnicoHorasModal = ({
  openModal,
  handleOpenModalTecnico,
  chamado,
  atualizaModal,
  codTecnicoChamado,
}) => {
  const usuarioControle = getControle();
  const [horas, setHoras] = useState("00");
  const [minutos, setMinutos] = useState("00");
  const queryClient = useQueryClient();
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [horasTrabalhadas, setHorasTrabalhadas] = useState("");
  const [selectedTipoCooperacao, setSelectedTipoCooperacao] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [corDialog, setCorDialog] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [nomeTecnicoCooparticipacao, setNomeTecnicoCooparticipacao] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);
  const [nomeTecnico, setNomeTecnico] = useState("");

  const [data, setData] = useState(new Date());

  const ContHTML = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const { data: listTecnico, isLoading: isLoadingTecnico } = useQuery(
    ["usuario-detalhe", usuarioControle],

    async () => {
      await api.get(`/usuarioDetalhe/` + usuarioControle).then((response) => {
        setNomeTecnico(response?.data?.records[0]);
      });
      //return response?.data?.records;
    }
  );

  const codTecnico = JSON.parse(localStorage.getItem("@userTecnico"));

  const { data: cooparticipacao, isLoading: isLoadingCoop } = useQuery(
    ["list-coorpatipacao"],
    async () => {
      const response = await api.get("/controle-tipo-servico");
      setSelectedTipoCooperacao(response.data.records);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );


  //função botão fechar alert
  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  useEffect(() => {
    if (!openModal) {
      if (!isSaved) {
        setHorasTrabalhadas("");
        setDescricao("");
        setHoras("00");
        setMinutos("00");
        setSelectedTipoCooperacao("");
        setData(moment()); 
      }
      setIsSaved(false);
    }

    const getControleTempoCooperacao = async () => {
      await api.get(`controle-tempo/${chamado}`).then((response) => {
        setNomeTecnicoCooparticipacao(response.data);
      });
    };
    Promise.all([getControleTempoCooperacao()]);
  }, [openModal, isSaved]);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "900px",
    bgcolor: "white",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };

  const formattedValueTags = descricao.replace(/<\/?[^>]+(>|$)/g, "").trim();
  /*     .replace(/\s+/g, ""); */

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 4) {
      value = value.slice(-4);
    }

    value = value.padStart(4, "0");

    let formattedValue = `${value.slice(0, 2)}:${value.slice(2)}`;

    e.target.value = formattedValue;

    setHorasTrabalhadas(formattedValue);
  };

  const handleBlur = () => {
    let valor = horasTrabalhadas.replace(/[^0-9]/g, "");

    valor = valor.padStart(4, "0");

    let horas = valor.substring(0, 2);
    let minutos = valor.substring(2, 4);

    if (parseInt(minutos, 10) > 59) {
      minutos = "59";
    }

    const formatado = `${horas}:${minutos}`;

    if (formatado !== "00:00") {
      setHorasTrabalhadas(formatado);
      const [h, m] = formatado.split(":").map(Number);
      setHoras(h);
      setMinutos(m);
    } else {
      setHorasTrabalhadas("");
      setHoras(0);
      setMinutos(1);
    }
  };

  const handleChangeTipoCooperacao = (event) => {
    let valor = event.target.value;
    setSelectedTipoCooperacao(valor);
  };

  const handleData = (date) => {
    console.log("date", date);
    setData(date);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:850px)");

  const today = moment();
  const minDate = today.subtract(5, 'days').format("YYYY-MM-DD");

  const MensagemCont = ContHTML(descricao);

  const handleSalvarCooparticipante = async () => {
    setLoading(true);

    if (MensagemCont.length < 5) {
      setMensagemDialog(
        "O campo de comentário não pode ter menos de 5 caracteres."
      );
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(true);
      setLoading(false);
      return;
    }

    const dadosJson = {
      chamado: chamado,
      tempo: horasTrabalhadas.replace(/:/g, "."),
      codTecnico: codTecnico.codigo,
      data: moment(data).format("DD/MM/YYYY"),
      idTipoServico: selectedTipoCooperacao,
      idComentario: null,
      comentario: descricao,
    };

    try {
      setLoading(true);
      console.log("Caiu no post", dadosJson);
      await api.post(`controle-tempo/`, dadosJson);
      setMensagemDialog("Operação realizada com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setDescricao("");
      setSelectedTipoCooperacao("");
      setHorasTrabalhadas("");
      setHoras("");
      setMinutos("");
      handleOpenModalTecnico();
      setLoading(false);
      await atualizaModal();
    } catch (err) {
      console.log(err);
      setMensagemDialog(err.response.data[0] || "Erro ao salvar!");
      setOpenMsg(!openMsg);
      setTipoDialog("error");
      setCorDialog("error");
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        sx={{ width: "100%", height: "100%", borderRadius: "none" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Box>
              <Typography variant={isMobile ? "h6" : "h5"}>
                Participar
              </Typography>
              <Box
                sx={{
                  display: isMobile ? "flex" : "grid",
                  flexDirection: isMobile ? "column" : "row",
                  gridTemplateColumns: isMobile ? "1fr 1fr" : "2fr 4fr",
                  gap: isMobile ? 1 : 2,
                  mt: isMobile ? 1 : 2,
                }}
              >
                <Box
                  sx={{
                    display: isMobile ? "flex" : "grid",
                    gap: isMobile ? 1 : 4,
                  }}
                >
                  {isLoadingTecnico && (
                    <>
                      <Skeleton variant="circular" width={150} height={150} />
                      <Box sx={{ pl: 2 }}>
                        <Skeleton variant="rounded" width={150} height={60} />
                        <Skeleton variant="rounded" width={210} height={60} />
                      </Box>
                    </>
                  )}
                  {!isLoadingTecnico && (
                    <>
                      <Avatar
                        sx={{
                          width: isMobile ? 75 : 140,
                          height: isMobile ? 75 : 140,
                          marginLeft: isMobile ? 0 : 4,
                          marginTop: isMobile ? 0 : 2,
                          mb: isMobile ? 2 : 0,
                        }}
                        src={nomeTecnico?.avatar}
                      />
                      <Box sx={{ pl: 2 }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: isMobile ? 13 : 16,
                            fontWeight: "bold",
                            marginBottom: isMobile ? 0 : 3,
                          }}
                        >
                          {nomeTecnico?.descricao}
                        </Typography>
                        <Typography variant="body2">
                          {nomeTecnico?.email}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: isMobile ? "flex" : "grid",
                    gap: 2,
                    flexDirection: isMobile ? "column" : "none",
                  }}
                >
                  <DescQuill
                    id={5}
                    value={descricao}
                    onChange={(e) => {
                      setDescricao(e);
                    }}
                    // onBlur={handleSubmitDesc}
                    placeholder={
                      "Insira o resumo da sua cooparticipação para este chamado..."
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
                      gap: 1,
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    {!isLoadingCoop && (
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: isMobile ? 13 : 16,
                          }}
                        >
                          Tipo de Cooparticipação
                        </Typography>
                        <Select
                          sx={{ width: isMobile ? 140 : 180 }}
                          size="small"
                          id="Tipo"
                          value={selectedTipoCooperacao}
                          onChange={handleChangeTipoCooperacao}
                        >
                          {cooparticipacao &&
                            cooparticipacao.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={
                                  item.descricao === "Trabalho" &&
                                  codTecnico.codigo != codTecnicoChamado
                                }
                              >
                                {item.descricao}
                              </MenuItem>
                            ))}
                        </Select>
                      </Box>
                    )}

                    <Box sx={{ textAlign: "start" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: isMobile ? 13 : 16,
                          
                        }}
                      >
                        Data Trabalhada
                      </Typography>
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={"pt-br"}
                      >
                        <DatePicker
                          views={['day']}
                          minDate={minDate}
                          maxDate={moment(new Date())}
                          value={moment(data)}
                          onChange={handleData}
                          shouldDisableDate={(date) => moment(date).isBefore(minDate) || moment(date).isAfter(moment())}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                sx: { height: 9, width: isMobile ? 105 : 120},
                                readOnly: true, 
                                onCopy: (e) => e.preventDefault(),
                                onPaste: (e) => e.preventDefault(), 
                                onCut: (e) => e.preventDefault(), 
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>

                    <Box sx={{ textAlign: "start" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: isMobile ? 13 : 16,
                        }}
                      >
                        Horas Trabalhadas
                      </Typography>
                      <TextField
                        value={horasTrabalhadas}
                        id="outlined-number"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{
                          maxWidth: isMobile ? 135 : 165,
                          flex: "1 1 auto",
                        }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        placeholder="00:00"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleOpenModalTecnico}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleSalvarCooparticipante();
                }}
                variant="contained"
                disabled={
                  (horasTrabalhadas == "" && horasTrabalhadas.length == 0) ||
                  horasTrabalhadas == "00:00" ||
                  selectedTipoCooperacao == undefined ||
                  selectedTipoCooperacao == "" ||
                  formattedValueTags.length <= 3 ||
                  loading
                }
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        </>
      </Modal>

      {openMsg && (
        <Dialog open={openMsg} onClose={handleClick}>
          <Alert
            severity={tipoDialog}
            color={corDialog}
            role="button"
            onClose={() => {
              setOpenMsg(false);
            }}
            sx={{ "& .MuiAlert-icon": { color: "green" } }}
          >
            <AlertTitle></AlertTitle>
            {mensagemDialog}
          </Alert>
        </Dialog>
      )}
    </>
  );
};
