import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const ProjectFilters = ({
  selectedProjeto,
  handleChangeProjeto,
  statusChamado,
  filteredProjeto,
  styleSelect,
}) => {
  return (
    <div>
      <FormControl sx={{ marginTop: 1 }}>
        <InputLabel sx={styleSelect} id="projeto">
          Projeto
        </InputLabel>
        <Select
          label="Projeto"
          value={selectedProjeto || "Selecionar"}
          onChange={handleChangeProjeto}
          disabled={statusChamado.current == "CONCLUIDO"}
          labelId="projeto"
          id="projeto"
          size="small"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: 170,
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            minWidth: 150,
          }}
        >
          <MenuItem value="Selecionar" disabled>
            Selecionar
          </MenuItem>
          {filteredProjeto.map((item) => (
            <MenuItem
              style={{ color: item.diasConclusao < 0 && "red" }}
              disabled={item.diasConclusao < 0}
              key={item.id}
              value={item.id}
              >
              {item.descricao}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ProjectFilters;
