import { image, subtitleImage } from "./image";

export const getExportProperties = (sprintId, tasks, projeto) => {
  return {
    fileName: `${sprintId}-Gantt.pdf`,
    pageOrientation: "Landscape",
    exportType: "CurrentViewData",
    pageSize: "A3",
    fitToWidthSettings: {
      isFitToWidth: true,
      chartWidth: "70%",
      gridWidth: "30%",
    },
    pdfGanttFontStyle: {
      fontSize: 20,
      fontFamily: "Roboto",
    },
    header: {
      fromTop: 0,
      fromLeft: 0,
      height: 150,
      contents: [
        {
          type: "Image",
          src: image,
          position: { x: 10, y: 30 },
          size: { height: 20, width: 350 },
        },
        {
          type: "Text",
          value: `Projeto: ${projeto.dataInicio.slice(
            0,
            10
          )} - ${projeto.dataConclusao.slice(0, 10)}`,
          position: { x: 1530, y: 0 },
          style: {
            fontSize: 14,
          },
        },
      ],
    },
    footer: {
      fromBottom: 160,
      height: 100,
      contents: [
        {
          type: "Image",
          src: subtitleImage,
          position: { x: 1380, y: 20 },
          size: { height: 20, width: 500 },
        },
        {
          type: "PageNumber",
          pageNumberType: "Arabic",
          format: "Página {$current} de {$total}",
          position: { x: 0, y: 25 },
          size: { height: 50, width: 100 },
          style: {
            textBrushColor: "#000000",
            hAlign: "Center",
            vAlign: "Bottom",
          },
        },
      ],
    },
  };
};
