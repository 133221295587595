import { Button, Tooltip, Typography } from "@mui/material";
import { useSprintData } from "../../../../contexts/SprintDataContext";

export const SprintButton = ({ sprint, index }) => {
  const { selectedSprint, fetchSelectedSprint } = useSprintData();

  return (
    <Tooltip title={sprint.nome} arrow placement={index < 4 ? "top" : "bottom"}>
      <Button
        key={sprint.id}
        size="small"
        color="primary"
        variant={`${
          selectedSprint && selectedSprint === sprint.id
            ? "contained"
            : "outlined"
        }`}
        sx={{
          width: "100%",
          maxHeight: "30px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          "& .MuiButton-text": {
            width: "100px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            display: "block",
          },
        }}
        onClick={() => fetchSelectedSprint(sprint.id)}
      >
        <Typography
          maxWidth={300}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "left",
            display: "block",
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Roboto",
          }}
        >
          {sprint.nome}
        </Typography>
      </Button>
    </Tooltip>
  );
};
