import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const Eficiencia = ({ chartEficiencia }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1250));

  const series = [chartEficiencia.eficiencia.eficiencia];

  const options = {
    chart: {
      height: 420,
      type: "radialBar",
      offsetX: -20,
      offsetY: 5,
    },
    colors: ["#223ff9"],
    title: {
      text: "Eficiência",
      style: {
        fontWeight: "normal",
        fontSize: "17px",
      },
      offsetX: 20,
      offsetY: -5,
    },
    responsive: [
      {
        breakpoint: 1463,
        options: {
          plotOptions: {
            radialBar: {
              hollow: {
                size: "40%",
              },
            },
          },
        },
      },
    ],
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        dataLabels: {
          show: true,
          value: {
            show: false,
          },
          name: {
            show: true,
            fontSize: "16px",
            fontWeight: 400,
            color: "#4E4E4E",
            offsetY: 5,
          },
        },
      },
    },
    labels: series.map(value => `${series}%`),
  };

  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        padding: "20px",
        borderRadius: "8px",
        height: 280,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
        }}
      >
        <Box id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            width={180}
            height={200}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
          <Box sx={{ display: "grid" }}>
            <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
              Estimadas
            </Typography>
            <Typography variant="subtitle2" color="gray">
              Trabalhadas
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default Eficiencia;
