import { Box, Card, CardContent, IconButton } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import TechnList from "./Modal/listaTecnicos";

export const AuxTecnicos = ({ handleColaboradores, task, statusAtual }) => {
  const [openTable, setOpenTable] = useState(false);

  const handleChangeTable = () => {
    setOpenTable(!openTable);
  };
  return (
    <Card
      sx={{
        display: "grid",
        flexDirection: "column",
        whiteSpace: "nowrap",
        mt: 2,
      }}
    >
      <CardContent sx={{ p: 2, display: "flex", gap: 2 }}>
        <IconButton onClick={handleColaboradores}>
          {statusAtual != "CONCLUIDO" ? (
            <PersonAddAltIcon fontSize="small" color="action" />
          ) : (
            ""
          )}
        </IconButton>
        {task.controleServico?.controleTempoAuxiliar?.length > 0 && (
          <IconButton onClick={handleChangeTable}>
            {openTable ? (
              <VisibilityOff fontSize="small" color="action" />
            ) : (
              <Visibility fontSize="small" color="action" />
            )}
          </IconButton>
        )}
      </CardContent>
      {openTable && <TechnList task={task.controleServico} />}
    </Card>
  );
};
