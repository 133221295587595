export const timelineSettings = {
  topTier: {
    format: "MMM/yyyy",
    unit: "Month",
  },
  bottomTier: {
    unit: "Day",
    format: "d",
  },
};
