import { z } from "zod";

export const createFormSchema = z.object({
    descricao: z.string().min(1, "Campo obrigatório"),
    dataInicio: z.string({
      required_error: "Data de Início é obrigatória",
    }),
    dataFim: z.string({
      required_error: "Data Final é obrigatória",
    }),
    responsavel: z.string().min(1, "Selecione o responsável"),
    colorValue: z.string().min(1, "Selecione uma cor"),
    observacao: z.string().min(10, "Campo obrigatório (Min 10 caracteres)"),
    status: z.enum(["ANDAMENTO", "CONCLUIDO", "ATRASADO", "INATIVADO"], {
      errorMap: () => ({ message: "Selecione um status" }),
    }),
});
