import React from "react";
import { HistoricoHorasEst } from "../../Sprint/components/Modal/HistoricoHorasEst";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";

const HistEstimHours = ({
  historicoHrEstimadas,
  handleChecked,
  isChecked,
  eyes,
  eyesSlash,
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {historicoHrEstimadas.length > 0 && (
        <Box alignItems="center" justifyContent="center"  sx={{marginTop: isMobile ? 2 : 0}}>
          <Box display="flex" alignItems="flex-start" justifyContent="center" >
            <Button onClick={handleChecked}>
              <img
                className="image ml-3"
                sx={{ maxHeight: "300px" }}
                src={isChecked ? eyes : eyesSlash}
                alt="Photo"
              />
            </Button>
            <Typography variant="body2" fontWeight={500} ml={1}>
              Histórico Horas Estimadas
            </Typography>
          </Box>
          {isChecked ? (
            <HistoricoHorasEst listaHistorico={historicoHrEstimadas} />
          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
};

export default HistEstimHours;
