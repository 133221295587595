import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AlertSuccess, AlertDanger } from "./styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";

import api from "../../../../services/api";
import { formatarDataBaseDados } from "../../../../utils/utils";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import moment from "moment";

export const SprintEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [descricao, setDescricao] = useState("");
  const [dataInicio, setDataInicio] = useState(moment(new Date()));
  const [dataFim, setDataFim] = useState(null);
  const [status, setStatus] = useState("A");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [ openDateInicial, setOpenDateInicial ] = useState(false);
  const [ openDateFim, setOpenDateFim ] = useState(false);

  const responsavel = localStorage.getItem("USER_NAME");

  const [isSendingPOST, setIsSendingPOST] = useState(false);

  /*Editar Registros*/
  const editCadastros = async (e) => {
    e.preventDefault();

    try {
      setIsSendingPOST(true);
      if (id == "n") {
        var dadosJson = {
          nome: descricao,
          inicio: moment(dataInicio).format("DD/MM/YYYY HH:mm:ss"),
          termino: moment(dataFim).format("DD/MM/YYYY HH:mm:ss"),
          status: status,
          userCreated: responsavel.toUpperCase(),
        };

        //console.log("dadosJson Novo", dadosJson);
        await api.post(`controle-sprint/`, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      } else {
        var dadosJson = {
          id: id,
          nome: descricao,
          inicio: moment(dataInicio).format("DD/MM/YYYY HH:mm:ss"),
          termino: moment(dataFim).format("DD/MM/YYYY HH:mm:ss"),
          status: status,
        };
        // console.log("dadosJson Editar", dadosJson);
        await api.put(`controle-sprint/${id}`, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        history.push({
          pathname: "/sprint",
        });

        setIsSendingPOST(false);
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      setIsSendingPOST(false);
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  function formataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];

    return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
  }

  useEffect(() => {
    const getProjeto = async () => {
      await api.get(`/controle-sprint/` + id).then((response) => {
        setDescricao(response.data.records[0].nome);
        setDataInicio(formataStringData(response.data.records[0].inicio));
        setDataFim(formataStringData(response.data.records[0].termino));
        setStatus(response.data.records[0].status);
      });
    };

    if (id !== "n") {
      getProjeto();
    }
  }, [id]);

  const handleChangeStatus = (event) => {
    let tipoStatus = event.target.value;
    setStatus(tipoStatus);
  };

  const currentYear = moment().year();

  const MinDataInicial = () => {
    const minDate = moment().subtract(7, "days").toDate();
    const currentDate = moment();
    const endOfYear = moment().endOf('year');
    let maxDate;
  
    if (currentDate.isAfter(endOfYear.subtract(7, 'days'))) {
      maxDate = currentDate.add(1, 'year').toDate();
    } else {
      maxDate = endOfYear.add(7, 'days').toDate();
    }
  
    return { minDate, maxDate };
  };


  const MinDataFinal = (startDate) => {
    const minDate = startDate ? moment(startDate).toDate() : new Date();
    const maxDate = startDate
      ? moment(startDate).add(1, "year").toDate()
      : new Date(currentYear + 1, 11, 31);
    return { minDate, maxDate };
  };

  const { minDate: startMinDate, maxDate: startMaxDate } = MinDataInicial();
  const { minDate: endMinDate, maxDate: endMaxDate } = MinDataFinal(dataInicio);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <FormControl
                  className="form-horizontal"
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr 1fr 0.5fr",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="descricao"
                    name="descricao"
                    disabled={isSendingPOST}
                    required
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    placeholder="Descrição ..."
                  />

                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={dayjs.locale("pt-br")}
                  >
                    <DatePicker
                      views={['day']}
                      open={openDateInicial}
                      onOpen={() => setOpenDateInicial(true)}
                      onClose={() => setOpenDateInicial(false)}
                      label="Data Início"
                      minDate={startMinDate}
                      maxDate={startMaxDate}
                      value={dataInicio}
                      disabled={isSendingPOST}
                      onChange={(newDate) => {
                        setDataInicio(newDate);
                        if (dataFim && moment(newDate).isAfter(dataFim)) {
                          setDataFim(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                        onClick={(e) => setOpenDateInicial(true)}
                          placeholder="Data Início"
                          label="Size"
                          id="outlined-size-small"
                          size="small"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true, 
                            onCopy: (e) => e.preventDefault(),
                            onPaste: (e) => e.preventDefault(), 
                            onCut: (e) => e.preventDefault(), 
                          }}
                        />
                      )}
                      dayOfWeekFormatter={(day) => {
                        const daysOfWeek = [
                          "Dom",
                          "Seg",
                          "Ter",
                          "Qua",
                          "Qui",
                          "Sex",
                          "Sab",
                        ];
                        return daysOfWeek[day];
                      }}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <DatePicker
                      views={['month', 'year', 'day']}
                      open={openDateFim}
                      onOpen={() => setOpenDateFim(true)}
                      onClose={() => setOpenDateFim(false)}
                      label="Data Final"
                      value={dataFim}
                      minDate={endMinDate} 
                      maxDate={endMaxDate} 
                      disabled={isSendingPOST}
                      onChange={(newDate) => setDataFim(newDate)}
                      renderInput={(params) => (
                        <TextField
                        onClick={(e) => setOpenDateFim(true)}
                          label="Size"
                          placeholder="Data Final"
                          required
                          id="outlined-size-small"
                          size="small"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true, 
                            onCopy: (e) => e.preventDefault(),
                            onPaste: (e) => e.preventDefault(), 
                            onCut: (e) => e.preventDefault(), 
                          }}
                        />
                      )}
                      dayOfWeekFormatter={(day) => {
                        const daysOfWeek = [
                          "Dom",
                          "Seg",
                          "Ter",
                          "Qua",
                          "Qui",
                          "Sex",
                          "Sab",
                        ];
                        return daysOfWeek[day];
                      }}
                    />
                  </LocalizationProvider>

                  <Button
                    type="submit"
                    disabled={isSendingPOST || !descricao || !dataFim}
                    variant="contained"
                    onClick={(e) => editCadastros(e)}
                  >
                    Salvar
                  </Button>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <FormControlLabel
                      value="A"
                      control={<Radio />}
                      disabled={isSendingPOST}
                      label="EM AGUARDO"
                    />
                    <FormControlLabel
                      value="S"
                      control={<Radio />}
                      disabled={isSendingPOST}
                      label="ANDAMENTO"
                    />

                    {id !== "n" && (
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        disabled={isSendingPOST}
                        label="CONCLUÍDO"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
