import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { Button } from "react-bootstrap";
import { AlertSuccess, AlertDanger } from "./styles";
import SemImg from "../../../../assets/sem-foto.svg";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Rating,
  Typography,
} from "@mui/material";

const HotelEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [nome, setNome] = useState([]);
  const [email, setEmail] = useState([]);
  const [site, setSite] = useState([]);
  const [contato, setContato] = useState([]);

  const [logradouro, setLogradouro] = useState([]);
  const [bairro, setBairro] = useState([]);
  const [cidade, setCidade] = useState([]);
  const [uf, setUf] = useState([]);
  const [pais, setPais] = useState([]);

  const [avaliacao, setAvaliacao] = useState(0);

  const [observacao, setObservacao] = useState([]);
  const [codigoPostal, setCodigoPostal] = useState([]);
  const [cnpj, setCnpj] = useState([]);
  const [status, setStatus] = useState(0);

  const [images, setImages] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);
  const [arquivo, setArquivo] = useState([]);

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [aguardando, setAguardando] = useState(true);
  const [statusImagem, setStatusImagem] = useState(false);
  const [indexPrincipal, setIndexPrincipal] = useState(0);

  const [open, setOpen] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);

  const [progress, setProgress] = useState();

  /* valores da caixa de dialog
    md = medio
    xs = pequeno
    sm = default (entre o pequeno  )
    lg = largo
    xl = grande
     */
  const [maxWidth, setMaxWidth] = useState("md");

  const handleClickOpen = () => {
    setAguardando(true);
    let idReg = localStorage.getItem("idHot");
    api.get(`hotelalbum/hotel/` + idReg).then((response) => {
      setImages(response.data.records);
      setImagemPrincipal(response.data.records[indexPrincipal]);
      setAguardando(false);
    });

    setOpen(true);
  };

  const handleClickCancelar = (event, id) => {
    history.push({
      pathname: "/hoteis",
    });
  };

  const handleClose = () => {
    if (imagemPrincipal) {
      setOpen(false);

      const redVoltar = setTimeout(() => {
        history.push({
          pathname: "/hoteis",
        });
        localStorage.removeItem("idHot");
      }, 1000);

      return () => clearTimeout(redVoltar);
    } else {
      setStatusImagem(true);
    }
  };

  const alteraImagemPrincipal = (item, idx) => {
    setImagemPrincipal(item);
    setIndexPrincipal(idx);
  };
  const telaResponsiva = (ParAjusta) => {
    if (ParAjusta !== "0") {
      return "col-md-9";
    } else {
      return "col-md-12";
    }
  };

  /*Editar Registros*/
  const editHospedagem = async (e) => {
    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          nome: nome,
          contato: contato,
          email: email,
          site: site,
          status: status,
          observacao: observacao,
          cidade: cidade,
          uf: uf,
          logradouro: logradouro,
          bairro: bairro,
          pais: pais,
          cnpj: cnpj,
          codigopostal: codigoPostal,
          avaliacao: avaliacao,
        };

        await api.put(`hotel/` + id, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          id: id,
          nome: nome,
          contato: contato,
          email: email,
          site: site,
          status: status,
          observacao: observacao,
          cidade: cidade,
          uf: uf,
          logradouro: logradouro,
          bairro: bairro,
          pais: pais,
          cnpj: cnpj,
          codigopostal: codigoPostal,
          avaliacao: avaliacao,
        };

        await api.post(`hotel/`, dadosJson).then((res) => {
          localStorage.setItem("idHot", res.data.records[0].id);
        });

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        let idReg = localStorage.getItem("idHot");

        if (idReg == "0") {
          history.push({
            pathname: "/hoteis",
          });
        } else if (id === "0") {
          handleClickOpen();
        } else {
          history.push({
            pathname: "/hoteis",
          });
        }
      }, 1000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  function uploadImagem(e) {
    var data = new FormData();

    var idReg = localStorage.getItem("idHot");

    data.append("file", e.target.files[0]);
    data.append("idHotel", id !== "0" ? id : idReg);
    data.append("posicao", images.length + 1);

    api
      .post(`hotelalbum/enviar-arquivo`, data, {
        mode: "no-cors",
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          type: "formData",
        },
        body: data,
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        function (res) {
          if (res.status === 200 || res.status === 201) {
            setAguardando(true);
            api
              .get(`hotelalbum/hotel/` + (id !== "0" ? id : idReg))
              .then((response) => {
                setImages(response.data.records);
                setImagemPrincipal(response.data.records[indexPrincipal]);
                //getImages();
                getArquivo();
                console.log(
                  "Imagem Principal " +
                    `${response.data.records[indexPrincipal]}`
                );
                setAguardando(false);
              });
          } else if (res.status == 401) {
            alert("Erro ao enviar a imagem");
          }
        },
        function (err) {
          alert("Erro no modo de Submeter!");
        }
      );
  }
  const excluirFoto = async (img) => {
    try {
      await api.post(`hotelalbum/apagar-arquivo`, img);

      getImages();
      getArquivo();
    } catch (err) {
      console.log(err);
    }
  };
  const alteraStatusImagem = async (img) => {
    try {
      await api.put(`hotelalbum/` + img.id, img);

      getImages();
    } catch (err) {}
  };

  useEffect(() => {
    const getHotel = async () => {
      await api.get(`hotel/` + id).then((response) => {
        setNome(response.data.records[0].nome.toUpperCase());

        setEmail(response.data.records[0].email);
        setSite(response.data.records[0].site);
        setContato(response.data.records[0].contato);

        setLogradouro(response.data.records[0].logradouro.toUpperCase());
        setBairro(response.data.records[0].bairro.toUpperCase());

        setCidade(response.data.records[0].cidade.toUpperCase());
        setUf(response.data.records[0].uf.toUpperCase());
        setPais(response.data.records[0].pais.toUpperCase());

        setCodigoPostal(response.data.records[0].codigopostal);
        setCnpj(response.data.records[0].cnpj);

        setAvaliacao(response.data.records[0].avaliacao);

        setObservacao(response.data.records[0].observacao.toUpperCase());
      });
    };

    getHotel();
    getImages();
    getArquivo();
  }, [id]);

  const getImages = async () => {
    var idReg = localStorage.getItem("idHot");

    setAguardando(true);
    await api
      .get(`hotelalbum/hotel/` + (id !== "0" ? id : idReg))
      .then((response) => {
        setImages(response.data.records);
        setImagemPrincipal(response.data.records[indexPrincipal]);
        console.log(
          "Imagem Principal " + `${response.data.records[indexPrincipal]}`
        );
        setAguardando(false);
      });
  };
  const getArquivo = async () => {
    var idReg = localStorage.getItem("idHot");
    setArquivo([]);
    setAguardando(true);
    await api
      .get(`hotelalbum/hotel/arquivo/` + (id !== "0" ? id : idReg))
      .then((response) => {
        setArquivo(response.data.records[0].hotelAlbum);
        setAguardando(false);
      });
  };

  /**
   * Trata a Renderizacao da imagem para ser exibida
   * de acordo com o valor preenchido no Objeto imagemPrincipal
   * @param {*} imPrincipal
   * @returns
   */
  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px; ${
                imagemPrincipal.hotelAlbum?.status == 1
                  ? ""
                  : "filter: grayscale();"
              }}`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
          <p className="text-muted text-center">
            Principal:{" "}
            {imagemPrincipal.hotelAlbum?.status == 1 ? "Ativo" : "Inativo"}{" "}
            <i
              className="fas fa-sync"
              onClick={(e) => alteraStatusImagem(imagemPrincipal.hotelAlbum)}
            ></i>
          </p>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  function verificarImagemModal(imPrincipal) {
    //MOSTRAR IMAGEM
    if (imPrincipal) {
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 150px}`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
          <p className="text-muted text-center"></p>
        </div>
      );
    }
  }

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Adicionar Imagens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="callout callout-info">
              <h5>Obrigatório</h5>
              <p>Adicione as imagens que representa o Hotel</p>
            </div>
            {(() => {
              //console.log("Status da Imagem " + statusImagem);

              if (statusImagem == true) {
                return (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    ></button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alerta!
                    </h5>
                    Obrigatório a escolha da imagem.
                  </div>
                );
              }
            })()}
          </DialogContentText>

          <div className="col-md-12">
            {(() => {
              if (localStorage.getItem("idHot") !== "0") {
                return (
                  <div className="col-md-12">
                    {/* Profile Image */}
                    {!aguardando && (
                      <div>
                        <div className="card card-comment card-outline">
                          <div className="card-body">
                            {verificarImagemModal(imagemPrincipal)}

                            <div className="row">
                              <div className="row">
                                <div className="text-left col-10">
                                  <label
                                    for="arquivo"
                                    className="btn btn-success"
                                  >
                                    Adicionar Imagem{" "}
                                    <i className="fa fa-plus"></i>
                                  </label>
                                  <input
                                    type="file"
                                    id="arquivo"
                                    Style="display:none"
                                    onChange={(e) => uploadImagem(e)}
                                  ></input>
                                </div>

                                {imagemPrincipal && (
                                  <div className="text-right col-6">
                                    <button
                                      className="btn btn-danger"
                                      onClick={(e) =>
                                        excluirFoto(imagemPrincipal.hotelAlbum)
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {images.map(
                            (
                              item,
                              idx //border-radius: 20px;
                            ) => (
                              <div className="col-sm-5">
                                <div
                                  className="product-image-thumbs"
                                  onClick={(e) =>
                                    alteraImagemPrincipal(item, idx)
                                  }
                                >
                                  <div className="product-image-thumb">
                                    <img
                                      Style={`${
                                        item && item.hotelAlbum.status == 1
                                          ? ""
                                          : "filter: grayscale();"
                                      }}`}
                                      src={`data:image/jpeg;base64, ${item.blobImage}`}
                                      alt="Photo"
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            })()}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>

      <div className="row">
        {(() => {
          if (id !== "0") {
            return (
              <div className="col-md-3">
                {/* Profile Image */}
                {!aguardando && (
                  <div>
                    <div className="card card-comment card-outline">
                      <div className="card-body">
                        {verificarImagem(imagemPrincipal)}

                        <div className="row">
                          <div className="row">
                            {id === "0" ? (
                              <p>Gravar o registro para inserir imagens!</p>
                            ) : (
                              <div className="text-left col-6">
                                <label
                                  for="arquivo"
                                  className="btn btn-success"
                                >
                                  <i className="fa fa-plus"></i>
                                </label>
                                <input
                                  type="file"
                                  id="arquivo"
                                  Style="display:none"
                                  onChange={(e) => uploadImagem(e)}
                                ></input>
                              </div>
                            )}
                            {imagemPrincipal && (
                              <div className="text-right col-6">
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) =>
                                    excluirFoto(imagemPrincipal.hotelAlbum)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {images.map(
                        (
                          item,
                          idx //border-radius: 20px;
                        ) => (
                          <div className="col-sm-5">
                            <div
                              className="product-image-thumbs"
                              onClick={(e) => alteraImagemPrincipal(item, idx)}
                            >
                              <div className="product-image-thumb">
                                <img
                                  Style={`${
                                    item && item.hotelAlbum.status == 1
                                      ? ""
                                      : "filter: grayscale();"
                                  }}`}
                                  src={`data:image/jpeg;base64, ${item.blobImage}`}
                                  alt="Photo"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                {/* NÃO APAGAR ESTE TRECHO ABAIXO DE DUPLICAÇÃO DO REGISTRO */}
                {/* <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Manutenção do Registro</h3>
                    <div className="card-tools"></div>
                  </div>
                  <div className="card-body p-0">
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <a
                          onClick={duplicaRegistro}
                          href="#"
                          className="nav-link"
                        >
                          Duplicar Registro
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}

                {/*    <MaquinarioSetorPatrimonio id={maquinarioId} /> */}
              </div>
            );
          }
        })()}

        <div className={telaResponsiva(id)}>
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editHospedagem}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group was-validated">
                        <label
                          htmlFor="input"
                          className="col-sm-12 col-form-label"
                        >
                          Hotel
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="nome"
                          name="nome"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                          placeholder="Nome do Hotel"
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label
                        htmlFor="inputEmail"
                        className="col-sm-12 col-form-label"
                      >
                        E-mail
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-mail do Hotel"
                        required="required"
                      />
                    </div>

                    <div className="col-sm-4">
                      <label
                        htmlFor="inputSite"
                        className="col-sm-4 col-form-label"
                      >
                        Site
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="site"
                        name="site"
                        value={site}
                        onChange={(e) => setSite(e.target.value)}
                        placeholder="Site do Hotel"
                      />
                    </div>
                    <div className="col-sm-4">
                      <label
                        htmlFor="inputSkills"
                        className="col-sm-12 col-form-label"
                      >
                        Telefone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contato"
                        name="contato"
                        value={contato}
                        onChange={(e) => setContato(e.target.value)}
                        placeholder="(00)0000-0000"
                      />
                      <div className="invalid-feedback">
                        Preencha esse campo.
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-8">
                      <label
                        htmlFor="inputSkills"
                        className="col-sm-6 col-form-label"
                      >
                        Endereço
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="logradouro"
                        name="logradouro"
                        value={logradouro}
                        onChange={(e) => setLogradouro(e.target.value)}
                        placeholder="Endereço do Hotel"
                      />
                      <div className="invalid-feedback">
                        Preencha esse campo.
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <label
                        htmlFor="inputSkills"
                        className="col-sm-6 col-form-label"
                      >
                        Bairro
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bairro"
                        name="bairro"
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                        placeholder="Bairro"
                      />
                      <div className="invalid-feedback">
                        Preencha esse campo.
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-10">
                      <label
                        htmlFor="inputSkills"
                        className="col-sm-6 col-form-label"
                      >
                        Cidade
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cidade"
                        name="cidade"
                        value={cidade}
                        onChange={(e) => setCidade(e.target.value)}
                        placeholder="Cidade"
                      />
                      <div className="invalid-feedback">
                        Preencha esse campo.
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <label
                        htmlFor="inputSkills"
                        className="col-sm-12 col-form-label"
                      >
                        UF
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="uf"
                        name="uf"
                        value={uf}
                        onChange={(e) => setUf(e.target.value)}
                        placeholder="UF"
                      />
                      <div className="invalid-feedback">
                        Preencha esse campo.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <label
                        htmlFor="inputSkills"
                        className="col-sm-4 col-form-label"
                      >
                        País
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pais"
                        name="pais"
                        value={pais}
                        onChange={(e) => setPais(e.target.value)}
                        placeholder="País"
                      />
                      <div className="invalid-feedback">
                        Preencha esse campo.
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3">
                      <label
                        htmlFor="inputName"
                        className="col-sm-12 col-form-label"
                      >
                        Código Postal
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="codigopostal"
                        name="codigopostal"
                        value={codigoPostal}
                        onChange={(e) => setCodigoPostal(e.target.value)}
                        placeholder="Codigo Postal"
                        required="required"
                      />
                    </div>

                    <div className="col-sm-4">
                      <label
                        htmlFor="inputName"
                        className="col-sm-4 col-form-label"
                      >
                        CNPJ
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cnpj"
                        name="cnpj"
                        value={cnpj}
                        onChange={(e) => setCnpj(e.target.value)}
                        placeholder="CNPJ"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="col-sm-6">
                      <div>
                        <Box component="fieldset" borderColor="transparent">
                          <Typography className="mb-2 mt-4" component="legend">
                            <b>Avaliar</b>
                          </Typography>
                          <Rating
                            name="simple-controlled"
                            value={avaliacao}
                            onChange={(event, newValue) => {
                              setAvaliacao(newValue);
                            }}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group was-validated">
                        <label
                          htmlFor="inputExperience"
                          className="col-sm-6 col-form-label"
                        >
                          Observação
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="observacao"
                          placeholder="Observação..."
                          onChange={(e) => setObservacao(e.target.value)}
                          defaultValue={observacao}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="float-right">
                        <button
                          type="submit"
                          className="btn btn-md btn-outline-primary"
                        >
                          <i className="fa fa-paper-plane mr-2"></i>
                          SALVAR
                        </button>
                      </div>

                      <div className="float-right mr-2">
                        <button
                          onClick={(event) => {
                            handleClickCancelar(event, id);
                          }}
                          type="submit"
                          className="btn btn-md btn-outline-danger"
                        >
                          <i className="fa fa-times mr-2"></i>
                          CANCELAR
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};
export default HotelEditar;
