import { createContext, useContext, useState } from "react";
const UserFiltersContext = createContext();
export const UserFilters = ({ children }) => {
  // tecnico
  const [selectedTecnicos, setSelectedTecnicos] = useState([]);
  const [checkedTecnicos, setCheckedTecnicos] = useState([]);
  // Sprint
  const [checkedSprints, setCheckedSprints] = useState([]);
  const [selectedSprints, setSelectedSprints] = useState([]);
  // Solicitante
  const [selectedSolicitantes, setSelectedSolicitantes] = useState([]);
  const [checkedSolicitantes, setCheckedSolicitantes] = useState([]);
  // Status
  const [selectedStatus, setSelectedStatus] = useState([]);
  // Categoria
  const [selectedClassIds, setSelectedClassIds] = useState([]);
  // Projetos
  const [checkedProjetos, setCheckedProjetos] = useState([]);
  const [selectedProjetos, setSelectedProjetos] = useState([]);

  return (
    <UserFiltersContext.Provider
      value={{
        selectedProjetos,
        setSelectedProjetos,
        checkedProjetos,
        setCheckedProjetos,
        selectedTecnicos,
        setSelectedTecnicos,
        checkedTecnicos,
        setCheckedTecnicos,
        checkedSprints,
        setCheckedSprints,
        selectedSprints,
        setSelectedSprints,
        selectedSolicitantes,
        setSelectedSolicitantes,
        checkedSolicitantes,
        setCheckedSolicitantes,
        selectedStatus,
        setSelectedStatus,
        selectedClassIds,
        setSelectedClassIds,
      }}
    >
      {children}
    </UserFiltersContext.Provider>
  );
};

export const useUserFilters = (field) => {
  const context = useContext(UserFiltersContext);
  if (field) return context[field];
  return context
};
