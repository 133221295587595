import { Box, MenuItem, Select } from "@mui/material";
import { useQuery } from "react-query";
import api from "../../../services/api";

export const GanttFilters = ({ projectId, currentSprint, onSelectSprint }) => {
  const { data, isLoading, error } = useQuery(
    ["gantt-sprints", projectId],
    async () => {
      const response = await api.get(`controle-sprint/projeto/${projectId}`);

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
    }
  );

  if (isLoading) {
    return (
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        <Select value={""}>
          <MenuItem value="" disabled>
            Carregando...
          </MenuItem>
        </Select>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        <Select value={""}>
          <MenuItem value="" disabled>
            Erro ao carregar os dados
          </MenuItem>
        </Select>
      </Box>
    );
  }

  return (
    <Select
      onChange={onSelectSprint}
      value={currentSprint === 0 ? "0" : currentSprint || "none"}
      fullWidth
    >
      {!isLoading && error && (
        <MenuItem value="" disabled>
          Erro ao carregar os dados
        </MenuItem>
      )}

      <MenuItem value="none" disabled={true}>
        Selecione...
      </MenuItem>

      {!isLoading &&
        !error &&
        data.sprints
          .filter((sprint) => sprint.id !== 0)
          .map((sprint) => (
            <MenuItem key={sprint.id} value={sprint.id}>
              {sprint.nome}
            </MenuItem>
          ))}
    </Select>
  );
};
