import { createContext, useContext, useEffect, useState } from "react";


const ColapsedMenuContext = createContext();

export const ColapsedMenuProvider = ({ children }) => {
    const [isMenuCollapsed, setIsMenuCollapsed] = useState("");


  const handleBool = () => {
    setIsMenuCollapsed(!isMenuCollapsed)
  }

    return (
    <ColapsedMenuContext.Provider value={{ isMenuCollapsed, setIsMenuCollapsed, handleBool }}>
        {children}
    </ColapsedMenuContext.Provider>
    );
};

export const useCollapse = () => {
    return useContext(ColapsedMenuContext);
  };

