import { Box, Divider, LinearProgress, Typography } from "@mui/material";

export const DetalheSprintStatistics = ({ statistics }) => {
  const progressBarValue = (100 * statistics.concluido) / statistics.total;

  return (
    <Box className="card w-100 p-2">
      <Typography variant="body1">{statistics.nome}</Typography>

      <Box my={2} mt={2}>
        <Typography>Estatística</Typography>
        <Divider sx={{ my: 1 }} />

        <Typography className="text-muted">
          Total: {statistics.total}{"hr"}
        </Typography>
        <Typography className="text-muted">
          Pendente: {statistics.pendente} {" | "}
          {statistics.horasEstimadasPendente}{"hr"}
        </Typography>
        <Typography className="text-muted">
          Andamento: {statistics.andamento} {" | "}
          {statistics.horasEstimadasAndamento}{"hr"}
        </Typography>
        <Typography className="text-muted">
          Testes: {statistics.teste} {" | "}
          {statistics.horasEstimadasTeste}{"hr"}
        </Typography>

        <Typography className="text-muted">
          Concluido: {statistics.concluido} {" | "}
          {statistics.horasEstimadasConcluido}{"hr"}
        </Typography>

        <Box my={2}>
          <Typography className="text-muted">
            {statistics.concluido} / {statistics.total}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progressBarValue}
            color="warning"
          />
        </Box>
      </Box>
    </Box>
  );
};
