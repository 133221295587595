import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { REACT_APP_TOTAL_GRID } from "../../../../utils/constants";
import { addZeroes } from "../../../../utils/utils";
import { Link } from "@mui/material";
import api from "../../../../services/api";

const CadastrosGrid = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  

  let limit = parseInt(REACT_APP_TOTAL_GRID);
  useEffect(() => {
    api.get(`controle-prioridade`).then((response) => {
      setData(response.data.records);
    });
  }, []);


  const handleClick = (event, id) => {
    history.push({
      pathname: "/cadastrosItem/" + id,
    });
  };

  return (
    <>
      <div>
        <div className="card-header border-0">
          <h4>
            <small>
            {data.length > 0 && <>Total: <b style={{ fontSize: 18 }}></b> {data.length}</>}
            </small>
          </h4>
          <div className="card-tools"></div>
        </div>
        <div className="card-body table-responsive-sm p-2">
          <table>
            <thead>
              <tr>
                <th style={{ width: "9%", textAlign: "center" }}>ID</th>
                <th style={{ width: "35%", textAlign: "center" }}>Descrição</th>
                <th style={{ width: "10%", textAlign: "center" }}>Cor</th>
                <th style={{ width: "25%", textAlign: "center" }}>Status</th>
                <th style={{ width: "10%", textAlign: "center" }}>Sla</th>
              </tr>
            </thead>
            <tbody>
              {data
              .sort((a, b) => a.id - b.id)
              .map((item) => {
                return (
                  <tr key={item.id}>
                    <td data-label="ID">
                      <Link
                        className="text-primary"
                        type="button"
                        id={item.id}
                        onClick={(event) => {
                          handleClick(event, item.id);
                        }}
                      >
                        {addZeroes(item.id, 4)}
                      </Link>
                    </td>
                    <td data-label="DESCRICAO">{item.descricao}</td>
                    <td data-label="COR">
                      <div
                        className="header float-center"
                        style={{
                          backgroundColor: `${item.cor}`,
                          borderRadius: 12,
                          width: "auto",
                          height: "20px",
                          display: "block",
                          
                        }}
                      > </div>
                    </td>
                    <td data-label="Status">{item.status != 0 ? " Ativo" : " Inativo "}</td>
                    <td data-label="SLA">{item.sla == null ? " - " : item.sla}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </>
  );
};

export default CadastrosGrid;
