import { Dialog, DialogContent } from "@mui/material";

export const TaskDialog = ({ children, isOpen, handleDialogState }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogState}
      maxWidth="lg"
      fullWidth
      scroll="body"
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
