import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { AlertSuccess, AlertDanger } from "./styles";

export const TipoChamadoEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [descricao, setDescricao] = useState([]);
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });


  /*Editar Registros*/
  const editCadastros = async (e) => {
    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          descricao: descricao,
        };

        //console.log("PUUUUUT ::::::", JSON.stringify(dadosJson));
        await api.put(`controle-tipo/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          descricao: descricao,
        };

        // console.log("Caiu no POST ::::::", JSON.stringify(dadosJson));
        await api.post(`controle-tipo/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/tipochamado",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getTipoChamado = async () => {
      await api.get(`/controle-tipo/${id}`).then((response) => {
        setDescricao(response.data.records[0].descricao);
      });
    };

    if (id !== "0") {
      getTipoChamado();
    } 
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editCadastros}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <label className="col-sm-6 col-form-label">
                        Descrição
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="descricao"
                        name="descricao"
                        placeholder="Descrição..."
                        onChange={(e) => setDescricao(e.target.value)}
                        defaultValue={descricao}
                        required="required"
                      />
                    </div>
                  </div>
                  
                  <div className="form-group row float-right mt-2">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        {" "}
                        Salvar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
