import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { addZeroes } from '../../../../utils/utils';
import api from "../../../../services/api";

export const MotoristaDataGrid = () => {
    let history = useHistory();

    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);

    const handleClick = (event, codigo) => {
        history.push({
            pathname: '/MotoristaItem/' + codigo
        });
    };

    const handleChange = value => {
        setSelectedRows(value);
    }

    const deleteRow = event => {
        console.log(event.target.id);
    };

    const viewRow = event => {
        console.log(event.target.id);
    };

    useEffect(() => {

        api.get("motorista")
            .then((response) => {
                setData(response.data.records);

            });

    }, []);

    if (!data) return null;

    return (
        < div >
            <div className="row">
                <div className="col-12">
                    <table>
                        <caption></caption>
                        <thead>
                            <tr>
                                <th style={{ width: "5%", textAlign: 'center'}} scope="col">Ações</th>
                                <th style={{ width: "7%", textAlign: 'center' }} scope="col">N.Registro</th>
                                <th style={{ width: "10%", textAlign: 'left' }} scope="col">Motorista </th>
                                <th style={{ width: "15%", textAlign: 'left' }} scope="col">Obs </th>
                                
                                <th style={{ width: "10%" }} scope="col">Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((item) => {
                                
                                return (

                                    <tr key={item.id} >

                                            <td>
                                            <button
                                                type="button"

                                                className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
                                                onClick={(event) => {
                                                        handleClick(event, item.id);
                                                }}
                                            >
                                                <i className="fas fa-pen"></i>
                                            </button>
                                        </td>

                                        <td style={{ textAlign: 'center' }} data-label="ID">
                                            <div>
                                                {addZeroes(item.id, 8)}
                                            </div>
                                        </td>

                                        <td style={{ textAlign: 'left' }} data-label="Motorista "> {item.usuario.descricao}</td>
                                        <td style={{ textAlign: 'left' }} data-label="Obs. "> {item.observacao}</td>
                                        

                                        <td data-label="Status">{item.status == 1 ? " Ativo" : " Inativo "} </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >


    );
}
