export const formatDateToMMddYYYY = (dateString) => {
  if (!dateString) return new Date();

  const dateOnly = dateString.split("T")[0];
  const [year, month, day] = dateOnly.split("-").map(Number);

  const formattedDate = `${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}-${year}`;

  return formattedDate;
};

export const formatSprintDateToMMddYYYY = (sprintDate) => {
  const parts = sprintDate.split("/");

  const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;

  return formattedDate;
};

export const formatDateToYYYYMMDD = (date) => {
  const [day, month, year] = date.split("/").map(Number);

  const formattedDate = `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;

  return formattedDate;
};

export const formatDateRemoveHoursToMMddYYYY = (date, increment) => {
  const datePart = date.split(" ")[0];
  const parts = datePart.split("/");

  const formattedDate = `${parts[1]}/${parts[0] - 2}/${parts[2]}`;

  return formattedDate;
};

export const checkEndDateIsSmallerThanCurrentDate = (endDate) => {
  const currentDate = new Date();

  const endDateParts = endDate.split(" ")[0].split("/");
  const formattedEndDate = `${endDateParts[2]}-${endDateParts[1].padStart(
    2,
    "0"
  )}-${endDateParts[0].padStart(2, "0")}`;
  const endDateObj = new Date(formattedEndDate);

  return currentDate > endDateObj;
};
