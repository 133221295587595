import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

const TechAssistant = ({ task }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: isMobile ? 2 : 0,
      }}
    >
      <Box sx={{ display: "grid", flexDirection: "column" }}>
        <Typography variant="body2" fontWeight={500}>
          Técnicos
        </Typography>
        <Typography variant="body1" fontWeight={500}>
          {task.qtdAuxiliares}
        </Typography>
      </Box>

      <Box sx={{ display: "grid", flexDirection: "column" }}>
        <Typography variant="body2" fontWeight={500}>
          Horas Extras
        </Typography>
        <Typography variant="body1" fontWeight={500}>
          {converterParaHorasMinutos(task.horasAuxiliares)}
        </Typography>
      </Box>
    </Box>
  );
};

export default TechAssistant;
