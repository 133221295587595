import { z } from "zod";

export const createTaskScheme = z.object({
  dataSolicitacao: z.date({
    required_error: "Data da solicitação é obrigatória",
  }),
  horaEstimada: z.string().optional(),
  responsavel: z.number().min(1, "Responsável é obrigatório"),
  gravidade: z.string().min(1, "Gravidade é obrigatória"),
  projeto: z.string().nullable(),
  sprint: z.string().min(1, "Sprint é obrigatório"),
  classificacao: z.string().min(1, "Classificação é obrigatória"),
  tipo: z.string().min(1, "Tipo é obrigatório"),
  titulo: z
    .string()
    .min(1, "Título é obrigatório")
    .max(100, "Título não pode ter mais de 100 caracteres"),
  descricao: z
    .string()
    .min(10, "Descrição é obrigatória"),
  // anexos: z.array(z.object({ name: z.string(), path: z.string() })).optional(),
});
