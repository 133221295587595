import React, { useState } from "react";
import "../../../components/controles/css/TreeView.css"; // Importe estilos CSS
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { useEventosLinksContext } from "../../../contexts/LinksEventosContext";
import { Tooltip } from "@mui/material";

const TreeNode = ({ node, onSaveEdit }) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const { updateCategoriaId, updateSubCategoriaId } = useEventosLinksContext();

  const handleEdit = () => {
    setEditedValue(node.descricao);
    setIsEditing(true);
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();
    onSaveEdit(node.id, editedValue);
    setIsEditing(false);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="tree-node">
        <span onClick={toggleExpand} className="expand mr-2 text-warning">
          {node.subCategorias && node.subCategorias.length > 0 && isExpanded ? (
            <i className="fa fa-chevron-down text-sm text-warning mr-2"></i>
          ) : node.subCategorias && node.subCategorias.length > 0 ? (
            <i className="fa fa-chevron-right text-sm text-warning mr-2"></i>
          ) : (
            <span className="ml-2"> </span>
          )}
        </span>

        {isEditing ? (
          <>
            <input
              type="text"
              value={editedValue}
              onChange={handleInputChange}
            />

            <Tooltip title="Salvar edição" arrow>
              <button
                type="button"
                onClick={(e) => handleSaveEdit(e)}
                className="edit-button"
              >
                <span>
                  <i className="fa fa-check"></i>
                </span>
              </button>
            </Tooltip>

            <Tooltip title="Sair da edição" arrow>
              <button
                type="button"
                onClick={(e) => handleClose(e)}
                className="edit-button"
                style={{ background: "#d9534f" }}
              >
                <span>
                  <i className="fa fa-close"></i>
                </span>{" "}
              </button>
            </Tooltip>
          </>
        ) : (
          <>
            <span className="node-description">
              {node.evento}
              {node.evento && "."}
              {node.categoria}
              {node.categoria && "."}
              {node.id} {node.descricao}{" "}
            </span>

            {(node.subCategorias?.length > 0 ||
              node.subCategorias?.length === 0) && (
              <>
                <Tooltip title="Editar Categoria" arrow>
                  <button
                    onClick={handleEdit}
                    className="edit-button"
                    style={{ background: "#449e48" }}
                  >
                    <span>
                      <i className="fa fa-pencil"></i>
                    </span>
                  </button>
                </Tooltip>

                <Link to={`/eventoSubcategorias/${node.id}`}>
                  <Tooltip title="Criar SubCategoria" arrow>
                    <button
                      className="edit-button"
                      onClick={() => updateCategoriaId(node.id)}
                      style={{ background: "#0275d8" }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </Tooltip>
                </Link>
              </>
            )}
          </>
        )}
      </div>
      {isExpanded && node.subCategorias && node.subCategorias.length > 0 && (
        <ul className="sub-tree">
          {node.subCategorias.map((subCategoria) => (
            <div key={subCategoria.id}>
              <Link
                to={`/eventoSubcategorias/${node.id}`}
                onClick={() => {
                  updateCategoriaId(node.id);
                  updateSubCategoriaId(subCategoria.id);
                }}
              >
                <TreeNode node={subCategoria} onSaveEdit={onSaveEdit} />
              </Link>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

const TreeView = ({ data, onSaveEdit }) => {
  return (
    <div className="tree-view">
      {data &&
        data
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <div key={item.id} className="tree-view-item">
              <TreeNode node={item} onSaveEdit={onSaveEdit} />
            </div>
          ))}
    </div>
  );
};

export default TreeView;
