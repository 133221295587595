import {
  Box,
  Button,
  Card,
  CardContent,
  createTheme,
  Divider,
  IconButton,
  InputBase,
  Paper,
  responsiveFontSizes,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect, useRef, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "react-query";
import api from "../services/api";
import { getControle } from "../services/auth";
import { FilterCategoria } from "./Search/FiltrosSearch/FilterCategoria";
import { FilterStatus } from "./Search/FiltrosSearch/FilterStatus";
import { AtendimentoTaskTab } from "../pages/AtendimentoOS/AtendimentoTaskTab";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { useUserFilters } from "../contexts/FiltersSearchContext";
import { useDebounce } from "../hooks/useDebounce";
import { SideFilter } from "./Search/SideFilter";

export const Search = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [comparVar, setComparVar] = useState([]);
  const [comparSprint, setComparSprint] = useState([]);
  const [combinationTecnicos, setCombinedTecnicos] = useState([]);
  const [combinationProjetos, setCombinedProjetos] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const {
    setSelectedTecnicos,
    setCheckedTecnicos,
    selectedStatus,
    setSelectedStatus,
    selectedClassIds,
    setSelectedClassIds,
    setCheckedSprints,
    setCheckedProjetos,
    setSelectedSprints,
    setSelectedProjetos,
    setCheckedSolicitantes,
    setSelectedSolicitantes,
  } = useUserFilters();

  const usuarioControle = getControle();
  const searchRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const selectElements = document.querySelectorAll(
        ".MuiSelect-root, .MuiPopover-root, .MuiPaper-root, .MuiBox-root, .MuiBackdrop-root"
      );

      let isClickInsideSelect = false;
      selectElements.forEach((select) => {
        if (select.contains(event.target)) {
          isClickInsideSelect = true;
        }
      });

      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        cardRef.current &&
        !cardRef.current.contains(event.target) &&
        !isClickInsideSelect
      ) {
        setOpen(false);
        setSearch("");
        setSelectedTecnicos([]);
        setCheckedTecnicos([]);
        setSelectedStatus([]);
        setSelectedClassIds([]);
        setSelectedSprints([]);
        setSelectedProjetos([]);
        setCheckedSprints([]);
        setCheckedProjetos([]);
        setSelectedSolicitantes([]);
        setCheckedSolicitantes([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
    setSearch("");
    setSelectedTecnicos([]);
    setSelectedProjetos([]);
    setCheckedTecnicos([]);
    setSelectedStatus([]);
    setSelectedClassIds([]);
    setSelectedSprints([]);
    setCheckedSprints([]);
    setCheckedProjetos([]);
    setSelectedSolicitantes([]);
    setCheckedSolicitantes([]);
  };

  const buildFilters = () => {
    const filters = {
      buscar: search,
    };

    if (selectedClassIds.length > 0) {
      filters.categorias = selectedClassIds;
    }
    if (selectedStatus.length > 0) {
      filters.status = selectedStatus;
    }
    if (comparVar.length > 0) {
      filters.solicitantes = comparVar;
    }
    if (comparSprint.length > 0) {
      filters.sprints = comparSprint;
    }
    if (combinationTecnicos.length > 0) {
      filters.tecnicos = combinationTecnicos;
    }
    if (combinationProjetos.length > 0) {
      filters.projetos = combinationProjetos;
    }

    return filters;
  };

  const filters = useMemo(buildFilters, [
    search,
    selectedClassIds,
    selectedStatus,
    comparVar,
    comparSprint,
    combinationTecnicos,
    combinationProjetos,
  ]);

  const verificFetch = useMemo(() => {
    return (
      search.trim() !== "" ||
      selectedClassIds.length > 0 ||
      selectedStatus.length > 0 ||
      comparVar.length > 0 ||
      comparSprint.length > 0 ||
      combinationTecnicos.length > 0 ||
      combinationProjetos.length > 0
    );
  }, [
    search,
    selectedClassIds,
    selectedStatus,
    comparVar,
    comparSprint,
    combinationTecnicos,
    combinationProjetos,
  ]);

  const debouncedFilters = useDebounce(filters);

  const { data: dataResponse, error: errorMessage } = useQuery(
    ["searchResults", debouncedFilters],
    async ({ signal }) => {
      if (!verificFetch) {
        return null;
      }
      const response = await api.post(
        `controle-servico/busca-generica?codUsuario=${usuarioControle}`,
        debouncedFilters,
        { signal }
      );
      return response.data;
    },
    {
      enabled: verificFetch,
      refetchOnWindowFocus: false,
      onError: () => {
        return [];
      },
    }
  );

  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    data: listClassificacoes,
    isLoading: isLoadingClass,
    isError: isErrorClass,
  } = useQuery(
    "lista-classificacao",
    async () => {
      const response = await api.get("controle-classificacao");
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: solicitante,
    isLoading: isLoadingSolic,
    isError: isErrorSolic,
  } = useQuery(
    "lista-solicitante",
    async () => {
      const response = await api.get(
        `/usuarioDetalhe?codUsuario=${usuarioControle}`
      );
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: listSprints,
    isLoading: isLoadingSprints,
    isError: isErrorSprint,
  } = useQuery(
    ["lista-sprint"],
    async () => {
      const params = {
        codUsuario: localStorage.getItem("@userControll"),
      };

      const response = await api.get("controle-sprint", {
        params,
      });
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const {
    data: listProjetos,
    isLoading: isLoadingProjetos,
    isError: isErrorProjetos,
  } = useQuery(
    "lista-projetos",
    async () => {
      const params = {
        codUsuario: localStorage.getItem("@userControll"),
      };

      const response = await api.get("cadastro-projetos", {
        params,
      });

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: listTecnicos, isLoading: isLoadingTecnicos } = useQuery(
    "lista-tecnicos",
    async () => {
      const params = {
        codUsuario: localStorage.getItem("@userControll"),
      };

      const response = await api.get("tecnico", {
        params,
      });

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (
    isErrorClass ||
    isErrorSolic ||
    isErrorSprint ||
    isErrorProjetos ||
    isLoadingTecnicos
  ) {
    return (
      <Box
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Stack spacing={3}>
          <Skeleton variant="rounded" width={300} height={40} />
        </Stack>
      </Box>
    );
  }

  if (
    isErrorClass ||
    isErrorSolic ||
    isErrorSprint ||
    isErrorProjetos ||
    isLoadingTecnicos
  ) {
    return "Erro ao buscar dados!";
  }

  const renderError = (error) => {
    if (!error) return null;
    if (typeof error === "object" && error.response.data) {
      return error.response.data;
    }
    return error.response.data;
  };

  const handleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const renderFilters = () => (
    <Stack spacing={2}>
      <FilterCategoria
        classificacoes={listClassificacoes}
        selectedClassIds={selectedClassIds}
        setSelectedClassIds={setSelectedClassIds}
      />
      <FilterStatus
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      <Card>
        <SideFilter
          filterName="Solicitante"
          data={solicitante}
          setFilter={setComparVar}
        />
      </Card>
      <Card>
        <SideFilter
          filterName="Sprint"
          data={listSprints}
          setFilter={setComparSprint}
          switchButton
        />
      </Card>
      <Card>
        <SideFilter
          filterName="Tecnico"
          data={listTecnicos}
          setFilter={setCombinedTecnicos}
        />
      </Card>
      <Card>
        <SideFilter
          filterName="Projeto"
          data={listProjetos}
          setFilter={setCombinedProjetos}
        />
      </Card>
    </Stack>
  )

  return (
    <Box display="flex" flexDirection="row" justifyContent="end" width="100%">
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: open ? (isMobile ? "100%" : "57%") : "30%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Pesquisa..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          ref={searchRef}
          inputProps={{ "aria-label": "search" }}
        />

        <Box sx={{ p: "10px" }} aria-label="search">
          <SearchIcon color="disabled" />
        </Box>
        {open && (
          <Box
            ref={cardRef}
            style={{
              position: "absolute",
              top: "100%",
              left: isMobile ? "0%" : "auto",
              right: isMobile ? "auto" : 0,
              paddingTop: "1px",
              paddingBottom: "10px",
              zIndex: 999,
              width: isMobile ? "100%" : "55%",
              height: 800,
              overflowY: "auto",
            }}
          >
            <Card sx={{ width: isMobile ? "100%" : "100%", margin: "0 auto" }}>
              <CardContent>
                <Box
                  display="grid"
                  gridTemplateColumns={isMobile ? "0.6fr 0.9fr" : "1fr 1fr"}
                  gap={isMobile ? "20px" : "0"}
                  margin="0 auto"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography variant="subtitle1" color="primary">
                      Atendimento Tasks
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={(e) => handleClose(e)}
                      variant="outlined"
                      size="small"
                    >
                      Fechar
                    </Button>
                  </Box>
                </Box>
                <Divider
                  sx={{ height: 2, my: 1, color: "white" }}
                  orientation="horizontal"
                />
                <Box textAlign="end">
                  <Tooltip title="Filtros">
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={handleFilters}
                    >
                      {isFilterOpen ? (
                        <FilterListOffIcon />
                      ) : (
                        <FilterListIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box sx={{ display: "flex", gap: "5px", padding: "5px" }}>
                  {/* Tabela */}
                  <Box
                    width={isFilterOpen ? (isMobile ? "100%" : "65%") : "100%"}
                  >
                    <AtendimentoTaskTab
                      response={dataResponse}
                      errorSearch={renderError(errorMessage)}
                    />
                  </Box>
                  {isFilterOpen && (
                    <>
                      {isMobile ? (
                        // Filtro quando esta em Mobile
                        <Card
                          elevation={4}
                          style={{
                            position: "absolute",
                            top: "15%",
                            left: "40%",
                            right: "auto",
                            padding: "10px",
                            zIndex: 999,
                            width: "55%",
                            height: 800,
                            overflowY: "auto",
                            background: "white",
                          }}
                        >
                          {renderFilters()}
                        </Card>
                      ) : (
                        // Filtro quando esta em WEB

                        <Box width={isMobile ? "100%" : "35%"} m={0}>
                          {isLoadingClass ||
                            isLoadingSolic ||
                            isLoadingSprints ||
                            isLoadingProjetos ||
                            isLoadingTecnicos ? (
                            <Stack spacing={2}>
                              <Skeleton
                                variant="rounded"
                                width={250}
                                height={30}
                              />
                              <Skeleton
                                variant="rounded"
                                width={250}
                                height={30}
                              />
                              <Skeleton
                                variant="rounded"
                                width={250}
                                height={100}
                              />
                              <Skeleton
                                variant="rounded"
                                width={250}
                                height={100}
                              />
                              <Skeleton
                                variant="rounded"
                                width={250}
                                height={100}
                              />
                            </Stack>
                          ) : 
                            renderFilters()
                          }
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        )}
      </Paper>
    </Box>
  );
};
