import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const InsertText = ({ handleSalvar, MensagemCont, loading }) => {
  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleSalvar}
        disabled={MensagemCont.length < 10}
        sx={{ background: "#primary", maxHeight: "40px", marginBottom: "10px" }}
      >
        {loading ? <CircularProgress size={20} /> : "Inserir"}
      </Button>
    </Box>
  );
};

export default InsertText;