import { Avatar, Box, Typography } from "@mui/material";
import { PDFHeaderCard } from "./PDFHeaderCard";

export const PDFHeader = ({ dates, filters, task }) => {
  const headerListItems = [
    { label: "Chamados", value: task?.total },
    { label: "Estimativa", value: task?.estimadas },
    { label: "Trabalhadas", value: task?.trabalhadas },
    { label: "Diferenças", value: task?.diferenca },
    { label: "Auxiliares", value: task?.auxiliares },
    { label: "h Fora Período", value: task?.foraPeriodo },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr) 0.5fr 1fr",
        gap: 1,
        mt: 4,
      }}
      style={{ fontFamily: "DejaVu Sans", fontSize: "12px", fontWeight: "700" }}
    >
      <PDFHeaderCard title="Unidade" content={filters?.unidade} />

      <Box sx={{ display: "grid", gap: 1 }}>
        <PDFHeaderCard title="Data Inicial" content={dates?.start} />
        <PDFHeaderCard title="Data Final" content={dates?.end} />
      </Box>

      <PDFHeaderCard
        title="Técnico"
        content={
          <>
            {filters?.tecnico?.codigo && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}
              >
                <Avatar
                  src={`${filters?.tecnico?.imagem}?t=${new Date().getTime()}`}
                  sx={{ backgroundColor: "#fff" }}
                  imgProps={{
                    crossOrigin: "anonymous",
                  }}
                />
                <Typography variant="body2" fontWeight={600}>
                  {filters?.tecnico?.usuario || filters?.tecnico?.nome}
                </Typography>
              </Box>
            )}

            {!filters?.tecnico?.codigo && (
              <Typography fontWeight={600} variant="body2">
                Todos
              </Typography>
            )}
          </>
        }
      />

      <Box sx={{ display: "grid", gap: 1 }}>
        <PDFHeaderCard title="Projeto" content={filters?.projeto || "Todos"} />
        <PDFHeaderCard title="Sprint" content={filters?.sprint || "Todos"} />
      </Box>

      <PDFHeaderCard
        title="Unidade"
        content="Matriz Franca / SP"
        background={{
          backgroundColor: "#f5f5f5",
        }}
        isListCard
        items={headerListItems}
      />

      <PDFHeaderCard
        title="Status"
        content={filters?.status || "Todos"}
        contentProps={{
          sx: {
            fontSize: 30,
            lineHeight: 1,
          },
        }}
      />
    </Box>
  );
};
