import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  DialogContentText,
  TextField,
  FormControl,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";

function ErrorBox({ open, onClose, responsavel, horas }) {
  let Horas = "";
  let Responsavel = "";

  if (responsavel == 9) {
    Responsavel = "Nenhum Responsável foi Atribuido!";
  }

  if (horas <= 0) {
    Horas += "\nHoras Estimadas não definidas!";
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: 10,
        },
      }}
    >
      <DialogTitle
        className="text-danger text-center text-white"
        style={{ backgroundColor: "#ef5350" }}
      >
        ATENÇÃO!
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text-center p-2">
          {Responsavel}
          <br />
          {Horas}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center">
        <Button
          onClick={onClose}
          fullWidth
          variant="contained"
          style={{ border: 0, fontSize: 18, background: "#ef5350" }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorBox;
