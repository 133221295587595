const ganttBarColor = {
  PENDENTE: "#0d47a1",
  ANDAMENTO: "#ffa000",
  CONCLUIDO: "#33691e",
  TESTE: "#424242",
};

export const queryTaskbarInfo = (args) => {
  const color = ganttBarColor[args.data.taskData.status];

  if (color) {
    args.progressBarBgColor = color;
    args.milestoneColor = color;
  }
};
