import React, { useEffect } from 'react';
import LogoMin from "../../src/assets/LogoDemocratavaMin.svg";
import { loadTree } from '../utils/loadtree';
import { MenuItemOS } from './controles/MenuItemOS';
import { UserIdentOs } from './controles/UserIdentOs';
import { useCollapse } from '../contexts/ColapsedMenuContext';

const MenuOS = () => {
  const { isMenuCollapsed } = useCollapse();

  useEffect(() => {
    loadTree();
  }, []);

  return (
    <div>
      <aside className={`main-sidebar sidebar-dark-primary elevation-4 ${isMenuCollapsed ? 'hidden' : ''}`}>
        <a href="atendimento" className="brand-link">
          <img
            src={LogoMin}
            alt="Logo Democrata"
            className="brand-image img-circle elevation-3"
            style={{ opacity: '.8' }} 
          />
          <span className="brand-text font-weight-light">Atendimento</span>
        </a>
        <div className="sidebar" style={{ backgroundColor: "#304056" }}>
          <UserIdentOs />
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <MenuItemOS />
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default MenuOS;