import { useState, useEffect } from "react";
import { getControle } from "../../../services/auth";
import api from "../../../services/api";


const TotaisSolicitacoesRelacionadas = ({setValor}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    api.get("/demanda_processos/usuario/" + getControle()).then((response) => {
      setData(response.data.records.filter((demm => demm.demanda.status === 0 && demm.tipoStatus != 'INATIVO' )));
    });
  }, [getControle]);


  var somatorioSolicitadasRelacionadas = data
  .filter((event) => event.demanda.solicitante.codUsuario != getControle()).length
  setValor(somatorioSolicitadasRelacionadas);

  return (
    <>
      <b className="mr-1" style={{ color: 'black' }}> {somatorioSolicitadasRelacionadas}</b><h7 style={{ color: 'black' }}></h7>
    </>
  );
};

export default TotaisSolicitacoesRelacionadas;
