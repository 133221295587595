import React, { useEffect, useState } from "react";
import {
  Typography,
  Chip,
  useTheme,
  useMediaQuery,
  Box,
  Link,
  TableRow as TableRowCore,
  TableCell,
  Collapse,
  TableHead,
  Table,
  TableBody,
  Tooltip,
  TableFooter,
  TablePagination,
  Pagination,
} from "@mui/material";
import SprintModal from "../../Sprint/components/SprintModal";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";
import { MobileCard } from "./MobileCard";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";


const statusMapping = {
  PENDENTE: { label: "Pendente", background: "warning", color: "warning" },
  ANDAMENTO: { label: "Andamento", background: "primary", color: "#fff" },
  TESTE: { label: "Teste", background: "default", color: "#fff" },
  CONCLUIDO: { label: "Concluído", background: "success", color: "success" },
};

const TableRow = ({ children, ...props }) => (
  <TableRowCore
    sx={{
      minHeight: "52px",
      maxHeight: "52px",
      width: "fit-content",
      breakInside: "avoid",
      padding: "0 10px 0 10px",
    }}
    {...props}
  >
    {children}
  </TableRowCore>
);

const TableCellHeader = ({ children, ...props }) => (
  <TableCell
    align="center"
    sx={{
      padding: 0,
      margin: "8px 0",
      fontSize: "12px",
      textTransform: "unset",
      fontWeight: "initial",
      position: "relative",
    }}
    {...props}
  >
    {children}
  </TableCell>
);

const TextSmallHeader = ({ children, ...props }) => (
  <Typography
    style={{
      fontSize: "12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    {...props}
  >
    {children}
  </Typography>
);

const Separator = () => (
  <div
    style={{
      height: "14px",
      width: "1px",
      backgroundColor: "#E0E0E0",
      position: "absolute",
      float: "right",
      right: 0,
      marginTop: "1px",
    }}
  ></div>
);

const useScrollToTop = (page) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [page]);
};

const DataGridTable = ({ data, manipulatedData, handleManipulatedData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [ordering, setOrdering] = useState({
    column: "Chamado",
    order: "DESC",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    setModalIsOpen(!modalIsOpen);
  };

  useScrollToTop(page);

  useEffect(() => {
    if (data && data.length > 0) {
      const columns = {
        Chamado: "codigo",
        Título: "titulo",
        Solicitante: "userName",
        "Data Abertura": "datcad",
        Setor: "tipo.descricao",
        Técnico: "codigoControleServicoTecnico.usuario",
        Estimadas: "horasEstimadas",
        Trabalhadas: "totalHorasTrabalhadas",
        Auxiliares: "horasAuxiliares",
        Restantes: data.totalHorasRestantes
          ? "totalHorasRestantes"
          : "totalHorasExcedentes",
        Status: "status",
      };

      //sorting
      let sortedData = [];
      if (ordering.order === "DESC") {
        if (columns[ordering.column].includes(".")) {
          const strings = columns[ordering.column].split(".");
          sortedData = data.sort((a, b) =>
            a[strings[0]][strings[1]].localeCompare(b[strings[0]][strings[1]])
          );
        } else if (typeof data[0][columns[ordering.column]] == "string") {
          sortedData = data.sort((a, b) =>
            a[columns[ordering.column]].localeCompare(
              b[columns[ordering.column]]
            )
          );
        } else
          sortedData = data.sort(
            (a, b) => a[columns[ordering.column]] - b[columns[ordering.column]]
          );
      } else {
        if (columns[ordering.column].includes(".")) {
          const strings = columns[ordering.column].split(".");
          sortedData = data.sort((a, b) =>
            b[strings[0]][strings[1]].localeCompare(a[strings[0]][strings[1]])
          );
        } else if (typeof data[0][columns[ordering.column]] == "string") {
          sortedData = data.sort((a, b) =>
            b[columns[ordering.column]].localeCompare(
              a[columns[ordering.column]]
            )
          );
        } else
          sortedData = data.sort(
            (a, b) => b[columns[ordering.column]] - a[columns[ordering.column]]
          );
      }

      //pagination
      const paginatedData = sortedData.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
      );

      handleManipulatedData(paginatedData);
    }
  }, [data, ordering, page, rowsPerPage]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1350"));

  const transformDate = (date) =>
    new Date(date).toLocaleDateString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const renderHeaderCells = (columnName, width) => {
    const handleClickOrder = () => {
      setOrdering({
        order:
          ordering.column === columnName && ordering.order === "DESC"
            ? "ASC"
            : "DESC",
        column: columnName,
      });
      setPage(0);
    };

    return (
      <TableCellHeader
        width={width || "auto"}
        onClick={handleClickOrder}
        sx={{
          cursor: "pointer",
          "& :hover": {
            "#icon": {
              display: "flex !important",
            },
          },
        }}
      >
        <Separator />
        <div
          style={{
            display: "flex",
            textTransform: "initial",
            justifyContent: "center",
          }}
        >
          <TextSmallHeader>{columnName}</TextSmallHeader>
          <div
            id="icon"
            style={{
              height: "16px",
              width: "16px",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "2px",
              display: ordering.column === columnName ? "flex" : "none",
            }}
          >
            {ordering.order === "DESC" && ordering.column === columnName ? (
              <ArrowDownward fontSize="8px" />
            ) : ordering.order === "ASC" && ordering.column === columnName ? (
              <ArrowUpward fontSize="8px" />
            ) : (
              <ArrowDownward color="disabled" fontSize="8px" />
            )}
          </div>
        </div>
      </TableCellHeader>
    );
  };

  if (!data || data.length === 0) {
    return (
      <Typography
        sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
      >
        Não há dados a serem exibidos.
      </Typography>
    );
  }

  return (
    <Box sx={{ display: !isMobile && "flex", p: 1, mb: 1, width: "100%" }}>
      {isMobile && (
        <>
          {manipulatedData.map((task) => (
            <MobileCard
              key={task.codigo}
              handleTaskSelect={handleTaskSelect}
              task={task}
              page={page}
            />
          ))}
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Pagination
              count={Math.ceil(data.length / rowsPerPage)}
              page={page + 1}
              onChange={(e, value) => setPage(value - 1)}
            />
          </Box>
        </>
      )}

      {!isMobile && (
        <React.Fragment>
          <Table
            padding="none"
            stickyHeader
            sx={{
              "td ": {
                padding: "5px",
              },
              th: {
                padding: "5px 0",
              },
            }}
          >
            <TableHead>
              <TableRow sx={{ gap: 0.2 }}>
                {renderHeaderCells("Chamado", "6%")}
                {renderHeaderCells("Título")}
                {renderHeaderCells("Solicitante", "12%")}
                {renderHeaderCells("Data Abertura", "10%")}
                {renderHeaderCells("Setor", "9%")}
                {renderHeaderCells("Técnico", "13%")}
                {renderHeaderCells("Estimadas", "7%")}
                {renderHeaderCells("Trabalhadas", "7%")}
                {renderHeaderCells("Auxiliares", "7%")}
                {renderHeaderCells("Restantes", "7%")}
                {renderHeaderCells("Status", "8%")}
              </TableRow>
            </TableHead>
            <TableBody>
              {manipulatedData.map((demanda, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell sx={{ fontSize: "12px" }} align="center">
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleTaskSelect(demanda.codigo)}
                      >
                        {demanda.codigo}
                      </Link>
                    </TableCell>
                    <Tooltip title={demanda.titulo}>
                      <TableCell sx={{ fontSize: "12px" }} align="center">
                        <Typography
                          variant="inherit"
                          sx={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical",
                          }}
                        >
                          {demanda.titulo}
                        </Typography>
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={demanda.userName}>
                      <TableCell sx={{ fontSize: "12px" }} align="center">
                        <Typography
                          variant="inherit"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {demanda.userName}
                        </Typography>
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={transformDate(demanda.datcad)}>
                      <TableCell sx={{ fontSize: "12px" }} align="center">
                        {transformDate(demanda.datcad)}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title={demanda.tipo.descricao}>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "12px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {demanda.tipo.descricao}
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: "#E8EAF6",
                          borderRadius: 1,
                          boxShadow: 1,
                          color: "#283593",
                          width: "100%",
                          padding: "10px 2px",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {demanda.codigoControleServicoTecnico.usuario ||
                          "Não Definido"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: "#C8E6C9",
                          borderRadius: 1,
                          boxShadow: 1,
                          color: "#1B5E20",
                          width: "100%",
                          padding: "10px 2px",
                          textAlign: "center",
                        }}
                      >
                        {converterParaHorasMinutos(
                          demanda.horasEstimadas || "00"
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: "#FFE082",
                          borderRadius: 1,
                          boxShadow: 1,
                          color: "#FF6F00",
                          width: "100%",
                          padding: "10px 2px",
                          textAlign: "center",
                        }}
                      >
                        {converterParaHorasMinutos(
                          demanda.totalHorasTrabalhadas || "00"
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: "#D3D3D3",
                          borderRadius: 1,
                          boxShadow: 1,
                          color: "#878787",
                          width: "100%",
                          padding: "10px 2px",
                          textAlign: "center",
                        }}
                      >
                        {converterParaHorasMinutos(
                          demanda.horasAuxiliares || "00"
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          backgroundColor: "#83e9cd",
                          borderRadius: 1,
                          boxShadow: 1,
                          width: "100%",
                          padding: "10px 2px",
                          textAlign: "center",
                          color: demanda.totalHorasExcedentes
                            ? "red"
                            : demanda.totalHorasRestantes
                            ? "blue"
                            : "#00695C",
                        }}
                      >
                        {converterParaHorasMinutos(
                          demanda.totalHorasRestantes ||
                            demanda.totalHorasExcedentes ||
                            "00"
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        sx={{ fontSize: "12px" }}
                        label={statusMapping[demanda.status].label}
                        color={statusMapping[demanda.status].background}
                      />
                    </TableCell>
                  </TableRow>

                  {demanda && demanda.controleTempoResponse.length > 0 && (
                    <TableRowCore
                      sx={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <TableCell colSpan={"0.1"} sx={{ border: "none" }} />
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                        colSpan={6}
                      >
                        <Collapse
                          in={demanda.controleTempoResponse?.length}
                          timeout="auto"
                          unmountOnExit
                          // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
                          sx={{
                            marginTop: "0 auto",
                            marginBottom:
                              demanda.controleTempoForaPeriodo.length > 0
                                ? ""
                                : "20px",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                              component="div"
                            >
                              Registros Auxiliares
                            </Typography>
                            <Table
                              sx={{
                                "td ": {
                                  padding: "6px 16px",
                                },
                                th: {
                                  padding: "6px 16px",
                                },
                              }}
                              size="small"
                            >
                              <TableHead>
                                <TableRowCore>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Data do Auxilio
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Técnico
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Horas Gastas
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Tipo Serviço
                                  </TableCell>
                                </TableRowCore>
                              </TableHead>
                              <TableBody>
                                {/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
                                {demanda.controleTempoResponse &&
                                  demanda.controleTempoResponse.map(
                                    (aux, index) => (
                                      <TableRowCore
                                        style={{
                                          marginBottom: "40px",
                                          padding: 0,
                                          borderBottomColor: "black",
                                          minHeight: "20px",
                                          maxHeight: "20px",
                                        }}
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        key={index}
                                      >
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          {aux.data}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          <Typography
                                            variant="inherit"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {aux.tecnicoClass.usuario}
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          <Box
                                            sx={{
                                              backgroundColor: "#83e9cd",
                                              borderRadius: 1,
                                              boxShadow: 1,
                                              color: "#00695C",
                                              width: "50%",
                                              textAlign: "center",
                                              padding: "2px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {converterParaHorasMinutos(
                                              aux.tempo
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          {aux.tipoServicoClass.descricao}
                                        </TableCell>
                                      </TableRowCore>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRowCore>
                  )}

                  {demanda && demanda.controleTempoForaPeriodo.length > 0 && (
                    <TableRowCore
                      sx={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <TableCell colSpan={"0.1"} />
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                        colSpan={6}
                      >
                        <Collapse
                          in={demanda.controleTempoForaPeriodo?.length}
                          timeout="auto"
                          unmountOnExit
                          sx={{ marginTop: "0 auto", marginBottom: "20px" }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                              component="div"
                            >
                              Registros Fora Periodo
                            </Typography>
                            <Table
                              sx={{
                                "td ": {
                                  padding: "6px 16px",
                                },
                                th: {
                                  padding: "6px 16px",
                                },
                              }}
                              size="small"
                            >
                              <TableHead>
                                <TableRowCore>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Data do Auxilio
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Técnico
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Horas Gastas
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "10px" }}>
                                    Tipo Serviço
                                  </TableCell>
                                </TableRowCore>
                              </TableHead>
                              <TableBody>
                                {/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
                                {demanda.controleTempoForaPeriodo &&
                                  demanda.controleTempoForaPeriodo.map(
                                    (aux, index) => (
                                      <TableRowCore
                                        style={{
                                          marginBottom: "40px",
                                          padding: 0,
                                          borderBottomColor: "black",
                                          minHeight: "20px",
                                          maxHeight: "20px",
                                        }}
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        key={index}
                                      >
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          {aux.data}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          <Typography
                                            variant="inherit"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {aux.tecnicoClass.usuario}
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          <Box
                                            sx={{
                                              backgroundColor: "#83e9cd",
                                              borderRadius: 1,
                                              boxShadow: 1,
                                              color: "#00695C",
                                              width: "50%",
                                              textAlign: "center",
                                              padding: "2px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {converterParaHorasMinutos(
                                              aux.tempo
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px" }}>
                                          {aux.tipoServicoClass.descricao}
                                        </TableCell>
                                      </TableRowCore>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRowCore>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center" colSpan={11}>
                  <TablePagination
                    sx={{ "& p": { margin: 0 } }}
                    component="div"
                    count={data.length}
                    page={page}
                    setPage={setPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página:"
                    labelDisplayedRows={(pagData) =>
                      `${pagData.from} - ${pagData.to} de ${pagData.count}`
                    }
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </React.Fragment>
      )}
      {modalIsOpen && (
        <SprintModal
          showModal={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          data={selectedTask}
        />
      )}
    </Box>
  );
};

export default DataGridTable;
