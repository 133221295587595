import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

export const HoursMetrics = ({
  total,
  estimativa,
  trabalhadas,
  auxiliares,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
      fontWeight={500}
      mt={1}
      color="text.secondary"
    >
      <Typography className="mr-2" variant="body2">
        Total: <strong>{total || 0}</strong> 
      </Typography>
      <Typography className="mr-2" variant="body2">
        Estimativa: <strong>{converterParaHorasMinutos(estimativa || "00")}</strong>
      </Typography>
      <Typography className="mr-2" variant="body2">
        Trabalhadas: <strong>{converterParaHorasMinutos(trabalhadas || "00")}</strong> 
      </Typography>
      <Typography variant="body2">
        Auxiliares: <strong>{converterParaHorasMinutos(auxiliares || "00")}</strong>
      </Typography>
    </Box>
  );
};
