import { useState } from "react";
import TotaisSolicitacoes from "./TotaisSolicitacoes";
import GridSolicitacaiAndamento from "./GridSolicitacaoAndamento";
import GridProcessoVinculado from "./GridProcessoVinculado";
import TotaisSolicitacoesAndamento from "./TotaisSolicitacoesAndamento";
import TotaisSolicitacoesConcluidas from "./TotaisSolicitacoesConcluidas";
import TotaisSolicitacoesRelacionadas from "./TotaisSolicitacoesRelacionadas";

const Solicitacoes = () => {
  const [totaisSolic, setTotaisSolic] = useState();
  const [totaisConcluidas, setTotaisConcluidas] = useState();
  const [totaisAndamento, setTotaisAndamento] = useState();
  const [totaisRelacionadas, setTotaisRelacionadas] = useState();

  return (
    <div className="content">
      <div className="row">
        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <span className="description-percentage">
              <i className="fas fa-pen text-info" />
            </span>

            {/* <h5 className="description-header">
            {SolicTotais}
            </h5> */}

            <h5 className="description-header">
              <TotaisSolicitacoes setValor={setTotaisSolic} />
            </h5>

            <span className="description-text">Solicitadas</span>
          </div>
        </div>

        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <span className="description-percentage text-warning">
              {(() => {
                <i />
                if (totaisSolic == 0) {
                  return (
                    <span>
                      {"0%"}
                    </span>
                  );
                } else {
                  return (
                    <span>
                      {(totaisAndamento / totaisSolic).toFixed(2) * 100}%
                    </span>
                  );
                }
              })()}

              {/* <i /> {(totaisAndamento / totaisSolic).toFixed(2) * 100}% */}
            </span>
            <h5 className="description-header">
              <TotaisSolicitacoesAndamento setValor={setTotaisAndamento} />
            </h5>
            <span className="description-text">Andamento</span>
          </div>
        </div>

        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <span className="description-percentage text-success">

              {(() => {
                <i />
                if (totaisSolic == 0) {
                  return (
                    <span>
                      {"0%"}
                    </span>
                  );
                } else {
                  return (
                    <span>
                      {(totaisConcluidas / totaisSolic).toFixed(2) * 100}%
                    </span>
                  )
                }
              })()}
            </span>
            <h5 className="description-header">
              <TotaisSolicitacoesConcluidas setValor={setTotaisConcluidas} />
            </h5>
            <span className="description-text">Concluidas</span>
          </div>
        </div>

        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <span className="description-percentage text-info">
              <i className="fas fa-solid fa-users" />
            </span>
            <h5 className="description-header">
              <TotaisSolicitacoesRelacionadas setValor={setTotaisRelacionadas} />
            </h5>
            <span className="description-text">Relacionadas</span>
          </div>
        </div>
      </div>

      <GridSolicitacaiAndamento />
      <GridProcessoVinculado />


      {/* <div className="card-footer"></div> */}
    </div>
  );
};
export default Solicitacoes;
