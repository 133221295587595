import React from "react";
import { Link } from "react-router-dom";
import { useEventosLinksContext } from "../../contexts/LinksEventosContext";

const DirecionamentoPaginas = () => {
  const { categoriaId, eventoId } = useEventosLinksContext();

  return (
    <div>
      <ul>
        <p>
          <Link to={`/eventos/`}>Tela Eventos</Link> {"-"}{" "}
          {eventoId && (
            <>
              <Link to={`/eventoItem/${eventoId}`}>Tela Categorias </Link>{" "}
            </>
          )}
        </p>
      </ul>
    </div>
  );
};

export default DirecionamentoPaginas;
