import {
  Select,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";

import { CustomDatePicker } from "../Projeto/Component/CustomDatePicker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { converterParaHorasMinutos } from "../../../utils/convertToHoursMinutes";
import { AvatarSprint } from "../../../components/AvatarSprint";

export const ProjectKanbanFilters = ({
  tabs,
  projectTotals,
  filters,
  setFilters,
}) => {
  const { id } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: selectedStatus,
    }));
  };

  const handleInitialDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      initialDate: date,
    }));
  };

  const handleEndDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate: date,
    }));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "0.4fr 0.8fr 1fr",
          alignItems: "start",
          gap: 2,
          mb: 2,
        }}
      >
        <Select
          value={filters.status || 0}
          onChange={handleStatusChange}
          name="status"
        >
          <MenuItem value={0}>Todos</MenuItem>
          <MenuItem value="PENDENTE">Pendente</MenuItem>
          <MenuItem value="ANDAMENTO">Andamento</MenuItem>
          <MenuItem value="TESTE">Teste</MenuItem>
          <MenuItem value="CONCLUIDO">Concluido</MenuItem>
        </Select>

        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 1 }}>
          <CustomDatePicker
            value={filters.initialDate}
            onChange={handleInitialDate}
            label="Data Início"
            style={{ width: "10px", height: "32px" }}
          />

          <CustomDatePicker
            value={filters.endDate}
            onChange={handleEndDate}
            label="Data Conclusão"
          />
        </Box>
        {tabs}
      </Box>
    </Box>
  );
};
