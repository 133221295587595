import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export const ganttSubtitles = [
  { label: "PENDENTE", color: "#0d47a1" },
  { label: "ANDAMENTO", color: "#ffa000" },
  { label: "CONCLUIDO", color: "#33691e" },
  { label: "TESTE", color: "#424242" },
];

export const GanttSubtitles = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 0.6fr",
          alignItems: "center",
          my: 1,
          gap: 1,
        }}
      >
        {ganttSubtitles.map((subtitle) => (
          <Box
            key={subtitle.title}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 14,
                height: 14,
                backgroundColor: subtitle.color,
                borderRadius: "10px",
              }}
            ></Box>
            <Typography variant="overline">{subtitle.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
