import React from 'react';
import Dropzone from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';

const FileDropzone = ({ handleDrop, loading, files }) => (
  <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mt-2">
    <Dropzone onDrop={handleDrop} multiple={true} maxFiles={0}>
      {({ getRootProps, getInputProps }) => (
        <div className="dz-message dropzone" {...getRootProps()}
        >
          {loading ? (
            <CircularProgress size={40} />
          ) : (
            <>
              <input {...getInputProps()} />
              <i className="material-icons text-muted"></i>
              Arraste &amp; solte o arquivo nesta área, ou clique para selecionar.
              {files.length > 0 && (
                <div>
                  <h5>Arquivo(s)</h5>
                  <ul>
                    {files.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Dropzone>
  </div>
);

export default FileDropzone;