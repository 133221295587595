import React from "react";
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export const KanbanMetricsSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: !isMobile && "center",
        mt: 2,
        mb: !isMobile && 2,
      }}
    >
      <Box>
        <KanbanFiltersSkeleton />

        <HoursMetricsSkeleton />
      </Box>

      <Box sx={{ mt: isMobile && 4 }}>
        <AvatarSprintSkeleton />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: 1,
            justifyContent: isMobile ? "center" : "flex-end",
            alignItems: "end",
          }}
        >
          <Skeleton variant="text" width={100} />

          <Skeleton variant="text" width={60} />
        </Box>
      </Box>
    </Box>
  );
};

const KanbanFiltersSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={200}
      height={40}
      sx={{ borderRadius: 1, mb: 2 }}
    />
  );
};

const HoursMetricsSkeleton = () => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row" }}
      fontWeight={500}
      justifyContent="flex-start"
      mt={1}
      color="text.secondary"
    >
      <Skeleton variant="text" width={60} />
      <Skeleton variant="text" width={100} sx={{ ml: 2 }} />
      <Skeleton variant="text" width={100} sx={{ ml: 2 }} />
    </Box>
  );
};

const AvatarSprintSkeleton = () => {
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="circular" width={40} height={40} />
    </Box>
  );
};
