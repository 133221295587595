import React from "react";
import { FormControl, TextField, Typography } from "@mui/material";

const EstimHours = ({
  handleSubmit,
  validateHoraEstimada,
  responsavel,
  isLoadingRequest,
  horaEstimada,
  handleChange,
}) => {
  return (
    <FormControl sx={{marginTop: 1}}>
      <Typography variant="body2" fontWeight={500}>Horas Estimadas</Typography>
      <TextField
        id="outlined-number"
        onBlur={handleSubmit}
        disabled={
          validateHoraEstimada() ||
          responsavel?.codigo == 9 ||
          responsavel == null ||
          isLoadingRequest
        }
        value={horaEstimada}
        type="text"
        size="small"
        onChange={handleChange}
        onKeyDown={(e) => {
          if (
            !/^[0-9:]$/i.test(e.key) &&
            e.key !== "Delete" &&
            e.key !== "Backspace"
          ) {
            e.preventDefault();
          }
        }}
        placeholder="00:00"
        sx={{
          m: 1,
          width: 115,
          "& .MuiInputBase-input": {
            textAlign: "right",
          },
          ".MuiOutlinedInput-notchedOutline": {
            border:
              horaEstimada === "00" ||
              horaEstimada === "00:00" ||
              horaEstimada === ""
                ? "2px solid red"
                : "1px solid rgba(0, 0, 0, 0.23)",
          },
        }}
      />
    </FormControl>
  );
};

export default EstimHours;
