import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import api from "../../services/api";
import { addZeroes, formatarDataBr } from "../../utils/utils";
import { FormLabel } from "react-bootstrap";
import { getControle } from "../../services/auth";
import TotaisComentarios from "../../pages/Processos/Component/TotaisComentarios";
import { MsgSemSolic } from "../../pages/Processos/Component/MsgSemSolic";
import TotaisComentariosNaoLidas from "../../pages/Processos/Component/TotaisComentariosNaoLidas";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";

const ListaVinculado = ({ calls, blobImage }) => {
  let history = useHistory();

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [nome, setNome] = useState(calls);
  const [dataHora, setDataHora] = useState([]);
  const [dataHoraLimite, setDataHoraLimite] = useState([]);

  const [status, setStatus] = useState([]);

  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);

  const [value, setValue] = useState(dayjs(new Date()).subtract(30, "day").format("YYYY-MM-DD"));
  const [valueFim, setValueFim] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

  const [tipoRel, setTipoRel] = useState("AGUARDANDO");

  const tipoListaStatus = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  const dataFiltroInicial = async (dtInicial) => {
    if (moment.isMoment(dtInicial) && dtInicial.isValid()) {
      let dataInicial = dtInicial.format("YYYY-MM-DD");
      console.log("dtInicial no dataFiltroInicial ============ ", dataInicial);
      let dataFinal = valueFim;
      console.log("dataFinal no dataFiltroInicial ============ ", dataFinal);
      setValue(dtInicial);
      setDateValue1(dataInicial);
      setDateValue2(dataFinal);
  } else {
      console.error("dtInicial não é um objeto Moment válido:", dtInicial);
  }
};


  const dataFiltroFinal = async (dtFinal) => {
    let dataFinal = JSON.stringify(dtFinal);
    setValueFim(dtFinal);
    setDateValue2(dataFinal.slice(1, 11));
  };

  useEffect(() => {
    api.get("/demanda_processos/usuario/" + getControle()).then((response) => {
      setData(
        response.data.records.filter(
          (dema) => dema.demanda.solicitante.codUsuario != getControle() 
          && dema.dataHora >= `${dateValue1.length == 0 ? value : dateValue1}` &&
          dema.dataHora.substring(0, 10) <=`${dateValue2.length == 0 ? valueFim : dateValue2}`
        )
      );

      setDataHora(response.data.records);
      setDataHoraLimite(response.data.records);
    });
    /* listaDados() */;
    tipoListaStatus();
  }, [value, valueFim, dateValue1, dateValue2]);



  const handleClick = (event, id) => {
    history.push({
      pathname: "/processoItem/" + id,
    });
  };

  console.log("data ========== ", data);
  console.log("value ========= ", value);
  console.log("valueFim ====== ", valueFim);
  console.log("dateValue1 === ", dateValue1);
  console.log("dateValue2 === ", dateValue2);
  console.log("tipoRel ====== ", tipoRel);



  return (
    <>
      {(() => {
        {
          return (
            <>
              <div className="card-header ">
                <div className="card-row">
                  <form className="form-horizontal">
                    <div className="row">
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group ">
                          <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale="pt-br"
                          >
                            <Stack spacing={3}>
                              <DatePicker
                                label="Data Inicial"
                                value={dateValue1.length == 0 ? value : dateValue1}
                                onChange={(newValue) => {
                                  dataFiltroInicial(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                dayOfWeekFormatter={(day, date) => {
                                  const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                  return daysOfWeek[day];
                                }}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <div className="form-group">
                          <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale="pt-br"
                          >
                            <Stack spacing={3}>
                              <DatePicker
                                label="Data Final"
                                value={valueFim}
                                onChange={(newValue) => {
                                  dataFiltroFinal(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                dayOfWeekFormatter={(day, date) => {
                                  const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                  return daysOfWeek[day];
                                }}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </div>
                      </div>

                      <div className="col-sm-8 col-md-8">
                        <div className="card-row">
                          <div className="col-sm-12 mr-3">
                            <FormControl spacing={3}>
                              <FormLabel></FormLabel>
                              <RadioGroup
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 24,
                                  },
                                }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={tipoRel}
                                onChange={tipoListaStatus}
                              >

                                <FormControlLabel
                                  value="AGUARDANDO"
                                  control={<Radio />}
                                  label="Aguardando"
                                  sx={{
                                    color: "#de795b",
                                  }}
                                /> 


                                <FormControlLabel
                                  value="ANDAMENTO"
                                  control={<Radio />}
                                  label="Andamento"
                                  sx={{
                                    color: "#5bc0de",
                                  }}
                                />

                                <FormControlLabel
                                  value="CONCLUIDO"
                                  control={<Radio />}
                                  label="Concluído"
                                  sx={{
                                    color: "#5cb85c",
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card-header ">
                <div className="card-row">
                  {data
                    .filter((event) => {
                      return event.demanda.solicitante.codUsuario != getControle()
                      && tipoRel == event.tipoStatus;
                    })
                    .sort((a, b) => b.id - a.id)
                    .map(
                      (
                        item //
                      ) => (
                        <div className="card">
                          <div className="row">
                            <div className="col-2">
                              <Stack>
                                <button
                                  onClick={(event) => {
                                    handleClick(event, item.demanda.id);
                                  }}
                                  type="button"
                                  className="btn btn-sm bg-primary"
                                >
                                  Processo
                                  <br />
                                  <strong>{addZeroes(item.id, 8)}</strong>
                                </button>
                              </Stack>
                            </div>

                            <div className="col-1">
                              <div className="text-center text-muted">
                                <span className="username">
                                  <b>Demanda</b>
                                  <br />
                                  {addZeroes(item.demanda.id, 8)}
                                </span>
                              </div>
                            </div>

                            <div className="col-2">
                              <div className="text-center text-muted">
                                <span>
                                  <b>Solicitante</b>
                                </span>
                                <br />
                                {item.demanda.solicitante.nome}
                              </div>
                            </div>

                            <div className="col-2">
                              <div className="text-center text-muted">
                                <span>
                                  <b>Data de abertura</b>
                                </span>
                                <br />
                                {formatarDataBr(item.dataHora)}
                              </div>
                            </div>

                            <div className="col-1">
                              <div className="text-center text-muted">
                                <span>
                                  <b>Data Limite</b>
                                </span>
                                <br />
                                {item.dataHoraLimite == null
                                ? ""
                                : formatarDataBr(item.dataHoraLimite)}
                              </div>
                            </div>

                            <div className="col-1">
                              <div className="text-center text-muted">
                                <span>
                                  <b>Msg</b>
                                </span>
                                <br />
                                <a href="#" className="text-center" 
                                onClick={(event) => {
                                  handleClick(event, item.demanda.id);
                                }}>
                                  <i>
                                    {" "}
                                    <TotaisComentarios tipo={"PROCESSOS"} parametros={item.id} cortipo = "blue"/>
                                  </i>
                                </a>
                                <div></div>
                              </div>
                            </div>

                            <div className="col-1">
                              <div className="text-center text-muted">
                                <span>
                                  <b>Não Lidas</b>
                                </span>
                                <br />
                                <a href="#" className="text-center text-muted" 
                                onClick={(event) => {
                                  handleClick(event, item.demanda.id);
                                }}>
                                  <i>
                                    {" "}
                                    <TotaisComentariosNaoLidas tipo={"PROCESSOS"} parametros={item.id} cortipo = "red"/>
                                  </i>
                                </a>
                                <div></div>
                              </div>
                            </div>

                            <div className="col-2">
                              <div className="text-center text-muted">
                                <span>
                                  <b>Status do Processo</b>
                                </span>
                                <br />
                                {item.tipoStatus}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </>
          );
        }
      })()}
    </>
  );
};

export default ListaVinculado;
