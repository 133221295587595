import { Bolt } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { forwardRef } from "react";
import { Warning } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ConfirmBox({
  open,
  closeDialog,
  title,
  deleteFunction,
  isLoadingRequest,
}) {
  console.log("ss", isLoadingRequest)

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      scroll="body"
      disableBackdropClick
      disableEscapeKeyDown

      TransitionComponent={Transition}
    >
      <DialogContent sx={{ px: 4, position: "relative" }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Warning sx={{ marginRight: 1, color: "red" }} />
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Excluir Task
                </Typography>
              </Box>
              <Typography variant="h6" marginBottom={2}>
                Deseja excluir o registro permanentemente?
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 0,
              }}
            >
              <DialogActions
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={closeDialog}
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: 100, marginRight: 1 }}
                  disabled={isLoadingRequest}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={deleteFunction}
                  size="small"
                  variant="contained"
                  sx={{ minWidth: 100, marginRight: 2 }}
                  color="error"
                  disabled={isLoadingRequest}
                >
                  {isLoadingRequest && "Deletando..."}
                  {!isLoadingRequest && "Excluir"}
                </Button>
              </DialogActions>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmBox;
