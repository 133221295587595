import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import "gantt-task-react/dist/index.css";
import { EventosEditar } from "./Component/EventosEditar";
import DirecionamentoPaginas from "./Direcionamento";

class Evento extends Component {
  handleNewItem = () => {
    this.props.history.push("/eventos/");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Categorias de Eventos",
      nomeNivelCrumb: "Página Eventos",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0 ml-2">{this.state.nomePagina}</h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item active">
                          <DirecionamentoPaginas />
                          </li>
                        </ol>
                      </div>
                    </div>
                    
                    <EventosEditar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Evento;
