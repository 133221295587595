import react from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DetalheSprintChart } from "../../../DetalheSprint/Components/DetalheSprintChart";

export const ModalDesempenho = ({
  estimativa,
  total,
  sprintId,
  showModal,
  onClose,
}) => {
  return (
    <Dialog variant="outlined" fullWidth maxWidth="lg" open={showModal}>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "grey",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DetalheSprintChart
          estimativa={estimativa}
          total={total}
          sprintId={sprintId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            onClose();
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
