import { useEffect, useState } from "react";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Typography } from "@mui/material";
import api from "../../../services/api";
import { formatarData } from "../../../utils/utils";

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-7} fill={stroke} fontSize={14} textAnchor="middle">
      {value}
    </text>
  );
};

export const DetalheSprintChart = ({ estimativa, total, sprintId }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await api.get(`sprintburndown/${sprintId}`).then((response) => {
        setData(response.data.records);
      });
    };

    fetchData();
  }, [sprintId]);

  const Array = data.map((item) => ({
    data: formatarData(item.data),
    quantidade: item.quantidade,
  }));

  return (
    <>
      <Box my={4}>
        <Typography variant="h6"> GRÁFICO DE DESEMPENHO BURNDOWN </Typography>
        <Typography>Estimativa em horas: {estimativa}</Typography>
        <Typography>Total: {total} </Typography>
      </Box>
      <div
        className="p-2"
        style={{ border: "1px solid #DEDEDE", borderRadius: 3}}
      >
        <div className="text-center" sx={{ color: "#332D2D" }}>
          <h2>Gráfico BurnDown</h2>
        </div>
        <ResponsiveContainer width={"100%"} height={420}>
          <LineChart
            width={600}
            height={400}
            data={Array}
            margin={{ top: 20, right: 18, left: 10, bottom: 5 }}
          >
            <CartesianGrid horizontal vertical={false} fillOpacity={0.2} />
            <XAxis dataKey="data" />
            <YAxis dataKey="quantidade" />
            <Tooltip />
            <Line
              strokeWidth={4}
              connectNulls
              type="monotone"
              dataKey="quantidade"
              stroke="#3A67E0"
              fill="#3A67E0"
            >
              <LabelList content={<CustomizedLabel />} />
            </Line>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
