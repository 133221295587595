import { PdfColor } from "@syncfusion/ej2-pdf-export";

const pdfGanttColor = {
  PENDENTE: new PdfColor(255, 153, 20),
  ANDAMENTO: new PdfColor(56, 176, 0),
  CONCLUIDO: new PdfColor(58, 12, 163),
  TESTE: new PdfColor(135, 206, 235),
};

export function pdfQueryTaskbarInfo(args) {
  const color = pdfGanttColor[args.data.taskData.status];

  if (color) {
    args.taskbar.progressColor = color;
    args.taskbar.taskColor = args.taskbar.taskBorderColor = color;
    args.taskbar.milestoneColor = color;
  }

  args.labelSettings.leftLabel.value = `${args.data.taskData.TaskID}`;
}
