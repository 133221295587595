import { Box, Card } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { converterParaHorasMinutos } from "../../../../utils/convertToHoursMinutes";

const TempoTrab = ({ dataInicio, dataFim, temp }) => {
  const maxValue =
    temp && temp.tempoTrabalhado
      ? Math.max(...temp.tempoTrabalhado.map((tec) => tec.tempoTrabalhado))
      : 0;

  const series =
    temp && temp.tempoTrabalhado
      ? [
          {
            name: "Tempo Trabalhado",
            data: temp.tempoTrabalhado.map((tec) => tec.tempoTrabalhado),
          },
        ]
      : [];

  const options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: { show: false },
    },
    title: {
      text: `Tempo Trabalhado - ${dayjs(dataInicio).format(
        "DD/MM/YYYY"
      )} á ${dayjs(dataFim).format("DD/MM/YYYY")}`,
      style: { fontSize: "16px" },
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        dataLabels: { position: "top" },
      },
    },
    colors: ["#223ff9"],
    dataLabels: { enabled: false },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories:
        temp && temp.tempoTrabalhado
          ? temp.tempoTrabalhado.map((tec) => tec.tecnico.nome)
          : [],
      axisBorder: { show: true },
      axisTicks: { show: false },
    },
    yaxis: {
      min: 0,
      max: maxValue,
      labels: {
        show: false,
        formatter: (val) => converterParaHorasMinutos(val),
      },
    },
  };

  return (
    <Card sx={{ width: "100%", height: 280 }}>
      <Box id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={260}  
        />
      </Box>
    </Card>
  );
};

export default TempoTrab;
