import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Link,
  ListItemText,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import naoLocalizado from "../../assets/naoLocalizado.png";
import buscandoDados from "../../assets/buscandoDados.png";
import { addZeroes, formatarDataBr } from "../../utils/utils";
import { useState } from "react";
import SprintModal from "./Sprint/components/SprintModal";
import CloseIcon from "@mui/icons-material/Close";

export const AtendimentoTaskTab = ({ response, errorSearch }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedItems =
    response &&
    response.content.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isTable = useMediaQuery(theme.breakpoints.down("1512px"));

  const handleTaskSelect = (codigo) => {
    const selected = response.content.find((item) => item.codigo === codigo);
    setSelectedTask(selected);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedTask(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "PENDENTE":
        return { color: "#0D47A1", backgroundColor: "#BBDEFB" };
      case "ANDAMENTO":
        return { color: "#FFAA1C", backgroundColor: "#FFF3E0" };
      case "TESTE":
        return { color: "black", backgroundColor: "#D9E2EC" };
      case "CONCLUIDO":
        return { color: "green", backgroundColor: "#C8E6C9" };
      default:
        return { color: "inherit", backgroundColor: "transparent" };
    }
  };

  const columns = [
    {
      field: "task",
      width: isTable ? 60 : 150,
      headerName: "TASK",
      pinned: "left",
      renderCell: (params) => (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {addZeroes(params.value, 5)}
        </div>
      ),
    },

    {
      field: "data",
      headerName: "Data",
      headerAlign: "left",
      width: isTable ? 80 : 150,
    },

    {
      field: "titulo",
      headerName: "Titulo",
      headerAlign: "left",
      width: isTable ? 85 : 230,
    },

    {
      field: "gravidade",
      headerName: "Gravidade",
      headerAlign: "left",
      width: isTable ? 80 : 150,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            backgroundColor: params.row.urgencia.cor || "transparent",
            borderRadius: 6,
            color: "white",
            padding: "4px",
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      width: isTable ? 120 : 150,
      renderCell: (params) => (
        <div
          style={{
            color: getStatusColor(params.value).color,
            backgroundColor: getStatusColor(params.value).backgroundColor,
            padding: "4px",
            borderRadius: 6,
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const rows =
    response &&
    response.content.map((item, index) => ({
      id: index,
      task: item.codigo,
      data: formatarDataBr(item.datcad),
      titulo: item.titulo,
      gravidade: item.urgencia.descricao,
      status: item.status,
      originalId: item.id,
      urgencia: item.urgencia, // aqui adiciona para ele mapear as urgencias
    }));

  return (
    <>
      <Box>
        {response && response.content.length > 0 ? (
          <>
            {isMobile ? (
              <>
                {paginatedItems.map((item) => (
                  <Card
                    onClick={() => handleTaskSelect(item.codigo)}
                    sx={{ cursor: "pointer", marginBottom: 2 }}
                  >
                    <CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Box display="flex" justifyContent="flex-start">
                          <Typography fontSize={10} fontWeight="bold">
                            Chamado:
                          </Typography>
                          <Typography fontSize={10}>{item.codigo}</Typography>
                        </Box>

                        <Box display="flex" justifyContent="flex-end">
                          <Typography fontSize={10} fontWeight="bold">
                            Data:
                          </Typography>
                          <Typography fontSize={10}>
                            {formatarDataBr(item.datcad)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="row">
                        <Typography fontSize={10} fontWeight="bold">
                          Titulo:
                        </Typography>
                        <Typography fontSize={10}>{item.titulo}</Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Box display="flex" justifyContent="flex-start">
                          <Box
                            sx={{
                              width: "60px",
                              background: `${item.urgencia.cor}`,
                              borderRadius: 10,
                            }}
                          >
                            <Typography
                              fontSize={10}
                              color="white"
                              textAlign="center"
                            >
                              {item.urgencia.descricao}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex" justifyContent="flex-end">
                          <Box
                            sx={{
                              width: "80px",
                              background: `${
                                getStatusColor(item.status).backgroundColor
                              }`,
                              borderRadius: 10,
                            }}
                          >
                            <Typography
                              fontSize={10}
                              color={`${getStatusColor(item.status).color}`}
                              textAlign="center"
                            >
                              {item.status}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
                <Box display="flex" justifyContent="center" mt={2}>
                  <Pagination
                    count={Math.ceil(response.content.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              </>
            ) : (
              <Box sx={{ overflowX: "auto" }}>
                <DataGrid
                  style={{ minHeight: "800px" }}
                  rows={rows}
                  columns={columns}
                  components={{
                    NoRowsOverlay: () => <Stack></Stack>,
                  }}
                  onRowClick={(params) => handleTaskSelect(params.row.task)}
                  initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: "status",
                          sort: "desc",
                        },
                      ],
                    },
                  }}
                  sx={{
                    "& .MuiDataGrid-row": {
                      maxHeight: "auto",
                    },
                    "& .MuiDataGrid-cell": {
                      padding: "4px",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      padding: "4px",
                    },
                  }}
                  disableColumnMenu
                  disableColumnFilter
                  disableSelectionOnClick
                  disableColumnSelector
                  disableDensitySelector
                  autoHeight
                  hideFooter
                  rowHeight={35}
                  headerHeight={35}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {!errorSearch && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                m={2}
              >
                <img
                  width={340}
                  height={250}
                  src={buscandoDados}
                  alt="Buscando Dados"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    marginTop: 40,
                  }}
                />
              </Box>
            )}
          </>
        )}

        {errorSearch && (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <img
                width={300}
                height={300}
                src={naoLocalizado}
                alt="Não Localizado"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  width: "300px",
                  marginTop: 50,
                  marginBottom: 20,
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography>
                <b>Não foi encontrado nenhum resultado.</b>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {modalIsOpen && selectedTask && (
        <SprintModal
          showModal={modalIsOpen}
          closeModal={handleCloseModal}
          data={selectedTask.codigo}
        />
      )}
    </>
  );
};
