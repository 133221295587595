import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  FormControl,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import dayjs from "dayjs";
import api from "../../../../services/api";
import BacklogGrid from "./BacklogGrid";
import "dayjs/locale/pt-br";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/pt-br";
import { useQuery } from "react-query";
import { getCC, getControle } from "../../../../services/auth";
import { useUnidadesContext } from "../../../../contexts/UnidadesContext";
import { BacklogCharts } from "./BacklogCharts";

const NewDatePicker = ({ value, onChange, label }) => {
  return (
    <DatePicker
      components={{
        OpenPickerIcon: ArrowDropDownIcon,
      }}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} fullWidth variant="outlined" />
      )}
      label={label}
      dayOfWeekFormatter={(day, date) => {
        const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
        return daysOfWeek[day];
      }}
    />
  );
};

export const BacklogFilter = () => {
  const { unidades, currentUnidade, onChangeUnidade } = useUnidadesContext();

  let history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [dateValue1, setDateValue1] = useState(
    dayjs(new Date()).subtract(30, "day")
  );
  const [dateValue2, setDateValue2] = useState(dayjs(new Date()));
  const [tipoRel, setTipoRel] = useState("Todos");
  const [tipoChamadoAprovacao, setTipoChamadoAprovacao] = useState("S");
  const [selectedGravidade, setSelectedGravidade] = useState("Todos");
  const [selectedDepto, setSelectedDepto] = useState(null);
  const [listGravidade, setListGravidade] = useState([]);
  const [order, setOrder] = useState(1);

  const { data: metricsData, isLoading } = useQuery(
    [
      "backlog-metrics",
      dateValue1,
      dateValue2,
      selectedGravidade,
      selectedDepto,
      currentUnidade,
      tipoChamadoAprovacao,
      order,
    ],
    async () => {
      const params = {
        codUsuario: getControle(),
        dtInicio: dateValue1.format("YYYY-MM-DD"),
        dtFinal: dateValue2.format("YYYY-MM-DD"),
      };

      if (selectedGravidade !== "Todos") {
        params.gravidade = selectedGravidade;
      }

      if (selectedDepto && selectedDepto !== "Todos") {
        params.departamento = selectedDepto;
      }

      if (tipoChamadoAprovacao) {
        params.aprovGerente = tipoChamadoAprovacao;
      }

      if (currentUnidade) {
        params.unidade = currentUnidade.cnpj;
      }
      const response = await api.get("/controle-servico/metricas", {
        params,
      });
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleChangeDataInicial = (date) => {
    setDateValue1(date);
  };
  const handleChangeDataFinal = (date) => {
    setDateValue2(date);
  };

  const handleChangeDepto = (event) => {
    setSelectedDepto(event.target.value);
  };

  useEffect(() => {
    const getGravidade = async () => {
      await api.get(`/controle-prioridade/`).then((response) => {
        setListGravidade(response.data.records);
      });
    };
    getGravidade();
  }, []);

  const tipoStatusChamado = async (evento) => {
    let relChamado = evento.target.value;
    setTipoChamadoAprovacao(relChamado);
  };

  const handleChangeGravidade = (event) => {
    let valor = event.target.value;
    setSelectedGravidade(valor);
  };

  const handleNewTask = () => {
    history.push({
      pathname: "/ChamadoItem/0",
    });
  };

  
  return (
    <>
      <Box>
        <Card>
          <CardContent>
            <Box
              display="grid"
              gridTemplateColumns={
                isMobile
                  ? "1fr"
                  : isTablet
                  ? "1fr 1fr 1fr 1fr"
                  : getCC() == "0012010301"
                  ? "1fr 1fr 1fr 1fr 1fr 1fr 0.8fr"
                  : "1fr 1fr 1fr 1fr 1fr 0.8fr"
              }
              gap={1}
            >

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"pt-br"}
              >
                <FormControl>
                  <NewDatePicker
                    components={{
                      OpenPickerIcon: ArrowDropDownIcon,
                    }}
                    value={dateValue1.format("YYYY-MM-DD")}
                    onChange={(newValue) => handleChangeDataInicial(newValue)}
                    label={"Data Inicial"}
                    variant="outlined"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"pt-br"}
              >
                <Stack spacing={3}>
                  <FormControl>
                    <NewDatePicker
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon,
                      }}
                      label={"Data Final"}
                      value={dateValue2}
                      onChange={(newValue) => {
                        handleChangeDataFinal(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              </LocalizationProvider>

              <FormControl>
                <InputLabel>Gravidade</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  label="Gravidade"
                  value={selectedGravidade.toString() || "Todos"}
                  onChange={handleChangeGravidade}
                >
                  {!listGravidade.some(
                    (item) => item.id.toString() === "Todos"
                  ) && <MenuItem value={"Todos"}>Todos</MenuItem>}
                  {listGravidade
                    .filter((item) => item.status === "1")
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id.toString()}>
                        {item.descricao}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>Tipo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tipoChamadoAprovacao}
                  variant="outlined"
                  label="Tipo"
                  onChange={tipoStatusChamado}
                >
                  <MenuItem value={"Todos"}>Todos</MenuItem>
                  <MenuItem value={"S"}>Aprovados</MenuItem>
                  <MenuItem value={"N"}>Reprovados</MenuItem>
                  <MenuItem value={"A"}>Aguardando</MenuItem>
                </Select>
              </FormControl>

              {getCC() == "0012010301" && (
                <FormControl>
                  <InputLabel>Unidade Produção</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentUnidade}
                    variant="outlined"
                    label="Unidade Produção"
                    onChange={(e) => onChangeUnidade(e.target.value)}
                  >
                    {unidades.map((item) => (
                      <MenuItem key={item.ccusto} value={item}>
                        {item.empresa}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl>
                <InputLabel>Depto/Solicitante</InputLabel>
                <Select
                  labelId="depto-label"
                  id="depto-select"
                  value={selectedDepto || "Todos"}
                  variant="outlined"
                  label="Depto/Solicitante"
                  onChange={handleChangeDepto}
                >
                  <MenuItem value={"Todos"}>Todos</MenuItem>
                  {!isLoading &&
                    metricsData &&
                    metricsData[0].departamento.map((depto) => (
                      <MenuItem key={depto.departamento} value={depto.codigo}>
                        {depto.departamento}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleNewTask}
                sx={{ fontSize: "11px" }}
              >
                Criar Task
              </Button>
            </Box>

            <BacklogCharts
              metrics={!isLoading && metricsData && metricsData[0]}
              filters={{
                dtInicio: new Date(dateValue1).toLocaleDateString("pt-BR"),
                dtFinal: new Date(dateValue2).toLocaleDateString("pt-BR"),
                gravidade: selectedGravidade,
              }}
            />

            <BacklogGrid
              statusFilter={tipoRel}
              statusGravidade={selectedGravidade}
              dataInicio={dateValue1}
              dataFim={dateValue2}
              ordenar={order}
              departamento={selectedDepto}
              tipo={tipoChamadoAprovacao}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
