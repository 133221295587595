import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import api from "../../../services/api";
import { formatDateToYYYYMMDD } from "../ProjetoKanban/gantt-functions/ganttDateFormatter";
import { Fragment, useState } from "react";
import { CustomDatePicker } from "../Projeto/Component/CustomDatePicker";

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-7} fill={stroke} fontSize={14} textAnchor="middle">
      {value}
    </text>
  );
};

function convertDateFormat(inputDate) {
  var parts = inputDate.split("/");

  var newDate = parts[2] + "-" + parts[1] + "-" + parts[0];

  return newDate;
}

export const BurndownProjeto = ({ tabs }) => {
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: projectData, isLoading: isLoadingProjectData } = useQuery(
    ["projeto-data-chart", id],
    async () => {
      const response = await api.get(`controle-sprint/projeto/${id}`);

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  const convertedApiDates = !isLoadingProjectData && {
    startDate: convertDateFormat(projectData.projeto.dataInicio.slice(0, 10)),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const { data: burndownData, isLoading } = useQuery(
    ["burndown-projeto", id, dates],
    async () => {
      const projectStartDate = dates.startDate || convertedApiDates.startDate;
      const projectEndDate = dates.endDate || convertedApiDates.endDate;

      const response = await api.get(
        `cadastro-projetos/burndown/41?dtInicial=${projectStartDate}&dtFinal=${projectEndDate}`
      );

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!projectData,
    }
  );

  if (isLoadingProjectData || isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          my: 10,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const data = Object.keys(burndownData).map((key) => ({
    data: new Date(key).toLocaleDateString("pt-BR", {
      timeZone: "UTC",
    }),
    quantidade: burndownData[key],
  }));

  const handleChangeStartDate = (date) => {
    setDates((prevState) => {
      return {
        ...prevState,
        startDate: convertDateFormat(
          new Date(date).toLocaleDateString("pt-BR")
        ),
      };
    });
  };

  const handleChangeEndDate = (date) => {
    setDates((prevState) => {
      return {
        ...prevState,
        endDate: convertDateFormat(new Date(date).toLocaleDateString("pt-BR")),
      };
    });
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : `1fr 1fr 1fr`,
          gap: 2,
          mb: 2,
        }}
      >
        <CustomDatePicker
          key={`inicio-${projectData.projeto.id}`}
          value={dates.startDate || convertedApiDates.startDate}
          minDate={convertedApiDates.startDate}
          onChange={handleChangeStartDate}
          label="Inicio"
        />
        <CustomDatePicker
          key={`fim-${projectData.projeto.id}`}
          value={dates.endDate || convertedApiDates.endDate}
          onChange={handleChangeEndDate}
          label="Fim"
        />

        {tabs}
      </Box>
      <ResponsiveContainer height={420}>
        <LineChart
          width={600}
          height={400}
          data={data}
          margin={{ top: 20, right: 18, left: 10, bottom: 5 }}
        >
          <CartesianGrid horizontal vertical={false} fillOpacity={0.2} />
          <XAxis dataKey="data" />
          <YAxis dataKey="quantidade" />
          <Tooltip label={"data"} />
          <Text>Teste</Text>
          <Line
            strokeWidth={4}
            connectNulls
            type="monotone"
            dataKey="quantidade"
            stroke="#3A67E0"
            fill="#3A67E0"
          >
            <LabelList content={<CustomizedLabel />} />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
};
