import Header from "../components/Header";
import MenuOS from "../components/MenuOS";
import { formatStringToCapitalize } from "../utils/formatStringToCapitalize";

export const DefaultLayout = ({ children, pageTitle, breadcrumbs }) => {
  return (
    <div className="wrapper">
      <Header />
      <MenuOS />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{formatStringToCapitalize(pageTitle)}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {breadcrumbs.map((crumb, index) => (
                    <li
                      key={`crumb-${index}`}
                      className="breadcrumb-item active"
                    >
                      <a href={crumb.href}>
                        <i />
                        {crumb.label}
                        {index < breadcrumbs.length - 1}
                      </a>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
            <div className="card py-4">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
