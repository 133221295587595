import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const TypeFilters = ({
  selectedTipo,
  handleChangeTipo,
  statusChamado,
  filteredTipo,
  styleSelect,
}) => {
  return (
    <div>
      <FormControl sx={{ marginTop: 1 }}>
        <InputLabel sx={styleSelect} id="tipo">
          Tipo
        </InputLabel>
        <Select
          label="Tipo"
          value={selectedTipo.id}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
          onChange={handleChangeTipo}
          disabled={statusChamado.current === "CONCLUIDO"}
          labelId="tipo"
          id="tipo"
          size="small"
        >
          <MenuItem value="" disabled>
            Selecionar
          </MenuItem>
          {filteredTipo
            .sort((a, b) => {
              if (a.descricao > b.descricao) return 1;
              if (a.descricao < b.descricao) return -1;
              return 0;
            })
            .map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.descricao}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TypeFilters;
