import React from "react";
import { Stack, TextField, InputAdornment, Box, Typography, Tooltip, Avatar, Menu, MenuItem, IconButton, Card, CardContent } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import GridMoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import IconeUrgencia from "../../../DetalheSprint/Components/IconeUrgencia";
import IconeStatus, { IconeStatusSprint } from "./IconeStatus";

const BacklogGridMobile = ({
  isMobile,
  handleSearch,
  search,
  resultSearch,
  dataControleServico,
  urgenciaOrder,
  converterParaHorasMinutos,
  anchorEl,
  handleClose,
  dataSprint,
  handleSelectSprint,
  setConfirmationModalOpen,
  handleSelectChamado,
  usuarioNome,
  openDelete,
  truncateText,
  formatarDatacomHora,
}) => {
  return (
    <>
      <Stack>
        <TextField
          onChange={handleSearch}
          value={search}
          name="search"
          id="search"
          variant="standard"
          placeholder="Filtrar por número chamado / Descrição..."
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { paddingLeft: "5px" },
          }}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: 3,
            marginTop: 2,
            border: "0.5px solid #C6C4C0",
            padding: 0.6,
            alignItems: "left",
            justifyContent: "left",
            "& input::placeholder": {
              textAlign: "left",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          }}
        />
      </Stack>

      {(resultSearch.length > 0 ? resultSearch : search ? [] : dataControleServico)
        .sort((a, b) => {
          if (a.minutosRestantes < 0 && b.minutosRestantes < 0) {
            if (a.minutosRestantes > b.minutosRestantes) return 1;
            if (a.minutosRestantes < b.minutosRestantes) return -1;
          } else if (a.minutosRestantes < 0) {
            return -1;
          } else if (b.minutosRestantes < 0) {
            return 1;
          }

          if (a.minutosRestantes > 0 && b.minutosRestantes > 0) {
            if (a.minutosRestantes > b.minutosRestantes) return 1;
            if (a.minutosRestantes < b.minutosRestantes) return -1;
          }

          return urgenciaOrder[a.urgencia.descricao] - urgenciaOrder[b.urgencia.descricao];
        })
        .map((item) => (
          <Card key={item.codigo} sx={{ mt: 1, borderTop: `2px solid ${item.urgencia.cor}` }}>
            <CardContent>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor="#F2F2F2">
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography variant="subtitle1">
                      <Link
                        to={`/ChamadoItem/${item.codigo}`}
                        style={{
                          color: "black",
                          fontSize: 14,
                          cursor: "pointer",
                          marginLeft: 8,
                        }}
                      >
                        {item.codigo}
                      </Link>
                    </Typography>
                    <IconeUrgencia urgency={item.urgencia} />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={item.minutosRestantes < 0 ? "Tempo Ultrapassado" : "Tempo Restante"}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <HistoryIcon
                          className={item?.minutosRestantes < 0 ? "fa-blink" : ""}
                          style={{
                            fontSize: "18px",
                            marginRight: 4,
                            color: item?.minutosRestantes < 0 ? "red" : "#6C6767",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "12px",
                            color: item?.minutosRestantes < 0 ? "red" : "#6C6767",
                          }}
                        >
                          {converterParaHorasMinutos(item.minutosRestantes)}
                        </span>
                      </Box>
                    </Tooltip>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={item.codigoControleServicoTecnico?.nome}>
                      <Avatar src={item.codigoControleServicoTecnico?.imagem} sx={{ width: 30, height: 30 }} />
                    </Tooltip>

                    <Tooltip title="Sprint">
                      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                        {dataSprint.map((sp) => (
                          <MenuItem
                            key={sp.id}
                            onClick={() => {
                              handleSelectSprint(sp.id);
                              setConfirmationModalOpen(true);
                            }}
                          >
                            <IconeStatusSprint task={sp} /> {sp.nome}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Tooltip>

                    {item.aprovadoGerente === "S" && (
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleSelectChamado(event, item)}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <Tooltip title={"Solicitante"} arrow>
                  <Box display="flex" flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mt={2} mb={1}>
                    <Typography variant="subtitle2">{item.userName}</Typography>
                  </Box>
                </Tooltip>

                <Tooltip title={"Título do Chamado"} arrow>
                  <Typography sx={{ mb: 1 }} variant="subtitle2">
                    {truncateText(item.titulo, 35)}
                  </Typography>
                </Tooltip>
              </Box>

              <Tooltip title={"Aprovação Gerencial"} arrow>
                <Box sx={{ gridTemplateColumns: "2fr 0.1fr 0.2fr", display: "grid" }}>
                  <Typography variant="subtitle2">
                    {item.dataGerente !== null ? formatarDatacomHora(item.dataGerente) : <Box sx={{ visibility: "hidden" }}>{"--------"}</Box>}
                  </Typography>

                  <Box sx={{ flexGrow: 1 }} />

                  {usuarioNome === item.userName && (
                    <IconButton aria-label="delete" size="small" color="error" onClick={() => openDelete(item.codigo)}>
                      <Tooltip title="Excluir Chamado">
                        <DeleteIcon fontSize="inherit" />
                      </Tooltip>
                    </IconButton>
                  )}

                  <IconeStatus task={item} />
                </Box>
              </Tooltip>
            </CardContent>
          </Card>
        ))}
    </>
  );
};

export default BacklogGridMobile;