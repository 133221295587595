import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import SignIn from "./pages/SignIn";
import NaoEncontrada from "./pages/404/404";
import Solicitacoes from "./pages/Solicitacoes/Solicitacoes";
import Home from "./pages/Home/Home";
import AtendimentoOS from "./pages/AtendimentoOS/Atendimento";
import Processos from "./pages/Processos/Processos";
import Processo from "./pages/Processos/Processo";
import LeituraRfid from "./pages/LeituraRfid/LeituraRfid";
import Eventos from "./pages/Eventos/Eventos";
import Evento from "./pages/Eventos/Evento";
import EventoSub from "./pages/Eventos/EventoSub";
import ProcessoVinculado from "./pages/ProcessoVinculado/ProcessoVinculado";
import ViagensKamban from "./pages/Viagens/Viagens";
import Viagem from "./pages/Viagens/Viagem";
import Hotel from "./pages/Viagens/Hoteis/Hotel";
import Hoteis from "./pages/Viagens/Hoteis/Hoteis";
import Motorista from "./pages/Viagens/Motorista/Motorista";
import Motoristas from "./pages/Viagens/Motorista/Motoristas";
import CadastrosBasicos from "./pages/AtendimentoOS/CadastrosBasicos/CadastrosBasicos";
import CadastrosBasico from "./pages/AtendimentoOS/CadastrosBasicos/CadastrosBasico";
import Classificacao from "./pages/AtendimentoOS/Classificacao/Classificacao";
import Classificacoes from "./pages/AtendimentoOS/Classificacao/Classificacoes";
import TipoChamados from "./pages/AtendimentoOS/TipoChamados/TipoChamados";
import TipoChamado from "./pages/AtendimentoOS/TipoChamados/TipoChamado";
import Colaborador from "./pages/AtendimentoOS/CadastrosBasicos/Colaboradores/Colaborador";
import Colaboradores from "./pages/AtendimentoOS/CadastrosBasicos/Colaboradores/Colaboradores";
import Projeto from "./pages/AtendimentoOS/Projeto/Projeto";
import Projetos from "./pages/AtendimentoOS/Projeto/Projetos";
import Sprints from "./pages/AtendimentoOS/Sprint/Sprints";
import Sprint from "./pages/AtendimentoOS/Sprint/Sprint";
import DetalheSprint from "./pages/DetalheSprint/DetalheSprint";
import Painel from "./pages/AtendimentoOS/Painel/Painel";
import { UserSprintWrapper } from "./contexts/UserSprint";
import AberturaChamado from "./pages/AtendimentoOS/AberturaChamados/AbertChamado";
import { SprintDataProvider } from "./contexts/SprintDataContext";
import { EventosLinksProvider } from "./contexts/LinksEventosContext";

import ProjetosKanban from "./pages/AtendimentoOS/ProjetoKanban/ProjetosKanban";
import Relatorios from "./pages/AtendimentoOS/Relatorios/atendimentos";
import { UnidadesProvider } from "./contexts/UnidadesContext";
import { ColapsedMenuProvider } from "./contexts/ColapsedMenuContext";
import { UserFilters } from "./contexts/FiltersSearchContext";
import MetricasList from "./pages/AtendimentoOS/Metricas/Metricas";
import { MetricasListProvider } from "./contexts/MetricasListaContext";

/*
CONTROLE DE ROTAS
controla e protege acesso ao endereços navegaveis do sistema.
*/

/*
VERIFICA SE HÁ UMA ROTA PRIVADA
e se o usuario tem os devidos acesso a ela
*/
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/" component={Home} />

      <PrivateRoute path="/homeApp" component={() => <h1>App</h1>} />
      <UnidadesProvider>
        <ColapsedMenuProvider>
          <SprintDataProvider>
            <MetricasListProvider>
              <PrivateRoute path="/home" component={Home} />

              <PrivateRoute path="/solicitacoes" component={Solicitacoes} />
              <PrivateRoute
                path="/solicitacoesItem/:id"
                component={Solicitacoes}
              />

              <PrivateRoute path="/viagens" component={ViagensKamban} />
              <PrivateRoute path="/viagemItem/:id" component={Viagem} />

              <PrivateRoute path="/hoteis" component={Hoteis} />
              <PrivateRoute path="/hotelItem/:id" component={Hotel} />

              <PrivateRoute path="/motoristas" component={Motoristas} />
              <PrivateRoute path="/motoristaItem/:id" component={Motorista} />

              <PrivateRoute
                path="/processoVinculado"
                component={ProcessoVinculado}
              />
              <PrivateRoute
                path="/processoVinculadoItem/:id"
                component={ProcessoVinculado}
              />

              <PrivateRoute path="/processos" component={Processos} />
              <PrivateRoute path="/processoItem/:id" component={Processo} />

              <PrivateRoute path="/leituraRfid" component={LeituraRfid} />

              <EventosLinksProvider>
                <UserFilters>
                  <PrivateRoute path="/atendimento" component={AtendimentoOS} />
                  <PrivateRoute path="/eventos" component={Eventos} />
                  <PrivateRoute path="/eventoItem/:id" component={Evento} />
                  <PrivateRoute
                    path="/eventoSubcategorias/:id"
                    component={EventoSub}
                  />

                  <PrivateRoute
                    path="/cadastros"
                    component={CadastrosBasicos}
                  />
                  <PrivateRoute
                    path="/cadastrosItem/:id"
                    component={CadastrosBasico}
                  />

                  <PrivateRoute
                    path="/classificacao"
                    component={Classificacoes}
                  />
                  <PrivateRoute
                    path="/classificacaoItem/:id"
                    component={Classificacao}
                  />

                  <PrivateRoute path="/tipoChamado" component={TipoChamados} />
                  <PrivateRoute
                    path="/tipoChamadoItem/:id"
                    component={TipoChamado}
                  />

                  <PrivateRoute path="/colaborador" component={Colaboradores} />
                  <PrivateRoute
                    path="/colaboradorItem/:id"
                    component={Colaborador}
                  />

                  <PrivateRoute path="/projeto" component={Projetos} />

                  <PrivateRoute path="/projetoItem/:id" component={Projeto} />

                  <UserSprintWrapper>
                    {/* Utilização deste context para fornecer aos componentes filhos os
        dados do usuário selecionado em um Kanban. */}

                    <PrivateRoute path="/sprint" component={Sprints} />
                    <PrivateRoute path="/sprintItem/:id" component={Sprint} />
                    <PrivateRoute path="/painel" component={Painel} />
                    <PrivateRoute
                      path="/detalhe-sprint/:sprintId"
                      component={DetalheSprint}
                    />
                    <PrivateRoute path="/metricas/:id" component={MetricasList} />
                    <PrivateRoute path="/relatorios" component={Relatorios} />

                    <PrivateRoute
                      path="/chamadoItem/:id"
                      component={AberturaChamado}
                    />
                    <PrivateRoute
                      path="/projeto-kanban/:id"
                      component={ProjetosKanban}
                    />
                  </UserSprintWrapper>
                </UserFilters>
              </EventosLinksProvider>
            </MetricasListProvider>
          </SprintDataProvider>
        </ColapsedMenuProvider>
      </UnidadesProvider>

      <Route path="*" component={NaoEncontrada} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
