export const statusConfig = {
  PENDENTE: {
    label: "Pendente",
    background: "warning",
    color: "warning.main",
  },
  ANDAMENTO: {
    label: "Andamento",
    background: "primary",
    color: "primary.main",
  },
  INATIVADO: {
    label: "Inativo",
    background: "default",
    color: "#e1e1e1",
  },
  ATRASADO: {
    label: "Atrasado",
    background: "warning",
    color: "error.main",
  },
  CONCLUIDO: {
    label: "Concluido",
    background: "success",
    color: "success.main",
  },
};
