import React from "react";
import { InputLabel, Card, Box } from "@mui/material";
import { DescQuill } from "../../../../components/EditorToolbar";

const DescriptionDialog = ({
  statusChamado,
  descricao,
  handleChangeDescricao,
  handleSubmitDesc,
  creatorUser
}) => {
  const userName = localStorage.getItem("USER_NAME").toUpperCase();

  return (
    <Box>
      <InputLabel htmlFor="descricao">Descrição</InputLabel>
      {statusChamado.current === "PENDENTE" && userName == creatorUser ? (
        <DescQuill
          id={1}
          value={descricao}
          onChange={handleChangeDescricao}
          onBlur={handleSubmitDesc}
        />
      ) : (
        <Card sx={{ padding: 2 }}>
          <Box
            id="descricao"
            name="descricao"
            dangerouslySetInnerHTML={{ __html: descricao }}
            style={{ width: "100%", whiteSpace: "pre-wrap" }}
          />
        </Card>
      )}
    </Box>
  );
};

export default DescriptionDialog;
