import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import "../pages/AtendimentoOS/Sprint/components/style.css";

import "react-quill/dist/quill.snow.css";

import { Box } from "@mui/material";

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value,
  },
  toolbar: {
    container: "#toolbar1",
    handlers: {
      undo: undoChange,
      redo: redoChange,

    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  keyboard: {
    bindings: {
      handleEnter: {
        key: 13,
        handler: function (range, context) {
          this.quill.insertText(range.index, "\n");
          this.quill.setSelection(range.index + 1, "silent");
        },
      },
    },
  },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "code-block",
  "newline",
];

const QuillToolbar = ({ id }) => (
  <Box
    id={`toolbar${id}`}
    display="flex"
    flexWrap="wrap"
    whiteSpace="nowrap"
    alignItems="center"
    alignSelf="center"
    justifyContent="flex-start"
    gap={1}
  >
    <span className="ql-formats" style={{ textAlign: "left" }}>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>

    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <button className="ql-blockquote" />
      <select className="ql-align" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-code-block" />
    </span>
  </Box>
);

export const DescQuill = ({
  id,
  value,
  onChange,
  onBlur,
  readOnly,
  placeholder,
  style,
  ...props
}) => {
  useEffect(() => {
    const input = document.querySelector(
      "input[data-link]"
    )
    input.dataset.link = "https://www.site.com";
    input.placeholder = "https://www.site.com";
  }, [])

  return (
    <div className="text-editor" style={{ maxWidth: "100%" }}>
      <QuillToolbar style={style} id={id} />
      <ReactQuill
        theme="snow"
        placeholder={placeholder ? placeholder : "Digite..."}
        modules={{ ...modules, toolbar: `#toolbar${id}` }}
        formats={formats}
        value={value}
        readOnly={readOnly}
        onChange={onChange}
        preserveWhitespace
        onBlur={onBlur}
        id={id}
        style={style}
        {...props}
      />
    </div>
  )
};
