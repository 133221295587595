import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  DialogContentText,
  TextField,
  FormControl,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import api from "../../../../services/api";
import { useQueryClient } from "react-query";
import { useSprintData } from "../../../../contexts/SprintDataContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ModalJustific = ({
  open,
  onClose,
  data,
  responsavel,
  statusAnt,
  statusChamado,
}) => {
  const { sprintId } = useParams();
  const { selectedSprint: selectedSprintId } = useSprintData();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [mensagem, setMensagem] = useState("");

  const queryClient = useQueryClient();

  const handleSalvar = async (e) => {
    const dataString = e;
    const dataObj = new Date(dataString);

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let dataHora = today.toISOString();

    e.preventDefault();
    try {
      var dadosJson = {
        mensagem: mensagem,
        chamadoId: data,
        userName: responsavel.usuario,
        tipo: 1,
        publico: statusAnt == "CONCLUIDO" ? "N" : "S",
        datcad: dataHora,
      };
      await api.post(`controle-servico-mensagens/`, dadosJson);

      queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
      queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
      queryClient.invalidateQueries("projeto-chamados");
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfirmation = async () => {
    try {
      var dadosJson = {
        status: statusChamado,
        previous: statusAnt,
      };
      await api.put(`controle-servico/${data}`, dadosJson);
    } catch (err) {
      console.log(err);
    }

    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title" style={{ fontSize: 30 }}>
        Resumo
      </DialogTitle>
      <DialogContentText className="text-center mb-3">
        Justifique o motivo de estar retornando o chamado!
      </DialogContentText>

      <div>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            justifyContent: "center",
            "& > :not(style)": { m: 1 },
            gap: 2,
          }}
        >
          <TextField
            style={{
              minWidth: 550,
            }}
            onBlur={handleSalvar}
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
            id="outlined-multiline-static"
            label="Justifique"
            multiline
            rows={5}
            variant="outlined"
          />
        </Box>
        <FormControl className="m-4">
          <Button
            variant="contained"
            onClick={handleConfirmation}
            disabled={mensagem.trim().length === 0}
          >
            {" "}
            Concluir
          </Button>
        </FormControl>
      </div>
    </Dialog>
  );
};

export default ModalJustific;
