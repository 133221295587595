import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import TreeView from "./TreeView";
import api from "../../../services/api";
import {
  AlertSuccess,
  AlertDanger,
} from "../../../components/controles/styles";
import { useEventosLinksContext } from "../../../contexts/LinksEventosContext";
import { Box } from "@mui/material";

export const EventosEditar = () => {
  const { id } = useParams();
  const [descricao, setDescricao] = useState("");

  const [novaDescricao, setNovaDescricao] = useState("");
  const [statusRecord, setStatusRecord] = useState({ type: "", mensagem: "" });
  const [eventoCat, setEventoCat] = useState([]);

  const { eventoId, categoriaId, updateCategoriaId, updateSubCategoriaId } =
    useEventosLinksContext();

  const getEvento = async () => {
    await api.get(`/demanda_eventos/` + eventoId).then((response) => {
      setDescricao(response.data.records[0].descricao.toUpperCase());
    });
  };

  const fetchCategories = async () => {
    const response = await api.get(`/demanda_eventos/completo/${eventoId}`);
    return response.data.records;
  };

  const fetchCurrentCategory = async () => {
    const response =
      categoriaId && (await api.get(`/categorias/${categoriaId}`));

    setNovaDescricao(response.data.records[0].descricao);

    return response.data.records[0];
  };

  const { data: categories, refetch } = useQuery("categories", fetchCategories);
  const { data: currentCategory } = useQuery(
    "categoriaAtual",
    fetchCurrentCategory,
    {
      enabled: !!categoriaId,
    }
  );

  const fethItemCategories = () => {
    categories.map((item) => {
      setEventoCat(item.categorias);
    });
  };

  const handleSaveEditMutation = async ({ id, editedValue }) => {
    try {
      var dadosJson = {
        descricao: editedValue,
      };

      await api.put(`/categorias/${id}`, dadosJson);
      setStatusRecord({
        type: "success",
        mensagem: "Categoria editada com sucesso",
      });
      refetch();
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    } finally {
      setTimeout(() => {
        setStatusRecord({ type: "", mensagem: "" });
      }, 3000);
    }
  };

  const { mutate } = useMutation(handleSaveEditMutation);

  const handleSaveEdit = (id, editedValue) => {
    mutate({ id, editedValue });
  };

  const newCategory = async (e) => {
    e.preventDefault();

    try {
      var dadosJson = {
        evento: eventoId,
        descricao: novaDescricao,
      };

      if (categoriaId && novaDescricao.length > 1) {
        const response = await api.put(`categorias/${categoriaId}`, dadosJson);

        setNovaDescricao("");
        setStatusRecord({
          type: "success",
          mensagem: "Nova Categoria editada com sucesso",
        });
        refetch();

        return response;
      }

      await api.post(`categorias/`, dadosJson);

      refetch();

      setNovaDescricao("");
      setStatusRecord({
        type: "success",
        mensagem: "Nova Categoria criada com sucesso",
      });
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na criação desta Categoria",
      });
    } finally {
      updateCategoriaId(null);
      setNovaDescricao("");
      setTimeout(() => {
        setStatusRecord({ type: "", mensagem: "" });
      }, 3000);
    }
  };

  useEffect(() => {
    if (categories) {
      fethItemCategories();
    }
    getEvento();
  }, [categories]);

  return (
    <>
      <div>
        {statusRecord.type === "erro" ? (
          <AlertDanger>{statusRecord.mensagem}</AlertDanger>
        ) : (
          ""
        )}
        {statusRecord.type === "success" ? (
          <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="col-sm" style={{ color: "gray", fontSize: 15 }}>
            {eventoId} {" - "} {descricao}{" "}
          </label>
          <div className="card">
            <div className="card-body">
              <form className="form-horizontal">
                <div className="row">
                  <div className="col-sm-10">
                    <label>Descrição</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Descrição da Categoria..."
                        id="description"
                        name="description"
                        onChange={(e) => setNovaDescricao(e.target.value)}
                        value={novaDescricao}
                        size="80"
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ marginTop: "30px", width: 100 }}
                      onClick={newCategory}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="form-horizontal">
                      <div className="col-sm-12">
                        <Box
                          sx={{
                            minHeight: 600,
                            flexGrow: 1,
                            //maxWidth: 1000,
                            fontSize: 20,
                          }}
                        >
                          <label>
                            {eventoId} {" - "} {descricao}{" "}
                          </label>
                          <TreeView
                            data={eventoCat}
                            onSaveEdit={handleSaveEdit}
                          />
                        </Box>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
