import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import CardStep from "./CardStep";
import api from "../../../services/api";
import { addZeroes, formatarDataBr } from "../../../utils/utils";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/system";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FormatBold } from "@mui/icons-material";
import { string } from "prop-types";
import { getControle } from "../../../services/auth";
import { Stack } from "react-bootstrap";

import "../../../components/controles/css/file.css";

import csv from "../../../assets/icones/csv.png";
import xls from "../../../assets/icones/excel.png";
import pdf from "../../../assets/icones/pdf.png";
import word from "../../../assets/icones/word.png";
import zip from "../../../assets/icones/zip.png";
import pptx from "../../../assets/icones/pptx.png";
import rar from "../../../assets/icones/rar.png";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[500] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ListaCards = ({ calls, blobImage, itProcess }) => {
  let history = useHistory();

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [nome, setNome] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [titulo, setTitulo] = useState("");
  const [motivo, setMotivo] = useState("");
  const [codigoUsuario, setCodigoUsuario] = useState([]);
  const [setor, setSetor] = useState([]);
  const [dataAbertura, setDataAbertura] = useState([]);
  const [dataFimPrazo, setDataFimPrazo] = useState([]);
  const [status, setStatus] = useState([]);
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);

  const [value, setValue] = useState(dayjs(new Date()).subtract(30, "day"));
  const [valueFim, setValueFim] = useState(dayjs(new Date()));

  const [tipoProcesso, setTipoProcesso] = useState([]);
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [openMsg, setOpenMsg] = useState(false);
  const [content, setContent] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [dataUsuario, setDataUsuario] = useState([]);
  const [usuarioAcaoModal, setUsuarioAcaoModal] = useState([]);
  const [mensagem, setMensagem] = useState("");
  const [arquivosDemanda, setArquivosDemanda] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleChangeMSG = () => {};

  const exibirMensagem = () => {
    setMensagem("Desvincular do processo!");
  };

  const limparMensagem = () => {
    setMensagem("");
  };

  const valorIDUsuario = dataUsuario
    .filter((usuar) => {
      return usuar.usuario.codUsuario == getControle();
    })
    .map((it) => {
      return it.id;
    });

  const handleFecharModal = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let dataHora = today.toISOString();
    let solicitante = valorIDUsuario;

    var dadosJson = {
      demanda: parseInt(id),
      processos: usuarioAcaoModal[0].id,
      descricao: motivo,
      dataHora: dataHora,
      solicitante: parseInt(solicitante),
      status: 1,
    };

    if (motivo.length < 10) {
      setMensagemDialog(" O Campo motivo deverá conter mais de 20 caracteres!");
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    } else if (motivo.length > 10) {
      api.post(`demanda_mensagem/`, dadosJson);

      var upJson = {
        tipoStatus: "INATIVO",
      };
      api.put(`/demanda_processos/` + usuarioAcaoModal[0].id, upJson);

      setMotivo("");
      setMensagemDialog("Saída do Processo confirmada.");
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpen(false);
    }
  };

  const handleCancelarModal = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (events) => {
    setIsChecked(events.target.checked);
  };

  const tipoLista = async (event) => {
    let rel = event.target.value;

    var upJson = {
      id: id,
      status: rel,
    };

    try {
      api.put(`/demanda/` + id, upJson);

      setTipoProcesso(rel);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  const dadosFiltros = () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);

    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));
  };

  const getLista = async () => {
    await api
      .get(`/anexo_demanda/demanda/` + id + `/anexos`)
      .then((response) => {
        setArquivosDemanda(response.data.records);
      });
  };

  useEffect(() => {
    // Colocar interval aqui
    const getDados = () => {
      api.get("/demanda/" + id).then((response) => {
        setData(response.data.records);
        setNome(response.data.records[0].solicitante.nome);
        setCodigoUsuario(response.data.records[0].solicitante.codUsuario);
        setDescricao(response.data.records[0].descricao);
        setTitulo(response.data.records[0].titulo);
        setDataAbertura(response.data.records[0].dataAbertura);
        setDataFimPrazo(response.data.records[0].dataFimPrazo);
        setStatus(response.data.records[0].status);
        setTipoProcesso(response.data.records[0].status);
        setContent(response.data.records[0].descricao);
        setUsuarios(response.data.records[0].usuarios.usuario);
      });
    };

    const getVinculoUsuario = async () => {
      await api.get(`vincular_usuario/demanda/` + id).then((response) => {
        setDataUsuario(response.data.records);
      });
    };

    const getUsuario = () => {
      api.get(`demanda_processos/demanda/` + id).then((response) => {
        setUsuarioAcaoModal(
          response.data.records.filter(
            (user) => user.usuario.usuario.codUsuario == getControle()
          )
        );
      });
    };

    getVinculoUsuario();
    getUsuario();
    getLista();
    getDados(); /* () => clearInterval(interval); */
    /* const interval = setInterval(() => {
      getDados();
    }, 1000); */

    /*  return */
  }, [id, tipoProcesso]);

  const Status = (status) => {
    if (status === 0) {
      return (
        <b
          style={{
            fontSize: 16,
            color: "#78CBE4",
          }}
        >
          ANDAMENTO
        </b>
      );
    } else if (status === 1) {
      return (
        <b
          style={{
            fontSize: 16,
            color: "#7BC57B",
          }}
        >
          CONCLUÍDO
        </b>
      );
    }
  };

  const handleEditClick = (event, id) => {
    history.push({
      pathname: "/solicitacoesItem/" + id,
    });
  };

  const confirmaInatividadeProcesso = async (event) => {
    let id = event.target.value;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Dialog
              open={true}
              onClose={onClose}
              aria-labelledby="customized-dialog-title"
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
            >
              <DialogTitle id="customized-dialog-title" onClose={onClose}>
                Confirma saída do Processo?
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  Esteja ciente que ao sairdo Processo, todos que fazem parte,
                  receberão essa mensagem!
                </Typography>
                <Typography gutterBottom>
                  Este procedimento é irreversível!
                </Typography>
                <Typography gutterBottom>
                  Deseja realmente sair do Processo?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    handleInativarMSG(id);
                    onClose();
                  }}
                  color="primary"
                >
                  SIM
                </Button>
                <Button autoFocus onClick={onClose} color="primary">
                  NÃO
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    });
  };

  const handleInativarMSG = (idExcluir) => {
    let id = idExcluir;
    var dadosJson = {
      status: 1,
    };
    try {
      api.put(`demanda_mensagem/` + id, dadosJson);
      setMensagemDialog("Mensagem Inativada!");
      setTipoDialog("warning");
      setCorDialog("warning");
      setOpenMsg(!openMsg);
      setComentar("");
    } catch (err) {
      setMensagemDialog("Houve uma problema com sua ação de inativar!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card card-light card-outline">
          <div className="card-header">
            <div className="row">
              <div className="col-md-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-md-2 col-md-2">{addZeroes(id, 8)}</div>

                  <div className="col-md-2 col-md-2">
                    <a>
                      <i className="fas fa-user mr-2" />
                      {nome}
                    </a>
                  </div>

                  <div className="col-md-2 col-md-2">
                    <span className="description">
                      <i className="fas fa-calendar-day mr-1 text-success" />
                      Abertura: {formatarDataBr(dataAbertura)}
                    </span>
                  </div>
                  <div className="col-md-2 col-md-2 text-left">
                    <span className="description">
                      <i className="fas fa-exclamation mr-1 text-danger" />
                      Prazo: {formatarDataBr(dataFimPrazo)}
                    </span>
                  </div>

                  {(() => {
                    if (status != 1) {
                      if (codigoUsuario == getControle()) {
                        return (
                          <div className="col-md-4 col-md-4 text-right">
                            <Stack spacing={3}>
                              <button
                                onClick={(event) => {
                                  handleEditClick(event, id);
                                }}
                                type="button"
                                className="btn bg-primary"
                                style={{
                                  textAlign: "right",
                                  textAlign: "center",
                                }}
                              >
                                <i className="fa fa-pencil mr-2" />
                                Editar Demanda
                              </button>
                            </Stack>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-md-4 col-md-4 text-right">
                            <Stack spacing={3}>
                              <Button onClick={handleOpen}>
                                <button
                                  className="btn btn-sm btn-outline-primary"
                                  onMouseOver={exibirMensagem}
                                  onMouseOut={limparMensagem}
                                >
                                  Desvincular!
                                </button>
                              </Button>
                            </Stack>
                            {mensagem && (
                              <div
                                className="d-flex flex-row p-1"
                                style={{
                                  background: "#f2f2f2",
                                  width: 150,
                                  borderRadius: 4,
                                  fontSize: 13,
                                  opacity: 0.4,
                                  float: "right",
                                }}
                              >
                                {mensagem}
                              </div>
                            )}

                            <Modal
                              open={open}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Typography
                                  className="modal-title text-center"
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  Obrigatório informar o motivo.
                                </Typography>
                                <Typography id="modal-modal-description">
                                  <div className="edit-container text-center mt-3 mb-3">
                                    <StyledTextarea
                                      maxRows={18}
                                      onChange={(e) =>
                                        setMotivo(e.target.value)
                                      }
                                      aria-label="maximum height"
                                      placeholder="Informe o motivo da sua saída"
                                      defaultValue=""
                                    />
                                  </div>

                                  <div className="text-center">
                                    <button
                                      onClick={handleFecharModal}
                                      className="edit-btn btn-outline-success mr-4"
                                    >
                                      Salvar
                                    </button>
                                    <button
                                      onClick={handleCancelarModal}
                                      className="edit-btn btn-outline-primary"
                                    >
                                      Fechar
                                    </button>
                                  </div>
                                </Typography>
                              </Box>
                            </Modal>
                          </div>
                        );
                      }
                    } else {
                      return (
                        <div
                          className="col-12 col-md-12 col-lg-2 order-1 order-md-2"
                          style={{ color: "blue", fontSize: 18 }}
                        >
                          {" "}
                          Processo Concluído!
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="card-tools col-md-5">
                <div className="input-group input-group-sm">
                  {(() => {
                    if (codigoUsuario == getControle()) {
                      return (
                        <FormControl spacing={3}>
                          <RadioGroup
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 16,
                              },
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={tipoProcesso}
                            onChange={tipoLista}
                          >
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Andamento"
                              sx={{
                                color: "#5bc0de",
                              }}
                            />

                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Concluído"
                              sx={{
                                color: "#5cb85c",
                                fontSize: 16,
                                fontFamily: "Roboto",
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      );
                    } else {
                      return Status(status);
                    }
                  })()}
                </div>
              </div>
              <div className="col-md-7">
                <div className="flex-row justify-content-end mt-2 col-sm-12">
                  <span class="mr-2" style={{ fontSize: 14 }}>
                    {" "}
                    <i class="fa fa-solid fa-square text-success"></i> Concluído
                  </span>
                  <span class="mr-2" style={{ fontSize: 14 }}>
                    {" "}
                    <i class="fa fa-solid fa-square text-primary"></i> Andamento
                  </span>
                  <span class="mr-2" style={{ fontSize: 14 }}>
                    {" "}
                    <i class="fa fa-solid fa-square text-warning"></i>{" "}
                    Aguardando
                  </span>
                  <span class="mr-2" style={{ fontSize: 14 }}>
                    {" "}
                    <i class="fa fa-solid fa-square text-danger"></i> Em atraso
                  </span>
                  <span style={{ fontSize: 14 }}>
                    {" "}
                    <i class="fa fa-solid fa-square text-secondary"></i> Fora do
                    processo
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                <div className="card-header">
                  <h4
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      color: "#505050",
                    }}
                  >
                    <b>{titulo}</b>
                  </h4>
                  <p
                    style={{
                      fontSize: 16,
                      textAlign: "justify",
                    }}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />

                  <div className="row">
                    {arquivosDemanda.map((item) => (
                      <div className="col-sm-2">
                        <button className="file-man-box">
                          <div className="file-img-box px-2 py-2">
                            {(() => {
                              if (
                                item.type ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ) {
                                return (
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={xls}
                                    alt=""
                                  />
                                );
                              } else if (
                                item.type === "application/vnd.ms-excel"
                              ) {
                                return (
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={xls}
                                    alt=""
                                  />
                                );
                              } else if (
                                item.type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              ) {
                                return (
                                  <img
                                    className="rounded mx-auto d-block img-fluid img-thumbnail"
                                    src={word}
                                    alt=""
                                  />
                                );
                              } else if (item.type === "image/png") {
                                return (
                                  <a href={`${item.caminho}`} target="_blank">
                                    <img
                                      className="rounded mx-auto d-block  img-thumbnail"
                                      src={`${item.caminho}`}
                                      alt={item.type === "image/png"}
                                    />
                                  </a>
                                );
                              } else if (item.type === "image/jpeg") {
                                return (
                                  <a href={`${item.caminho}`} target="_blank">
                                    <img
                                      className="rounded mx-auto d-block  img-thumbnail"
                                      src={`${item.caminho}`}
                                      alt={item.type === "image/jpg"}
                                    />
                                  </a>
                                );
                              } else if (
                                item.type === "application/x-zip-compressed"
                              ) {
                                return (
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail "
                                    src={zip}
                                    alt=""
                                  />
                                );
                              } else if (
                                item.type ===
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                              ) {
                                return (
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={pptx}
                                    alt=""
                                  />
                                );
                              } else if (
                                item.type === "application/octet-stream"
                              ) {
                                return (
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={rar}
                                    alt=""
                                  />
                                );
                              } else if (item.type === "application/pdf") {
                                return (
                                  <img
                                    className="rounded mx-auto d-block  img-thumbnail"
                                    src={pdf}
                                    alt=""
                                  />
                                );
                              } else if (item.type === "text/csv") {
                                return (
                                  <img
                                    className="rounded mx-auto d-block img-thumbnail"
                                    src={csv}
                                    alt=""
                                  />
                                );
                              } else {
                                return "";
                              }
                            })()}
                          </div>

                          <a
                            href={item.caminho}
                            className="file-download text-info"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-download" />
                          </a>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                <CardStep
                  codSolicitante={codigoUsuario}
                  altStatus={tipoProcesso}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12"></div>
      </div>
      <Dialog open={openMsg} onClose={handleClick}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            //width: '80%',
            //margin: 'auto',
            "& .MuiAlert-icon": {
              color: "blue",
            },
            //backgroundColor: "green"
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>
    </>
  );
};

export default ListaCards;
