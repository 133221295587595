import React, { Component } from "react";

import BacklogGrid from "../pages/AtendimentoOS/BackLog/Component/BacklogGrid";

import { BacklogFilter } from "../pages/AtendimentoOS/BackLog/Component/BacklogFilter";

export default class DashboardOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Backlog",
      nomeNivelCrumb: "PAINEL",
    };
  }

  render() {
    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1 className="m-0">{this.state.nomePagina} </h1>
                    </div>

                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item active">
                          <a
                            className="btn btn-secondary btn-sm mr-2"
                            href="/painel"
                          >
                            {this.state.nomeNivelCrumb}
                          </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <BacklogFilter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
