import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  httpAgent: false,
  baseURL: process.env.REACT_APP_LINK_API_DEMANDA,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true
});



api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});




export default api;
