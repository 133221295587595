import React, { Component } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import MenuOS from "../../../components/MenuOS";
import AtendimFilters from "./componentes/AtendimFilters";

const Relatorios = () => {
  return (
    <div className="wrapper">
      <Header />
      <MenuOS />

      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1 className="m-0">Relatório de Produtividade</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item active">
                          <a href="/atendimento">
                            Página Inicial
                          </a>
                          {" / "}
                          <a href="/Painel">
                            Painel
                          </a>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="card p-2" style={{ background: "white" }}>
                    <AtendimFilters />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Relatorios;