import React, { Component } from "react";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import Footer from "../../../components/Footer";
import HotelEditar from "./Componentes/HotelEditar";

class Hotel extends Component {
  handleNewItem = (e, ItProcesso) => {
    this.props.history.push("/hotelItem/:id");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Hotel ",
      nomeNivelCrumb: "Listar Hoteis",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0" style={{ paddingLeft: "0.4rem" }}>
                          {this.state.nomePagina}
                        </h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li
                            className="breadcrumb-item active"
                            style={{ paddingRight: "0.6rem" }}
                          >
                            <a href="/hoteis">{this.state.nomeNivelCrumb}</a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HotelEditar />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Hotel;
