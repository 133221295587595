import React, { useState } from "react";
import api from "../../../services/api";
import "../../../pages/Processos/Component/VisualizarProcessos";
import "../../../components/controles/css/file.css";
import csv from "../../../assets/icones/csv.png";
import xls from "../../../assets/icones/excel.png";
import pdf from "../../../assets/icones/pdf.png";
import word from "../../../assets/icones/word.png";
import zip from "../../../assets/icones/zip.png";
import pptx from "../../../assets/icones/pptx.png";
import rar from "../../../assets/icones/rar.png";
import { CircularProgress } from "@mui/material";

const fileTypeIcons = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": xls,
  "application/vnd.ms-excel": xls,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    word,
  "image/png": "",
  "image/jpeg": "",
  "application/x-zip-compressed": zip,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    pptx,
  "application/octet-stream": rar,
  "application/pdf": pdf,
  "text/csv": csv,
};

export default function ArquivosMensagens({
  id_msg,
  userOwner,
  msgst,
  refresh,
  dataMensagemAnexo,
}) {
  const [loadingFileId, setLoadingFileId] = useState(null);

  const excluirFoto = async (img) => {
    try {
      setLoadingFileId(img.id);
      const dadosJson = {
        id: img.id,
        type: img.type,
        caminho: img.caminho,
        posicao: img.posicao,
        nome: img.nome,
        status: img.status,
        mensagem: img.mensagem,
      };

      await api.post(`/anexo_mensagem/apagar-arquivo`, dadosJson);
      await refresh();
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingFileId(null);
    }
  };

  const renderFileIcon = (item) => {
    const icon = fileTypeIcons[item.type];
    if (item.type === "image/png" || item.type === "image/jpeg") {
      return (
        <a href={item.caminho} target="_blank" rel="noopener noreferrer">
          <img
            className="rounded mx-auto d-block img-thumbnail"
            src={item.caminho}
            alt={item.type}
          />
        </a>
      );
    } else if (icon) {
      return (
        <img
          className="rounded mx-auto d-block img-thumbnail"
          src={icon}
          alt=""
        />
      );
    }
    return null;
  };

  return (
    <>
      {dataMensagemAnexo.map((item) => (
        <div key={item.id} className="">
          <button className="file-man-box" disabled={loadingFileId === item.id}>
            {userOwner && loadingFileId !== item.id && (
              <a
                href
                className="file-close"
                onClick={(e) => {
                  e.preventDefault();
                  excluirFoto(item);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            )}

            <div className="file-img-box px-2 py-2">
              {loadingFileId === item.id ? (
                <CircularProgress />
              ) : (
                renderFileIcon(item)
              )}
            </div>
            {(userOwner || msgst === 0) && loadingFileId !== item.id && (
              <a
                href={item.caminho}
                className="file-download text-info"
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-download" />
              </a>
            )}
          </button>
        </div>
      ))}
    </>
  );
}
