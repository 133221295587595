import React, { useEffect, useState } from "react";
import { getControle } from "../../../services/auth";
import api from "../../../services/api";

/**
 * Tras todos os comentários das mensagens por demanda e processos
 * exemplo de uso
 * <TotaisComentarios tipo={"DEMANDA"} parametros={ID da demanda} />
 * <TotaisComentarios tipo={"PROCESSOS"} parametros={ID do processos} />
 * @param {*} param0
 * @returns
 */
export default function TotaisComentariosNaoLidas({ tipo, parametros, cortipo }) {
  const [dataTotaisNaoLidas, setDataTotaisNaoLidas] = useState([]);

  useEffect(() => {

   
    if (tipo == "PROCESSOS") {
      api.get(`/demanda_mensagem/processo/` + parametros).then((response) => {
        setDataTotaisNaoLidas(
          response.data.records.filter(
            (msg) =>
              (msg.statusLeitura == 0) || (msg.statusLeitura == null)
            // && msg.solicitante.usuario.codUsuario == getControle()
          )
        );
      });
    } else {
      api.get(`/demanda_mensagem/demanda/` + parametros).then((response) => {
        setDataTotaisNaoLidas(
          response.data.records.filter(
            (msg) =>
              (msg.statusLeitura == 0) || (msg.statusLeitura == null)
            //&& msg.solicitante.usuario.codUsuario == getControle()
          )
        );
      });
    }

     

  }, [tipo, parametros]);
 
  return (

<>
      <a style={{ fontSize: 18}}  className="btn btn-sm bg-orange">
      <i className="fas fa-comment-slash"></i><br />
      {/* <i className="fas fa-comments"></i><br />   */}
        {dataTotaisNaoLidas.length}
        </a>
      </>

    
  );
}