import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  DialogActions,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Link,
  Grid,
} from "@mui/material";
import { Form } from "react-bootstrap";
import eyes from "../../../../assets/eye.svg";
import eyesSlash from "../../../../assets/eye-slash.svg";
import "../../../../components/controles/css/file.css";

import api from "../../../../services/api";
import { getControle } from "../../../../services/auth";
import ConfirmBox from "../../../../components/controles/ConfirmBox";
import ResponsiveDialog from "./ModalConfirmacao";
import ModalJustific from "./ModalJustific";
import ErrorBox from "./ErrorBox";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { MsgUsuario } from "./MensagemUsuario";

import { useSprintData } from "../../../../contexts/SprintDataContext";
import { DescQuill } from "../../../../components/EditorToolbar";

import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { HeadModal } from "../../SprintModal/Componentes/HeadModal";
import { AssignResponsavel } from "../../SprintModal/Componentes/Technical";
import EstimHours from "../../SprintModal/Componentes/EstimHours";
import HistEstimHours from "../../SprintModal/Componentes/HistEstimHours";
import StatusFilters from "../../SprintModal/Componentes/Filters/StatusFilters";
import ProjectFilters from "../../SprintModal/Componentes/Filters/ProjectFilters";
import SprintFilters from "../../SprintModal/Componentes/Filters/SprintFilters";
import ClassificationFilters from "../../SprintModal/Componentes/Filters/ClassificationFilters";
import TypeFilters from "../../SprintModal/Componentes/Filters/TypeFilters";
import DescriptionDialog from "../../SprintModal/Componentes/DescriptionDialog";
import MessageDialog from "../../SprintModal/Componentes/MessageDialog";
import FileDropzone from "../../SprintModal/Componentes/ImputText";
import Anexos from "../../SprintModal/Componentes/Anexos";
import InsertText from "../../SprintModal/Componentes/ButtonInsert";
import ListaTécnicos from "./Modal/listaTecnicos";
import { TecnicoHorasModal } from "./Modal/TecnicoHorasModal";
import TechAssistant from "../../SprintModal/Componentes/TechAssistant";
import { AuxTecnicos } from "./AuxTecnicos";

const ContHTML = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};
const SprintModal = ({ showModal, data, closeModal }) => {
  const { selectedSprint: selectedSprintId } = useSprintData();

  const [task, setTask] = useState();
  const [mensagem, setMensagem] = useState("");
  const [mensagemEdit, setMensagemEdit] = useState("");

  const [mensagemUsuario, setMensagemUsuario] = useState([]);

  const [newHoraEstimada, setNewHoraEstimada] = useState(0);
  const [trocaTecnico, setTrocaTecnico] = useState(true);
  const [openMsg, setOpenMsg] = useState(false);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [descricao, setDescricao] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [dataUsuario, setDataUsuario] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [horaEstimada, setHoraEstimada] = useState("");
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoadingHoras, setIsLoadingHoras] = useState(false);

  const [privado, setPrivado] = useState("N");
  const [files, setFiles] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [historicoHrEstimadas, setHistoricoHrEstimadas] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModalTecnico = () => {
    setModalOpen(!modalOpen);
  };
  // Filtros
  const [statusChamado, setStatusChamado] = useState({
    previous: null,
    current: null,
  });

  const [selectedProjeto, setSelectedProjeto] = useState("");
  const [filteredProjeto, setFilteredProjeto] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState("");
  const [filteredSprint, setFilteredSprint] = useState([]);
  const [selectedClassificacao, setSelectedClassificacao] = useState("");
  const [filteredClassificacao, setFilteredClassificacao] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState("");
  const [filteredTipo, setFilteredTipo] = useState([]);
  const [responsavel, setResponsavel] = useState("");
  const [filteredResponsavel, setFilteredResponsavel] = useState([]);
  const [previous, setPrevious] = useState("");
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [openColaborador, setOpenColaborador] = useState(false);

  const [openJustific, setOpenJustific] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { sprintId } = useParams();
  const queryClient = useQueryClient();

  const handlePrivateChange = () => {
    setPrivado(privado === "S" ? "N" : "S");
  };
  const [isChecked, setIsChecked] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [msgDeleted, setMsgDeleted] = useState({
    mensagem: `Anexo excluído  `,
  });

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  const usuarioControle = getControle();

  const handleCloseModal = () => {
    setStatusChamado({
      current: "ANDAMENTO",
      previous: "PENDENTE",
    });
  };

  const handleCloseModalReturn = () => {
    setStatusChamado({
      current: "TESTE",
      previous: "TESTE",
    });
  };

  const handleEditClick = (messageId, messageText) => {
    setEditingMessageId(messageId);
    setMensagemEdit(messageText);
  };

  //função botão fechar alert
  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const excluirAnexo = async (icon, it) => {
    const dataHora = new Date().toISOString();
    const dadosJson = {
      mensagem: msgDeleted.mensagem + it,
      chamadoId: data,
      userName: dataUsuario,
      publico: "N",
      tipo: 1,
      datcad: dataHora,
    };

    try {
      const response = await api.delete(`controle-anexos/${icon}`);

      if (response.status === 200 || response.status === 2001) {
        await api.post(`controle-servico-mensagens/`, dadosJson);

        setDeleted(true);
        const updatedAnexos = task.anexos.filter((anexo) => anexo.id !== icon);

        setTask({
          ...task,
          anexos: updatedAnexos,
        });

        const updatedData = await api.get(
          `controle-servico-mensagens/chamado/${data}`
        );
        setMensagemUsuario(updatedData.data.records);
      } else {
        console.log(
          "Erro ao excluir anexo. Código de resposta:",
          response.status
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleted(true);
    }
  };

  const handleDeleteUserMessage = async () => {
    try {
      let anexoId =
        (deleteData.anexos.length > 0 && deleteData.anexos[0].id) || null;

      await Promise.all([
        anexoId && api.delete(`controle-anexos/${anexoId}`),
        api.delete(`controle-servico-mensagens/` + deleteData.id),
      ]);

      const updatedAnexos = task.anexos.filter((anexo) => anexo.id !== anexoId);

      setTask({
        ...task,
        anexos: updatedAnexos,
      });

      const updatedData = await api.get(
        `controle-servico-mensagens/chamado/${data}`
      );
      setMensagemUsuario(updatedData.data.records);

      setMensagemDialog(`Operação realizada com sucesso! ${deleteData?.id}`);
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpen(false);
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    }
  };

  const handleSalvarEdit = async (e, msgId) => {
    e.preventDefault();

    try {
      var dadosJson = {
        id: msgId,
        mensagem: mensagemEdit,
      };
      await api.put(`controle-servico-mensagens/` + msgId, dadosJson);

      setMensagem("");

      const updatedData = await api.get(
        `controle-servico-mensagens/chamado/${data}`
      );
      setMensagemUsuario(updatedData.data.records);

      setEditingMessageId(null);
    } catch (err) {
      console.log(err);
    }
  };

  const getAnexos = async () => {
    await api
      .get(`controle-anexos/controle/${data}/anexos`)
      .then((response) => {
        setAnexos(response.data.records);
      });
  };

  const getMensagem = async () => {
    await api
      .get(`controle-servico-mensagens/chamado/${data}`)
      .then((response) => {
        setMensagemUsuario(response.data.records);
      });
  };
  const MensagemCont = ContHTML(mensagem);

  const handleSalvar = async (e) => {
    e.preventDefault();

    const dataHora = new Date().toISOString();

    if (MensagemCont.length < 10) {
      setMensagemDialog(
        "O campo de comentário não pode ter menos de 10 caracteres."
      );
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
      return;
    }

    const dadosJson = {
      mensagem,
      chamadoId: data,
      userName: dataUsuario,
      publico: privado,
      tipo: 1,
      datcad: dataHora,
    };

    try {
      setLoading(true);
      const res = await api.post(`controle-servico-mensagens/`, dadosJson);
      await handleUpload(res.data.records[0].id);
      setFiles([]);
      setMensagem("");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      getMensagem();
      getAnexos();
    }
  };

  const getHistoricoHoras = async () => {
    await api.get(`alteracao-horas-estimadas/${data}`).then((response) => {
      setHistoricoHrEstimadas(response.data.records);
    });
  };
  const formatarHoraEstimada = (hora) => {
    if (hora === null || hora === undefined) return "00:00";

    const [h, m] = hora.toString().split(".");
    const horas = String(h).padStart(2, "0");
    const minutos = m
      ? m.length === 1
        ? String(Number(m) * 10).padStart(2, "0")
        : m.padStart(2, "0")
      : "00";
    return `${horas}:${minutos}`;
  };

  const fetchTaskData = async () => {
    const response = await api.get(`controle-servico/${data}`);
    setTask(response.data.records[0]);
    setDescricao(response.data.records[0].controleServico.problema);
    setSelectedProjeto(response.data.records[0].controleServico.projeto);
    setSelectedSprint(response.data.records[0].controleServico.sprintId);
    setSelectedClassificacao(
      response.data.records[0].controleServico.classificacao.id
    );
    setSelectedTipo(response.data.records[0].controleServico.tipo);
    setPrevious(response.data.records[0].controleServico.previous);
    setStatusChamado((prevStatus) => ({
      previous: response.data.records[0].controleServico.status,
      current: response.data.records[0].controleServico.status,
    }));
    setResponsavel(
      response.data.records[0].controleServico.codigoControleServicoTecnico
    );
    const horasEstimadas =
      response.data.records[0].controleServico?.horasEstimadasTecnico;

    setHoraEstimada(formatarHoraEstimada(horasEstimadas));
  };

  useEffect(() => {
    const getSolicitante = async () => {
      await api.get(`/usuarioDetalhe/` + usuarioControle).then((response) => {
        setDataUsuario(response.data.records[0].nome);
      });
    };

    const getProjeto = async () => {
      await api
        .get(`cadastro-projetos/status?codUsuario=${usuarioControle}&status=ANDAMENTO`)
        .then((response) => {
          setFilteredProjeto(response.data.records);
        });
    };

    const getSprint = async () => {
      await api
        .get(`controle-sprint?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredSprint(response.data.records);
        });
    };

    const getClassificacao = async () => {
      await api.get(`controle-classificacao`).then((response) => {
        setFilteredClassificacao(response.data.records);
      });
    };

    const getTipo = async () => {
      await api.get(`controle-tipo`).then((response) => {
        setFilteredTipo(response.data.records);
      });
    };

    const getResponsavel = async () => {
      await api
        .get(`tecnico?codUsuario=${usuarioControle}`)
        .then((response) => {
          setFilteredResponsavel(response.data.records);
        });
    };

    Promise.all([
      getHistoricoHoras(),
      fetchTaskData(),
      getSolicitante(),
      getTipo(),
      getClassificacao(),
      getProjeto(),
      getSprint(),
      getResponsavel(),
      getAnexos(),
      getMensagem(),
    ]);
  }, [data]);

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  const handleChangeResponsavel = async (resp) => {
    setTrocaTecnico(false);
    setResponsavel(resp);

    try {
      setIsLoadingRequest(true);

      const dadosJson = {
        codigoControleServicoTecnico: { codigo: resp.codigo },
        horasEstimadasTecnico: "00.00",
      };
      await api.put(`controle-servico/${data}`, dadosJson);

      setMensagemDialog(
        resp?.codigo == 9
          ? "Inserir um responsável"
          : "Inserir Horas Estimadas para continuar."
      );
      setTipoDialog("success");
      setCorDialog("success");
      setNewHoraEstimada("00:00");
      setHoraEstimada("00:00");
      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);
      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleChangeStatusChamado = async (event) => {
    const valor = event.target.value;
    const horasFormat = horaEstimada.replace(/:/g, ".");
    const horas = newHoraEstimada > 0 ? newHoraEstimada : horasFormat;
    const previousStatus = statusChamado.current;

    /* Estamos validando a seguinte regra: se o status atual for ANDAMENTO, e o que foi clicado (novo status) for PENDENTE
    caso verdadeiro, as horas estimadas do campo são zeradas. */
    if (previousStatus === "ANDAMENTO" && valor === "PENDENTE") {
      setNewHoraEstimada("00:00");
      setHoraEstimada("00:00");
    }

    setStatusChamado((prev) => ({
      previous: prev.current,
      current: valor,
    }));

    console.log("setStatusChamado", statusChamado);

    console.log("PreviousStatus:", previousStatus);

    const shouldOpenModal =
      valor === "CONCLUIDO" ||
      valor === "TESTE" ||
      ((previousStatus === "TESTE" || previousStatus === "CONCLUIDO") &&
        valor === "PENDENTE");

    if (shouldOpenModal) {
      setConfirmationModalOpen(true);
      setPrevious(statusChamado.current);
    } else {
      try {
        const isHorasValid = horas !== "00.00" && responsavel.codigo !== 9;

        if (isHorasValid) {
          await api.put(`controle-servico/${data}`, {
            status: valor,
            horasEstimadasTecnico: horas,
            previous: previousStatus,
          });
          queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
          queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
          queryClient.invalidateQueries("projeto-chamados");

          setMensagemDialog("Status alterado com sucesso!!");
          setTipoDialog("success");
          setCorDialog("success");
          setOpenMsg((prev) => !prev);
          setPrevious(statusChamado.current);

          return;
        }



        setOpenAlert(true);
      } catch (err) {
        console.error(err);
        setMensagemDialog("Houve um erro na operação deste item!");
        setTipoDialog("error");
        setCorDialog("error");
      } finally {
        getHistoricoHoras();
      }
    }
  };

  const handleCancelClick = () => {
    setMensagem("");
    setEditingMessageId(null);
  };

  const handleChangeProjeto = async (event) => {
    let projeto = event.target.value;

    setSelectedProjeto(event.target.value);

    try {
      await api.put(`controle-servico/${data}`, {
        projeto,
      });

      queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
      queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
      queryClient.invalidateQueries("projeto-chamados");

      setMensagemDialog("Projeto alterado com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleChangeSprint = async (event) => {
    let valor = event.target.value;

    try {
      setSelectedSprint({
        id: valor,
      });

      await api.put(`controle-servico/${data}`, {
        sprintId: {
          id: valor,
        },
      });

      queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
      queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
      queryClient.invalidateQueries("projeto-chamados");

      setMensagemDialog("Sprint alterada com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleChangeClassificacao = async (event) => {
    let valor = event.target.value;

    setSelectedClassificacao(valor);

    try {
      api.put(`controle-servico/${data}`, {
        classificacao: {
          id: valor,
        },
      });

      setMensagemDialog("Classificação alterada com sucesso");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleChangeTipo = async (event) => {
    let valor = event.target.value;

    try {
      setSelectedTipo({
        id: valor,
      });

      api.put(`controle-servico/${data}`, {
        tipo: {
          id: valor,
        },
      });

      setMensagemDialog("Tipo alterado com sucesso!");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleSubmit = async () => {
    let valor = horaEstimada.replace(/[^0-9]/g, "");

    valor = valor.padStart(4, "0");

    let horas = valor.substring(0, 2);
    let minutos = valor.substring(2, 4);

    if (parseInt(minutos, 10) > 59) {
      minutos = "59";
    }

    const formatado = `${horas}:${minutos}`;

    if (formatado !== "00:00") {
      setHoraEstimada(formatado);
    } else {
      setHoraEstimada("");
    }

    try {
      setIsLoadingHoras(true);

      let horasFormat = formatado.replace(/:/g, ".");

      const dadosJson = {
        horasEstimadasTecnico: horasFormat,
        problema: descricao,
      };

      await api.put(`controle-servico/${data}`, dadosJson);
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    } finally {
      setIsLoadingHoras(false);
    }
  };

  const handleSubmitDesc = async () => {
    try {
      const dadosJson = {
        problema: descricao,
      };

      await api.put(`controle-servico/${data}`, dadosJson);
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    }
  };

  const handleFechar = () => {
    closeModal();
    handleSubmitDesc();

    queryClient.invalidateQueries(`selected-sprint-${selectedSprintId}`);
    queryClient.invalidateQueries(`selected-sprint-${sprintId}-detalhe`);
    queryClient.invalidateQueries("projeto-chamados");
  };

  const handleChange = (e) => {
    let valor = e.target.value.replace(/[^0-9]/g, "");

    if (valor.length > 4) {
      valor = valor.substring(1, 5);
    }

    let horas;
    if (valor.length <= 2) {
      horas = valor.padStart(2, "0");
    } else {
      horas = valor.substring(0, 2) + ":" + valor.substring(2);
    }

    setHoraEstimada(horas);
  };

  const validateHoraEstimada = () => {
    if (
      statusChamado.current == "PENDENTE" ||
      (previous == null && horaEstimada == 0) ||
      (statusChamado.current == "PENDENTE" && previous == null)
    ) {
      return false;
    } else if (
      trocaTecnico == "" ||
      trocaTecnico == true ||
      previous == "PENDENTE" ||
      (previous == null && horaEstimada != 0) ||
      previous != null
    ) {
      return true;
    }
  };

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = async (id) => {
    setLoading(true);

    const uploadPromises = files.map(async (file) => {
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("idComentario", id);
      fileFormData.append("controle", data);

      const response = await api.post(
        `controle-servico-mensagens/enviar-arquivo`,
        fileFormData,
        {
          mode: "no-cors",
          method: "POST",
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }
      );
      const fileWithUrl = {
        ...file,
        caminho: response.data.records[0].caminho,
      };
      setTask((prevTask) => ({
        ...prevTask,
        anexos: [...prevTask.anexos, response.data.records[0]],
      }));
    });

    try {
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
      getMensagem();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const styleSelect = {
    color: "#333333",
    fontSize: 19,
  };

  const handleChangeDescricao = (newDesc) => {
    setDescricao(newDesc);
  };

  const handleChangeMensagem = (content) => {
    setMensagem(content);
  };

  const handleMensagemEdit = (value) => {
    setMensagemEdit(value);
  };

  const clearEstimatedHours = () => {
    setHoraEstimada("00:00");
    setNewHoraEstimada("00:00");
  };

  const handleColaboradores = () => {
    setOpenColaborador(!openColaborador);
  };

  return (
    task && (
      <Dialog
        fullScreen={fullScreen}
        variant="outlined"
        fullWidth
        maxWidth="lg"
        open={showModal}
        onClose={handleFechar}
      >
        <Card
          display="flex"
          sx={{
            // height: "100%",
            maxHeight: isMobile ? "100vh" : "100vh",
            overflowY: "auto",
          }}
        >
          <CardContent
            sx={{
              display: "grid",
              fontSize: 30,
              margin: 0.01,
            }}
          >
            {/* Cabeçalho do Modal */}
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 20,
                marginTop: scrolled ? "10px" : "0px",
              }}
            >
              <HeadModal
                task={task}
                isMobile={isMobile}
                fullScreen={fullScreen}
              />
            </Box>

            <Card
              sx={{
                overflowY: "auto",
                marginTop: 2,
                borderRadius: 2,
                borderTop: "4px solid #404467",
              }}
            >
              <CardContent>
                <Grid
                  container
                  whiteSpace="nowrap"
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    {/* Tecnico responsável */}
                    <AssignResponsavel
                      responsavel={responsavel}
                      filteredResponsavel={filteredResponsavel}
                      statusChamado={statusChamado}
                      handleChangeResponsavel={handleChangeResponsavel}
                    />
                  </Grid>

                  {/* Horas estimadas do chamado */}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    <EstimHours
                      handleSubmit={handleSubmit}
                      validateHoraEstimada={validateHoraEstimada}
                      responsavel={responsavel}
                      isLoadingRequest={isLoadingRequest}
                      horaEstimada={horaEstimada}
                      handleChange={handleChange}
                    />
                  </Grid>

                  {/* Historico de horas estimadas */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    sx={{ textAlign: "center" }}
                  >
                    <HistEstimHours
                      historicoHrEstimadas={historicoHrEstimadas}
                      handleChecked={handleChecked}
                      isChecked={isChecked}
                      eyes={eyes}
                      eyesSlash={eyesSlash}
                    />
                  </Grid>

                  {/* técnicos auxiliar*/}
                  {task.controleServico?.qtdAuxiliares !== 0 &&
                    task.controleServico?.horasAuxiliares !== 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={3}
                        sx={{ textAlign: "center" }}
                      >
                        <TechAssistant task={task.controleServico} />
                      </Grid>
                    )}
                </Grid>
              </CardContent>
            </Card>

            {/* Horas Auxílio */}
            <Grid container whiteSpace="nowrap">
              <Grid lg={12} xs={12} sm={12} md={12}>
                <AuxTecnicos
                  handleColaboradores={handleOpenModalTecnico}
                  statusAtual={statusChamado.current}
                  task={task}
                />
              </Grid>
            </Grid>

            <Card
              sx={{
                overflowY: "auto",
                marginTop: 2,
                borderRadius: 2,
                borderTop: "4px solid #404467",
                gap: 1,
              }}
            >
              <CardContent>
                <Grid
                  container
                  whiteSpace="nowrap"
                  justifyContent={"space-between"}
                >
                  {/* Filtro Status */}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    <StatusFilters
                      statusChamado={statusChamado}
                      handleChangeStatusChamado={handleChangeStatusChamado}
                      responsavel={responsavel}
                      horaEstimada={horaEstimada}
                      newHoraEstimada={newHoraEstimada}
                      styleSelect={styleSelect}
                    />
                  </Grid>

                  {/* Filtro Projeto */}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    <ProjectFilters
                      selectedProjeto={selectedProjeto}
                      handleChangeProjeto={handleChangeProjeto}
                      statusChamado={statusChamado}
                      filteredProjeto={filteredProjeto}
                      styleSelect={styleSelect}
                    />
                  </Grid>

                  {/* Filtro Sprint */}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    <SprintFilters
                      selectedSprint={selectedSprint}
                      handleChangeSprint={handleChangeSprint}
                      filteredSprint={filteredSprint}
                      statusChamado={statusChamado}
                      styleSelect={styleSelect}
                    />
                  </Grid>

                  {/* Filtro Classificação */}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    <ClassificationFilters
                      selectedClassificacao={selectedClassificacao}
                      statusChamado={statusChamado}
                      handleChangeClassificacao={handleChangeClassificacao}
                      filteredClassificacao={filteredClassificacao}
                      styleSelect={styleSelect}
                    />
                  </Grid>

                  {/* Filtro Tipo */}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={2}
                    sx={{ textAlign: "center" }}
                  >
                    <TypeFilters
                      selectedTipo={selectedTipo}
                      handleChangeTipo={handleChangeTipo}
                      statusChamado={statusChamado}
                      filteredTipo={filteredTipo}
                      styleSelect={styleSelect}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Campo descrição chamado */}
            <DialogContent>
              <Box noValidate component="form">
                <DescriptionDialog
                  statusChamado={statusChamado}
                  descricao={descricao}
                  handleChangeDescricao={handleChangeDescricao}
                  handleSubmitDesc={handleSubmitDesc}
                  creatorUser={task.controleServico.userName}
                />

                {task.anexos && task.anexos.length > 0 && (
                  <Card sx={{ marginTop: 2 }}>
                    <CardContent>
                      <Anexos task={task} excluirAnexo={excluirAnexo} />
                    </CardContent>
                  </Card>
                )}

                {/* Mensagem e botão Private */}
                <Grid>
                  <MessageDialog
                    privado={privado}
                    handlePrivateChange={handlePrivateChange}
                  />
                </Grid>

                {/* Comentarios chamado */}
                <Grid
                  container
                  whiteSpace="nowrap"
                  justifyContent={"space-between"}
                >
                  <Grid item lg={10} md={12} xs={12} sm={12}>
                    {/* Barra de formatação */}
                    <Form.Group>
                      <DescQuill
                        id={2}
                        value={mensagem}
                        onChange={handleChangeMensagem}
                      />
                      {/* bloco de comentário e anexos  */}
                      <FileDropzone
                        handleDrop={handleDrop}
                        loading={loading}
                        files={files}
                      />
                    </Form.Group>
                  </Grid>

                  <Grid
                    item
                    lg={2}
                    md={12}
                    xs={12}
                    sm={12}
                    sx={{ justifyContent: "center", display: "flex" }}
                  >
                    <InsertText
                      handleSalvar={handleSalvar}
                      MensagemCont={MensagemCont}
                      loading={loading}
                    />
                  </Grid>
                </Grid>

                {isConfirmationModalOpen && (
                  <ResponsiveDialog
                    open={isConfirmationModalOpen}
                    onClose={() => setConfirmationModalOpen(false)}
                    fechaSprintModal={handleFechar}
                    responsavel={responsavel}
                    sprintId={selectedSprint.id}
                    refreshComentarios={() => getMensagem()}
                    statusChamado={statusChamado.current}
                    statusChamadoObject={statusChamado}
                    anterior={statusChamado.previous}
                    closeModal={
                      statusChamado.previous == "TESTE"
                        ? handleCloseModalReturn
                        : handleCloseModal
                    }
                    data={data}
                    idDoTecnico={
                      task?.controleServico?.codigoControleServicoTecnico
                        ?.usuarioCompleto?.codUsuario
                    }
                    dataUsuario={dataUsuario}
                    limparHoras={clearEstimatedHours}
                  />
                )}

                <Box>
                  <MsgUsuario
                    deleted={msgDeleted}
                    mensagemUsuario={mensagemUsuario}
                    editingMessageId={editingMessageId}
                    dataUsuario={dataUsuario}
                    handleSalvarEdit={handleSalvarEdit}
                    handleEditClick={handleEditClick}
                    openDelete={openDelete}
                    mensagem={mensagemEdit}
                    handleChangeMensagem={handleMensagemEdit}
                    handleCancelClick={handleCancelClick}
                  />
                </Box>

                {openJustific && (
                  <ModalJustific
                    open={openJustific}
                    onClose={() => setOpenJustific(false)}
                    responsavel={responsavel}
                    statusAnt={statusChamado.previous}
                    statusChamado={statusChamado.current}
                    data={data}
                  />
                )}

                <ErrorBox
                  open={openAlert}
                  onClose={() => setOpenAlert(false)}
                  responsavel={responsavel?.codigo}
                  horas={horaEstimada}
                />
              </Box>
            </DialogContent>

            <Box>
              <Dialog open={openMsg} onClose={handleFecharAlert}>
                <Alert
                  severity={tipoDialog}
                  color={corDialog}
                  role="button"
                  onClose={() => {
                    setOpenMsg(false);
                  }}
                  sx={{
                    "& .MuiAlert-icon": {
                      color: "blue",
                    },
                  }}
                >
                  <AlertTitle />
                  {mensagemDialog}
                </Alert>
              </Dialog>

              {open && (
                <ConfirmBox
                  open={open}
                  closeDialog={() => setOpen(false)}
                  title={deleteData?.mensagem}
                  deleteFunction={handleDeleteUserMessage}
                />
              )}
            </Box>
          </CardContent>
        </Card>
        <TecnicoHorasModal
          openModal={modalOpen}
          handleOpenModalTecnico={handleOpenModalTecnico}
          chamado={task.controleServico.codigo}
          atualizaModal={fetchTaskData}
          codTecnicoChamado={
            task.controleServico?.codigoControleServicoTecnico?.codigo
          }
        />
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ marginBottom: "10px" }}
            autoFocus
            onClick={handleFechar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default SprintModal;
