/**
 * @description UserSprint é responsável por fornecer aos componentes o atual usuário selecionado na Sprint.
 *
 * @returns {JSX}
 */

import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSprintData } from "./SprintDataContext";

const UserSprintContext = createContext();

export const UserSprintWrapper = ({ children }) => {
  const { selectedSprint } = useSprintData();
  const [selectedUser, setSelectedUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setSelectedUser(null);
  }, [location.pathname, selectedSprint]);

  const userSprintValues = {
    selectedUser,
    setSelectedUser,
  };

  return (
    <UserSprintContext.Provider value={userSprintValues}>
      {children}
    </UserSprintContext.Provider>
  );
};

export const useUserSprint = () => {
  return useContext(UserSprintContext);
};
