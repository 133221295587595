import { Box, List, ListItem, Typography } from "@mui/material";

export const PDFHeaderCard = ({
    title,
    content,
    titleProps = {},
    contentProps = {},
    background,
    items,
    isListCard,
}) => {
    return (
        <>
            {!isListCard && (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        p: 2,
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: 2,
                        backgroundColor: background?.color || "#f8f8f8",
                        ...background,
                    }}
                >
                    <Typography variant="body2" fontSize={18} {...titleProps}>
                        {title}
                    </Typography>
                    {typeof content === "string" && (
                        <Typography fontWeight={800} variant="body2" fontSize={!contentProps && "16px"} {...contentProps}>
                            {content}
                        </Typography>
                    )}
                    {typeof content !== "string" && content}
                </Box>
            )}

            {isListCard && (
                <Box sx={{ border: 1, borderRadius: 2, borderColor: "#f8f8f8" }}>
                    <List>
                        {items?.map((item) => (
                            <ListItem
                                sx={{ display: "flex", justifyContent: "flex-end", gap: 0.5 }}
                                key={`${item?.label}-${item?.value}`}
                            >
                                <Typography variant="body2">{item?.label}:</Typography>
                                <Typography variant="body2">{item?.value}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};
