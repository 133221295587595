import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";

const SprintFilters = ({
  selectedSprint,
  handleChangeSprint,
  filteredSprint,
  statusChamado,
  styleSelect,
}) => {
  return (
    <FormControl sx={{ marginTop: 1 }}>
      <InputLabel sx={styleSelect} id="sprint">
        Sprint
      </InputLabel>
      <Select
        label="Sprint"
        value={selectedSprint.id}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: 185,
          minWidth: 150,
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        onChange={handleChangeSprint}
        labelId="sprint"
        id="sprint"
        size="small"
        disabled={statusChamado.current == "CONCLUIDO"}
      >
        <MenuItem value="" disabled>
          Selecionar
        </MenuItem>
        {filteredSprint
          .filter((its) => {
            return its.status == "S" && its.nome !== "BACKLOG";
          })
          .sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          })
          .map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Typography inherit sx={{ overflow: "hidden", textOverflow: "ellipsis", minWidth: 150 }}>
                {item.nome}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SprintFilters;
