import React from "react";
import "dayjs/locale/pt-br";
import Solicitante from "./Solicitante";
import FormHospedagem from "./FormHospedagem";
import Passagem from "../Passagens/Passagem";
import Transporte from "../Transportes/Transporte";

const SolicitacaoEditarAbas = () => {
  return (
    <>
      <div className="card col-12 ">
        <div className="row">
          <div className=" col-12 card-header">
            <ul className="nav nav-pills">
              <li className="nav-item col-md-3 mb-1 col-sm-3">
                <a
                  className="nav-link"
                  id="custom-tabs-two-home-tab"
                  href="#custom-tabs-two-home"
                  role="navigation"
                  aria-controls="custom-tabs-two-home"
                  aria-selected="true"
                  data-toggle="tab"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <i class="fas fa-edit mr-2"></i>
                  Dados do Solicitante
                </a>
              </li>
              <li className="nav-item col-md-3 mb-1 col-sm-3">
                <a
                  className=" nav-link"
                  id="custom-tabs-two-profile-tab"
                  href="#custom-tabs-two-profile"
                  role="navigation"
                  aria-controls="custom-tabs-two-profile"
                  aria-selected="false"
                  data-toggle="tab"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <i class="fa fa-plane mr-2"></i>
                  Passagem Áereas
                </a>
              </li>
              <li className="nav-item col-md-3 mb-1 col-sm-3">
                <a
                  className="nav-link"
                  id="custom-tabs-two-messages-tab"
                  href="#custom-tabs-two-messages"
                  role="navigation"
                  aria-controls="custom-tabs-two-messages"
                  aria-selected="false"
                  data-toggle="tab"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <i class="fas fa-hotel mr-2"></i>
                  Hospedagem
                </a>
              </li>
              <li className="nav-item col-md-3 col-sm-3">
                <a
                  className="nav-link"
                  id="custom-tabs-two-settings-tab"
                  href="#custom-tabs-two-settings"
                  role="navigation"
                  aria-controls="custom-tabs-two-settings"
                  aria-selected="false"
                  data-toggle="tab"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <i class="fa fa-car mr-2"></i>
                  Transporte
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="card-body">
          <div className="tab-content" id="custom-tabs-two-tabContent">
            <div
              className="tab-pane fade show active"
              id="custom-tabs-two-home"
              role="tabpanel"
              aria-labelledby="custom-tabs-two-home-tab"
            >
              <Solicitante />
            </div>
            <div
              className="tab-pane fade"
              id="custom-tabs-two-profile"
              role="tabpanel"
              aria-labelledby="custom-tabs-two-profile-tab"
            >
              <Passagem />
            </div>
            <div
              className="tab-pane fade"
              id="custom-tabs-two-messages"
              role="tabpanel"
              aria-labelledby="custom-tabs-two-messages-tab"
            >
              <FormHospedagem/> 
            </div>
            <div
              className="tab-pane fade"
              id="custom-tabs-two-settings"
              role="tabpanel"
              aria-labelledby="custom-tabs-two-settings-tab"
            >
              <Transporte />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolicitacaoEditarAbas;
