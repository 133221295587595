import moment from "moment";
import api from "../services/api";

export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

export const addZeroes = (num, len) => {
    let numberWithZeroes = String(num);
    let counter = numberWithZeroes.length;

    while (counter < len) {

        numberWithZeroes = "0" + numberWithZeroes;

        counter++;

    }

    return numberWithZeroes;
}

export const dataAdd = (dtBase, _status, _usuario) => {
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    let dataAtual = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    //let atual = dayjs(new Date()).add(3, "day");
    const atual = dataAtual;

    const d1 = dtBase;
    const d2 = atual;

    const diffInMs = new Date(d1) - new Date(d2);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);



    let dias = diffInDays.toFixed(0);
    if (_status == "CONCLUIDO") {
      return (

        <small className="badge badge-success">
            {_usuario} - Concluído
        </small>
      );
    } else if (dtBase == null) {
      return (
        <small className="badge badge-danger">
          {_usuario} - Sem Prazo
           
        </small>
      );
    } else if (dias < 0) {
      return (
        <small className="badge badge-danger">
          
          
          {_usuario} - {dias} dia(s) em Atraso
          
        </small>
      );
    } else {
      return (
        
        <small className="badge badge-secondary">
          {_usuario} - 
          Faltam {dias} dia(s)
        </small>
      );
    }
  };

export const formatarDataBr = (stringData, DataHora) => {

    let dataInput = stringData;
    let data = new Date(dataInput);
    let dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    let horaFormatada = data.toLocaleTimeString('pt-BR', { timeZone: 'UTC' });
    if (DataHora == "DataHora"){
        return dataFormatada + " " + horaFormatada;
    } else {
        return dataFormatada;
    }
    
}

export const formatarDataInvert = (stringData, DataHora) => {

    let dataInput = stringData;
    let data = new Date(dataInput);
    let dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    let horaFormatada = data.toLocaleTimeString('pt-BR', { timeZone: 'UTC' });
    if (DataHora == "DataHora"){
        return horaFormatada + " " + dataFormatada;
    } else {
        return dataFormatada;
    }
    
}


/*
let dateObj = new Date('02/03/2018 00:00:00')

let dateString = dateObj.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute:'2-digit',
    second:'2-digit'
}).replace(/\//g, '-')

console.log(dateString)
*/
export const formatarDataBaseDados = (stringData) => {

    let dateObj = new Date(stringData);

    /* let dateString = dateObj.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute:'2-digit',
        second:'2-digit'
    }).replace(/\//g, '-') */

//console.log(moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
      
        return moment(dateObj, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
   
    
}

export const formatarDataBaseDadosNew = (stringData) => {

    let dateObj = new Date(stringData);
  
    /* let dateString = dateObj.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute:'2-digit',
        second:'2-digit'
    }).replace(/\//g, '-') */
  
  //console.log(moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
      
        return moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
  
    
  }

  export const formatarData = (stringData) => {

    let dateObj = new Date(stringData);
      
        return moment(dateObj, 'DD/MM').format('DD/MM');
  
    
  }



export default { addZeroes, formatarDataBr, formatarDataBaseDados, formatarDataBaseDadosNew, formatarDataInvert, formatarData};

