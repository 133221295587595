import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export const CustomDatePicker = ({
  value,
  onChange,
  label,
  size,
  name,
  minDate,
  ...props
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={"pt-br"}
      key={label}
    >
      <DatePicker
        key={label}
        minDate={minDate}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            size={size}
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        dayOfWeekFormatter={(day, date) => {
          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
          return daysOfWeek[day];
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};
