import React, { useState, useEffect } from "react";
import { getCC } from "../../services/auth";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useUnidadesContext } from "../../contexts/UnidadesContext";
const menuJson = require("./menuElementOS.json");

export const MenuItemOS = () => {
  const [data, setData] = useState(menuJson.data);
  const { currentUnidade } = useUnidadesContext();

  return (
    <div>
      {data
      .filter((st, index) => {
          if (currentUnidade && data[4].ccusto == currentUnidade.ccusto && st.ativo == "S") {
            return true;       
          } else {
            return index != 4;
          }
        })

        .map((item) => (
          <div>
            {(() => {
              if (item.menuIten.length > 0) {
                return (
                  <>
                    <li className="nav-item" key={item.id}>
                      <a href="#" className="nav-link">
                        <i className={item.nomeIcon} />

                        <p className="ml-1">
                          {item.itemPaiLabel}
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>

                      <ul className="nav nav-treeview">
                        {item.menuIten.map((mitem) => (
                          <li className="nav-item" key={mitem.id}>
                            <Link to={mitem.link} className="nav-link">
                              <i className={mitem.nomeIcon} />
                              <p>{mitem.elementoLabel}</p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </>
                );
              } else {
                return (
                  <>

                    <p className="nav-item ml-3" style={{ color: "#bdbebd" }}>
                    <i className="far fa-list-alt nav-icon mr-2 mb-3" />
                    <span className="brand-text">
                      <Link to={item.link}>{item.itemPaiLabel}</Link>
                     </span>
                    </p>
                  </>
                );
              }
            })()}
          </div>
        ))}
    </div>
  );
};
