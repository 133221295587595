import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import Cards from "./Cards";
import { useEffect, useState } from "react";
import api from "../../../services/api";

export const ColunasMovimentacao = ({ parametro, tipoColuna }) => {
  let history = useHistory();

  const [data, setData] = useState([]);


  useEffect(() => {
    api.get("/viagem").then((response) => {
      
        setData(
              response.data.records.filter(
                (dema) => (dema.status === `${parametro}`) 
             )
        );

      
    });
  });


  return (
    <>
      <Grid>
        <div class="container">
          <div
            className="col mt-2"
            style={{ textAlign: "center", width: "100%" }}
          >
            <i class="fa fa-plane mr-2" style={{ color: "#304056" }}></i>
            <strong>{tipoColuna}</strong>

            <div className="col mt-2" style={{ textAlign: "left" }}>
            {data.sort((a, b) => b.id - a.id)
                      .map(
                        (
                          it //
                        ) => (
                          <Cards it={it.demanda} compromisso={it.datahoracompromisso} destino={it.destino} gravidade={it.gravidade}/>
                          
                        )
                      )}
              
             
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};
