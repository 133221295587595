import { Edit } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { addZeroes } from "../../../../utils/utils";

export const ProjectMobileCard = ({ projects }) => {
  const status = {
    PENDENTE: {
      label: "Pendente",
      color: "warning",
    },
    ANDAMENTO: {
      label: "Andamento",
      background: "#ff773d",
      color: "#fff",
    },
    INATIVADO: {
      label: "Inativo",
      background: "#495057",
      color: "#fff",
    },
    ATRASADO: {
      label: "Atrasado",
      background: "#d90429",
      color: "#fff",
    },
    CONCLUIDO: {
      label: "Concluido",
      color: "success",
    },
  };

  return (
    <Box sx={{ backgroundColor: "#f8f8f8", px: 2, py: 1 }}>
      {projects.map((project, index) => (
        <Fragment key={project.id}>
          <Box
            sx={{
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "1fr",
              gap: 1,
              width: "100%",
              my: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{ width: 14, height: 14, backgroundColor: project.cor }}
                ></Box>
                <Box
                  sx={{
                    py: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    sx={{
                      flexWrap: "nowrap",
                      maxLines: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: 200,
                    }}
                  >
                    {addZeroes(project.id, 6)} - {project.descricao}
                  </Typography>
                </Box>

                <Link to={`/projetoItem/${project.id}`}>
                  <IconButton size="small" color="primary">
                    <Edit />
                  </IconButton>
                </Link>
              </Box>

              <Link to={`projeto-kanban/${project.id}`}>
                <Button fullWidth variant="outlined" size="small">
                  Painel
                </Button>
              </Link>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                mb: 2,
              }}
            >
              <Typography variant="body2">
                {project.dataInicio.slice(0, 10)}
              </Typography>
              <Typography textAlign={"end"} variant="body2">
                {project.dataConclusao.slice(0, 10)}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: status[project.status].background,
                borderRadius: "5px",
              }}
            >
              <Typography
                textAlign={"center"}
                color={status[project.status].color}
              >
                Status: {status[project.status].label}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 1,
                my: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f8edeb",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Horas Estimadas
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {project.totalHorasEstimadasProjeto}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#fdffb6",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Andamento
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {project.andamento}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#ffd166",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Teste
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {project.teste}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#baf2d8",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Typography textAlign={"center"} variant="body2">
                  Concluído
                </Typography>
                <Typography textAlign={"center"} fontWeight={600}>
                  {project.concluido}
                </Typography>
              </Box>
            </Box>
          </Box>

          {index !== projects.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Box>
  );
};
