export const calculateGridColumns = (clientWindowWidth, columns) => {
  const defaultColumns = [4, 3, 2, 1];

  if (clientWindowWidth > 1280) {
    return columns?.[0] || defaultColumns[0];
  }

  if (clientWindowWidth > 960 && clientWindowWidth <= 1280) {
    return columns?.[1] || defaultColumns[1];
  }

  if (clientWindowWidth > 600 && clientWindowWidth <= 960) {
    return columns?.[2] || defaultColumns[2];
  }

  return columns?.[3] || defaultColumns[3];
};
