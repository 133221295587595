import React, { useEffect } from "react";

import "dayjs/locale/pt-br";

import { useState } from "react";
import api from "../../../services/api";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Rating,

  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import img from "../Hoteis/Componentes/placa-do-hotel.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { formatarDataBaseDados } from "../../../utils/utils";
import { AlertDanger, AlertSuccess } from "../Motorista/Component/styles";

const FormHospedagem = () => {
  let history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [listHotel, setListHotel] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [email, setEmail] = useState([]);
  const [nome, setNome] = useState([]);


  const [observacao, setObservacao] = useState([]);
  const [reserva, setReserva] = useState([]);

  const [idHotel, setIdHotel] = useState(0);
  const [images, setImages] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);
  const [arquivo, setArquivo] = useState([]);

  const [aguardando, setAguardando] = useState(true);
  const [statusImagem, setStatusImagem] = useState(false);
  const [indexPrincipal, setIndexPrincipal] = useState(0);


  const [valueCheckIn, setValueCheckIn] = useState(dayjs(new Date()));
  const [valueCheckOut, setValueCheckOut] = useState(dayjs(new Date()));

  const [openMsg, setOpenMsg] = useState(false);
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [idViagem, setIdViagem] = useState([]);

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const optionsHotel = listHotel
    .sort((a, b) => a.nome - b.nome)
    .map(function (ff) {
      return { value: ff.id, label: ff.nome };
    });



  const handleChangeHoteis = (selected) => {
    setSelectedHotel(selected.value);
  };

  useEffect(() => {

    const getHotel = async () => {

      await
        api.get(`/hospedagem/demanda/` + id).then((response) => {
          setIdHotel(response.data.records[0].id);
          setSelectedHotel(response.data.records[0].hotel);
          setReserva(response.data.records[0].reserva);
          setValueCheckIn(response.data.records[0].checkIn);
          setValueCheckOut(response.data.records[0].checkOut);
          setObservacao(response.data.records[0].observacao);
          setIdViagem(response.data.records[0].viagem);

        });

      api.get(`hotelalbum/hotel/` + selectedHotel)
        .then((res) => {
          setImages(res.data.records);
          setImagemPrincipal(res.data.records[indexPrincipal]);

          setAguardando(false);
        });


    };
    getHotel();
    //getArquivo();

  }, [id]);

  //LISTA DO HOTEL
  useEffect(() => {

    api.get(`/hotel`).then((response) => {
      setData(response.data.records);
      setListHotel(response.data.records);
     
      setEmail(response.data.records[0].email);
      setNome(response.data.records);
    });

  }, []);

  const getImages = async () => {

    setAguardando(true);
    await api
      .get(`hotelalbum/hotel/` + idHotel)
      .then((response) => {
        setImages(response.data.records);
        setImagemPrincipal(response.data.records[indexPrincipal]);

        setAguardando(false);
      });
  };

  const getArquivo = async () => {
    var idReg = localStorage.getItem("idHot");
    setArquivo([]);
    setAguardando(true);
    await api
      .get(`hotelalbum/hotel/arquivo/` + (id !== "0" ? id : idReg))
      .then((response) => {
        setArquivo(response.data.records[0].hotelAlbum);
        setAguardando(false);
      });
  };


  const editHospedagem = async (e) => {
    e.preventDefault();
       /**     
    CRIADO UMA LISTA DE JSON SEPARADO
    DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO
*/
    var dadosJson = {
      
        checkIn: valueCheckIn,
        checkOut: valueCheckOut,
        hotel: selectedHotel,
        observacao: observacao,
        reserva: reserva,
        viagem: idViagem
      
    };

    try {
     
      
      if (idHotel !== 0 ) {
        await api.put(`/hospedagem/` + idHotel, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });

      } else {  
        // console.log(JSON.stringify(dadosJson))
        await api.post(`/hospedagem/`, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const limparMSG = setTimeout(() => {
        setStatusRecord({
          type: "",
          mensagem: "",
        });

      }, 2000); 

      return () => clearTimeout(limparMSG);
     

    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };


  const altDataOut = async (event) => {
    const dataString = event;
    const dataObj = new Date(dataString);

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    let dataAtual = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    var dia = String(dataObj.getDate()).padStart(2, "0");
    var mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    var ano = dataObj.getFullYear();
    let dataSelec = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    const d1 = dataAtual;
    const d2 = dataSelec;

    const diffInNewDate = new Date(d2) - new Date(d1);
    const diffInNovaData = diffInNewDate / (1000 * 60 * 60 * 24);

    let dataSelecionada = diffInNovaData.toFixed(0);

    try {
      if (dataSelecionada < 0) {
        setMensagemDialog(
          `Data Limite não pode ser inferior a data de hoje: ${formatarDataBr(
            dataAtual
          )}!`
        );

        setTipoDialog("error");
        setCorDialog("error");
        setOpenMsg(!openMsg);
      } else {
        setValueCheckOut(dataString);
      }
    } catch (err) {
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };


  const altData = async (event) => {
    const dataString = event;
    const dataObj = new Date(dataString);

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    let dataAtual = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    var dia = String(dataObj.getDate()).padStart(2, "0");
    var mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    var ano = dataObj.getFullYear();
    let dataSelec = ano + "-" + mes + "-" + dia + " " + "00:00:00";

    const d1 = dataAtual;
    const d2 = dataSelec;

    const diffInNewDate = new Date(d2) - new Date(d1);
    const diffInNovaData = diffInNewDate / (1000 * 60 * 60 * 24);

    let dataSelecionada = diffInNovaData.toFixed(0);

    try {
      if (dataSelecionada < 0) {
        setMensagemDialog(
          `Data Limite não pode ser inferior a data de hoje: ${formatarDataBr(
            dataAtual
          )}!`
        );

        setTipoDialog("error");
        setCorDialog("error");
        setOpenMsg(!openMsg);
      } else {
        setValueCheckIn(dataString);
      }
    } catch (err) {
      setMensagemDialog("Houve um problema ao inserir a Mensagem");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };


  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px; ${imagemPrincipal.hotelAlbum?.status == 1
                ? ""
                : "filter: grayscale();"
                }}`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>

        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  return (
    <>



      <section className="content">
        <div className="container-fluid">
          <div className="row">

            <div className="col-md-3">
              <div className="card card-primary card-outline">
                <div className="card-body box-profile">
                {!aguardando && (
                  <div>
                    <div className="card card-comment card-outline">
                      <div className="card-body">
                        {verificarImagem(imagemPrincipal)}

                         
                      </div>
                    </div>

                    <div className="row">
                      {images.map(
                        (
                          item,
                          idx //border-radius: 20px;
                        ) => (
                          <div className="col-sm-5">
                            
                              <div className="product-image-thumb">
                                <img
                                  Style={`${
                                    item && item.hotelAlbum.status == 1
                                      ? ""
                                      : "filter: grayscale();"
                                  }}`}
                                  src={`data:image/jpeg;base64, ${item.blobImage}`}
                                  alt="Photo"
                                />
                              </div>
                            </div>
                           
                        )
                      )}
                    </div>
                  </div>
                )}
                  

                  <form className="form-horizontal" onSubmit={editHospedagem}>
                  

                    <ul className="list-group list-group-unbordered mb-3">

                      <li className="list-group-item">
                        <b>CheckIn</b> <a className="float-right"></a>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pt-br"
                        >
                          <div class="input-group date" data-provide="datepicker">
                            <DatePicker
                              className="form-control"
                              value={valueCheckIn}
                              onChange={(newValue) => {
                                altData(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </div>
                        </LocalizationProvider>
                      </li>
                      <li className="list-group-item">
                        <b>CheckOut</b> <a className="float-right"></a>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pt-br"
                        >
                          <div class="input-group date" data-provide="datepicker">
                            <DatePicker
                              className="form-control"
                              value={valueCheckOut}
                              onChange={(newValue) => {
                                altDataOut(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </div>
                        </LocalizationProvider>
                      </li>
                      <li className="list-group-item">
                        <b>N° Reserva</b> <a className="float-right"></a>
                        <input
                          type="text"
                          className="form-control"
                          id="reserva"
                          name="Numero da Reserva"
                          value={reserva}
                          onChange={(e) => setReserva(e.target.value)}

                        />
                      </li>
                      <li className="list-group-item">
                        <b>Observação</b> <a className="float-right"></a>
                        <textarea
                          className="form-control"
                          type="text"
                          id="observacao"
                          placeholder="Observação..."
                          onChange={(e) => setObservacao(e.target.value)}
                          defaultValue={observacao}
                          required="required"
                        />
                      </li>
                    </ul>
                    <div className="row">
                      <div className="col-sm-12">
                        <button type="submit" className="btn btn-success">
                          Gravar
                        </button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

            </div>

            <div className="col-md-9">

              <div className="card card-outline card-primary">
                <>

                  <div class="col-md-12">
                    <div className="card ">
                      <div className="card-header">
                        {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}
                      </div>

                      <form className="form-horizontal" >
                        <div className="col-md-4 col-sm-4">
                          <InputLabel>Hotel</InputLabel>
                          <Select
                            value={optionsHotel.filter(
                              (obj) => obj.value === selectedHotel
                            )}
                            onChange={handleChangeHoteis}
                            options={optionsHotel}
                            clearable={"true"}
                            searchable={"true"}
                            labelKey={"nome"}
                            valueKey={"id"}
                            noOptionsMessage={() =>
                              "Nenhum registro Encontrado"
                            }
                          />



                        </div>
                      </form>


                      {/* <FormControl>

                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select-label"
                            sx={{ m: 1, minWidth: 300 }}
                            
                            value={selectedHotel}
                            onChange={handleChangeHoteis}
                            spacing={3}
                          >
                            <MenuItem value="">Selecione</MenuItem>
                            {listHotel.filter((hot) => hot.id === selectedHotel)
                              .sort((a, b) => {
                                if (a.nome > b.nome) return 1;
                                if (a.nome < b.nome) return -1;
                                return 0;
                              })
                              .map((item) => (
                                <MenuItem value={item.id}>{item.nome}</MenuItem>
                              ))}
                          </Select>
                        </FormControl> */}

                    </div>
                  </div>



                  {data
                    .filter((hot) => hot.id === selectedHotel)
                    .map((item) => (
                      <div key={item.id}>
                        <div className="card-header">
                          <h3 className="card-title text-primary ">
                            <img src={img} alt="icon" className="mb-2 " />
                            <b>{item.nome}</b>
                          </h3>
                        </div>
                        <div className="form-group col-md-12 col-sm-6 d-flex align-items-stretch flex-column">
                          <div className="card-body">
                            <div className="row">
                              <div className=" col-md-6">
                                <InputLabel>
                                  Email{" "}
                                  <i className="fas fa-sm fa-envelope text-muted" />
                                </InputLabel>
                                <span>{item.email} </span>
                              </div>
                              <div className="col-md-6 ">
                                <InputLabel>
                                  Site <i className="fas fa-sm fa-link text-muted" />
                                </InputLabel>
                                <span>{item.site}</span>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-6 ">
                                <InputLabel>
                                  Endereço{" "}
                                  <i className="fas fa-sm fa-building text-muted" />
                                </InputLabel>
                                <span>
                                  {item.cidade}
                                  {" - "}
                                  {item.uf}
                                  {" - "}
                                  {item.pais}
                                </span>
                              </div>
                              <div className="col-6 ">
                                <InputLabel>
                                  Fone <i className="fas fa-sm fa-phone text-muted" />
                                </InputLabel>
                                <span>{item.contato}</span>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-6 ">
                                <Box
                                  component="fieldset"
                                  mb={3}
                                  borderColor="transparent"
                                >
                                  <InputLabel>Avaliação</InputLabel>
                                  <Rating
                                    name="read-only"
                                    value={item.avaliacao}
                                    readOnly
                                  />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              </div>


            </div>

          </div>
        </div>

        <Dialog open={openMsg}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            "& .MuiAlert-icon": {
              color: "	#0275d8",
            },
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>

      </section>

    </>
  );
};

export default FormHospedagem;
