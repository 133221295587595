import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { getControle } from "../../../services/auth";
import { addZeroes, dataAdd, delay, formatarDataBr } from "../../../utils/utils";
import TotaisComentarios from "./TotaisComentarios";
import { MsgSemSolic } from "./MsgSemSolic";
import TotaisComentariosNaoLidas from "./TotaisComentariosNaoLidas";
import LoadingSpinner from "../../../components/controles/LoadingSpinner";
import ProcessosAlerta from "./ProcessosAlerta";

const GridProcessoVinculado = () => {
  let history = useHistory();
  const { id } = useParams();
  const { demanda } = useParams();
  const [data, setData] = useState([]);
  const [novoData, setNovoData] = useState([]);
  const [dataHLimite, setDataHLimite] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  /**
   *
   * @param {*} event
   * @param {*} tipo "sigla da solicitacao"
   * @param {*} id "id da demanda"
   */
  const handleClick = (event, tipo, id, cortipo) => {
    if (tipo == "VIAG") {
      return history.push({
        pathname: "/viagens/",
      });
    } else {
      return history.push({
        pathname: "/processoItem/" + id,
      });
    }
  };

  useEffect(() => {
    const getVinculado = async () => {
      setIsLoading(true);
      await api
        .get("/demanda_processos/usuario/" + getControle())
        .then((response) => {
          setData(
            response.data.records.filter(
              (dema) =>
                dema.tipoStatus != "CONCLUIDO" &&
                dema.demanda.solicitante.codUsuario != getControle()
            )
          );
          
          setDataHLimite(
            response.data.records.filter(
              (dee) => dee.dataHoraLimite != "CONCLUIDO"
            )
          );
        });

      
    };

    getVinculado();
    setIsLoading(false);

  }, [getControle()]);

  const DataHoje = (_dataHoraLimite) => {
    if (_dataHoraLimite != null) {
      var data = new Date();
      var dia = String(data.getDate()).padStart(2, "0");
      var mes = String(data.getMonth() + 1).padStart(2, "0");
      var ano = data.getFullYear();
      let dataAtual = ano + "-" + mes + "-" + dia + " " + "00:00:00";

      const atual = dataAtual;
      const d1 = _dataHoraLimite;
      const d2 = atual;

      const diffInMs = new Date(d1) - new Date(d2);

      const diffInNovaData = diffInMs / (1000 * 60 * 60 * 24);

      let dataSelecionada = diffInNovaData.toFixed(0);

      if (dataSelecionada < 0) {
        return { backgroundColor: "#FF8C00" };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const GridDados = () => {
    return (
      <div className="card">
        <div className="card-header border-0">
          <h3 className="card-title text-muted">Processo Vinculado</h3>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
            >
              <i className="fas fa-minus" />
            </button>
          </div>
        </div>
        <div className="card-body table-responsive p-0">
          <table className="table table-striped table-valign-middle">
            <thead>
              <tr>
                <th style={{ width: "10%", textAlign: "center" }} scope="col">
                  Reg
                </th>
                <th style={{ width: "8%", textAlign: "center" }} scope="col">
                  Processo 
                </th>
                <th style={{ width: "16%", textAlign: "center" }} scope="col">
                  Solicitante
                </th>
                <th style={{ width: "16%", textAlign: "center" }} scope="col">
                  Titulo
                </th>
                <th style={{ width: "14%", textAlign: "center" }} scope="col">
                  Dt.Vinculo
                </th>
                <th style={{ width: "10%", textAlign: "center" }} scope="col">
                  Prazo
                </th>
                {/* <th style={{ width: "10%", textAlign: "center" }} scope="col">
                  MSG
                </th> */}
                <th style={{ width: "12%", textAlign: "center" }} scope="col">
                  Novas MSG
                </th>
                {/* <th style={{ textAlign: "center" }}>Sigla</th> */}

                <th style={{ width: "15%", textAlign: "center" }} scope="col">
                  Processos
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter((dema) => dema.tipoStatus != "INATIVO")
                .sort((a, b) => b.id - a.id)
                .map(
                  (
                    it 
                  ) => (
                    <tr style={{ fontSize: 12 }} key={it.id}>
                      <td className="text-primary text-center">
                        <b
                          type="button"
                          id={it.demanda.id}
                          onClick={(event) => {
                            handleClick(
                              event,
                              it.demanda.eventos.sigla,
                              it.demanda.id
                            );
                          }}
                        >
                          {addZeroes(it.demanda.id, 8)}
                          
                        </b>
                      </td>

                      <td className="text-center">{addZeroes(it.id, 8)}</td>

                      <td className="text-center">
                        {it.demanda.solicitante.nome}
                      </td>
                      <td className="text-center">
                        {it.demanda.titulo}
                      </td>

                                         
                      <td className="text-center">
                        {formatarDataBr(it.dataHora)}
                        
                      </td>

                      {(() => {
                        if (it.dataHoraLimite != null) {
                          return (
                            <td className="text-center">
                              {formatarDataBr(it.dataHoraLimite)}
                              {dataAdd(it.dataHoraLimite, it.tipoStatus,it.usuario.usuario.nome )}
                            </td>
                          );
                        } else {
                          return <td className="text-center">
                            {dataAdd(it.dataHoraLimite, it.tipoStatus,it.usuario.usuario.nome )}
                             </td>;
                        }
                      })()}

                      

                      <td style={{ fontSize: "15px" }} className="text-center">
                        <a
                          href="#"
                          className="text-primary"
                          onClick={(event) => {
                            handleClick(event, "", it.demanda.id);
                          }}
                        >
                          <TotaisComentariosNaoLidas
                            tipo={"PROCESSOS"}
                            parametros={it.id}
                            cortipo="red"
                          />
                        </a>
                      </td>

                      <td className="text-center">
                        <ProcessosAlerta demanda={it.demanda.id} />
                        
                      </td>

                     
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : data.length == 0 ? (
        <MsgSemSolic tipoProcess={true} />
      ) : (
        <GridDados />
      )}
    </>
  );
};

export default GridProcessoVinculado;
