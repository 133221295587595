export const toolbarOptions = (isFullScreen) => {
  return [
    {
      text: "Exportar PDF",
      tooltipText: "Exportar PDF",
      id: "PdfExport",
      prefixIcon: "e-pdfexport",
    },
    {
      text: isFullScreen ? "Fechar tela cheia" : "Abrir em tela cheia",
      tooltipText: "FullScreen",
      id: "FullScreen",
      prefixIcon: `<span className="bi bi-arrows-angle-expand"></span>`,
    },
  ];
};
