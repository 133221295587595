import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { LeituraRfidGrid } from "./Component/LeituraRfidGrid";
import { QueryClient, QueryClientProvider } from "react-query";

class LeituraRfid extends Component {
  handleNewItem = (e) => {
    this.props.history.push("/eventoItem/0");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Eventos",
      nomeNivelCrumb: "Página Inicial",
    };
  }

  render() {
    const queryClient = new QueryClient();
    return (
      <QueryClientProvider client={queryClient}>
        <LeituraRfidGrid />
      </QueryClientProvider>
    );
  }
}

export default LeituraRfid;
